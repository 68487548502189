import React, { useState, useEffect } from "react";
import { getAxiosInstance } from "../../redux/common";
import Loader from "../../components/loader";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  Box,
  InputLabel,
  Select,
  MenuItem,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import generateQrCodes from "./generateQrCodes";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  errorText: {
    color: theme.palette.error.main,
    marginTop: theme.spacing(1),
  },
  usedCode: {
    color: theme.palette.error.main,
    textDecoration: "line-through"
  }
}));

function Codes() {
  const [packages, setPackages] = useState();
  const [search, setSearch] = useState("");
  const [displayPasswordResetAlert, setDisplayPasswordResetAlert] = useState(
    false
  );
  const [printingHouses, setPrintingHouses] = useState();
  const [openGeneratePackageDialog, setOpenGeneratePackageDialog] = useState(
    false
  );
  const [openPrintingHouseDialog, setOpenPrintingHouseDialog] = useState(false);
  const [openCodesDialog, setOpenCodesDialog] = useState(false);

  //generate package dialog
  const [printingHouse, setPrintingHouse] = useState("");
  const [quantity, setQuantity] = useState("");
  const [printingHouseError, setPrintingHouseError] = useState("");
  const [quantityError, setQuantityError] = useState("");
  const [packageDialogError, setPackageDialogError] = useState("");

  //edit or add printing house dialog
  const [printingHouseEditMode, setPrintingHouseEditMode] = useState(false);
  const [printingHouseId, setPrintingHouseId] = useState("");
  const [printingHouseLabel, setPrintingHouseLabel] = useState("");
  const [printingHouseIdError, setPrintingHouseIdError] = useState("");
  const [printingHouseLabelError, setPrintingHouseLabelError] = useState("");
  const [printingHouseDialogError, setPrintingHouseDialogError] = useState("");

  //codes dialog
  const [pack, setPack] = useState(null);

  const history = useHistory();
  const classes = useStyles();

  const DateFormatter = (props) => {
    const string = props.value;

    var options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };
    const date =
      string[string.length - 1] !== "Z" ? string.concat("Z") : string;

    return new Date(date).toLocaleDateString(
      [new Intl.Locale("pl-PL")],
      options
    );
  };

  const findPrintingHouseLabel = (id) => {
    const ph = printingHouses.find((ph) => ph.id === id);
    return ph && ph.label;
  };

  const loadPackages = async () => {
    try {
      const packagesResult = await getAxiosInstance().get(
        "/generator/v1/owner/top"
      );

      setPackages(packagesResult.data);
    } catch (e) {
      if (e.response.status === 401 || e.response.status === 403) {
        history.push("/owner-panel/login");
      }
      setPackages(null);
    }
  };

  const loadPrintingHouses = async () => {
    try {
      const phResult = await getAxiosInstance().get("/printingHouse/v1/owner");
      setPrintingHouses(phResult.data);
    } catch (e) {
      if (e.response.status === 401 || e.response.status === 403) {
        history.push("/owner-panel/login");
      }
      setPrintingHouses(null);
    }
  };

  useEffect(() => {
    if (packages === undefined) {
      loadPackages();
    }
    if (printingHouses === undefined) {
      loadPrintingHouses();
    }
  });

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setDisplayPasswordResetAlert(false);
  };

  const handleClickOpenGeneratePackageDialog = () => {
    setOpenGeneratePackageDialog(true);
  };

  const handleCloseGeneratePackageDialog = () => {
    setOpenGeneratePackageDialog(false);
    setPrintingHouse("");
    setQuantity("");
  };

  const handleClickOpenPrintingHouseDialog = () => {
    setPrintingHouseId("");
    setPrintingHouseLabel("");
    setPrintingHouseEditMode(false);
    setOpenPrintingHouseDialog(true);
  };

  const handleClickOpenPrintingHouseEditingDialog = () => {
    const ph = printingHouses.find((ph) => ph.id === printingHouse);
    if (ph) {
      setPrintingHouseId(ph.id);
      setPrintingHouseLabel(ph.label);
      setPrintingHouseEditMode(true);
      setOpenPrintingHouseDialog(true);
    }
  };

  const handleClosePrintingHouseDialog = () => {
    setOpenPrintingHouseDialog(false);
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const handlePrintingHouseIdChange = (event) => {
    const id = (event.target.value.charAt(0) || "").toUpperCase();
    setPrintingHouseId(id);
    validatePrintingHouseDialog({ id });
  };

  const handlePrintingHouseLabelChange = (event) => {
    const label = event.target.value;
    setPrintingHouseLabel(label);
    validatePrintingHouseDialog({ label });
  };

  const validatePrintingHouseDialog = (ph) => {
    let valid = true;

    setPrintingHouseDialogError("");

    if (ph.id !== undefined) {
      if (ph.id === "") {
        setPrintingHouseIdError("Wartość wymagana");
        valid = false;
      } else {
        setPrintingHouseIdError("");
      }
    }

    if (ph.label !== undefined) {
      if (ph.label === "") {
        setPrintingHouseLabelError("Wartość wymagana");
        valid = false;
      } else {
        setPrintingHouseLabelError("");
      }
    }

    return valid;
  };

  const addOrEditPrintingHouse = async () => {
    let newPh = {
      id: printingHouseId,
      label: printingHouseLabel,
    };
    if (!validatePrintingHouseDialog(newPh)) return;

    try {
      if (printingHouseEditMode) {
        await getAxiosInstance().put("/printingHouse/v1/owner", newPh);
        const phIndex = printingHouses.findIndex(
          (ph) => ph.id === printingHouseId
        );
        if (phIndex >= 0) {
          const newPrintingHouses = [...printingHouses];
          newPrintingHouses[phIndex] = newPh;
          setPrintingHouses(newPrintingHouses);
        }
      } else {
        await getAxiosInstance().post("/printingHouse/v1/owner", newPh);
        const newPrintingHouses = [...printingHouses, newPh];
        setPrintingHouses(newPrintingHouses);
        setPrintingHouse(newPh.id);
      }
      setOpenPrintingHouseDialog(false);
    } catch (e) {
      console.error(e.response);
      setPrintingHouseDialogError(
        "Coś poszło nie tak. Spróbuj ponownie później."
      );
    }
  };

  const handlePrintingHouseChange = (event) => {
    const printingHouse = event.target.value;
    setPrintingHouse(printingHouse);
    validateGeneratePackageDialog({ printingHouse });
  };

  const handleQuantityChange = (event) => {
    let quantity = event.target.value.replace(/\D/g, "");
    quantity = parseInt(quantity) || quantity;
    setQuantity(quantity);
    validateGeneratePackageDialog({ quantity });
  };

  const validateGeneratePackageDialog = (pack) => {
    let valid = true;

    setPackageDialogError("");

    if (pack.quantity !== undefined) {
      if (pack.quantity === "") {
        setQuantityError("Wartość wymagana");
        valid = false;
      } else if (pack.quantity <= 0 || pack.quantity > 1000) {
        setQuantityError("Ilość kodów musi mieścić się w zakresie 1-1000");
        valid = false;
      } else {
        setQuantityError("");
      }
    }

    if (pack.printingHouse !== undefined) {
      if (pack.printingHouse === "") {
        setPrintingHouseError("Wartość wymagana");
        valid = false;
      } else {
        setPrintingHouseError("");
      }
    }

    return valid;
  };

  const combineCode = (pack, code) => {
    return (
      "" +
      pack.printingHouse +
      pack.year +
      pack.month +
      code
    ).toUpperCase();
  };

  const preparePackageForDialog = (pack) => {
    const usedCodes = [];
    const availableCodes = [];
    for (const code of pack.codes) {
      const isUsed = pack.usedCodes ? pack.usedCodes.findIndex(c => code === c) !== -1 : false;
      const listToAdd = isUsed ? usedCodes : availableCodes;
      listToAdd.push({fullCode: combineCode(pack, code), isUsed})
    }
    const newPackage = {
      ...pack,
      formattedCodes: [...availableCodes, ...usedCodes]
    }
    setPack(newPackage);
  }

  const generatePackage = async () => {
    const params = {
      printingHouse: printingHouse,
      quantity: quantity,
    };

    if (!validateGeneratePackageDialog(params)) return;

    try {
      const packageResult = await getAxiosInstance().post(
        `/generator/v1/owner/generate`,
        {},
        {
          params: params,
        }
      );
      const newPackage = packageResult.data;
      let stripedPackage = { ...newPackage };
      delete stripedPackage.codes;

      const newPackages = [stripedPackage, ...packages];
      setPackages(newPackages);
      setOpenGeneratePackageDialog(false);

      preparePackageForDialog(newPackage);
      setOpenCodesDialog(true);
    } catch (e) {
      console.error(e.response);
      setPackageDialogError("Coś poszło nie tak. Spróbuj ponownie później.");
    }
  };

  const handleClickOpenCodesDialog = async (id) => {
    const p = (
      await getAxiosInstance().get("/generator/v1/owner/package", {
        params: {
          id: id,
        },
      })
    ).data;

    preparePackageForDialog(p);
    setOpenCodesDialog(true);
  };

  const handleCloseCodesDialog = () => {
    setOpenCodesDialog(false);
    setPack(null);
  };

  const downloadQRCodesPDF = () => {
    generateQrCodes(pack.formattedCodes.map(code => code.fullCode));
  }

  const downloadCSV = () => {
    const csvContent = pack.formattedCodes
      .map((code) => `"${code.fullCode}"`)
      .join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    var link = document.createElement("a");
    var url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", pack.id + ".csv");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    setOpenCodesDialog(false);
    setPack(null);
  };

  if (packages && printingHouses) {
    return (
      <>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={displayPasswordResetAlert}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="info">
            Wystąpił błąd!
          </Alert>
        </Snackbar>
        <Box>
          <Button
            color="primary"
            onClick={handleClickOpenGeneratePackageDialog}
          >
            Generuj paczkę kodów
          </Button>
        </Box>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Data wygenerowania</TableCell>
                <TableCell align="right">Drukarnia</TableCell>
                <TableCell align="right">Ilość kodów</TableCell>
                <TableCell align="right">
                  <TextField
                    fullWidth
                    id="standard-basic"
                    label="Szukaj"
                    value={search}
                    onChange={handleSearchChange}
                  />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {packages.map((pack) => {
                const searchLowerCased = search ? search.toLowerCase() : null;
                const phName = findPrintingHouseLabel(pack.printingHouse);
                if (
                  !searchLowerCased ||
                  (phName && phName.toLowerCase().includes(searchLowerCased))
                ) {
                  return (
                    <TableRow key={pack.id}>
                      <TableCell component="th" scope="row">
                        <DateFormatter value={pack.ts} />
                      </TableCell>
                      <TableCell align="right">
                        {phName || "<nieznana>"}
                      </TableCell>
                      <TableCell>{pack.quantity}</TableCell>
                      <TableCell align="right">
                        <Button
                          color="primary"
                          onClick={() => handleClickOpenCodesDialog(pack.id)}
                        >
                          Wyświetl kody
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                } else {
                  return null;
                }
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Dialog
          open={openGeneratePackageDialog}
          onClose={handleCloseGeneratePackageDialog}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            Generuj nową paczkę kodów
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Wpisz liczbę kodów jakie chcesz wygenerować dla wybranej drukarni.
              {packageDialogError && (
                <Box>
                  <Typography className={classes.errorText}>
                    {packageDialogError}
                  </Typography>
                </Box>
              )}
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              name="quantity"
              label="Ilość kodów"
              required
              fullWidth
              value={quantity}
              error={!!quantityError}
              helperText={quantityError}
              onChange={handleQuantityChange}
            />
            <InputLabel id="printing-house-label">Drukarnia</InputLabel>
            <Select
              labelId="printing-house-label"
              name="printing-house"
              required
              fullWidth
              value={printingHouse}
              error={!!printingHouseError}
              helperText={printingHouseError}
              onChange={handlePrintingHouseChange}
            >
              {printingHouses.map((ph, index) => {
                return (
                  <MenuItem key={index} value={ph.id}>
                    {ph.label}
                  </MenuItem>
                );
              })}
            </Select>
            <Box>
              <Button
                size="small"
                color="primary"
                onClick={handleClickOpenPrintingHouseDialog}
              >
                dodaj drukarnię
              </Button>
              <Button
                size="small"
                disabled={!printingHouse}
                onClick={handleClickOpenPrintingHouseEditingDialog}
              >
                edytuj drukarnię
              </Button>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseGeneratePackageDialog}>Anuluj</Button>
            <Button onClick={generatePackage} color="primary">
              Generuj
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openPrintingHouseDialog}
          onClose={handleClosePrintingHouseDialog}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            {!printingHouseEditMode && "Dodaj nową drukarnie"}
            {printingHouseEditMode && "Edytuj drukarnie"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {!printingHouseEditMode &&
                "Wpisz jednoliterowy kod i nazwę drukarni."}
              {printingHouseEditMode &&
                "Wpisz nową nazwę drukarni. UWAGA! Nowa nazwa drukarni będzie zmieniona również w danych historycznych."}
              {printingHouseDialogError && (
                <Box>
                  <Typography className={classes.errorText}>
                    {printingHouseDialogError}
                  </Typography>
                </Box>
              )}
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="id"
              label="Kod"
              disabled={printingHouseEditMode}
              value={printingHouseId}
              onChange={handlePrintingHouseIdChange}
              error={!!printingHouseIdError}
              helperText={printingHouseIdError}
              required
              fullWidth
            />
            <TextField
              margin="dense"
              id="name"
              label="Nazwa"
              value={printingHouseLabel}
              onChange={handlePrintingHouseLabelChange}
              error={!!printingHouseLabelError}
              helperText={printingHouseLabelError}
              required
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClosePrintingHouseDialog}>Anuluj</Button>
            <Button onClick={addOrEditPrintingHouse} color="primary">
              {!printingHouseEditMode && "Dodaj"}
              {printingHouseEditMode && "Edytuj"}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openCodesDialog}
          onClose={handleCloseCodesDialog}
          scroll="paper"
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Kody w paczce:</DialogTitle>
          <DialogContent>
            <List dense>
              {pack &&
                pack.formattedCodes.map((code) => {
                  return (
                    <ListItem key={code.fullCode}>
                      <ListItemText
                        primary={
                          <span className={code.isUsed ? classes.usedCode : null}>
                            {code.fullCode}
                          </span>
                        }
                        // secondary={code.isUsed ? "Wykorzystany" : "Wolny"}
                      />
                    </ListItem>
                  );
                })}
            </List>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseCodesDialog}>Anuluj</Button>
            <Button onClick={downloadQRCodesPDF} color="primary">
              Pobierz kody QR
            </Button>
            <Button onClick={downloadCSV} color="primary">
              Pobierz CSV
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  } else {
    return <Loader loading={true} text="Wczytywanie danych" />;
  }
}

export default Codes;
