import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  alertAdd,
  createFilledFormData,
  fetchFilledFormsExtended,
  fetchForm,
  fetchFormData,
  fetchForms,
} from "../../redux/actions";
import FormDetails from "../../components/form-details";
import Can from "../../components/can";
import format from "date-fns/format";
import { Redirect } from "react-router-dom";

const FilledFormDetails = ({
  match,
  orgForms,
  fetchForm,
  fetchFormData,
  history,
  location,
}) => {
  const dispatch = useDispatch();
  const [form, setForm] = useState();
  const [rows, setRows] = useState([]);
  const formId = parseInt(match.params.testId);
  const filledFormsExtended = useSelector((s) => s.filledFormsExtended[formId]);

  const formData = useSelector((s) => s.formData);

  const [fetching, setFetching] = useState();
  const [formTemplate, setFormTemplate] = useState();

  useEffect(() => {
    if (!false) {
      dispatch(fetchFilledFormsExtended(formId));
    }
  }, [formId]);

  useEffect(() => {
    if (fetching === undefined) {
      if (formId) {
        setFetching(true);
        fetchForm(formId).then((fff) => {
          setFetching(false);
        });
      }
    }
  }, [orgForms, fetching, match, fetchForm]);

  useEffect(() => {
    const formId = parseInt(match.params.testId);
    const f = orgForms.find(({ id }) => id === formId);
    if (f) {
      setForm(f);
    }
  }, [orgForms, match]);

  useEffect(() => {
    if (form && !formTemplate && form.layoutId) {
      fetchFormData(form.layoutId);
      setFormTemplate(true);
    }
  }, [form, formTemplate, fetchFormData]);

  useEffect(() => {
    const loToRows = () => {
      return formData.layoutElementObject.children.map((c) => {
        return c.children.map((c) => {
          return c;
        });
      });
    };
    if (formData) {
      setRows(loToRows());
    }
  }, [formData]);

  const data = (filledFormsExtended || []).map(({ items, ...row }) => {
    const baseValues = {
      createdBy: {
        id: "createdBy",
        label: "createdBy",
        value: row.createdByFullName || row.createdBy,
      },
      createdAt: {
        id: "createdAt",
        label: "createdAt",
        value: format(new Date(row.createdAt), "yyyy-MM-dd HH:mm"),
      },
    };

    const values = {
      ...row,
      values: {
        ...baseValues,
        ...items.reduce((obj, current) => {
          return {
            ...obj,
            [current.id]: current,
          };
        }, {}),
      },
    };

    return values;
  });

  const page = () => (
    <div className="container is-fluid">
      <div className="title">Formularz "{form && form.name}"</div>
      {form && form.description && (
        <>
          <b>Opis</b>
          <br />
          <small>{form.description}</small>
        </>
      )}
      <hr />

      {data &&
        data.map(({ values }) => {
          location.values = values;
        })}
      {rows.length && (
        <FormDetails
          rows={rows}
          values={location.values}
          match={match}
          form={form}
        />
      )}

      <button className="button" onClick={history.goBack}>
        Cofnij
      </button>
      <hr />
    </div>
  );

  const redirect = () => <Redirect to="/login" />;

  return <Can permission="test-fill-details:page" ok={page} not={redirect} />;
};

const mapStateToProps = (state) => ({
  organization: state.organization,
  orgForms: state.orgForms,
  formData: state.formData,
});

const mapDispatchToProps = (dispatch) => ({
  fetchForms: () => dispatch(fetchForms()),
  fetchForm: (id) => dispatch(fetchForm(id)),
  fetchFormData: (id) => dispatch(fetchFormData(id)),
  createFilledFormData: ({ formId, values }) =>
    dispatch(
      createFilledFormData({
        formId,
        values,
      })
    ),
  alertAdd: (payload) => dispatch(alertAdd(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FilledFormDetails);
