import React, { useEffect, useState } from "react";
import { getAxiosInstance } from "../../redux/common";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import Can from "../../components/can";
import AppBarTitle from "../../components/app-bar-title";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Themed from "./themed";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import ContactMailIcon from "@material-ui/icons/ContactMail";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: 24,
    "& ul": {
      padding: theme.spacing(2),
    },
    "& li": {
      [theme.breakpoints.up("sm")]: {
        borderRadius: 12,
      },
      backgroundColor: "white",
      boxShadow:
        "0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2)",
      padding: 24,
      marginTop: 10,
    },
  },
  download: {
    width: "100%",
    textAlign: "center",
  },
  header: (props) => ({
    backgroundColor: props.headerColor
      ? props.headerColor
      : theme.palette.primary.main,
    [theme.breakpoints.up("sm")]: {
      borderRadius: "0 0 10px 10px",
    },
    color: "white",
    padding: 30,
    textAlign: "center",
    letterSpacing: 0,
  }),
}));

const Result = () => {
  const [result, setResult] = useState();
  const { resultId } = useParams();
  const questions =
    result &&
    result.template.layoutElementObject.children.map(
      (child) => child.children[0]
    );
  const answers = new Map();
  const isSmellTest = result?.form?.testType == "SMELL_TEST";
  const isResultPositive =
    questions &&
    (questions.length === result.formData.totalWeight ||
      (isSmellTest && questions.length - 1 === result.formData.totalWeight));
  const theme = useTheme();
  const classes = useStyles({
    headerColor: isResultPositive
      ? theme.palette.checkIcon.primary
      : theme.palette.secondary.main,
  });
  const history = useHistory();

  const loadResult = async () => {
    try {
      const formData = (
        await getAxiosInstance().get(
          `/api/form_data/by_id_extended/${resultId}`
        )
      ).data;
      const form = (
        await getAxiosInstance().get(`/api/forms/${formData.formId}`)
      ).data;
      const template = (
        await getAxiosInstance().get(`/api/form_template/${form.layoutId}`)
      ).data;

      const testResult = { formData, form, template };
      setResult(testResult);
    } catch (e) {
      console.error("sets error", e);

      setResult(null);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (result === undefined) {
    loadResult();
  }

  if (!result) {
    return (
      <>
        <AppBarTitle value="Wynik testu... (wczytywanie)" />
        <div>Loading...</div>
      </>
    );
  }

  result.formData.items.forEach((answer) =>
    answers.set(answer.id.toLowerCase(), answer)
  );

  return (
    <Box className={classes.root}>
      <AppBarTitle
        value={`Wynik testu ${
          result.form.testType === "TASTE_TEST" ? "smaku" : "węchu"
        }`}
      />
      <h1
        aria-hidden="false"
        aria-label={`Wynik testu ${
          result.form.testType === "TASTE_TEST" ? "smaku" : "węchu"
        }`}
        style={{ display: "none" }}
      >
        {`Wynik testu ${
          result.form.testType === "TASTE_TEST" ? "smaku" : "węchu"
        }`}
      </h1>
      <Grid container justify="center">
        <Grid item xs={12} sm={8}>
          <Box className={classes.header}>
            <Typography
              variant="h6"
              style={{
                color: theme.palette.background.default,
                fontSize: theme.typography.text2,
              }}
            >
              Twój wynik testu{" "}
              {result.form.testType === "TASTE_TEST" ? "smaku" : "węchu"} jest:
            </Typography>
            <Typography
              variant="h5"
              style={{
                color: theme.palette.background.default,
                fontSize: theme.typography.header,
              }}
            >
              {isResultPositive ? "PRAWIDŁOWY" : "NIEPRAWIDŁOWY"}
            </Typography>
          </Box>
          <Grid container justify="center">
            <Grid item xs={12} sm={8}>
              <Box px="24px" mt={2}>
                <Grid container justify="space-between" alignItems="center">
                  <Grid item xs={1} />
                  <Grid
                    item
                    xs={5}
                    style={{ color: theme.palette.text.primary2 }}
                  >
                    Prawidłowy
                  </Grid>
                  <Grid
                    item
                    xs={5}
                    style={{ color: theme.palette.text.primary2 }}
                  >
                    Wybrany
                  </Grid>
                  <Grid item xs={1} />
                </Grid>
              </Box>
              <ul>
                {questions.map((question, index) => {
                  const answer = answers.get(question.id.toLowerCase());
                  const correctAnswer = question.configuration.dictionary.dictionaryValues.find(
                    (value) => value.weight === 1
                  );

                  return (
                    <li
                      key={index}
                      style={{ background: theme.palette.background.default }}
                    >
                      <Grid
                        container
                        justify="space-between"
                        alignItems="center"
                      >
                        <Grid
                          item
                          xs={1}
                          style={{ color: theme.palette.text.primary2 }}
                        >
                          {index + 1}.
                        </Grid>
                        <Grid item xs={5}>
                          <b style={{ color: theme.palette.text.primary2 }}>
                            {correctAnswer
                              ? correctAnswer.stringValue
                              : "<brak>"}
                          </b>
                        </Grid>
                        <Grid item xs={5}>
                          <b style={{ color: theme.palette.text.primary2 }}>
                            {answer.value}
                          </b>
                        </Grid>
                        <Grid item xs={1}>
                          {answer.weight === 1 ? (
                            <CheckCircleIcon
                              role="img"
                              aria-hidden="false"
                              alt=""
                              title=""
                              style={{
                                display: "block",
                                color: theme.palette.checkIcon.primary,
                              }}
                            />
                          ) : (
                            <CancelIcon
                              role="img"
                              aria-hidden="false"
                              alt=""
                              title=""
                              style={{
                                display: "block",
                                color: theme.palette.error.main,
                              }}
                            />
                          )}
                        </Grid>
                      </Grid>
                    </li>
                  );
                })}
              </ul>
              <Box p={1} display="flex" justifyContent="center">
                <Button
                  color="primary"
                  href={`/api/form_data/${result.formData.id}/pdf_report`}
                >
                  Pobierz wynik&nbsp;
                  <PictureAsPdfIcon />
                </Button>
                <Button
                  color="primary"
                  href={`/api/form_data/${result.formData.id}/pdf_report?detailed=true`}
                >
                  Pobierz wynik szczegółowy&nbsp;
                  <PictureAsPdfIcon />
                </Button>
              </Box>
              {!isResultPositive && (
                <Box p={1} display="flex" justifyContent="center">
                  <Button
                    color="primary"
                    href={`https://testsmakuiwechu.pl/home/test-krok-po-kroku-wyniki/`}
                    target="_blank"
                  >
                    Skontaktuj się ze specjalistą&nbsp;
                    <ContactMailIcon />
                  </Button>
                </Box>
              )}
              <Box p={2}>
                <Button
                  onClick={() => history.goBack()}
                  color="primary"
                  variant="contained"
                  fullWidth
                >
                  Wróć do poprzedniego ekranu
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

const ThemedResult = () => (
  <Themed>
    <Result />
  </Themed>
);
const RedirectToLogin = () => <Redirect to="/login" />;
const ResultWithPermission = () => (
  <Can permission="sat-form:page" ok={ThemedResult} not={RedirectToLogin} />
);

export default ResultWithPermission;
