import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Alert from "@material-ui/lab/Alert";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { NavLink } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { alertAdd, sendContact } from "../../redux/actions";
import { useDispatch } from "react-redux";
import s from "./index.module.css";
import Can from "../../components/can";
import { Redirect } from "react-router-dom";
import AppBarTitle from "../../components/app-bar-title";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  paragraph: {
    color: theme.palette.text.alternative,
  },
  text: {
    color: theme.palette.text.alternative2,
  },
  contactLabel: {
    fontStyle: "normal",
    fontVariant: "normal",
    fontWeight: "700",
    fontSize: theme.typography.header,
    lineHeight: "28px",
    letterSpacing: "0.72px",
    color: theme.palette.text.alternative2,
  },
}));
const ContactLoggedUser = ({ sendContact }) => {
  const [name, setName] = useState("");
  const [mail, setMail] = useState("");
  const [messageText, setMessageText] = useState("");
  const [recaptchaValid, setRecaptchaValid] = useState(false);
  const [emailValid, setEmailValid] = useState(false);
  const [formIsValid, setFormIsValid] = useState(false);
  const [messageSent, setMessageSent] = useState(false);
  const [alert, setAlert] = useState(false);

  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();

  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const onChange = (value) => {
    setRecaptchaValid(!!value);
  };

  useEffect(() => {
    if (mail) {
      let polishChars1 = String(mail)
        .toLowerCase()
        .normalize("NFD")
        .search(/[\u0300-\u036f]/g);
      let polishChars2 = String(mail)
        .toLowerCase()
        .normalize("NFD")
        .search(/\u0142/g);

      setEmailValid(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          String(mail).toLowerCase()
        ) &&
          (polishChars2 !== 0
            ? polishChars1 + polishChars2 < 0
            : polishChars1 + polishChars2 < -1)
      );
    }
  }, [mail]);

  useEffect(() => {
    setFormIsValid(name && mail && messageText && recaptchaValid);
  }, [name, mail, messageText, recaptchaValid]);

  useEffect(() => {
    if (alert) {
      setTimeout(() => setAlert(false), 3000);
    }
  }, [alert]);

  const contact = async (e) => {
    e.preventDefault();

    const contactOk = await sendContact({
      name,
      mail,
      messageText,
    });

    if (contactOk) {
      // dispatch(
      //   alertAdd({
      //     text:
      //       "Dziękujemy za skorzystanie z formularza kontaktowego. Odpowiadamy w ciągu 24 godzin",
      //   })
      // );
      history.push("/sent");
    } else {
      setAlert(true);
    }
  };

  const page = () => (
    <Grid
      container
      spacing={2}
      xs={12}
      sm={10}
      xl={8}
      style={{
        padding: "18px",
        margin: "auto",
        display: "flex",
      }}
      direction="column"
    >
      <AppBarTitle value="Kontakt" />
      <h1
        aria-hidden="false"
        aria-label="Formularz kontaktowy"
        style={{ display: "none" }}
      >
        Formularz kontaktowy
      </h1>
      {alert && (
        <Grid item xs={12}>
          <Alert severity="error">Nie udało się wysłać wiadomości</Alert>
        </Grid>
      )}
      {/* <Grid item xs={10} xl={8}> */}
      <Grid item xs={12}>
        <h1
          aria-hidden="false"
          aria-label="Formularz kontaktowy"
          style={{ display: "none" }}
        >
          Formularz kontaktowy
        </h1>
        <label className={classes.contactLabel}>
          Formularz kontaktowy {<span className={classes.paragraph}>*</span>}
        </label>
      </Grid>
      <Grid item xs={12} sm={10} md={6}>
        <TextField
          label="Imię i nazwisko / nazwa organizacji"
          variant="outlined"
          value={name}
          fullWidth
          required
          // InputLabelProps={{
          //   shrink: true,
          // }}
          onChange={(e) => setName(e.target.value)}
          inputProps={{ "aria-label": "Imię i nazwisko / nazwa organizacji" }}
        />
      </Grid>
      <Grid item xs={12} sm={10} md={6}>
        <TextField
          label="E-mail"
          variant="outlined"
          value={mail}
          error={mail && !emailValid}
          helperText={
            mail &&
            !emailValid &&
            "Nieprawidłowy format wprowadzonego adresu email"
          }
          required
          fullWidth
          onChange={(e) => setMail(e.target.value)}
          inputProps={{ "aria-label": "E-mail" }}
        />
      </Grid>
      <Grid item xs={12} sm={10} md={6}>
        <TextField
          label="Treść wiadomości"
          variant="outlined"
          value={messageText}
          fullWidth
          multiline
          required
          rows={10}
          rowsMax={10}
          onChange={(e) => setMessageText(e.target.value)}
          inputProps={{ "aria-label": "Treść wiadomości" }}
        />
      </Grid>
      <Grid item xs={12}>
        <span style={{ float: "left" }} className={classes.text}>
          <span className={classes.paragraph}>*</span> Wszystkie pola
          obowiązkowe
        </span>
      </Grid>
      <Grid item xs={12}>
        <ReCAPTCHA
          size={!smallScreen ? "neutral" : "compact"}
          // size={"compact"}
          sitekey="6Lev2u4UAAAAAE0emXI5hh5W-VfQWagdCiqBpXRu"
          onChange={onChange}
        />
      </Grid>
      <Grid item xs={12} style={{ display: "flex", alignItems: "center" }}>
        <Button
          type="submit"
          size="small"
          variant="contained"
          color="primary"
          style={{ height: 40, width: 150, borderRadius: 8 }}
          disabled={!formIsValid || !emailValid}
          onClick={contact}
        >
          Wyślij
        </Button>
      </Grid>
    </Grid>
  );

  const redirect = () => <Redirect to="/login" />;

  return <Can permission="contact-logged-user:page" ok={page} not={redirect} />;
};

const mapStateToProps = (state) => ({
  loggingIn: state.loggingIn,
  loggedIn: state.loggedIn,
  errorMessage: state.errorMessage,
});

const mapDispatchToProps = (dispatch) => ({
  sendContact: (data) => dispatch(sendContact(data)),
  // alertAdd: (payload) => dispatch(alertAdd(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactLoggedUser);
