import React, { useEffect } from "react";

import RowControl from "../form-controls/row-control";

const FormDetails = ({ rows, values }) => {
  return (
    <div className="columns">
      <div className="column">
        <div>
          {rows.map((row, rowIndex) => {
            let rowValues = [];
            row.map(({ id }) => {
              if (!values[id.toUpperCase()]) rowValues.push({ id, value: " " });
              else rowValues.push(values[id.toUpperCase()]);
            });
            return (
              <RowControl
                key={rowIndex}
                items={row}
                rowValues={rowValues}
                filledFormMode
                disabled
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default FormDetails;
