import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Grid, Box, CardMedia, Typography, Button } from "@material-ui/core";
import styled from "styled-components";
// import image1 from "../../assets/magnusson-logo2.png";
import image1 from "../../assets/magnusson_ost.svg";
import image11 from "../../assets/magnusson_logo-3.jpg";
import image22 from "../../assets/ifps_resized.jpg";
// import image9 from "../../assets/ifps.png";
import image9 from "../../assets/ifps2.svg";
import image2 from "../../assets/ifps.jpg";
import image3 from "../../assets/fe.png";
import image333 from "../../assets/fe.svg";
import image33 from "../../assets/fe_resized.png";
import image4 from "../../assets/rp.png";
import image444 from "../../assets/rp.svg";
import image44 from "../../assets/rp_resized.png";
import image5 from "../../assets/ncbr.png";
import image555 from "../../assets/ncbr.svg";
import image55 from "../../assets/ncbr_resized.png";
import image6 from "../../assets/ue.png";
import image66 from "../../assets/ue_resized.png";
import image666 from "../../assets/ue.svg";
import background from "../../assets/background.jpg";
// import flowImage from "../../assets/flow.jpg";
import flowImage from "../../assets/flow.svg";
import flowMobileImage from "../../assets/flowMobile.jpg";
import movie_redirect from "../../assets/movie_redirect.png";
import ReactPlayer from "react-player";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import IconButton from "@material-ui/core/IconButton";
import Slide from "@material-ui/core/Slide";
import s from "./index.module.css";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import Link from "@material-ui/core/Link";
import { useTheme } from "@material-ui/core/styles";

const MuiNavLink = withStyles((theme) => ({
  root: {
    color: theme.palette.text.primary,
    "&:hover": {
      color: theme.palette.text.primary,
    },
  },
}))(Link);

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 250,
    // minHeight: 250,
    borderRadius: "0",
    boxShadow: "none",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    flex: "1",
    borderTop: `0.5px solid ${theme.palette.hr.primary}`,
  },
  media: {
    height: 140,
  },
  gridCard: {
    marginTop: "12px",
    display: "flex",
    flexDirection: "row",
    "@media (max-width: 1000px)": {
      flexDirection: "column",
      alignItems: "center",
    },
    flexWrap: "nowrap",
  },
  label: {
    letterSpacing: "0.3px",
    fontWeight: "300",
    fontSize: "60px",
    color: "#303030",
    "@media (max-width: 900px)": {
      fontSize: "1.25rem",
      letterSpacing: "0.27px",
    },
  },
  labelEmail: {
    letterSpacing: "0.3px",
    fontWeight: "400",
    fontSize: "60px",
    color: "#282829",
    "@media (max-width: 900px)": {
      fontSize: "0.9rem",
    },
  },
  bottomGrid: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    background: "#FFFFFF",
    // boxShadow: "0px -2px 2px rgba(0, 0, 0, 0.25)",
    position: "sticky",
    bottom: "0rem",
    [theme.breakpoints.down("sm")]: {
      bottom: "0.1rem",
    },
    zIndex: "100",
    background: theme.drawer.backgroundColor,
  },
  bottomGridLabel: {
    textAlign: "left",
    [theme.breakpoints.down("lg")]: {
      textAlign: "center",
    },
  },
  customSpan: {
    color: theme.palette.text.alternative,
  },
}));

const FeatureBox = styled(({ image, number, text, subtext, height }) => (
  <Box display="block" color="#fff" textAlign="center" m={1}>
    <CardMedia>
      <img src={image} style={{ height: height, marginRight: "0px" }} />
    </CardMedia>

    <Box fontWeight="bold">
      {number && (
        <Box display="inline" as="span" color="orange">
          {number}.&nbsp;
        </Box>
      )}
      {text}
    </Box>
    <Box fontSize={10}>{subtext}</Box>
  </Box>
))`
  width: 300px;
  height: 300px;
`;

const StartPage = () => {
  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const xSmallScreen = useMediaQuery((theme) => theme.breakpoints.down(360));
  const mediumScreen = useMediaQuery((theme) => theme.breakpoints.down(950));
  const imageDisplay = useMediaQuery((theme) => theme.breakpoints.down(1920));
  const largeImageDisplay = useMediaQuery((theme) =>
    theme.breakpoints.up(2200)
  );
  const theme = useTheme();
  const classes = useStyles();

  const details = [
    {
      title: () => (
        <MuiNavLink
          target="_blank"
          href="https://testsmakuiwechu.pl/home/test-wechu-i-smaku-idea/"
        >
          TEST <span className={classes.customSpan}>SMAKU I WĘCHU</span>
        </MuiNavLink>
      ),
      text:
        "To intuicyjne rozwiązanie, pozwalające na samodzielne przeprowadzanie badań przesiewowych smaku i węchu, bez konieczności wsparcia wykwalifikowanego personelu medycznego.",
      url: "https://testsmakuiwechu.pl/home/test-wechu-i-smaku-idea/",
    },
    {
      title: () => (
        <MuiNavLink
          target="_blank"
          href="https://testsmakuiwechu.pl/home/o-smaku-i-wechu/"
        >
          O SMAKU I WĘCHU
        </MuiNavLink>
      ),
      text:
        "Zaburzenia smaku dotykają około 5% populacji. Bardzo często towarzyszą problemom z węchem (nawet 85% przypadków).",
      url: "https://testsmakuiwechu.pl/home/o-smaku-i-wechu/",
    },
    {
      title: () => (
        <MuiNavLink
          target="_blank"
          href="https://testsmakuiwechu.pl/home/test-a-covid-19/"
        >
          TEST A COVID 19
        </MuiNavLink>
      ),
      text:
        "Obecna sytuacja epidemiologiczna, związana z rozprzestrzenianiem się wirusa SARS-CoV-2, powoduje konieczność poszukiwania nowych narzędzi, usprawniających diagnostykę oraz proces leczenia COVID-19.",
      url: "https://testsmakuiwechu.pl/home/test-a-covid-19/",
    },
  ];

  return (
    <Grid
      container
      style={{
        height: "100%",
        width: smallScreen && "99%",
        justifyContent: "center",
        alignContent: "flex-start",
      }}
    >
      <Grid
        item
        xs={12}
        sm={11}
        xl={10}
        container
        style={{
          padding: "50px 12px 36px 0px",
          display: "flex",
          alignItems: "center",
          flexDirection: smallScreen ? "column" : "row",
        }}
      >
        <h1
          aria-hidden="false"
          aria-label="Strona startowa"
          style={{ display: "none" }}
        >
          Strona startowa
        </h1>
        <Grid item>
          <span style={{ fontWeight: "600" }}>OPRACOWANIE PROJEKTU:</span>
        </Grid>
        <Grid item>
          <img
            alt="Logotyp GNP Magnusson"
            src={image1}
            style={{
              marginTop: smallScreen && "10px",
              height: smallScreen ? "35px" : "50px",
              marginLeft: !smallScreen && "25px",
              marginRight: !smallScreen && "25px",
            }}
          ></img>
        </Grid>
        <Grid item>
          <img
            alt="Logotyp Instytut Fizjologii i Patologii Słuchu"
            src={image9}
            style={{
              height: smallScreen ? "35px" : "50px",
              // marginLeft: !smallScreen && "25px",
            }}
          ></img>
        </Grid>
      </Grid>
      {!mediumScreen && (
        <>
          <Grid
            item
            xs={12}
            sm={11}
            xl={10}
            // style={{
            //   backgroundImage: `url(${flowImageMedium})`,
            //   backgroundSize: "cover",
            //   backgroundPosition: "center",
            //   backgroundRepeat: "no-repeat",
            // }}
          >
            {/* <img src={largeImageDisplay ? flowImageLarge : flowImageMedium}></img>
            <img src={flowImageMedium}></img> */}
            <div style={{ position: "relative" }}>
              <a href="/start-details">
                <img
                  src={flowImage}
                  alt="Przekierowanie do opisu działania systemu"
                ></img>
              </a>
              <div style={{ position: "absolute", bottom: "15px" }}>
                <span
                  style={{
                    // color: "#FF7A06",
                    color: theme.palette.text.alternative,
                    fontSize: theme.typography.mainTitle,
                    letterSpacing: "0.25px",
                    // paddingBottom: "50px",
                  }}
                >
                  JAK TO DZIAŁA {<br></br>}KROK PO KROKU
                </span>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={11} xl={10}>
            <Typography
              style={{
                fontSize: theme.typography.header,
              }}
              gutterBottom
              variant="h5"
              // style={{ fontSize: props.theme.typography.title }}
            >
              <a
                style={{
                  cursor: "pointer",
                  color: theme.palette.text.alternative2,
                  display: "flex",
                  alignItems: "center",
                  width: "209px",
                }}
                target="_blank"
                href="https://testsmakuiwechu.pl/home/test-krok-po-kroku-o-tescie/"
              >
                <span>ZOBACZ FILM</span>
                <span>
                  <img
                    style={{ height: "47px", marginLeft: "10px" }}
                    src={movie_redirect}
                    alt="Przekierowanie do strony zawierającej filmy instruktażowe"
                  ></img>
                </span>
              </a>
            </Typography>
          </Grid>
        </>
      )}
      {mediumScreen && (
        <Grid
          item
          container
          xs={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-end",
            flexDirection: "center",
            padding: "12px",
          }}
        >
          <Grid item xs={12}>
            <a href="/start-details">
              <img
                src={flowMobileImage}
                alt="Przekierowanie do opisu działania systemu"
              ></img>
            </a>
          </Grid>

          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span
              style={{
                color: theme.palette.text.alternative,
                fontSize: theme.typography.mainTitle,
                letterSpacing: "0.25px",
              }}
            >
              JAK TO DZIAŁA KROK PO KROKU
            </span>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "12px",
            }}
          >
            <Typography
              style={{
                fontSize: theme.typography.header,
              }}
              gutterBottom
              variant="h5"

              // style={{ fontSize: props.theme.typography.title }}
            >
              <a
                style={{
                  cursor: "pointer",
                  color: theme.palette.text.alternative2,
                  display: "flex",
                  alignItems: "center",
                  width: "209px",
                }}
                href="https://testsmakuiwechu.pl/home/test-krok-po-kroku-o-tescie/"
                target="_blank"
              >
                <span>ZOBACZ FILM</span>
                <span>
                  <img
                    style={{ height: "47px", marginLeft: "10px" }}
                    src={movie_redirect}
                    alt="Przekierowanie do strony zawierającej filmy instruktażowe"
                  ></img>
                </span>
              </a>
            </Typography>
          </Grid>
        </Grid>
      )}{" "}
      <Grid
        item
        xs={12}
        sm={11}
        xl={10}
        container
        justify="space-around"
        spacing={3}
        className={classes.gridCard}
      >
        {details.map((detail) => (
          <Grid
            item
            xs={10}
            md={4}
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "8px 32px 16px 0px",
            }}
          >
            <DetailCard
              theme={theme}
              title={detail.title}
              text={detail.text}
              url={detail.url}
              mediumScreen={mediumScreen}
            />
          </Grid>
        ))}
      </Grid>
      <br />
      <Grid
        item
        xs={12}
        sm={11}
        xl={10}
        container
        elevation={15}
        className={classes.bottomGrid}
        style={{
          display: "flex",
          // justifyContent: "center",
        }}
      >
        <Grid item container xs={12} style={{ paddingBottom: "10px" }}>
          <Grid item xs={12}>
            <hr
              style={{
                margin: "0 0 8px 0",
                backgroundColor: theme.palette.hr.primary,
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            xl={3}
            style={{ marginTop: "4px", marginBottom: "10px" }}
          >
            <p
              className={classes.bottomGridLabel}
              style={{
                fontSize: !smallScreen
                  ? theme.typography.footer
                  : theme.typography.footerMobile,
                fontWeight: "500",
              }}
            >
              UMOWA O DOFINANSOWANIE PROJEKTU W RAMACH PROGRAMU OPERACYJNEGO
              INTELIGENTNY ROZWÓJ
            </p>
            <p
              className={classes.bottomGridLabel}
              style={{
                fontSize: !smallScreen
                  ? theme.typography.footer
                  : theme.typography.footerMobile,
              }}
            >
              "System do wykrywania zaburzeń smaku i węchu oparty na badaniu
              wykonywanym samodzielnie w domu pacjenta"
            </p>
          </Grid>

          <Grid
            item
            xs={12}
            xl={9}
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <img
              alt="Logotyp Europejskiego Funduszu Rozwoju"
              src={smallScreen ? image3 : image333}
              style={{ height: xSmallScreen ? "25px" : smallScreen && "30px" }}
            />
            <img
              alt="Flaga Rzeczpospolita Polska"
              src={smallScreen ? image4 : image444}
              style={{ height: xSmallScreen ? "25px" : smallScreen && "30px" }}
            />
            <img
              alt="Logotyp Narodowego Centrum Badań i Rozwoju"
              src={smallScreen ? image5 : image555}
              style={{ height: xSmallScreen ? "25px" : smallScreen && "30px" }}
            />
            <img
              alt="Logotyp Unii Europejskiej"
              src={smallScreen ? image6 : image666}
              style={{ height: xSmallScreen ? "25px" : smallScreen && "30px" }}
            />
            {/*
            <img
              src={imageDisplay ? image4 : image44}
              style={{ height: smallScreen ? "30px" : imageDisplay && "50px" }}
            />
            <img
              src={imageDisplay ? image5 : image55}
              style={{ height: smallScreen ? "30px" : imageDisplay && "50px" }}
            />
            <img
              src={imageDisplay ? image6 : image66}
              style={{ height: smallScreen ? "30px" : imageDisplay && "50px" }}
            /> */}
          </Grid>
        </Grid>
      </Grid>
      {/* <Grid
        item
        xs={12}
        sm={11}
        elevation={15}
        className={classes.bottomGrid}
        style={{
          display: "flex",
          justifyContent: "space-around",
          marginTop: "12px",
        }}
      >
        <FeatureBox image={image3} height={smallScreen ? 25 : 50} />
        <FeatureBox image={image4} height={smallScreen ? 25 : 50} />
        <FeatureBox image={image5} height={smallScreen ? 25 : 50} />
        <FeatureBox image={image6} height={smallScreen ? 25 : 50} />
      </Grid> */}
    </Grid>
  );
};

export default StartPage;

const DetailCard = (props) => {
  const classes = useStyles();
  return (
    <Card
      className={classes.root}
      style={{
        borderTop:
          props.mediumScreen &&
          `0.5px solid  ${props.theme.palette.hr.primary}`,
      }}
    >
      <CardContent style={{ padding: "32px 16px 8px 0px" }}>
        <Grid container direction="row" alignItems="center" spacing={2}>
          <Grid item style={{ display: "flex", padding: "0px 8px 8px 8px" }}>
            <Typography
              style={{ flex: "1" }}
              gutterBottom
              variant="h5"
              style={{ fontSize: props.theme.typography.title }}
            >
              {props.title()}
            </Typography>
          </Grid>
        </Grid>
        <Typography
          variant="body2"
          // color="textSecondary"
          component="p"
          style={{
            margin: "24x auto 0px auto",
            fontSize: props.theme.typography.text,
            lineHeight: "1.2",
            margin: "24px 16px 0px auto",
          }}
        >
          {props.text}
        </Typography>
      </CardContent>
      <CardActions style={{ padding: "24px 16px 24px 0px" }}>
        <a
          href={props.url}
          style={{
            fontSize: props.theme.typography.text,
            color: props.theme.palette.text.alternative,
            // color: "#FF7A06",
          }}
          target="_blank"
        >
          Dowiedz się wiecej...
        </a>
      </CardActions>
    </Card>
  );
};
