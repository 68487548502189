import React, { useState, useEffect } from "react";
import { useParams, Link as RouterLink } from "react-router-dom";
import { getAxiosInstance } from "../../redux/common";
import Loader from "../../components/loader";
import {
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Grid,
  Typography,
  IconButton,
  Chip,
  MenuItem,
  FormControl,
  Select,
  Tooltip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { Add as AddIcon, Close as CloseIcon } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import ClearIcon from "@material-ui/icons/Clear";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  table: {},
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  status: {
    display: "flex",
    alignItems: "flex-end",
  },
  statusButtons: {
    display: "inline-flex",
  },
}));

function productToString(product) {
  switch (product) {
    case "SMELLTASTE_TEST":
      return "Test smaku i węchu";
    case "SMELL_TEST":
      return "Test węchu";
    case "TASTE_TEST":
      return "Test smaku";
    default:
      return product;
  }
}

function penniesToString(pennies) {
  const intValue = parseInt(pennies, 10);
  return (intValue / 100.0).toFixed(2);
}

function GeneratedTable(props) {
  const { obj, fields, title } = props;

  if (!obj || !fields) return null;

  return (
    <TableContainer component={Paper} elevation={3}>
      <Box m={2}>
        <Typography variant="h6" gutterBottom>
          {title}
        </Typography>
      </Box>
      <Table aria-label="orders list">
        <TableBody>
          {fields.map(([key, value, Formatter]) => (
            <TableRow key={key}>
              <TableCell component="th" scope="row">
                {value}:
              </TableCell>
              <TableCell align="left">
                {Formatter ? <Formatter value={obj[key]} /> : obj[key]}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function ProductRow({
  item,
  allowEdit = false,
  addMode = false,
  codeTextFieldProps = {},
  onStartAddingCode,
  onAddCode,
  onDeleteCode,
}) {
  const codes = item.productCodes;

  const codesToAdd = item.quantity - codes.length;

  return (
    <TableRow key={item.product}>
      <TableCell component="th" scope="row">
        {productToString(item.product)}
      </TableCell>
      <TableCell align="left">{item.quantity}</TableCell>
      <TableCell align="left">{penniesToString(item.total)}</TableCell>
      <TableCell align="left">{penniesToString(item.vatValue)}</TableCell>
      <TableCell align="left">{penniesToString(item.grossValue)}</TableCell>
      <TableCell align="left">
        {codes.map((code, index) => (
          <Box key={code} component="span" m={0.2}>
            <Chip
              name={`codes.${index}`}
              label={code}
              onDelete={
                allowEdit
                  ? () => onDeleteCode && onDeleteCode(code, item)
                  : null
              }
              color="primary"
              variant="outlined"
            />
          </Box>
        ))}
        {!addMode && allowEdit && codes.length < item.quantity && (
          <Button
            key="button.codesAdd"
            color="primary"
            onClick={() => onStartAddingCode && onStartAddingCode()}
          >
            {codes.length > 0
              ? "Dodaj kolejne numery"
              : "Rozpocznij nadawanie numerów"}
          </Button>
        )}
        {addMode && allowEdit && codes.length < item.quantity && (
          <Box component="span" m={0.5}>
            <TextField
              margin="dense"
              color="primary"
              autoFocus
              {...codeTextFieldProps}
            />
            <IconButton
              color="primary"
              onClick={() => onAddCode && onAddCode(item)}
            >
              <AddIcon />
            </IconButton>
          </Box>
        )}
        <Tooltip
          title={
            !allowEdit && codesToAdd
              ? "Numery możesz dodawać i edytować wyłącznie przy opłaconych lub skompletowanych zamówieniach"
              : ""
          }
        >
          <div>Pozostało numerów do nadania: {codesToAdd}</div>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
}

function OrderDetails() {
  const { tenantId, orderId } = useParams();
  const orderState = useState();
  const [order, setOrder] = orderState;
  const [currentCode, setCurrentCode] = useState("");
  const [currentCodeError, setCurrentCodeError] = useState("");
  const [addCodeToProduct, setAddCodeToProduct] = useState(null);
  const classes = useStyles();
  const history = useHistory();

  async function loadOrder() {
    try {
      const orderResult = await getAxiosInstance().get(
        "/order/v1/admin/order",
        {
          params: {
            tenantId: tenantId,
            orderId: orderId,
          },
        }
      );
      setOrder(orderResult.data);
    } catch (e) {
      console.log("order details error");
      setOrder(null);
    }
  }

  function handleCurrentCodeChange(event) {
    setCurrentCode(event.target.value);
  }

  useEffect(() => {
    setCurrentCodeError("");
  }, [currentCode]);

  useEffect(() => {
    setCurrentCode("");
  }, [addCodeToProduct]);

  function handleCancelAddCode() {
    setCurrentCode("");
    setAddCodeToProduct(null);
  }

  function handleAddCode(item) {
    const code = currentCode;
    getAxiosInstance()
      .post(
        "/order/v1/admin/order/product/code",
        {},
        {
          params: {
            tenantId: tenantId,
            orderId: order.orderId,
            code: code,
            productId: item.product,
          },
        }
      )
      .then((response) => {
        const result = response.data;

        setCurrentCode("");

        setOrder((prevOrder) => {
          return {
            ...prevOrder,
            items: prevOrder.items.map((prevItem) => {
              if (prevItem.product === item.product) {
                return {
                  ...prevItem,
                  productCodes: [...prevItem.productCodes, code],
                };
              } else {
                return prevItem;
              }
            }),
            status: result.status,
          };
        });
      })
      .catch((error) => {
        let errorMessage;
        switch (error.response?.data?.name) {
          case "InvalidCodeError":
            errorMessage = "Kod jest nieprawidłowy";
            break;
          case "CodeIsUsedError":
            errorMessage = "Kod został już wcześniej użyty";
            break;
          case "NotFoundError":
            errorMessage = "Kod nieznany";
            break;
          default:
            errorMessage = `Serwer zwrócił błąd: ${
              error.response?.data?.detail || "nieznany"
            }`;
        }
        setCurrentCodeError(errorMessage);
      });
  }

  function handleDeleteCode(code, item) {
    getAxiosInstance()
      .delete("/order/v1/admin/order/product/code", {
        params: {
          tenantId: tenantId,
          orderId: order.orderId,
          code: code,
          productId: item.product,
        },
      })
      .then((response) => {
        const result = response.data;
        setOrder((prevOrder) => ({
          ...prevOrder,
          items: prevOrder.items.map((prevItem) => ({
            ...prevItem,
            productCodes: prevItem.productCodes.filter((pc) => pc !== code),
          })),
          status: result.status,
        }));
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    if (order === undefined) {
      loadOrder();
    }
  });

  function DateFormatter(props) {
    const string = props.value;

    var options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };
    const date =
      string[string.length - 1] !== "Z" ? string.concat("Z") : string;

    return new Date(date).toLocaleDateString(
      [new Intl.Locale("pl-PL")],
      options
    );
  }

  function Status(props) {
    const orgStatus = props.value;
    const [status, setStatus] = useState(orgStatus);
    const [editMode, setEditMode] = useState(false);

    async function changeStatus() {
      try {
        await getAxiosInstance().put(
          "/order/v1/admin/order/status",
          {},
          {
            params: {
              tenantId: tenantId,
              orderId: orderId,
              newStatus: status,
            },
          }
        );

        setOrder(undefined);
      } catch (e) {
        console.error(e);
      }
    }

    function changeEditMode() {
      setStatus(orgStatus);
      setEditMode(!editMode);
    }

    const buttons = editMode ? (
      <>
        <Box component="span" m={0.5}>
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={changeStatus}
          >
            OK
          </Button>
        </Box>
        <Box component="span" m={0.5}>
          <Button size="small" variant="contained" onClick={changeEditMode}>
            Anuluj
          </Button>
        </Box>
      </>
    ) : (
      <Box component="span" m={0.5}>
        <Button
          size="small"
          variant="contained"
          color="primary"
          onClick={changeEditMode}
        >
          Edytuj
        </Button>
      </Box>
    );

    function handleChange(event) {
      setStatus(event.target.value);
    }

    return (
      <Box className={classes.status}>
        <FormControl className={classes.formControl}>
          <Select
            labelId="status-filter-label"
            id="status-filter-select"
            value={status}
            disabled={!editMode}
            onChange={handleChange}
          >
            <MenuItem value={"paid"}>opłacone</MenuItem>
            <MenuItem value={"ready"}>skompletowane</MenuItem>
            <MenuItem value={"completed"}>wysłane</MenuItem>
            <MenuItem value={"created"}>nieopłacone</MenuItem>
            <MenuItem value={"cancelled"}>anulowane</MenuItem>
          </Select>
        </FormControl>
        <Box className={classes.statusButtons} component="span" m={1}>
          {buttons}
        </Box>
      </Box>
    );
  }

  if (order === undefined) {
    return <Loader loading={true} text="Wczytywanie danych" />;
  } else if (order === null) {
    return <div>Błąd wczytywania danych</div>;
  } else {
    //const editablePart = (() => )();

    return (
      <>
        <Box className={classes.root}>
          <Box m={2}>
            <Grid container spacing={1}>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<ArrowBackIosIcon />}
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  Powrót
                </Button>
              </Grid>

              <Grid item style={{ display: "flex" }}>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<ArrowBackIcon />}
                  onClick={() => {
                    history.push("/owner-panel/orders");
                  }}
                >
                  Przejdź do menu
                </Button>
              </Grid>
            </Grid>
            <Typography align="center" variant="h4" gutterBottom>
              Szczegóły zamówienia
              <IconButton
                color="secondary"
                component={RouterLink}
                to="/owner-panel/orders"
              >
                <CloseIcon />
              </IconButton>
            </Typography>
          </Box>

          <Grid container spacing={3}>
            <Grid item xs>
              <GeneratedTable
                title="Zamówienie"
                obj={order}
                fields={[
                  ["orderNumber", "Numer zamówienia"],
                  ["tenantName", "Zamawiający"],
                  ["created", "Data zamówienia", DateFormatter],
                  ["status", "Status", Status],
                ]}
              />
            </Grid>
            <Grid item xs>
              <GeneratedTable
                title="Dane do wysyłki"
                obj={order.deliveryInfo}
                fields={[
                  ["name", "Imię i nazwisko"],
                  ["street", "Ulica"],
                  ["zipCode", "Kod pocztowy"],
                  ["city", "Miasto"],
                  ["post", "Poczta"],
                  ["phone", "Telefon"],
                  ["notes", "Dodatkowe uwagi"],
                ]}
              />
            </Grid>
            <Grid item xs>
              <GeneratedTable
                title="Dane do faktury"
                obj={order.billingInfo}
                fields={[
                  ["taxId", "NIP"],
                  ["name", "Nazwa firmy"],
                  ["street", "Ulica"],
                  ["zipCode", "Kod pocztowy"],
                  ["city", "Miasto"],
                  ["post", "Poczta"],
                ]}
              />
            </Grid>
          </Grid>

          <Box m={3}>&nbsp;</Box>

          <Grid container spacing={3}>
            <TableContainer component={Paper} elevation={3}>
              <Box m={2}>
                <Typography variant="h6" gutterBottom>
                  Zamówione produkty
                </Typography>
              </Box>
              <Table aria-label="items list">
                <TableHead>
                  <TableRow>
                    <TableCell align="right">Produkt</TableCell>
                    <TableCell align="right">Ilość</TableCell>
                    <TableCell align="right">Wartość netto</TableCell>
                    <TableCell align="right">VAT</TableCell>
                    <TableCell align="right">Wartość brutto</TableCell>
                    <TableCell align="right">Przydzielone numery</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {order.items
                    .filter(({ product }) => product !== "DELIVERY")
                    .map((item) => (
                      <ProductRow
                        key={item.product}
                        item={item}
                        addMode={addCodeToProduct === item.product}
                        allowEdit={
                          order.status === "paid" || order.status === "ready"
                        }
                        onStartAddingCode={() =>
                          setAddCodeToProduct(item.product)
                        }
                        onAddCode={handleAddCode}
                        onDeleteCode={handleDeleteCode}
                        codeTextFieldProps={{
                          error: !!currentCodeError,
                          helperText: currentCodeError,
                          value: currentCode,
                          onChange: handleCurrentCodeChange,
                          onKeyDown: (event) => {
                            if (event.key === "Enter") {
                              handleAddCode(item);
                            } else if (event.key === "Escape") {
                              handleCancelAddCode();
                            }
                          },
                          InputProps: {
                            endAdornment: (
                              <IconButton
                                onClick={handleCancelAddCode}
                                size="small"
                              >
                                <ClearIcon color="disabled" fontSize="small" />
                              </IconButton>
                            ),
                          },
                        }}
                      />
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Box>
      </>
    );
  }
}

export default OrderDetails;
