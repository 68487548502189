import React, { useEffect, useState } from "react";
import { useDrag } from "react-dnd";
import { ItemSubtypes, ItemTypes } from "../../../DnDTypes";
import * as classnames from "classnames";
import s from "./index.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// dragaMode = either 'clone' or 'move'

const TextControl = ({
  name,
  id,
  disabled,
  isSelected = false,
  dragMode = "move",
  configuration = {},
  markSelected,
  onChange,
  showSelfDescription,
  sidebarMode,
  formCreatorMode,
  removable,
  onRemove,
  rowIndex,
  index,
  setIsDragging,
  filledValue,
  filledFormMode,
}) => {
  const [, drag] = useDrag({
    item: {
      type: ItemTypes.COMPONENT,
      subtype: ItemSubtypes.DICTIONARY,
      dragMode,
      defaultLabel: "Słownik",
      id,
      rowIndex,
      index,
    },
    collect: (monitor, props) => {
      setIsDragging && setIsDragging(!!monitor.isDragging());

      return {
        isDragging: !!monitor.isDragging(),
      };
    },
    canDrag: () => {
      return dragMode === "move" || dragMode === "clone";
    },
  });

  const [value, setValue] = useState(filledValue || "");
  useEffect(() => {
    setValue(filledValue);
  }, [filledValue]);

  const clicked = () =>
    markSelected &&
    markSelected({
      id,
      configuration,
    });

  const updateValue = (value) => {
    setValue(value);

    if (onChange) {
      onChange({
        id,
        value,
      });
    }
  };

  if (sidebarMode) {
    return (
      <div ref={drag}>
        <input
          readOnly={true}
          type="text"
          className="input"
          placeholder="Słownik"
        />
      </div>
    );
  }

  const placeholder = showSelfDescription
    ? "Słownik"
    : (configuration && configuration.text) || "";
  const label = (configuration && configuration.label) || "";

  if (formCreatorMode) {
    return (
      <div ref={drag}>
        {label && <label className={classnames("label")}>{label}</label>}
        {!label && (
          <label
            dangerouslySetInnerHTML={{ __html: "&nbsp;" }}
            className={classnames("label")}
          ></label>
        )}
        <div ref={drag} className={s.relative}>
          <input
            placeholder={placeholder}
            className={classnames("input", {
              [s.hasActiveConfiguration]: isSelected,
            })}
            name={name}
            id={id}
            onClick={clicked}
            readOnly
          />
          <FontAwesomeIcon
            className={classnames(s.trashIcon, "has-text-grey")}
            onClick={onRemove}
            icon="trash"
            title="Usuń"
          />
        </div>
      </div>
    );
  }

  const dictionaryValues =
    (configuration.dictionary && configuration.dictionary.dictionaryValues) ||
    [];

  if (filledFormMode) {
    return (
      <div>
        {label && <label className={classnames("label")}>{label}</label>}
        {!label && (
          <label
            dangerouslySetInnerHTML={{ __html: "&nbsp;" }}
            className={classnames("label")}
          ></label>
        )}

        {dictionaryValues.map(({ stringValue, intValue }, index) => (
          <div className="control">
            <label>
              <input
                key={index}
                type="radio"
                name={id}
                onChange={() =>
                  onChange({ id, value: stringValue || intValue })
                }
                checked={(intValue || stringValue) == filledValue}
              />
              {stringValue || intValue}
            </label>
          </div>
        ))}
      </div>
    );
  }

  return (
    <div>
      {label && <label className={classnames("label")}>{label}</label>}
      {!label && (
        <label
          dangerouslySetInnerHTML={{ __html: "&nbsp;" }}
          className={classnames("label")}
        ></label>
      )}

      {dictionaryValues.map(({ stringValue, intValue }, index) => (
        <div className="control" key={index}>
          <label>
            <input
              type="radio"
              name={id}
              onChange={() => onChange({ id, value: stringValue || intValue })}
            />
            {stringValue || intValue}
          </label>
        </div>
      ))}

      {/*<input*/}
      {/*  type="text"*/}
      {/*  className={classnames("input")}*/}
      {/*  name={name}*/}
      {/*  placeholder={placeholder}*/}
      {/*  id={id}*/}
      {/*  value={value}*/}
      {/*  onChange={({target: {value}}) => updateValue(value)}*/}
      {/*  onClick={clicked}*/}
      {/*/>*/}
    </div>
  );
};

export default TextControl;
