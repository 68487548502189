import React from "react";
import { Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import LeftComponentMui from "../../left-component-mui";
import { Box } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Can from "../../components/can";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Paper from "@material-ui/core/Paper";
import Link from "@material-ui/core/Link";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles, withStyles } from "@material-ui/core/styles";

const MuiNavLink = withStyles((theme) => ({
  root: {
    color: theme.palette.anchor.primary,
    textDecoration: "underline",
    "&:hover": {
      color: theme.palette.text.primary,
      textDecoration: "underline",
    },
  },
}))(Link);

const useStyles = makeStyles((theme) => ({
  input: {
    background: "white",
    "&:hover": {
      background: "white",
    },
    "&.Mui-focused": {
      background: "white",
    },
  },
  paperStyle: {
    padding: "12px",
    // height: "90vh",
    width: "100%",
    background: theme.palette.background.tab,
    border: `1px solid ${theme.palette.background.border}`,
    borderBottomLeftRadius: "1rem",
    borderBottomRightRadius: "1rem",
    borderTopRightRadius: "0px",
    borderTopLeftRadius: "0px",
  },
}));

const PasswordResetMailLinkExpiredPage = ({ history }) => {
  const buttonHandler = (e) => {
    e.preventDefault();

    history.push("/login");
  };

  const classes = useStyles();
  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const globalTheme = useSelector((s) => s.globalTheme || "light");
  const page = () => {
    return (
      <Grid
        container
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Grid
          item
          xs={12}
          sm={8}
          md={6}
          lg={4}
          style={{ margin: smallScreen ? 0 : 50 }}
        >
          <h1
            aria-hidden="false"
            aria-label="Resetowanie hasła - link wygasł"
            style={{ display: "none" }}
          >
            Resetowanie hasła - link wygasł
          </h1>
          <Paper
            style={{
              background: "transparent",
              borderBottomLeftRadius: "2rem",
              borderBottomRightRadius: "2rem",
              margin: "12px",
            }}
          >
            <Tabs
              value={0}
              textColor="primary"
              aria-label="disabled tabs example"
              inkBarStyle={{ background: "blue" }}
              TabIndicatorProps={{
                style: {
                  display: "none",
                },
              }}
              style={{ background: "transparent" }}
            >
              <Tab
                label="Odzyskaj hasło"
                style={{ minWidth: "100%", color: "black" }}
              />
            </Tabs>

            <Paper className={classes.paperStyle}>
              <Grid
                container
                spacing={2}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Grid item xs={10}>
                  <Box p={1}>
                    <Box style={{ alignItems: "center" }}>
                      <Alert
                        severity="error"
                        style={{
                          backgroundColor:
                            globalTheme === "high-contrast" && "#000000",
                          border:
                            globalTheme === "high-contrast" &&
                            "1px solid #ffff00",
                          color: globalTheme === "high-contrast" && "#ffff00",
                        }}
                      >
                        Przepraszamy! Link do zmiany hasła wygasł.
                      </Alert>
                    </Box>
                  </Box>
                  <Box m={1}>
                    <Typography variant="subtitle1" gutterBottom>
                      <MuiNavLink href="/password-reset">
                        Kliknij tutaj
                      </MuiNavLink>
                      , aby przejść do formularza odpowiadającego za wysłanie
                      linku do zmiany hasła.
                    </Typography>
                  </Box>
                  <hr />
                  <Box display="flex" justifyContent="center">
                    <Button
                      variant={"contained"}
                      color="primary"
                      onClick={buttonHandler}
                    >
                      Wróc do ekranu logowania
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Paper>
          </Paper>
        </Grid>
      </Grid>
    );
  };
  return (
    <Can
      permission="password-reset-email-link-expired:view"
      ok={page}
      not={() => {
        return <Redirect to="/" />;
      }}
    />
  );
};

export default PasswordResetMailLinkExpiredPage;
