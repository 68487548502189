import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import Can from "../../components/can";
import makeStyles from "@material-ui/core/styles/makeStyles";

import Loader from "../../components/loader";
import { Grid, Typography } from "@material-ui/core";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: "15px",
  },
  icon: {
    fill: "red",
    width: "100px",
    height: "100px",
  },
  gridCentered: {
    display: "flex",
    justifyContent: "center",
  },
}));

const PaymentDeclinedPage = ({ history }) => {
  const classes = useStyles();

  useEffect(() => {
    setTimeout(() => history.push("/orders"), 6000);
  }, []);

  const page = () => (
    <div className={classes.root}>
      <Grid container spacing={3} alignItems="center" justify="center">
        <Grid item xs={12} className={classes.gridCentered}>
          <h1
            aria-hidden="false"
            aria-label="Odrzucona płatność"
            style={{ display: "none" }}
          >
            Odrzucona płatność
          </h1>
          <Typography variant="h4" gutterBottom>
            Płatność została odrzucona
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.gridCentered}>
          <CancelOutlinedIcon className={classes.icon} />
        </Grid>
        <Grid item xs={12} className={classes.gridCentered}>
          <Typography variant="overline" display="block" gutterBottom>
            Za moment nastąpi przekierowanie na stronę z zamówieniami...
          </Typography>
        </Grid>
      </Grid>
    </div>
  );

  const redirect = () => <Redirect to="/login" />;

  return <Can permission="paymentStatus:page" ok={page} not={redirect} />;
};

export default PaymentDeclinedPage;
