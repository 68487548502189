import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { inviteMultiplePeople } from "../redux/actions";
import { Redirect } from "react-router-dom";
import Can from "../components/can";
import { validate } from "email-validator";
import AddNewPerson from "./add-new-person";

const AddNewPersonsPage = ({ history }) => {
  const [mails, setMails] = useState([]);
  const [lastInputData, setLastInputData] = useState("");
  const ref = useRef();
  const refLast = useRef();
  const [valid, setValid] = useState(false);
  const [showNewLabel, setShowNewLabel] = useState(false);
  const [tmpIndex, setTmpIndex] = useState(0);
  const organization = useSelector((s) => s.organization);
  const dispatch = useDispatch();

  useEffect(() => {
    setValid(
      mails.map(({ valid }) => valid).filter((v) => v).length ===
        mails.length && mails.length > 0
    );
  }, [mails]);

  useEffect(() => {
    if (lastInputData.length > 0) {
      setMails([
        ...mails,
        {
          id: +new Date(),
          mail: lastInputData,
          sent: false,
          error: false,
        },
      ]);

      refLast.current.disabled = true;
      setTimeout(() => {
        ref.current.value = lastInputData;
        refLast.current.value = "";
        ref.current.focus();
        refLast.current.disabled = false;
      }, 50);
    }
    setShowNewLabel(true);
    setTmpIndex((prevState) => prevState + 1);
  }, [lastInputData, mails]);

  const invite = async (e) => {
    const mailsToSend = mails.filter(
      (m) => !m.sent && !m.error && validate(m.mail)
    );

    if (mailsToSend.length > 0) {
      const res = await dispatch(
        inviteMultiplePeople({
          mails: mailsToSend.map((m) => m.mail),
          orgId: organization.id,
        })
      );

      setMails([
        ...mails.map((m, index) => {
          const myRes = res.find((r) => r.mail === m.mail);

          return {
            ...m,
            ...(myRes ? { sent: myRes.created } : {}),
            ...(myRes ? { error: !myRes.created } : {}),
          };
        }),
      ]);
    }
  };

  const page = () => (
    <div className="container is-fluid">
      <div className="title">Dodaj nowe osoby do organizacji</div>

      <div className="">
        {mails.map(({ mail, sent, error }, index) => (
          <div className="field" key={index}>
            <AddNewPerson
              sent={sent}
              error={error}
              setData={(data) => {
                setMails(
                  mails.map((m, ind) => (ind === index ? { ...m, ...data } : m))
                );
              }}
              ref={index === mails.length - 1 ? ref : null}
            />
          </div>
        ))}

        <div className="field">
          <AddNewPerson
            showLabel={true}
            showNewLabel={showNewLabel}
            tmpIndex={tmpIndex}
            keepEmpty={true}
            setData={({ mail }) => setLastInputData(mail)}
            ref={refLast}
          />
        </div>

        <div className="field is-grouped-right is-grouped">
          <button className="button is-primary" onClick={invite}>
            Dodaj wybranych użytkowników do organizacji
          </button>
        </div>
      </div>
    </div>
  );

  const redirect = () => <Redirect to="/" />;

  return <Can permission="add-persons-page:view" ok={page} not={redirect} />;
};

export default AddNewPersonsPage;
