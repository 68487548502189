import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { NavLink, Redirect, useLocation } from "react-router-dom";
import Can from "../../components/can";
import Loader from "../../components/loader";
import HeaderMenu from "../../components/header-menu";
import { alertAdd, login } from "../../redux/actions";
import Cookies from "js-cookie";

import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import { LockOpen, Visibility } from "@material-ui/icons";
import { Box } from "@material-ui/core";
import styled from "styled-components";
import LeftComponentMui from "../../left-component-mui";
import s from "./index.module.css";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
  Grid,
  Paper,
  Avatar,
  TextField,
  Button,
  Typography,
  Link,
} from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import SignIn from "../../components/sign-in";
import SignUp from "../../components/sign-up";
import { makeStyles, withStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  text: {
    color: theme.palette.text.alternative3,
    letterSpacing: "0px",
    fontSize: theme.typography.text2,
  },
}));

const LoginPage = ({
  login,
  history,
  alertAdd,
  loggingIn,
  loggedIn,
  errorMessage = "",
}) => {
  const location = useLocation();
  const classes = useStyles();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [valid, setValid] = useState(false);
  const [providedRegisterData, setProvidedRegisterData] = useState({});

  const [invalidEmailFormat, setInvalidEmailFormat] = useState(false);

  const rememberMeLogin = Cookies.get("rememberMeLogin");

  const [value, setValue] = useState(location?.pathname === "/login" ? 0 : 1);
  const [reloadPage, setReloadPage] = useState(false);

  useState(() => {
    if (loggedIn) {
      history.push("/");
    } else {
      setUsername(rememberMeLogin);
    }
  }, [loggedIn]);

  useEffect(() => {
    if (!invalidEmailFormat && password.trim()) {
      setValid(true);
    } else {
      setValid(false);
    }
  }, [username, password]);

  // useEffect(() => {
  //   if (reloadPage) {
  //     setValue(0);
  //   }
  // }, [reloadPage]);

  // useEffect(() => {
  //   console.log(location?.pathname === "/login" ? 0 : 1);
  //   if (location.props) {
  //     setValue(1);
  //   }
  // }, []);

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  };

  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const handleChange = (event, newValue) => {
    if (newValue === 0) {
      history.push("/login");
    } else if (newValue === 1) {
      history.push("/register");
    }
  };

  return (
    <Can
      permission="login:view"
      ok={() => {
        return (
          <Grid
            container
            style={{
              display: "flex",
              justifyContent: "center",
              // height: "100vh",
              marginTop: "50px",
            }}
          >
            <h1
              aria-hidden="false"
              aria-label={
                location?.pathname === "/login"
                  ? "Strona logowania"
                  : "Strona rejestracji"
              }
              style={{ display: "none" }}
            >
              {location?.pathname === "/login"
                ? "Strona logowania"
                : "Strona rejestracji"}
            </h1>
            {location?.state?.orderRedirection && (
              <Grid
                item
                xs={12}
                style={{
                  margin: 50,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Typography className={classes.text}>
                  Zaloguj się, aby kontynuować
                </Typography>
              </Grid>
            )}
            <Grid
              item
              xs={12}
              sm={8}
              md={6}
              lg={4}
              // style={{ margin: smallScreen ? 0 : 50 }}
            >
              <Paper
                style={{
                  // background: "red",
                  borderBottomLeftRadius: "1rem",
                  borderBottomRightRadius: "1rem",
                  borderTopRightRadius: "1rem",
                  borderTopLeftRadius: "1rem",
                  margin: "12px",
                }}
              >
                <Tabs
                  value={value}
                  textColor="primary"
                  onChange={handleChange}
                  aria-label="disabled tabs example"
                  inkBarStyle={{ background: "blue" }}
                  TabIndicatorProps={{
                    style: {
                      display: "none",
                    },
                  }}
                  // style={{ background: "transparent" }}
                >
                  <Tab
                    tabIndex="10"
                    label="Zaloguj się"
                    style={{ minWidth: "50%" }}
                  />
                  <Tab
                    tabIndex="11"
                    label="Załóż konto"
                    style={{ minWidth: "50%" }}
                  />
                </Tabs>
                {value === 0 && <SignIn />}
                {value === 1 && (
                  <SignUp
                    setReloadPage={setReloadPage}
                    providedRegisterData={providedRegisterData}
                    setProvidedRegisterData={setProvidedRegisterData}
                  />
                )}
              </Paper>
            </Grid>
          </Grid>
        );
      }}
      not={() => {
        return <Redirect to="/" />;
      }}
    />
  );
};

const mapStateToProps = (state) => ({
  loggingIn: state.loggingIn,
  loggedIn: state.loggedIn,
  errorMessage: state.errorMessage,
});

const mapDispatchToProps = (dispatch) => ({
  login: (data) => dispatch(login(data)),
  alertAdd: (payload) => dispatch(alertAdd(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
