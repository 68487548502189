import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  fetchActiveForms,
  fetchFilledForms,
  removeForm,
} from "../../redux/actions";
import { Redirect } from "react-router-dom";
import SingleForm from "./single-form";
import Can from "../../components/can";
import PageTitle from "../../components/page-title";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import { makeStyles } from "@material-ui/core/styles";

const Page = ({
  history,
  activeForms,
  loggedIn,
  fetchActiveForms,
  fetchFilledForms,
  filledForms,
}) => {
  useEffect(() => {
    if (loggedIn) {
      fetchActiveForms();
    }
  }, [loggedIn]);

  const fill = (id) => {
    history.push(`/testdata/add/${id}`);
  };
  const remove = (id) => {
    removeForm(id);
  };

  const useStyles = makeStyles({
    table: {
      minWidth: 650,
    },
  });

  const classes = useStyles();

  const page = () => (
    <>
      <PageTitle title="Aktywne formularze" />

      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Nazwa formularza</TableCell>
            <TableCell>Historia wypełnień</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {activeForms &&
            activeForms.map((form) => (
              <SingleForm
                key={form.id}
                form={form}
                fill={fill}
                fetchFilledForms={fetchFilledForms}
                filledForms={filledForms}
                history={history}
              />
            ))}
        </TableBody>
      </Table>
    </>
  );

  const redirect = () => <Redirect to="/login" />;

  return <Can permission="activetest-page:view" ok={page} not={redirect} />;
};

const mapStateToProps = (state) => ({
  organization: state.organization,
  activeForms: state.activeForms,
  loggedIn: state.loggedIn,
  filledForms: state.filledForms,
});

const mapDispatchToProps = (dispatch) => ({
  fetchActiveForms: () => dispatch(fetchActiveForms()),
  fetchFilledForms: (formId) => dispatch(fetchFilledForms(formId)),
});

const ActiveFormsPage = connect(mapStateToProps, mapDispatchToProps)(Page);
export default ActiveFormsPage;
