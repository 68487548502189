import React, { useEffect, useState } from "react";

import Sidebar from "../sidebar/sidebar";
import RowControl from "../form-controls/row-control";
import ControlProperties from "../control-properties";
import Can from "../can";
import { Redirect } from "react-router-dom";
import s from "./index.module.css";

const FormEditor = ({ onUpdate, formId, initialRows, formEdited }) => {
  const [rows, setRows] = useState([[]]);
  const [dragCount, setDragCount] = useState(1);
  const setIsDragging = (value) => {
    // if (value) {
    //   setDragCount(1)
    // } else {
    //   setDragCount(0)
    // }
    // console.log(dragCount)
  };

  const [selectedItem, setSelectedItem] = useState();

  const tmpStateVariable = React.useRef();
  useEffect(() => {
    if (initialRows) {
      !tmpStateVariable.current
        ? (tmpStateVariable.current = true)
        : rows.length - 1 < initialRows.length && formEdited(true);
    }
  }, [onUpdate, rows]);

  useEffect(() => {
    if (rows && rows.length) {
      if (rows.length > 2) {
        for (var i = 1; i < rows.length - 1; i = i + 2) {
          if (rows[i].length !== 0) {
            rows.splice(i, 0, []);
          }
        }
      }

      // if last row is not empty, add new empty row
      if (rows[rows.length - 1].length !== 0) {
        setRows([...rows, []]);
        formEdited(true);
      }
    }
  }, [rows]);

  useEffect(() => {
    onUpdate && onUpdate(rows);
  }, [onUpdate, rows]);

  const updateRow = (rowIndex, rowItems) => {
    setRows(
      rows.map((row, index) => {
        if (index === rowIndex) {
          formEdited(true);
          return rowItems;
        }
        return row;
      })
    );
  };

  useEffect(() => {
    if (initialRows) {
      setRows([...initialRows, []]);
    }
  }, [initialRows]);

  const updateSelectedItemConfiguration = (conf) => {
    if (selectedItem) {
      const newRows = rows.map((r) => {
        return r.map((item) => {
          if (item.id === selectedItem.id) {
            const newConfiguration = {
              ...item.configuration,
              ...conf,
            };

            setSelectedItem({
              dataType: item.dataType,
              ...selectedItem,
              configuration: newConfiguration,
            });

            return {
              ...item,
              configuration: newConfiguration,
            };
          }

          return item;
        });
      });

      setRows(newRows);
    }
  };

  const removeItem = (row, rowIndex) => {
    setRows(
      rows
        .map((r) => {
          if (row === r) {
            r.splice(rowIndex, 1);
          }
          return r;
        })
        .filter((r, index, all) => {
          return r.length > 0 || index === all.length - 1;
        })
    );
  };

  const moveItemToIndex = ({
    item,
    targetRow,
    targetIndex,
    sourceRow,
    sourceIndex,
  }) => {
    let realItem = item;
    if (rows[sourceRow] !== undefined) {
      if (sourceRow !== undefined && sourceIndex !== undefined) {
        realItem = rows[sourceRow][sourceIndex];
      }

      const rr = rows
        .map((r, rIndex) => {
          if (rIndex === sourceRow) {
            r.splice(sourceIndex, 1);
          }

          if (rIndex === targetRow) {
            r.splice(targetIndex, 0, realItem);
          }

          return r;
        })
        .filter((row, index) => row.length > 0 || index === rows.length - 1);

      setRows(rr);
    }
  };

  const page = () => (
    <div className="columns">
      <div className="column is-one-fifth">
        <div className={s.sticky}>
          <Sidebar />
        </div>
      </div>
      <div className="column">
        <div>
          {rows.map((row, rowIndex) => {
            return (
              <RowControl
                key={rowIndex}
                update={(rowItems) => updateRow(rowIndex, rowItems)}
                markSelectedConfiguration={setSelectedItem}
                selectedId={(selectedItem && selectedItem.id) || -1}
                items={row}
                rowIndex={rowIndex}
                dragMode="move"
                formCreatorMode
                removeItem={(index) => removeItem(row, index)}
                moveItemToIndex={moveItemToIndex}
                showDropMarkers={dragCount > 0}
                setIsDragging={setIsDragging}
                rowsLength={rows.length}
              />
            );
          })}
        </div>
      </div>

      <div className="column is-one-fifth">
        <div className={s.sticky}>
          <ControlProperties
            controlId={selectedItem && selectedItem.id}
            dataType={selectedItem && selectedItem.dataType}
            configuration={(selectedItem || {}).configuration || {}}
            update={updateSelectedItemConfiguration}
            formEdited={formEdited}
          />
        </div>
      </div>
    </div>
  );

  const redirect = () => <Redirect to="/login" />;

  return <Can permission="test-editor-page:view" ok={page} not={redirect} />;
};

export default FormEditor;
