import React from 'react'
import {ItemSubtypes} from '../../../DnDTypes'
import {NUMBER_TYPE} from "../dictionary-properties";


export const SupportedLabelType = ItemSubtypes.LABEL

export const STRING_TYPE = 'text'
export const IMAGE_TYPE = 'file'

export class LabelProperties extends React.Component {
    constructor() {
        super()
        this.addLabelType = this.addLabelType.bind(this)
    }

    state = {
        labelType: '',
    }

    static getDerivedStateFromProps(props, state) {
        let labelType
        if (!state.labelType) {
            labelType = state.labelType
        }

        return {
            setValues: props && props.setValues,
        }
    }

    setType(labelType) {
        this.setState({labelType})
    }

    addLabelType() {
        this.props.setValues({
            ...this.props.values,
            labelType: this.state.labelType,
        })
    }


    render() {
        return (
            <li>
                {!this.state.labelType ? (
                    <>
                        <div className='is-size-6'>Zdefiniuj typ etykiety:</div>
                        <div>
                            <button className='button'
                                    onClick={() => {
                                        this.setType(STRING_TYPE)
                                    }}>Tekst
                            </button>
                            <button className='button'
                                    onClick={() => {
                                        this.setType(IMAGE_TYPE)
                                    }}>Obrazek
                            </button>
                        </div>
                    </>
                ) : (
                    <>
                        <div>Etykieta typu {this.state.labelType === STRING_TYPE
                            ? 'tekst'
                            : 'obrazek'}</div>
                        <div className="control">
                            <div>
                                <button className='button is-primary is-small'
                                        onClick={this.addLabelType}>Zapisz typ
                                </button>
                            </div>
                        </div>
                    </>
                )
                }
            </li>
        )
    }
}
