export const permissions = {
  ROLE_ADMIN: {
    static: [
      "adminMain:view",
      "main:view",
      // 'activated-kits:view',
      "adminSettings:view",
      "adminUsersList:view",
      "profilePage:view",
      "passwordChangePage:view",
      "orgDetails:change",
      "users:view",
      "terms-page:view",
      "orgDetails:view",
      "globalOrgDetails:view",
      "globalOrgDetails:change",
    ],
    dynamic: [],
  },
  // ROLE_SUPEROWNER: {
  //   static: [
  //     "orgDetails:view",
  //     "globalOrgDetails:view",
  //     "globalOrgDetails:change",
  //   ],
  //   dynamic: [],
  // },
  ROLE_OWNER: {
    static: [
      "form-add-page:view",
      // "admin:fetchOrgForms",
      "admin:fetchOrgUsers",
      "main:view",
      "activated-kits-page:view",
      "admin:fetchOrgReports",
      "profilePage:view",
      "passwordChangePage:view",
      "add-persons-page:view",
      "orgDetails:change",
      "users:view",
      "users-filled-tests:view",
      "new-test-kit:page",
      "orgDetails:view",
      "test-kit:page",
      "edit-test-kit:page",
      "proteges:page",
      "contact-logged-user:page",
    ],
    dynamic: [],
  },
  ROLE_USER: {
    static: [
      "main:view",
      "profilePage:view",
      "passwordChangePage:view",
      "test-fill:page",
      "test-fill-details:page",
      "activetests-page:view",
      "filled-kits:page",
      "test-kit:page",
      "edit-test-kit:page",
      "shopping-cart:page",
      "orders:page",
      "order-in-progress:page",
      "activated-kits-page:view",
      "account:remove",
      "sat-form:page",
      "proteges:page",
      "orgDetails:change",
      "paymentStatus:page",
      "add-test-set:page",
      "contact-logged-user:page",
      "start-page-logged:page",
    ],
    dynamic: [],
  },
  ROLE_EDITOR: {
    static: [
      "main:view",
      "activated-kits-page:view",
      // "form-add-page:view",
      "test-editor-page:view",
      "test:activate",
      "test:duplicate",
      "test:deactivate",
      "test:create",
      "users-filled-tests:view",
      "forms:fetch",
    ],
    dynamic: [],
  },
  GUEST: {
    static: [
      "login:view",
      "help:view",
      "about:view",
      "password-reset-email:view",
      "password-reset-email-link-expired:view",
    ],
  },
};

export const checkPermission = (permission, roles) => {
  let allPermissions = [];
  if (!roles) {
    allPermissions = [...permissions.guest.static];
  } else {
    roles.forEach((role) => {
      if (permissions[role]) {
        allPermissions = [...allPermissions, ...permissions[role].static];
      }
    });
  }

  return allPermissions.indexOf(permission) !== -1;
};
