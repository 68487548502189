import React, {useEffect, useState} from 'react'
import { useDrag } from 'react-dnd'
import { ItemSubtypes, ItemTypes } from '../../../DnDTypes'

// dragaMode = either 'clone' or 'move'

const ImageControl = ({
                        name, id, disabled, isSelected = false, dragMode = 'move',
                        configuration = {}, markSelected, onChange, showSelfDescription,
                        rowIndex, index, setIsDragging, filledValue
                      }) => {
  const [, drag] = useDrag({
    item: {
      type: ItemTypes.COMPONENT, subtype: ItemSubtypes.TEXT, dragMode,
      id,
      rowIndex,
      index,
    },
    collect: (monitor, props) => {
      setIsDragging && setIsDragging(!!monitor.isDragging())

      return {
        isDragging: !!monitor.isDragging(),
      }
    },
    canDrag: () => {
      return dragMode === 'move' || dragMode === 'clone'
    },
  })

  const [value, setValue] = useState(filledValue || '')
  useEffect(() => {
    setValue(filledValue)
  }, filledValue)

  const clicked = () => markSelected && markSelected({
    id,
    configuration,
  })

  const updateValue = value => {
    setValue(value)

    if (onChange) {
      onChange({
        id, value,
      })
    }
  }

  const placeholder = showSelfDescription ? 'Obraz' : configuration.text
  return (
    <div ref={drag}>
      {configuration && configuration.label && (
        <label>{configuration.label}</label>
      )}
      <input
        maxLength={configuration.size}
        disabled={disabled}
        type="text"
        className="input"
        name={name}
        placeholder={placeholder}
        id={id}
        onClick={clicked}
        value={value}
        onChange={({target: {value}}) => updateValue(value)}
      />
    </div>
  )
}

export default ImageControl
