import React, { useState } from "react";
import {
  Box,
  Button,
  FormControlLabel,
  Modal,
  Radio,
  RadioGroup,
  TextField,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { getAxiosInstance } from "../../redux/common";

const useStyles = makeStyles((theme) => ({
  root: {
    transform: "translateZ(0)",
  },
  modal: {
    display: "flex",
    padding: theme.spacing(),
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "white",
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  circularProgress: {
    marginLeft: 0,
    marginRight: theme.spacing.unit,
  },
}));

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

export const AddNewEmployee = ({
  loggedAsOwner,
  handleClose,
  refreshList = () => {},
}) => {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);

  const [nick, setNick] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("admin");
  const [emailError, setEmailError] = useState("");
  const [passError, setPassError] = useState("");

  const [createInProgress, setCreatingInProgess] = useState(false);

  const add = async () => {
    setCreatingInProgess(true);
    setEmailError("");
    setPassError("");

    const isAdmin = role === "admin";
    try {
      const response = await getAxiosInstance().post("/owner/v1/create", {
        name: nick,
        email,
        ...(!isAdmin
          ? {
              roles: ["SystemUser", "SystemOwner"],
            }
          : { roles: ["SystemUser"] }),
        password,
      });

      setTimeout(() => {
        setCreatingInProgess(false);
        refreshList();
        handleClose();
      }, 10000);
    } catch (e) {
      const data = e.response.data;
      if (data.name === "EmailAlreadyUsedError") {
        setEmailError("Email jset już używany w systemie");
      } else if (data.name === "PasswordRequirementsError") {
        setPassError(
          "Hasło niezgodne z regułami: 8-32 znaki, małą, duża litera, brak spacji, cyfra, znak specjalny: @ # $ % ^ & + ="
        );
      }
    }
  };

  return (
    <Modal
      className={classes.root}
      disablePortal
      disableEnforceFocus
      open
      onClose={handleClose ? handleClose : () => {}}
      aria-labelledby="Add new employee"
      aria-describedby="Adds new owner employee"
    >
      <form
        style={modalStyle}
        className={classes.paper}
        noValidate
        autoComplete="off"
      >
        <TextField
          label="Nazwa"
          autoComplete="off"
          fullWidth
          value={nick}
          onChange={(e) => setNick(e.target.value)}
        />
        <TextField
          label="Email"
          autoComplete="off"
          fullWidth
          value={email}
          error={emailError.length > 0}
          helperText={emailError}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          label="Hasło"
          autoComplete="off"
          fullWidth
          value={password}
          type="password"
          error={passError.length > 0}
          helperText={passError}
          onChange={(e) => setPassword(e.target.value)}
        />

        <RadioGroup
          aria-label="role"
          name="role"
          value={role}
          onChange={(e) => setRole(e.target.value)}
        >
          <FormControlLabel
            value="admin"
            control={<Radio />}
            label="Administrator"
          />
          {loggedAsOwner && (
            <FormControlLabel
              value="owner"
              control={<Radio />}
              label="Właściciel"
            />
          )}
        </RadioGroup>

        <Box mt={3} justifyItems="justify-end">
          <Button variant="contained" onClick={handleClose}>
            Anuluj
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={createInProgress}
            onClick={add}
          >
            {createInProgress && (
              <CircularProgress
                className={classes.circularProgress}
                size={20}
              />
            )}
            Dodaj
          </Button>
        </Box>
      </form>
    </Modal>
  );
};
