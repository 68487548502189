import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import "bulma/css/bulma.css";
import { BrowserRouter as Router, Route } from "react-router-dom";

import { DndProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import _ from "lodash";
import "./App.css";
import Header from "./components/header/header";
import HeaderMenu from "./components/header-menu";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faAngleDown,
  faAngleUp,
  faCheck,
  faCog,
  faDotCircle,
  faEye,
  faEyeSlash,
  faHeartbeat,
  faHome,
  faPen,
  faSignOutAlt,
  faSyncAlt,
  faTable,
  faTrash,
  faUserAlt,
  faUserFriends,
} from "@fortawesome/free-solid-svg-icons";
import {
  checkAuth,
  fetchConfig,
  fetchOrganization,
  logout,
  readFontSize,
  readGlobalTheme,
} from "./redux/actions";
import Alert from "./components/alert";
import Loader from "./components/loader";
import { noHeaderRoutes, routes } from "./routes";
import { fetchMyProfile } from "./redux/person/actions";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import { fetchCartItems } from "./redux/shopping-cart/actions";
import CssBaseline from "@material-ui/core/CssBaseline";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import IdleTimerContainer from "./components/idle-timer";

import {
  fontSizeLarge,
  fontSizeMedium,
  fontSizeNormal,
  highContrastTheme,
  lightTheme,
} from "./theme";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#102655",
    },
    secondary: {
      main: "#AC1426",
    },
    background: {
      default: "#FFFFFF",
    },
  },
  typography: {
    fontFamily: "Open Sans",
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
});

library.add(
  faSignOutAlt,
  faUserAlt,
  faSyncAlt,
  faUserFriends,
  faPen,
  faTrash,
  faDotCircle,
  faTable,
  faHome,
  faCog,
  faHeartbeat,
  faEye,
  faEyeSlash,
  faCheck,
  faAngleUp,
  faAngleDown
);

const App = ({
  checkAuth,
  loggedIn,
  fetchOrganization,
  fetchCartItems,
  alerts,
  loggingIn,
  checkingAuth,
  logout,
  readGlobalTheme,
  readFontSize,
  fetchConfig,
}) => {
  const globalTheme = useSelector((s) => s.globalTheme);
  const fontSize = useSelector((s) => s.fontSize);
  const [theme, setTheme] = useState(createMuiTheme(lightTheme));

  useEffect(() => {
    let mixin;
    switch (fontSize) {
      case "normal":
        mixin = fontSizeNormal;
        break;
      case "medium":
        mixin = fontSizeMedium;
        break;
      case "large":
        mixin = fontSizeLarge;
        break;
    }

    let th;
    switch (globalTheme) {
      case "light":
        th = lightTheme;
        break;
      case "high-contrast":
        th = highContrastTheme;
        break;
    }

    const themeConf = _.merge({}, th, mixin);

    setTheme(createMuiTheme(themeConf));
  }, [fontSize, globalTheme]);

  useEffect(() => {
    readGlobalTheme();
    readFontSize();
  }, []);

  useEffect(() => {
    if (
      loggedIn === undefined &&
      loggingIn === undefined
      // &&
      // !noHeaderRoutes.find(({ path }) => path === window.location.pathname)
    ) {
      checkAuth();
    }
  }, [loggedIn, loggingIn]);

  useEffect(() => {
    if (loggedIn && window.location.pathname.includes("/password-set-new")) {
      logout();
    }

    if (loggedIn) {
      fetchOrganization();
      fetchCartItems();
      fetchConfig();
    }
  }, [loggedIn]);

  if (
    (checkingAuth &&
      !noHeaderRoutes.find(({ path }) => path === window.location.pathname)) ||
    (window.location.pathname === "/account-activated" && checkingAuth) ||
    (window.location.pathname === "/account-already-activated" && checkingAuth)
  ) {
    return <Loader loading={true} text="Uwierzytelnianie" />;
  }

  const HeaderWithContent = (props) => {
    return (
      <Header {...props}>
        {routes.map((route, i) => {
          return (
            <Route
              key={route.path}
              path={route.path}
              component={route.component}
              exact={true}
            />
          );
        })}
      </Header>
    );
  };

  // const HeaderWithHomePageContent = (props) => {
  //   return (
  //     <HeaderMenu {...props}>
  //       {noHeaderRoutes.map((route) => {
  //         return (
  //           <Route
  //             key={route.path}
  //             path={route.path}
  //             component={route.component}
  //             exact={true}
  //           />
  //         );
  //       })}
  //     </HeaderMenu>
  //   );
  // };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <DndProvider backend={HTML5Backend}>
        {loggedIn && <IdleTimerContainer />}
        <Router>
          <Header>
            {routes.map((route, i) => {
              return (
                <Route
                  key={route.path}
                  path={route.path}
                  component={route.component}
                  exact={true}
                />
              );
            })}
          </Header>
          {/* <Route path="" component={HeaderWithContent} /> */}
          {/* {!loggedIn && <Route path="" component={HeaderWithHomePageContent} />} */}

          {/*<AppComment />*/}

          {!loggedIn && !checkingAuth && (
            <HeaderMenu>
              {noHeaderRoutes.map((route) => {
                return (
                  <Route
                    key={route.path}
                    path={route.path}
                    component={route.component}
                    exact={true}
                  />
                );
              })}
            </HeaderMenu>
          )}

          <div className="fixed-container">
            <Container style={{ marginTop: "34px" }}>
              {alerts.map((alert) => (
                <Box m={1} key={alert.id}>
                  <Alert {...alert} />
                </Box>
              ))}
            </Container>
          </div>
        </Router>
      </DndProvider>
    </ThemeProvider>
  );
};

const mapStateToProps = (state) => ({
  loggedIn: state.loggedIn,
  loggingIn: state.loggingIn,
  alerts: state.alerts,
  checkingAuth: state.checkingAuth,
});
const mapDispatchToProps = (dispatch) => ({
  checkAuth: () => dispatch(checkAuth()),
  fetchOrganization: () => dispatch(fetchOrganization()),
  fetchMy: () => dispatch(fetchMyProfile()),
  fetchCartItems: () => dispatch(fetchCartItems()),
  logout: () => dispatch(logout()),
  readGlobalTheme: () => dispatch(readGlobalTheme()),
  readFontSize: () => dispatch(readFontSize()),
  fetchConfig: () => dispatch(fetchConfig()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
