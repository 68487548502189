import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { normalizeZIP } from "../../../components/address-form/shipping-info";
import { makeStyles } from "@material-ui/core/styles";
import ZipInput from "../ZipInput";
import NipInput from "../NipInput";
import s from "./index.module.css";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
  formHint: {
    fontSize: theme.typography.hintText,
    lineHeight: "1.4",
    margin: "-5px auto 5px",
    color: theme.palette.text.primary,
  },

  cssLabelGreen: {
    color: "green",
  },

  cssLabelRed: {
    color: "red",
  },

  cssOutlinedInputGreen: {
    "&$cssFocused $notchedOutlineGreen": {
      borderColor: `${theme.palette.primary.main} !important`,
    },
  },

  cssOutlinedInputRed: {
    "&$cssFocused $notchedOutlineRed": {
      borderColor: `${theme.palette.primary.main} !important`,
    },
  },

  cssFocused: {},

  notchedOutlineGreen: {
    borderWidth: "1px",
    borderColor: "green !important",
  },
  notchedOutlineRed: {
    borderWidth: "1px",
    borderColor: "red !important",
  },
}));

const BillingAddressForm = ({
  cartItemEdition = false,
  nameFV,
  setNameFV,
  streetFV,
  setStreetFV,
  cityFV,
  setCityFV,
  zipFV,
  setZipFV,
  postFV,
  setPostFV,
  nipFV,
  setNipFV,
  notesFV,
  setNotesFV,
  showNotes = true,
  billingInfo,
  setBillingInfo,
  isValidListener,
  setDataChanged,
}) => {
  const classes = useStyles();

  const [zipIsValid, setZipIsValid] = useState(false);
  const [nipIsValid, setNipIsValid] = useState(false);
  const [valid, setValid] = useState(false);
  const globalTheme = useSelector((s) => s.globalTheme || "light");

  useEffect(() => {
    const isOK = nipIsValid && zipIsValid;
    setValid(isOK);
    isValidListener && isValidListener(isOK);
  }, [zipIsValid, nipIsValid]);

  const handleZIPCodeChange = (newZip) => {
    setZipFV(normalizeZIP(newZip, zipFV));
  };

  return (
    <Grid item container xs={12} spacing={1}>
      <Grid item xs={12}>
        <TextField
          label="Nazwa"
          variant="outlined"
          value={nameFV || ""}
          required={true}
          inputProps={{ maxLength: 255 }}
          inputProps={{ "aria-label": "Nazwa" }}
          InputLabelProps={{
            classes: globalTheme === "light" && {
              root:
                nameFV !== ""
                  ? globalTheme === "light" && classes.cssLabelGreen
                  : globalTheme === "light" && classes.cssLabelRed,
              focused: globalTheme === "light" && classes.cssFocused,
            },
          }}
          InputProps={{
            classes: globalTheme === "light" && {
              root:
                nameFV !== ""
                  ? classes.cssOutlinedInputGreen
                  : classes.cssOutlinedInputRed,
              focused: classes.cssFocused,
              notchedOutline:
                nameFV !== ""
                  ? classes.notchedOutlineGreen
                  : classes.notchedOutlineRed,
            },
            inputMode: "numeric",
          }}
          fullWidth
          onChange={({ target: { value: name } }) => {
            setDataChanged(true);
            setNameFV(name);
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Ulica"
          variant="outlined"
          helperText={"Przykład: Jasna 7A / 4"}
          value={streetFV || ""}
          required={true}
          inputProps={{ "aria-label": "Ulica" }}
          InputLabelProps={{
            classes: globalTheme === "light" && {
              root:
                streetFV !== "" ? classes.cssLabelGreen : classes.cssLabelRed,
              focused: classes.cssFocused,
            },
          }}
          InputProps={{
            classes: globalTheme === "light" && {
              root:
                streetFV !== ""
                  ? classes.cssOutlinedInputGreen
                  : classes.cssOutlinedInputRed,
              focused: classes.cssFocused,
              notchedOutline:
                streetFV !== ""
                  ? classes.notchedOutlineGreen
                  : classes.notchedOutlineRed,
            },
            inputMode: "numeric",
          }}
          fullWidth
          onChange={({ target: { value: street } }) => {
            setDataChanged(true);
            setStreetFV(street);
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Miasto"
          variant="outlined"
          value={cityFV || ""}
          required={true}
          inputProps={{ "aria-label": "Miasto" }}
          InputLabelProps={{
            classes: globalTheme === "light" && {
              root: cityFV !== "" ? classes.cssLabelGreen : classes.cssLabelRed,
              focused: classes.cssFocused,
            },
          }}
          InputProps={{
            classes: globalTheme === "light" && {
              root:
                cityFV !== ""
                  ? classes.cssOutlinedInputGreen
                  : classes.cssOutlinedInputRed,
              focused: classes.cssFocused,
              notchedOutline:
                cityFV !== ""
                  ? classes.notchedOutlineGreen
                  : classes.notchedOutlineRed,
            },
            inputMode: "numeric",
          }}
          fullWidth
          onChange={({ target: { value: city } }) => {
            setDataChanged(true);
            setCityFV(city);
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <ZipInput
          globalTheme={globalTheme}
          zipCode={zipFV || ""}
          handleZIPCodeChange={handleZIPCodeChange}
          isValidListener={setZipIsValid}
          setDataChanged={setDataChanged}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Poczta"
          variant="outlined"
          value={postFV || ""}
          fullWidth
          inputProps={{ "aria-label": "Poczta" }}
          onChange={({ target: { value: post } }) => {
            setDataChanged(true);
            setPostFV(post);
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <NipInput
          globalTheme={globalTheme}
          nipCode={nipFV || ""}
          setNipCode={(nip) => {
            setNipFV(nip);
          }}
          isValidListener={setNipIsValid}
          setDataChanged={setDataChanged}
        />
      </Grid>
      {showNotes && (
        <Grid item xs={12}>
          <TextField
            label="Uwagi"
            variant="outlined"
            multiline
            rows={3}
            rowsMax={8}
            value={notesFV || ""}
            fullWidth
            inputProps={{ "aria-label": "Uwagi" }}
            onChange={({ target: { value: notes } }) => {
              setDataChanged(true);
              setNotesFV(notes);
            }}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <span className={classes.formHint}>
          * Pola będą wymagane w późniejszym etapie przy składaniu zamówienia
        </span>
      </Grid>
    </Grid>
  );
};

export default BillingAddressForm;
