import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import s from "./index.module.css";
import { makeStyles } from "@material-ui/core/styles";
import Can from "../../components/can";
import { Redirect } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  paragraph: {
    color: theme.palette.paragraph.primary,
    fontSize: theme.typography.text,
  },
  contactLabel: {
    fontStyle: "normal",
    fontVariant: "normal",
    fontWeight: "700",
    fontSize: theme.typography.header,
    lineHeight: "28px",
    letterSpacing: "0.72px",
    color: theme.palette.text.alternative2,
  },
}));

const StartDetailsPage = () => {
  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const xSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const mediumScreen = useMediaQuery((theme) => theme.breakpoints.down(950));
  const imageDisplay = useMediaQuery((theme) => theme.breakpoints.down(1920));
  const largeImageDisplay = useMediaQuery((theme) =>
    theme.breakpoints.up(2200)
  );

  const classes = useStyles();

  const textList = [
    {
      text: () => (
        <p className={classes.paragraph}>
          <strong className={classes.paragraph}>LOGUJESZ SIĘ</strong> - PROFIL
          INDYWIDUALNY / ORGANIZACJA
        </p>
      ),
    },
    {
      text: () => (
        <p className={classes.paragraph}>
          <strong className={classes.paragraph}>KUPUJESZ TESTY</strong> - PROFIL
          INDYWIDUALNY / ORGANIZACJA
        </p>
      ),
    },
    {
      text: () => (
        <p className={classes.paragraph}>
          <strong className={classes.paragraph}>WYSYŁAMY TESTY</strong> –
          OTRZYMUJESZ POD WSKAZANY ADRES KOPERTĘ Z TESTAMI Z 12-ZNAKOWYM NUMEREM
          ZESTAWU
        </p>
      ),
    },
    {
      text: () => (
        <p className={classes.paragraph}>
          <strong className={classes.paragraph}>AKTYWUJESZ TESTY</strong> –
          LOGUJESZ SIĘ PONOWNIE NA{" "}
          <strong className={classes.paragraph}>ZBADAJSMAKIWECH.PL</strong> I
          AKTYWUJESZ TESTY:
          <ul style={{ listStyle: "inside" }}>
            <li
              style={{
                textIndent: "-24px",
                marginLeft: "20px",
                marginTop: "8px",
              }}
              className={classes.paragraph}
            >
              KROK 1 WPISUJĄC 12-ZNAKOWY NUMER ZESTAWU,
            </li>
            <li
              style={{
                textIndent: "-24px",
                marginLeft: "20px",
                marginTop: "8px",
              }}
              className={classes.paragraph}
            >
              KROK 2 WPISUJĄC 5-ZNAKOWY NUMER TESTU - PROFIL INDYWIDUALNY
              {<br />}
              UDOSTĘPNIASZ KOPERTY – PRZEKAZUJESZ KOPERTY Z TESTAMI PACJENTOM
              LUB PODOPIECZNYM – ORGANIZACJA
            </li>
          </ul>
        </p>
      ),
    },
    {
      text: () => (
        <>
          <p className={classes.paragraph}>
            <strong className={classes.paragraph}>WYKONUJESZ TESTY</strong> –
            POSTĘPUJESZ ZGODNIE Z INSTRUKCJĄ WYŚWIETLANĄ NA MONITORZE LUB
            EKRANIE TELEFONU - PROFIL INDYWIDUALNY
          </p>
          <p style={{ marginTop: "16px" }} className={classes.paragraph}>
            <strong className={classes.paragraph}>SPRAWDZASZ WYNIKI</strong> –
            PO WYKONANIU TESTÓW PRZEZ PACJENTÓW LUB PODOPIECZNYCH MOŻESZ
            SPRAWDZIĆ ICH WYNIKI – ORGANIZACJA
          </p>
        </>
      ),
    },
    {
      text: () => (
        <p className={classes.paragraph}>
          <strong className={classes.paragraph}>OTRZYMUJESZ WYNIKI</strong> -
          PROFIL INDYWIDUALNY
        </p>
      ),
    },
  ];

  const page = () => (
    <Grid
      container
      style={{
        height: "100%",
        width: smallScreen && "99%",
        justifyContent: "center",
        alignContent: "flex-start",
      }}
    >
      <Grid
        item
        container
        spacing={2}
        xs={12}
        sm={10}
        xl={10}
        style={{
          padding: "18px",
          margin: "auto",
          display: "flex",
        }}
        direction="column"
      >
        <Grid item xs={12}>
          <label className={classes.contactLabel}>
            JAK TO DZIAŁA - KROK PO KROKU
          </label>
        </Grid>
        <Grid item xs={12} style={{ padding: "22px" }}>
          <ol>
            {textList.map((e) => (
              <li
                className={classes.paragraph}
                style={{
                  marginBottom: "16px",
                }}
              >
                {e.text()}
              </li>
            ))}
          </ol>
        </Grid>
      </Grid>
    </Grid>
  );
  return (
    <Can
      permission="about:view"
      ok={page}
      not={() => <Redirect to="/login" />}
    />
  );
};

export default StartDetailsPage;
