import React, { useEffect, useState } from 'react'
import {connect} from 'react-redux'
import classnames from 'classnames'
import { fetchOrgUsers } from '../../redux/actions'

const FormPermissions = ({className, orgUsers, savePermissions, hide,
                           orgUsersFetched, orgUsersFetching, fetchOrgUsers,
                           selected=[]}) => {
  useEffect(() => {
    if (! orgUsersFetched && ! orgUsersFetching) {
      fetchOrgUsers();
    }
  }, [orgUsersFetched, orgUsersFetching]);

  const [selectedUsers, setSelectedUsers] = useState(selected)

  useEffect(() => {
    setSelectedUsers(selected);
  }, [selected])

  const onSelect = email => {
    setSelectedUsers([...selectedUsers, email])
  }

  const onUnselect = email => {
    setSelectedUsers(selectedUsers.filter(u => u !== email))
  }

  return (
    <div className={classnames(className, "panel")}>
      <p className="panel-heading">
        Lista użytkowników którzy mogą wypełniać ten formularz:
      </p>

        {
          orgUsers.filter((person) => person.user.removed != true).map(({firstName, surname, user: {username: email}}) => (
            <div key={email} className="panel-block">
              <label>
                <input
                  type="checkbox"
                  checked={selectedUsers.indexOf(email) !== -1}
                  onChange={e => e.target.checked ? onSelect(email) : onUnselect(email)}
                />{firstName || surname ? `${firstName} ${surname}, ` : ''}{email}
              </label>
            </div>
          ))
        }

        <div className="panel-block">
          <div className="field is-grouped is-grouped-right">
            <div className="control">
              <button className="button" onClick={() => hide()}>Anuluj</button>
            </div>
            <div className="control">
              <button className="button is-primary" onClick={() => savePermissions(selectedUsers)}>Zapisz</button>
            </div>
          </div>
        </div>
    </div>
  )
}

export default connect(state => ({
  orgUsers: state.orgUsers,
  orgUsersFetched: state.orgUsersFetched,
  orgUsersFetching: state.orgUsersFetching
}), dispatch => ({
  fetchOrgUsers: () => dispatch(fetchOrgUsers())
}))(FormPermissions)
