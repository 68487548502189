import React from "react";
import * as classnames from "classnames";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { useTheme } from "@material-ui/core/styles";

export const ConfirmDialog = ({ text, yesAction, noAction, busy }) => {
  return (
    <div className={classnames("modal", "is-active")}>
      <div className="modal-background"></div>
      <div className="modal-content">
        <div className="panel">
          <div className="panel-heading m-b-sm">{text}</div>
          <div className="block">
            <div className="field is-grouped is-grouped-right">
              <div className="control ">
                <button className="button" disabled={busy} onClick={noAction}>
                  Anuluj
                </button>
              </div>
              <div className="control">
                <button
                  className={classnames("button is-primary", {
                    "is-loading": busy,
                  })}
                  onClick={yesAction}
                >
                  Tak
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ConfirmDialogMUI = ({
  text,
  textDetails,
  yesAction,
  noAction,
  busy,
  handleClose,
  open,
}) => {
  const theme = useTheme();
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <div>
          <Typography variant="h6" gutterBottom>
            {text}
          </Typography>
        </div>
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          style={{ color: theme.palette.text.item }}
        >
          {textDetails || ""}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            noAction();
            handleClose();
          }}
          color="primary"
          autoFocus
        >
          NIE
        </Button>
        <Button
          onClick={() => {
            yesAction();
            handleClose();
          }}
          color="primary"
        >
          TAK
        </Button>
      </DialogActions>
    </Dialog>
  );
};
