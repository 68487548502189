import React, { useEffect, useState } from "react";
import { NavLink, Redirect } from "react-router-dom";
import Can from "../../components/can";
import {
  Box,
  MenuItem,
  Paper,
  Select,
  TableSortLabel,
  TextField,
} from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import Table from "@material-ui/core/Table";
import { connect, useDispatch } from "react-redux";
import {
  fetchFilledTests,
  toggleRequestedSharingByOwner,
} from "../../redux/actions";
import format from "date-fns/format";
import Checkbox from "@material-ui/core/Checkbox";
import makeStyles from "@material-ui/core/styles/makeStyles";
import AppBarTitle from "../../components/app-bar-title";
import { endOfDay, startOfDay } from "date-fns";
import FormControl from "@material-ui/core/FormControl";

const TEST_TYPE_TO_NAME_MAP = {
  TASTE_TEST: "Test smaku",
  SMELL_TEST: "Test węchu",
  DELIVERY: "-",
};

const useStyles = makeStyles(() => ({
  cursor: {
    cursor: "pointer",
  },
  positiveResult: {
    color: "green",
  },
  negativeResult: {
    color: "red",
  },
}));

const FilledKits = ({ filledTests, fetchFilledTests }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [filterModel, setFilterModel] = useState({});
  const [filters, setFilters] = useState({});

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [testType, setTestType] = useState("");

  const [columns, setColumns] = useState({
    orderNumber: {
      key: "orderNumber",
      label: "Zamówienie",
      sortable: true,
      renderFilter: filters.orderNumber,
    },
    code: {
      key: "code",
      label: "Zestaw",
      sortable: true,
    },
    testType: {
      key: "testType",
      label: "Typ testu",
      sortable: true,
      // renderFilter: (colDef) => (
      //   <>
      //     <FormControlLabel
      //       control={
      //         <Checkbox
      //           // checked={state.checkedB}
      //           // onChange={handleChange}
      //           name="checkedB"
      //           color="primary"
      //         />
      //       }
      //       label="Węch"
      //     />
      //     <FormControlLabel
      //       control={
      //         <Checkbox
      //           // checked={state.checkedB}
      //           // onChange={handleChange}
      //           name="checkedB"
      //           color="primary"
      //         />
      //       }
      //       label="Smak"
      //     />
      //   </>
      // ),
    },
    activatedByFullName: {
      key: "activatedByFullName",
      label: "Aktywujący",
      sortable: true,
    },
    filledByFullName: {
      key: "filledByFullName",
      label: "Wypełniający",
      sortable: true,
    },
    createdAt: {
      key: "createdAt",
      label: "Data rozpoczęcia",
      sortable: true,
    },
    completedAt: {
      key: "completedAt",
      label: "Data zakończenia",
      sortable: true,
    },
    result: {
      key: "result",
      label: "Wynik",
      sortable: false,
    },
    requestedSharingByOwner: {
      key: "requestedSharingByOwner",
      label: "Chcę widzieć wyniki",
      sortable: false,
    },
    details: {
      key: "details",
      label: "Szczegóły",
      sortable: false,
    },
  });

  const columnInOrder = [
    columns.orderNumber,
    columns.code,
    columns.testType,
    columns.activatedByFullName,
    columns.filledByFullName,
    columns.createdAt,
    columns.completedAt,
    columns.result,
    columns.requestedSharingByOwner,
    columns.details,
  ];

  useEffect(() => {
    setFilters({
      orderNumber: (colDef) => (
        <TextField
          size="small"
          onChange={(e) => {
            const value = e.target.value;

            if (value) {
              setFilterModel((f) => ({
                ...f,
                orderNumber: {
                  contains: value,
                },
              }));
            } else {
              setFilterModel(({ orderNumber, ...rest }) => rest);
            }
          }}
        />
      ),
      createdAt: (colDef) => {
        return (
          <TextField
            value={startDate}
            onChange={({ target: { value } }) => {
              setStartDate(value);
            }}
            type="date"
          />
        );
      },
      completedAt: (colDef) => {
        return (
          <TextField
            value={endDate}
            onChange={({ target: { value } }) => {
              setEndDate(value);
            }}
            type="date"
          />
        );
      },
      filledByFullName: (colDef) => (
        <TextField
          size="small"
          onChange={(e) => {
            const value = e.target.value;

            if (value) {
              setFilterModel((f) => ({
                ...f,
                filledByFullName: {
                  contains: value,
                },
              }));
            } else {
              setFilterModel(({ filledByFullName, ...rest }) => rest);
            }
          }}
        />
      ),
      activatedByFullName: (colDef) => (
        <TextField
          size="small"
          onChange={(e) => {
            const value = e.target.value;

            if (value) {
              setFilterModel((f) => ({
                ...f,
                activatedByFullName: {
                  contains: value,
                },
              }));
            } else {
              setFilterModel(({ activatedByFullName, ...rest }) => rest);
            }
          }}
        />
      ),
      code: () => (
        <TextField
          size="small"
          onChange={(e) => {
            const value = e.target.value;

            if (value) {
              setFilterModel((f) => ({
                ...f,
                code: {
                  contains: value,
                },
              }));
            } else {
              setFilterModel(({ code, ...rest }) => rest);
            }
          }}
        />
      ),
      testType: () => (
        <FormControl className={classes.formControl}>
          <Select
            value={testType}
            onChange={(e) => {
              setTestType(e.target.value);
            }}
          >
            <MenuItem value={""}>Brak</MenuItem>
            <MenuItem value={"SMELL_TEST"}>
              {TEST_TYPE_TO_NAME_MAP.SMELL_TEST}
            </MenuItem>
            <MenuItem value={"TASTE_TEST"}>
              {TEST_TYPE_TO_NAME_MAP.TASTE_TEST}
            </MenuItem>
          </Select>
        </FormControl>
      ),
    });
  }, [filterModel, startDate, endDate, testType]);

  const [direction, setDirection] = useState("asc");
  const [activeSortColumn, setActiveSortColumn] = useState("orderNumber");

  useEffect(() => {
    const model = {
      ...filterModel,
      ...(startDate
        ? {
            createdAt: {
              between: [
                startOfDay(new Date(startDate)).toISOString(),
                endOfDay(new Date(startDate)).toISOString(),
              ],
            },
          }
        : {}),
      ...(endDate
        ? {
            completedAt: {
              between: [
                startOfDay(new Date(endDate)).toISOString(),
                endOfDay(new Date(endDate)).toISOString(),
              ],
            },
          }
        : {}),
      ...(testType
        ? {
            testType: {
              is: testType,
            },
          }
        : {}),
    };

    const q = JSON.stringify(model);
    fetchFilledTests({
      sort: `${direction === "asc" ? "" : "-"}${activeSortColumn}`,
      ...(q != "{}" ? { q } : {}),
    });
  }, [activeSortColumn, direction, filterModel, startDate, endDate, testType]);

  const changeDirection = (columnKey) => {
    if (activeSortColumn === columnKey) {
      setDirection(direction === "asc" ? "desc" : "asc");
    } else {
      setActiveSortColumn(columnKey);
      setDirection("asc");
    }
  };

  const page = () => (
    <Box>
      <AppBarTitle value="Zrealizowane zestawy" />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {columnInOrder.map((colDef) => (
                <TableCell className={colDef.sortable ? classes.cursor : ""}>
                  {colDef.sortable ? (
                    <span onClick={() => changeDirection(colDef.key)}>
                      <TableSortLabel
                        active={colDef.key === activeSortColumn}
                        direction={direction}
                      />
                      {colDef.label}
                    </span>
                  ) : (
                    <span>{colDef.label}</span>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableHead>
            <TableRow>
              {columnInOrder.map((colDef) => (
                <TableCell className={colDef.sortable ? classes.cursor : ""}>
                  {filters[colDef.key] ? filters[colDef.key]() : null}
                  {/*{colDef.renderFilter && colDef.renderFilter(filterModel)}*/}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filledTests.map((filledTest, id) => {
              return (
                <TableRow key={id}>
                  <TableCell component="th" scope="row">
                    {filledTest.orderNumber}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {filledTest.code}
                  </TableCell>
                  <TableCell align="right">
                    {TEST_TYPE_TO_NAME_MAP[filledTest.testType]}
                  </TableCell>
                  <TableCell align="right">
                    {filledTest.activatedByFullName || filledTest.activatedBy}
                  </TableCell>
                  <TableCell align="right">
                    {filledTest.filledByFullName}
                  </TableCell>
                  <TableCell align="right">
                    {format(new Date(filledTest.createdAt), "yyyy-MM-dd HH:mm")}
                  </TableCell>
                  <TableCell align="right">
                    {filledTest.completedAt
                      ? format(
                          new Date(filledTest.completedAt),
                          "yyyy-MM-dd HH:mm"
                        )
                      : ""}
                  </TableCell>
                  <TableCell align="right">
                    {!filledTest.isShared && <span>Brak zgody</span>}

                    {filledTest.isShared && filledTest.text === "ok" && (
                      <span className={classes.positiveResult}>Prawidłowy</span>
                    )}

                    {filledTest.isShared && filledTest.text !== "ok" && (
                      <span className={classes.negativeResult}>
                        Nieprawidłowy
                      </span>
                    )}
                  </TableCell>
                  <TableCell align="right">
                    <Checkbox
                      checked={Boolean(filledTest.isSharingWithOwnerRequested)}
                      onChange={async () => {
                        await dispatch(
                          toggleRequestedSharingByOwner(
                            filledTest.scheduledTestId
                          )
                        );
                      }}
                    />
                  </TableCell>
                  <TableCell align="right">
                    <NavLink
                      to={{
                        pathname: `/tests/result/${filledTest.id}`,
                      }}
                      style={{
                        pointerEvents: filledTest.isShared ? "" : "none",
                      }}
                    >
                      Pokaż wynik szczegółowy
                    </NavLink>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );

  const redirect = () => <Redirect to="/tests/active" />;

  return <Can permission="filled-kits:page" ok={page} not={redirect} />;
};

const mapStateToProps = (state) => ({
  filledTests: state.filledTests,
});
const mapDispatchToProps = (dispatch) => ({
  fetchFilledTests: (params) => dispatch(fetchFilledTests(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FilledKits);
