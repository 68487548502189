import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  alertAdd,
  createFilledFormData,
  fetchForm,
  fetchFormData,
  fetchForms,
} from "../redux/actions";
import FormFiller from "../components/form-filler/form-filler";
import Can from "../components/can";

const FillFormPage = ({
  match,
  orgForms,
  fetchForm,
  fetchFormData,
  formData,
  createFilledFormData,
  alertAdd,
  history,
}) => {
  const [form, setForm] = useState();
  const [formTemplate, setFormTemplate] = useState();
  const [rows, setRows] = useState([]);

  const [fetching, setFetching] = useState();
  const [values, setValues] = useState({});

  const testId = match.params.testId;
  const scheduledTestId = match.params.scheduledTestId;

  useEffect(() => {
    if (fetching === undefined) {
      const formId = parseInt(testId, 10);
      if (formId) {
        setFetching(true);
        fetchForm(formId).then((fff) => {
          setFetching(false);
        });
      }
    }
  }, [orgForms, fetching, match, fetchForm]);

  useEffect(() => {
    const formId = parseInt(match.params.testId);
    const f = orgForms.find(({ id }) => id === formId);
    if (f) {
      setForm(f);
    }
  }, [orgForms, match]);

  useEffect(() => {
    if (form && !formTemplate && form.layoutId) {
      fetchFormData(form.layoutId);
      setFormTemplate(true);
    }
  }, [form, formTemplate, fetchFormData]);

  useEffect(() => {
    const loToRows = () => {
      return formData.layoutElementObject.children.map((c) => {
        return c.children.map((c) => {
          return c;
        });
      });
    };

    if (formData) {
      setRows(loToRows());
    }
  }, [formData]);

  useEffect(() => {
    let tmpValues = [values];

    if (rows.length !== tmpValues.length) {
      for (let i = 0; i < rows.length; i++) {
        if (values[rows[i][0].id] === undefined) {
          tmpValues.splice(i, 0, { [rows[i][0].id]: "" });
        }
      }

      tmpValues = tmpValues.filter(
        (tmpValue) => Object.keys(tmpValue).length !== 0
      );

      let tmpValuesObject = {};
      tmpValues.forEach(function (item) {
        Object.keys(item).forEach(function (key) {
          tmpValuesObject[key] = item[key];
        });
      });
      setValues(tmpValuesObject);
    }
  }, [rows]);

  const onFormUpdated = (data) => {
    if (data) {
      setValues({
        ...values,
        [data.id]: data.value,
      });
    }
  };

  useEffect(() => {
    // TODO: remove me
  }, [values]);

  const save = async (e) => {
    await createFilledFormData({
      formId: form.id,
      scheduledTestId,
      values,
    });

    alertAdd({ text: "Zapisano dane" });

    history.push("/tests");
  };

  const cancel = (e) => {
    history.push("/");
  };

  const page = () => (
    <div className="container is-fluid">
      <div className="title">Wypełnianie formularza "{form && form.name}"</div>
      {form && form.description && (
        <>
          <b>Opis</b>
          <br />
          <small>{form.description}</small>
        </>
      )}

      <hr />

      {rows.length && <FormFiller onUpdate={onFormUpdated} rows={rows} />}

      <hr />

      <div className="field is-grouped is-grouped-right">
        <div className="control">
          <button className="button" onClick={cancel}>
            Anuluj
          </button>
        </div>

        <div className="control">
          <button className="button is-primary " onClick={save}>
            Zapisz
          </button>
        </div>
      </div>
    </div>
  );

  const noAccess = () => <div>Brak dostępu</div>;

  return <Can permission="test-fill:page" ok={page} not={noAccess()} />;
};

const mapStateToProps = (state) => ({
  organization: state.organization,
  orgForms: state.orgForms,
  formData: state.formData,
});

const mapDispatchToProps = (dispatch) => ({
  fetchForms: () => dispatch(fetchForms()),
  fetchForm: (id) => dispatch(fetchForm(id)),
  fetchFormData: (id) => dispatch(fetchFormData(id)),
  createFilledFormData: ({ formId, scheduledTestId, values }) =>
    dispatch(
      createFilledFormData({
        formId,
        scheduledTestId,
        values,
      })
    ),
  alertAdd: (payload) => dispatch(alertAdd(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FillFormPage);
