import { checkPermission } from "../../rbac";
import { useSelector } from "react-redux";

const Can = ({ permission, ok, not }) => {
  const roles = useSelector((s) => s.roles);
  if (checkPermission(permission, roles)) {
    return ok ? ok() : null;
  } else {
    return not ? not() : null;
  }
};

export default Can;
