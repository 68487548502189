import React, { useEffect, useState } from "react";
import {
  alertAdd,
  fetchTerms,
  register_organization,
} from "../../redux/actions";
import { useHistory } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import Loader from "../../components/loader";
import ReCAPTCHA from "react-google-recaptcha";
import LeftComponentMui from "../../left-component-mui";
import Grid from "@material-ui/core/Grid";
import { Box } from "@material-ui/core";
import { LockOpen, Visibility } from "@material-ui/icons";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import styled from "styled-components";
import s from "./index.module.css";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FormControl from "@material-ui/core/FormControl";
import Tooltip from "@material-ui/core/Tooltip";
import Paper from "@material-ui/core/Paper";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles, withStyles, useTheme } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import Checkbox from "@material-ui/core/Checkbox";

const OrangeButton = styled(Button)`
  background-color: orange;
  color: white;
`;

const MuiNavLink = withStyles((theme) => ({
  root: {
    color: theme.palette.anchor.primary,
    textDecoration: "underline",
    "&:hover": {
      color: theme.palette.text.primary,
      textDecoration: "underline",
    },
  },
}))(Link);

const useStyles = makeStyles((theme) => ({
  input: {
    background: "white",
    "&:hover": {
      background: "white",
    },
    "&.Mui-focused": {
      background: "white",
    },
  },
  formHint: {
    fontSize: theme.typography.hintText,
    lineHeight: "1.4",
    margin: "-5px auto 5px",
    color: theme.palette.text.primary,
  },
  labelStrengthPassword: {
    display: "block",
    posistion: "relative",
    fontSize: "small",
    fontWeight: "500",
    width: "100%",
    color: theme.palette.text.primary,
  },
  errorFormHint: {
    fontSize: theme.typography.hintText,
    lineHeight: "1.4",
    margin: "-5px auto 5px",
    color: theme.palette.error.main,
  },
  text: {
    color: theme.palette.text.primary2,
    fontSize: theme.typography.footer,
  },
  paperStyle: {
    padding: "12px",
    // height: "90vh",
    width: "100%",
    background: theme.palette.background.tab,
    border: `1px solid ${theme.palette.background.border}`,
    borderBottomLeftRadius: "1rem",
    borderBottomRightRadius: "1rem",
    borderTopRightRadius: "0px",
    borderTopLeftRadius: "0px",
  },
}));

const registerStatusLabels = [
  {
    value: "PERSONAL",
    label: "Konto indywidualne",
    description:
      "Możesz kupić Testy Smaku i Węchu dla siebie, swoich dzieci lub podopiecznych.",
  },
  {
    value: "ORGANIZATION",
    label: "Organizacja",
    description:
      "Możesz kupić Testy Smaku i Węchu dla swoich pacjentów, pracowników, podopiecznych, klientów czy kuracjuszy i przekazać im je do wykonania.",
  },
];
const SignUp = ({
  creatingOrganization,
  joiningComplete,
  joinedOrganization,
  joiningOrganization,
  setReloadPage,
  providedRegisterData,
  setProvidedRegisterData,
}) => {
  const [name, setName] = useState(providedRegisterData.name || "");
  const [surname, setSurname] = useState(providedRegisterData.surname || "");
  const [orgName, setOrgName] = useState(providedRegisterData.orgName || "");
  const [mail, setMail] = useState(providedRegisterData.mail || "");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [formIsValid, setFormIsValid] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [recaptchaValid, setRecaptchaValid] = useState(false);
  const [passwordScore, setPasswordScore] = useState(0);
  const [passwordMissingElements, setPasswordMissingElements] = useState([]);
  const [typingTimer, setTypingTimer] = useState(null);
  const [termList, setTermList] = useState([]);
  const [allTermsAccepted, setAllTermsAccepted] = useState(false);

  const [isRegisterInProgress, setIsRegisterInProgress] = useState(false);
  const [registerCompleted, setRegisterCompleted] = useState(false);
  const [registerStatus, setRegisterStatus] = useState(
    providedRegisterData.registerStatus || "PERSONAL"
  );

  const [invalidPasswordInputChar, setInvalidPasswordInputChar] = useState(
    false
  );
  const [emailValid, setEmailValid] = useState(false);
  const [passwordValid, setPasswordValid] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();

  const terms = useSelector((s) => s.terms);

  const classes = useStyles();

  useEffect(() => {
    if (termList.length === 0) {
      dispatch(fetchTerms());
    }

    setAllTermsAccepted(
      termList.reduce((all, current) => all && current.accepted, true)
    );
  }, [termList]);

  useEffect(() => {
    if (mail) {
      let polishChars1 = String(mail)
        .toLowerCase()
        .normalize("NFD")
        .search(/[\u0300-\u036f]/g);
      let polishChars2 = String(mail)
        .toLowerCase()
        .normalize("NFD")
        .search(/\u0142/g);

      setEmailValid(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          String(mail).toLowerCase()
        ) &&
          (polishChars2 !== 0
            ? polishChars1 + polishChars2 < 0
            : polishChars1 + polishChars2 < -1)
      );
    }
  }, [mail]);

  useEffect(() => {
    if (terms.length > 0) {
      setTermList(
        terms.map((t) => ({
          ...t,
          accepted: false,
        }))
      );
    }
  }, [terms]);

  const submit = async (e) => {
    e.preventDefault();

    if (formIsValid) {
      const errorHandler = (e) => {
        setProvidedRegisterData({
          name: name,
          surname: surname,
          orgName: orgName,
          mail: mail,
          registerStatus: registerStatus,
        });
        let errorMessage;
        setIsRegisterInProgress(false);
        if (e.response.status === 409) {
          if (
            e.response.data.message ===
            "Organization name is not unique. Please change the name."
          ) {
            errorMessage = "Nazwa organizacji jest już używana w systemie.";
          } else {
            errorMessage =
              "Błąd rejestracji, email jest już używany w systemie.";
          }
        } else {
          errorMessage = `Błąd rejestracji, (${e.message}).`;
        }

        dispatch(
          alertAdd({
            text: errorMessage,
          })
        );
      };

      try {
        const domain = window.location.hostname;

        const proto = window.location.protocol === "https:" ? "wss" : "ws";
        const port = window.location.port ? ":" + window.location.port : "";

        const ws = new WebSocket(
          `${proto}://${domain}${port}/tenants/v1/ws/registration?email=${mail.toLowerCase()}`
        );

        ws.onopen = async function (e) {
          setIsRegisterInProgress(true);
          let payload = {};
          registerStatus === "PERSONAL"
            ? (payload = {
                firstName: name,
                surname,
                mail: mail.toLowerCase(),
                password,
                accountType: registerStatus,
              })
            : (payload = {
                orgName,
                mail: mail.toLowerCase(),
                password,
                accountType: registerStatus,
              });

          try {
            await dispatch(register_organization(payload));
          } catch (e) {
            errorHandler(e);
          }
        };
        ws.onmessage = function (e) {
          setIsRegisterInProgress(
            e.data.includes("TenantCreatedWsPayload") && false
          );
          setRegisterCompleted(e.data.includes("TenantCreatedWsPayload"));
        };
      } catch (e) {
        errorHandler(e);
      }
    }
  };

  const cancel = (e) => {
    e.preventDefault();

    history.push("/login");
  };

  useEffect(() => {
    if (registerStatus === "PERSONAL") {
      setFormIsValid(
        name &&
          surname &&
          mail &&
          password &&
          passwordsMatch &&
          recaptchaValid &&
          allTermsAccepted &&
          passwordScore > 4
      );
    } else if (registerStatus === "ORGANIZATION") {
      setFormIsValid(
        orgName &&
          mail &&
          password &&
          passwordsMatch &&
          recaptchaValid &&
          allTermsAccepted &&
          passwordScore > 4
      );
    }
  }, [
    orgName,
    name,
    surname,
    mail,
    passwordsMatch,
    password,
    recaptchaValid,
    allTermsAccepted,
    passwordScore,
    registerStatus,
  ]);

  useEffect(() => {
    setPasswordsMatch(password === password2);
  }, [password, password2]);

  useEffect(() => {
    if (registerCompleted) {
      setProvidedRegisterData({});
      setReloadPage(false);
      dispatch(
        alertAdd({
          text: "Rejestracja przebiegła pomyślnie.",
        })
      );
      history.push("/login");
      // setReloadPage(true);
    }
  }, [registerCompleted]);

  useEffect(() => {
    if (joiningComplete && isRegisterInProgress) {
      dispatch(
        alertAdd({
          text: "Rejestracja przebiegła pomyślnie.",
        })
      );
      history.push("/login");
    }
  }, [joiningComplete, history]);

  useEffect(() => {
    joinedOrganization === false &&
      dispatch(
        alertAdd({
          text: "Dołączenie do organizacji nie powiodło się.",
        })
      );
  }, [joinedOrganization]);

  const onChange = (value) => {
    setRecaptchaValid(!!value);
  };

  useEffect(() => {
    clearTimeout(typingTimer);
  }, []);

  const passwordMissingElementFilter = (
    tmpPasswordMissingElements,
    element
  ) => {
    return tmpPasswordMissingElements.filter(
      (missingElement) => missingElement !== element
    );
  };

  const testStrengthPassword = (e) => {
    let pass = e.target.value;
    let tmpPasswordScore = 0;
    let tmpPasswordMissingElements = [
      "Przynajmniej jedną małą literę",
      "Przynajmniej jedną wielką literę",
      "Przynajmniej jeden znak specjalny: ! @ # $ % & * _ + = ^",
      "Przynajmniej jedeną cyfrę",
      "Długość znaków między 8, a 32.",
    ];
    if (pass.length > 7 && pass.length < 33) {
      tmpPasswordScore = tmpPasswordScore + 1;
      tmpPasswordMissingElements = passwordMissingElementFilter(
        tmpPasswordMissingElements,
        "Długość znaków między 8, a 32."
      );
    }
    if (/[a-z]/.test(pass)) {
      tmpPasswordScore = tmpPasswordScore + 1;
      tmpPasswordMissingElements = passwordMissingElementFilter(
        tmpPasswordMissingElements,
        "Przynajmniej jedną małą literę"
      );
    }
    if (/[A-Z]/.test(pass)) {
      tmpPasswordScore = tmpPasswordScore + 1;
      tmpPasswordMissingElements = passwordMissingElementFilter(
        tmpPasswordMissingElements,
        "Przynajmniej jedną wielką literę"
      );
    }
    if (/[0-9]/.test(pass)) {
      tmpPasswordScore = tmpPasswordScore + 1;
      tmpPasswordMissingElements = passwordMissingElementFilter(
        tmpPasswordMissingElements,
        "Przynajmniej jedeną cyfrę"
      );
    }
    if (/[!@#$%^&*+=_]+/.test(pass)) {
      tmpPasswordScore = tmpPasswordScore + 1;
      tmpPasswordMissingElements = passwordMissingElementFilter(
        tmpPasswordMissingElements,
        "Przynajmniej jeden znak specjalny: ! @ # $ % & * _ + = ^"
      );
    }

    clearTimeout(typingTimer);
    if (tmpPasswordScore === 0) {
      setPasswordScore(tmpPasswordScore);
    }

    setPasswordValid(tmpPasswordScore === 5);

    setTypingTimer(
      setTimeout(() => {
        if (tmpPasswordScore) {
          setPasswordScore(tmpPasswordScore);
          setPasswordMissingElements(tmpPasswordMissingElements);
        }
      }, 1000)
    );
  };

  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const avatarStyle = { backgroundColor: "#1bbd7e" };
  const btnstyle = { margin: "8px 0" };

  return (
    <Paper className={classes.paperStyle}>
      <Grid container style={{ display: "flex", justifyContent: "center" }}>
        {/* <Grid item xs={12}>
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <LockOpen />
            <span style={{ fontSize: "20px" }}> Załóż konto</span>
          </Box>
        </Grid> */}
        {
          <Grid item xs={10}>
            <Loader
              loading={creatingOrganization || isRegisterInProgress}
              text={
                registerStatus === "PERSONAL"
                  ? "Tworzenie konta indywidualnego"
                  : "Tworzenie organizacji"
              }
            />
          </Grid>
        }

        {!isRegisterInProgress && (
          <Grid item xs={10}>
            <Box as="h1" fontSize={20} textAlign="center">
              {registerStatus === "create" && (
                <Box mt={1} as="h1" fontSize={15} textAlign="left">
                  Rejestrujesz się jako administrator oraz jednocześnie
                  zakładasz własną{" "}
                  <Tooltip title="W ramach organizacji, możesz budować formularze, zarządzać grupami użytkowników, udostępniając im formularze oraz przeglądać wyniki i eksportować je do własnych systemów.">
                    <a>organizację</a>
                  </Tooltip>
                  .
                </Box>
              )}
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    value={registerStatus || "create"}
                    name="customized-radios"
                  >
                    {registerStatusLabels.map((status, index) => {
                      return (
                        <Tooltip title={status.description}>
                          <FormControlLabel
                            key={index}
                            value={status.value}
                            label={status.label}
                            control={<Radio color={"primary"} />}
                            onChange={(event) =>
                              setRegisterStatus(event.currentTarget.value)
                            }
                          />
                        </Tooltip>
                      );
                    })}
                  </RadioGroup>
                </FormControl>
              </Box>
            </Box>
            <form onSubmit={submit} noValidate autoComplete="off">
              {registerStatus === "PERSONAL" ? (
                <>
                  <Box mt={1} display="flex">
                    <TextField
                      label="Imię"
                      variant="filled"
                      value={name}
                      fullWidth
                      required
                      onChange={(e) => setName(e.target.value)}
                      // InputProps={{ className: classes.input }}
                      inputProps={{ "aria-label": "imię" }}
                    />
                  </Box>
                  <Box mt={1} display="flex">
                    <TextField
                      label="Nazwisko"
                      variant="filled"
                      value={surname}
                      fullWidth
                      required
                      onChange={(e) => setSurname(e.target.value)}
                      // InputProps={{ className: classes.input }}
                      inputProps={{ "aria-label": "nazwisko" }}
                    />
                  </Box>
                </>
              ) : (
                <Box mt={1} display="flex">
                  <TextField
                    label="Nazwa organizacji"
                    variant="filled"
                    value={orgName}
                    fullWidth
                    required
                    onChange={(e) => setOrgName(e.target.value)}
                    // InputProps={{ className: classes.input }}
                    inputProps={{ "aria-label": "nazwa organizacji" }}
                  />
                </Box>
              )}
              <Box mt={1}>
                <TextField
                  name="email"
                  label={"Email"}
                  variant="filled"
                  value={mail}
                  error={mail && !emailValid}
                  helperText={
                    mail &&
                    !emailValid &&
                    "Nieprawidłowy format wprowadzonego adresu email"
                  }
                  required
                  fullWidth
                  onChange={(e) => setMail(e.target.value.split(" ").join(""))}
                  // InputProps={{ className: classes.input }}
                  inputProps={{
                    "aria-label": "Email",
                    "&:-webkit-autofill": {
                      WebkitBoxShadow: `0 0 0 1000px ${theme.palette.background.default} inset`,
                    },
                    fontSize: "30px",
                  }}
                />
              </Box>
              <Box mt={1}>
                <TextField
                  label="Hasło"
                  variant="filled"
                  type={showPassword ? "text" : "password"}
                  value={password}
                  error={
                    invalidPasswordInputChar || (!passwordValid && password)
                  }
                  helperText={
                    invalidPasswordInputChar
                      ? "Spacja nie jest dozwolona."
                      : !passwordValid && password
                      ? "Hasło nie spełnia wymogów"
                      : ""
                  }
                  required
                  onChange={(e) => {
                    if (e.target.value.includes(" ")) {
                      setInvalidPasswordInputChar(true);
                      setTimeout(
                        () => setInvalidPasswordInputChar(false),
                        3000
                      );
                    } else {
                      setInvalidPasswordInputChar(false);
                    }
                    setPassword(e.target.value.split(" ").join(""));
                    testStrengthPassword(e);
                  }}
                  fullWidth
                  InputProps={{
                    // className: classes.input,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowPassword(!showPassword)}
                          aria-label="toggle password visibility"
                        >
                          <Visibility />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{
                    "aria-label": "hasło",
                    "&:-webkit-autofill": {
                      WebkitBoxShadow: `0 0 0 1000px ${theme.palette.background.default} inset`,
                    },
                  }}
                />
              </Box>
              <Box mt={1}>
                <TextField
                  label="Powtórz hasło"
                  variant="filled"
                  type={showPassword ? "text" : "password"}
                  value={password2}
                  required
                  error={password2 && !passwordsMatch}
                  helperText={
                    password2 && !passwordsMatch && "Hasła nie pasują do siebie"
                  }
                  onChange={(e) =>
                    setPassword2(e.target.value.split(" ").join(""))
                  }
                  fullWidth
                  InputProps={{
                    // className: classes.input,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowPassword(!showPassword)}
                          aria-label="toggle password visibility"
                        >
                          <Visibility />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{ "aria-label": "powtórz hasło" }}
                />
              </Box>
              <Box mt={1}>
                <span className={classes.formHint}>* Pola wymagane</span>
              </Box>
              <Box mt={1} display="flex">
                <span className={classes.formHint}>
                  Aby spełnić zalożenie polityki Silnego Hasła prosi się o
                  podanie co najmniej jednej wielkiej litery, małej litery,
                  cyfry oraz znaku specjanlego. Hasło powinno być dłuższe niż 7
                  znaków.
                </span>
              </Box>
              {passwordScore > 0 && (
                <>
                  <label className={classes.labelStrengthPassword}>
                    Siła hasła:
                  </label>
                  <Box mt={1} display="flex">
                    <span
                      className={s.strengthPassword}
                      dataScore={passwordScore}
                    />
                  </Box>
                  {passwordMissingElements.length > 0 && (
                    <label
                      className={classes.errorFormHint}
                      style={{ marginTop: "10px" }}
                    >
                      Aby hasło było silne, należy zawrzeć:
                    </label>
                  )}
                  <span className={classes.errorFormHint}>
                    {passwordMissingElements.map((el, index) => {
                      return <li key={index}>{el}</li>;
                    })}
                  </span>
                </>
              )}
              <Box mt={1}>
                {termList && termList.length > 0 && (
                  <label className={classes.text}>
                    Akceptuję następujące regulaminy:{" "}
                  </label>
                )}{" "}
              </Box>
              <Box>
                {termList &&
                  termList.length > 0 &&
                  termList.map((term) => {
                    return (
                      <div key={term.id}>
                        <FormControlLabel
                          // style={{ height: "25px" }}
                          control={
                            <Checkbox
                              id={term.name}
                              name="checked"
                              color="primary"
                              checked={term.accepted}
                              onChange={() => {
                                setTermList(
                                  termList.map((acceptedTerm) =>
                                    acceptedTerm.id === term.id
                                      ? {
                                          ...acceptedTerm,
                                          accepted: !acceptedTerm.accepted,
                                        }
                                      : acceptedTerm
                                  )
                                );
                              }}
                              style={{ marginRight: "5px", marginLeft: "10px" }}
                            />
                          }
                          label={
                            <span>
                              <label
                                className={classes.text}
                                style={{ marginRight: "8px" }}
                                for={term.name}
                              >
                                {term.name}
                              </label>
                              <MuiNavLink
                                href={`/org-media/files${term.link}`}
                                target={"_blank"}
                              >
                                przeczytaj
                              </MuiNavLink>
                            </span>
                          }
                        />
                      </div>
                    );
                  })}
              </Box>
              <Box mt={1}>
                <ReCAPTCHA
                  size={"compact"}
                  // size={smallScreen ? "compact" : "neutral"}
                  sitekey="6Lev2u4UAAAAAE0emXI5hh5W-VfQWagdCiqBpXRu"
                  onChange={onChange}
                />
              </Box>
              <Box mt={1}>
                <Button
                  type="submit"
                  color="primary"
                  variant={"contained"}
                  fullWidth
                  disabled={!formIsValid || (mail && !emailValid)}
                >
                  Zarejestruj się
                </Button>
              </Box>
              {/* </Grid> */}
            </form>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};

const mapStateToProps = (state) => ({
  creatingOrganization: state.creatingOrganization,
  joiningOrganization: state.joiningOrganization,
  joinedOrganization: state.joinedOrganization,
  joiningComplete: state.joiningComplete,
});

export default connect(mapStateToProps)(SignUp);
