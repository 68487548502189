import React, { useState, useEffect } from "react";
import { getAxiosInstance } from "../../redux/common";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllProteges, addProtege, editProtege } from "../../redux/actions";
import { updateMyProfile } from "../../redux/person/actions";
import {
  TextField,
  Button,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Typography,
  ListSubheader,
  Link,
} from "@material-ui/core";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import { Link as RouterLink, useHistory } from "react-router-dom";
import Themed from "./themed";
import envelopeIcon from "../../assets/envelope3.jpg";
import AppBarTitle from "../../components/app-bar-title";
import Loader from "../../components/loader";
import ProtegesModal from "../proteges/proteges-handler-modal";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import plLocale from "date-fns/locale/pl";
import DateFnsUtils from "@date-io/date-fns";
import * as moment from "moment";
import { QRScanner } from "../../components/qr-scanner";
import Can from "../../components/can";
import { Redirect } from "react-router-dom";

const MuiNavLink = withStyles((theme) => ({
  root: {
    color: theme.palette.anchor.primary,
    underline: "always",
    fontWeight: "600",
    "&:hover": {
      color: theme.palette.text.primary,
    },
  },
}))(Link);

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(1),
    backgroundColor: theme.palette.background.default,
  },
  container: {
    backgroundColor: theme.palette.background.default,
    "& form": {
      padding: theme.spacing(2),
    },
    "& input": {
      height: "auto",
    },
    paddingBottom: theme.spacing(3),
  },
  button: {
    borderRadius: "8px",
    // color: "white",
    // background: "#A8A8A8",
  },
  buttonSubmit: {
    borderRadius: "8px",
    // color: "white",
    // background: "#102655",
    // "&.Mui-disabled": {
    //   backgroundColor: "#102655",
    // },
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    paddingTop: theme.spacing(1),
    "& button": {
      marginLeft: theme.spacing(2),
    },
  },
  imageContainer: {
    width: "100%",
    backgroundColor: theme.palette.background.default,
    paddingBottom: theme.spacing(3),
    textAlign: "center",
    "&>img": {
      width: "80%",
    },
  },
  errorText: {
    color: theme.palette.error.main,
    marginTop: theme.spacing(1),
  },
  focused: {
    "& label": {
      color: theme.palette.text.item,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: theme.palette.text.item,
        color: theme.palette.text.alternative3,
      },
      "& .Mui-focused": {
        borderColor: theme.palette.text.alternative3,
        color: theme.palette.text.alternative3,
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.text.alternative3,
        color: theme.palette.text.alternative3,
      },
    },
  },
}));

const PersonDetails = (props) => {
  const [formData, setFormData] = useState({
    firstName: "",
    surname: "",
    yearOfBirth: "",
    gender: "",
  });
  const [canContinue, setCanContinue] = useState(false);
  const [protegeList, setProtegeList] = useState([]);
  const [protegeId, setProtegeId] = useState("");
  const { classes, me, setPersonDetails } = props;
  const [openProtegesModal, setOpenProtegesModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [emptySetsText, setEmptySetsText] = useState("");

  useEffect(() => {
    async function loadSets() {
      try {
        const scheduledResult = await getAxiosInstance().get(
          "/api/v2/forms/scheduled",
          {
            params: {
              page: 0,
              pageSize: 100,
              orderL: "asc",
            },
          }
        );

        setEmptySetsText(scheduledResult?.data?.pagination.totalElements === 0);

        // setFetchingSets(false);
      } catch (e) {
        // console.error("sets error", e);
        // setFetchingSets(false);
      }
    }
    loadSets();
  }, []);

  useEffect(() => {
    setCanContinue(
      formData.firstName.length > 0 &&
        formData.surname.length > 0 &&
        formData.yearOfBirth.toString().length === 4 &&
        formData.gender.length > 0
    );
  }, [formData]);

  const dispatch = useDispatch();
  const theme = useTheme();
  const protegeAdded = useSelector((s) => s.protegeAdded);

  const handleChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    setFormData((formData) => ({
      ...formData,
      [name]: value,
    }));
  };

  const resetFormData = () => {
    let formData = {
      firstName: "",
      surname: "",
      yearOfBirth: "",
      gender: "",
    };

    if (protegeId === 0) {
      formData = {
        id: me.user.id,
        firstName: me.firstName,
        surname: me.surname,
        yearOfBirth: me.yearOfBirth || "",
        gender: me.gender || "",
      };
    } else if (protegeId >= 0) {
      const acc = protegeList.find((protege) => protege.id === protegeId);
      if (acc) {
        formData = {
          id: acc.id.toString(),
          firstName: acc.firstName,
          surname: acc.surname,
          yearOfBirth: acc.yearOfBirth,
          gender: acc.gender,
        };
      }
    }

    setFormData(formData);
  };

  const handleProtegeChange = (event) => {
    const target = event.target;
    const value = target.value;

    if (value !== undefined) {
      setProtegeId(value);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (editMode) {
      updateProfile();
    } else {
      setPersonDetails(formData);
    }
  };

  const loadProteges = async () => {
    try {
      const subaccounts = (await dispatch(fetchAllProteges())).sort((a, b) => {
        return a.firstName > b.firstName;
      });

      setProtegeList(subaccounts);
    } catch (e) {
      console.error(e.response);
      setProtegeList([]);
    }
  };

  const updateProfile = async () => {
    if (editMode && protegeId !== "") {
      try {
        if (protegeId === 0) {
          const newMe = {
            firstName: formData.firstName,
            surname: formData.surname,
            yearOfBirth: formData.yearOfBirth,
            gender: formData.gender,
            address: me.address,
          };
          const result = await dispatch(updateMyProfile(newMe));
          setEditMode(false);
        } else {
          const editedProtege = await dispatch(
            editProtege(protegeId, formData)
          );
          const indexToReplace = protegeList.findIndex((protege) => {
            return protege.id === editedProtege.id;
          });
          if (indexToReplace >= 0) {
            protegeList[indexToReplace] = editedProtege;
          }
          setEditMode(false);
        }
      } catch (e) {
        console.error(e.response);
        setErrorText("Coś poszło nie tak. Spróbuj ponownie później.");
      }
    }
  };

  useEffect(() => {
    loadProteges();
  }, [protegeAdded]);

  useEffect(() => {
    resetFormData();
  }, [protegeId, editMode]);

  useEffect(() => {
    setErrorText("");
  }, [formData]);

  return (
    <Box className={classes.container}>
      {openProtegesModal && (
        <ProtegesModal
          openProtegesModal={openProtegesModal}
          protegeHandlerType="add"
          handleClose={() => {
            setOpenProtegesModal(false);
          }}
          addProtege={(payload) => dispatch(addProtege(payload))}
        />
      )}
      <Grid container justify="center" padding={2}>
        <Grid item xs={12} sm={8}>
          <form onSubmit={handleSubmit}>
            <Box p={1}>
              {emptySetsText && (
                <Typography
                  variant="h5"
                  style={{
                    fontWeight: "500",
                    fontSize: "18",
                    color: theme.palette.text.item,
                  }}
                >
                  Nie masz jeszcze żadnego aktywnego testu
                </Typography>
              )}
            </Box>
            <Box p={1}>
              <Typography
                variant="h5"
                style={{
                  fontWeight: "600",
                  fontSize: "18",
                  color: theme.palette.text.alternative2,
                }}
              >
                Aktywuj nowy zestaw
              </Typography>
            </Box>
            <Box p={1}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="sub-account-select-label" required>
                  Profil
                </InputLabel>
                <Select
                  labelId="sub-account-select-label"
                  name="sub-account"
                  required
                  disabled={editMode}
                  value={protegeId}
                  onChange={handleProtegeChange}
                  label="Profil *"
                >
                  <MenuItem
                    key={0}
                    value={0}
                    style={{ color: theme.palette.text.alternative2 }}
                  >
                    Dla siebie
                  </MenuItem>
                  {protegeList.length > 0 && (
                    <MenuItem
                      disabled
                      style={{
                        color: theme.palette.text.alternative2,
                        opacity: "1",
                        fontSize: theme.typography.fontSize,
                        // background: "white",
                        fontWeight: "600",
                      }}
                    >
                      DLA DZIECKA
                    </MenuItem>
                  )}
                  {protegeList.map((protege, index) => {
                    return (
                      <MenuItem
                        key={index}
                        value={protege.id}
                        style={{
                          color: theme.palette.text.alternative2,
                          fontWeight: "400",
                        }}
                      >
                        {protege.firstName}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
            <Box className={classes.buttonContainer} p={2} mb={2}>
              <Button
                className={classes.button}
                variant="contained"
                size="small"
                fullWidth
                disabled={editMode || protegeId === ""}
                onClick={() => {
                  setEditMode(true);
                }}
              >
                Edytuj profil
              </Button>
              <Button
                className={classes.buttonSubmit}
                variant="contained"
                color="primary"
                size="small"
                fullWidth
                disabled={editMode}
                onClick={() => {
                  setOpenProtegesModal(true);
                }}
              >
                Dodaj profil
              </Button>
            </Box>
            <Box p={1}>
              <TextField
                className={classes.focused}
                name="firstName"
                label="Imię"
                disabled={!editMode}
                variant="outlined"
                fullWidth
                required
                value={formData.firstName}
                onChange={handleChange}
                inputProps={{ "aria-label": "Imię" }}
              />
            </Box>
            <Box p={1}>
              <TextField
                className={classes.focused}
                name="surname"
                label="Nazwisko"
                disabled={!editMode}
                variant="outlined"
                fullWidth
                required
                value={formData.surname}
                onChange={handleChange}
                inputProps={{ "aria-label": "Nazwisko" }}
              />
            </Box>
            <Box p={1}>
              <MuiPickersUtilsProvider locale={plLocale} utils={DateFnsUtils}>
                <KeyboardDatePicker
                  className={classes.focused}
                  id="start-date-picker-dialog"
                  label="Rok urodzenia"
                  required
                  disabled={!editMode}
                  inputVariant="outlined"
                  fullWidth
                  helperText={""}
                  value={formData.yearOfBirth.toString() || null}
                  onChange={(value) => {
                    let insertedYear = moment(value, "DD/MM/YYYY").year();
                    if (insertedYear < 1900) {
                      insertedYear = 1900;
                    } else if (new Date().getFullYear() < insertedYear) {
                      insertedYear = new Date().getFullYear();
                    }
                    setFormData({
                      ...formData,
                      yearOfBirth: insertedYear.toString(),
                    });
                  }}
                  views={["year"]}
                  okLabel="Zatwierdź"
                  clearLabel="Wyczyść"
                  cancelLabel="Anuluj"
                />
              </MuiPickersUtilsProvider>
            </Box>
            <Box p={1}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="gender-select-label" required>
                  Płeć
                </InputLabel>
                <Select
                  className={classes.focused}
                  labelId="gender-select-label"
                  name="gender"
                  required
                  disabled={!editMode}
                  value={formData.gender}
                  onChange={handleChange}
                  label="Płeć *"
                >
                  <MenuItem value={"Male"}>Mężczyzna</MenuItem>
                  <MenuItem value={"Female"}>Kobieta</MenuItem>
                </Select>
              </FormControl>
            </Box>
            {errorText && (
              <Box>
                <Typography className={classes.errorText}>
                  {errorText}
                </Typography>
              </Box>
            )}
            <Box className={classes.buttonContainer}>
              {editMode && (
                <>
                  <Button
                    className={classes.button}
                    variant="contained"
                    size="small"
                    fullWidth
                    onClick={() => {
                      setEditMode(false);
                    }}
                  >
                    Anuluj
                  </Button>
                  <Button
                    className={classes.buttonSubmit}
                    variant="contained"
                    size="small"
                    fullWidth
                    disabled={formData.yearOfBirth.toString().length !== 4}
                    type="submit"
                  >
                    Zapisz
                  </Button>
                </>
              )}
              {!editMode && (
                <>
                  <Button
                    className={classes.button}
                    variant="contained"
                    fullWidth
                    component={RouterLink}
                    to={"/tests"}
                  >
                    Anuluj
                  </Button>
                  <Button
                    color="primary"
                    className={classes.buttonSubmit}
                    variant="contained"
                    disabled={!canContinue}
                    fullWidth
                    type="submit"
                  >
                    Kontynuuj
                  </Button>
                </>
              )}
            </Box>
          </form>
        </Grid>
      </Grid>
    </Box>
  );
};

const activateCode = (code, personDetails) => {
  return getAxiosInstance().put(
    `/api/orders/accept-full-code/${code}`,
    personDetails
  );
};

const ActivateCode = (props) => {
  const {
    classes,
    me,
    personDetails,
    codeState,
    setOrganizationName,
    setTermsLink,
  } = props;
  const [code, setCode] = codeState;
  const [helperText, setHelperText] = props.errorTextState;
  const [qrOpen, setQrOpen] = useState(false);
  const history = useHistory();
  const theme = useTheme();

  const handleChange = (event) => {
    const target = event.target;
    const value = target.value;

    setHelperText("");

    setCode(value);
  };

  const changeCode = (data) => {
    setHelperText("");
    setCode(data);
    setQrOpen(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    let codeData;

    try {
      codeData = (
        await getAxiosInstance().get(`/order/v1/search/code?code=${code}`)
      ).data;

      if (codeData.order.tenantId === me.tenantId) {
        await activateCode(code, personDetails);
        history.push("/tests");
      } else {
        const terms = (
          await getAxiosInstance().get(
            `/api/organizations/tenant/${codeData.order.tenantId}/terms`
          )
        ).data;

        setHelperText("");
        setOrganizationName(terms.name);
        setTermsLink(terms.link);
      }
    } catch (e) {
      if (e.response?.status === 409) {
        setHelperText("Numer zestawu został już wcześniej aktywowany");
      } else if (e.response?.status === 404) {
        setHelperText("Nieprawidłowy numer zestawu");
      } else {
        setHelperText("Coś poszło nie tak. Spróbuj ponownie później.");
      }
    }
  };

  return (
    <Box className={classes.container}>
      <Grid container justify="center" spacing={2}>
        <Grid item xs={12} sm={12}>
          <Box className={classes.imageContainer} padding={2}>
            <img src={envelopeIcon} alt="" />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12}>
          <form onSubmit={handleSubmit}>
            <Box style={{ display: "flex" }}>
              <TextField
                name="code"
                label="Wprowadź 12-znakowy NUMER ZESTAWU"
                variant="outlined"
                fullWidth
                value={code}
                error={!!helperText}
                helperText={helperText}
                onChange={handleChange}
                InputLabelProps={{
                  style: {
                    paddingRight: "7px",
                  },
                }}
                inputProps={{
                  "aria-label": "Wprowadź dwunasto-znakowy NUMER ZESTAWU",
                }}
              />
              <Grid
                item
                style={{
                  margin: "auto 10px",
                  fontSize: "15px",
                }}
              >
                lub
              </Grid>
              <Button
                variant="outlined"
                size="small"
                onClick={() => {
                  setQrOpen(true);
                }}
                style={{ color: theme.palette.text.qr }}
              >
                Zeskanuj kod QR
              </Button>
            </Box>
            {/* {qrOpen && ( */}
            <QRScanner
              my={1}
              changeCode={changeCode}
              qrOpen={qrOpen}
              closeQrPanel={() => setQrOpen(false)}
              openQrPanel={() => setQrOpen(true)}
            />
            {/* )} */}
            <Box mt={2}>
              <Grid container justify="space-between">
                <Grid item xs={5}>
                  <Button
                    component={RouterLink}
                    to={"/tests"}
                    variant="contained"
                    fullWidth
                  >
                    Anuluj
                  </Button>
                </Grid>
                <Grid item xs={5}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    type="submit"
                  >
                    Kontynuuj
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </form>
        </Grid>
      </Grid>
    </Box>
  );
};

const AcceptConditions = (props) => {
  const [formData, setFormData] = useState({ isSharedWithOwner: "" });
  const { classes, personDetails, code, termsLink } = props;
  const [errorText, setErrorText] = props.errorTextState;
  const [organizationName, setOrganizationName] = props.organizationNameState;
  const history = useHistory();
  const theme = useTheme();

  const handleChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    setFormData((formData) => ({
      ...formData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      await activateCode(code, personDetails);
      history.push("/tests");
    } catch (e) {
      console.error(e.response);
      if (e.response.status === 409) {
        setErrorText("Numer zestawu został już wcześniej aktywowany");
        setOrganizationName(null);
      } else if (e.response.status === 404) {
        setErrorText("Nieprawidłowy numer zestawu");
        setOrganizationName(null);
      } else {
        setErrorText("Coś poszło nie tak. Spróbuj ponownie później.");
      }
    }
  };

  return (
    <Box className={classes.container}>
      <Grid container justify="center" spacing={2}>
        <Grid item xs={12} sm={8}>
          <form onSubmit={handleSubmit}>
            <Box display="block" m={1}>
              <Box mb={1}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  style={{
                    color: theme.palette.text.alternative2,
                    fontWeight: "bold",
                  }}
                >
                  Aktywuj zestaw
                </Typography>
                <br />
                <Typography
                  style={{
                    color: theme.palette.text.alternative2,
                  }}
                >
                  Właścicielem testu jest
                </Typography>
                <Typography
                  style={{
                    color: theme.palette.text.alternative2,
                    fontWeight: "bold",
                  }}
                >
                  <b>{organizationName}</b>
                </Typography>
                <Typography
                  style={{
                    color: theme.palette.text.alternative2,
                  }}
                >
                  Wyrażam zgodę na udostępnienie wyników badań zgodnie z{" "}
                  <MuiNavLink href={termsLink} target="_blank">
                    regulaminem
                  </MuiNavLink>
                  .
                </Typography>
              </Box>
              <br />
              <FormControl
                fullWidth
                variant="outlined"
                className={classes.focused}
              >
                <InputLabel id="isShared-select-label">
                  Zgoda na udostępnienie wyników
                </InputLabel>
                <Select
                  labelId="isShared-select-label"
                  name="isSharedWithOwner"
                  required
                  value={formData.isSharedWithOwner}
                  onChange={handleChange}
                  label="Zgoda na udostępnienie wyników"
                  className={classes.focused}
                >
                  <MenuItem
                    value={true}
                    style={{ color: theme.palette.text.alternative2 }}
                  >
                    Wyrażam zgodę
                  </MenuItem>
                  <MenuItem
                    value={false}
                    style={{ color: theme.palette.text.alternative2 }}
                  >
                    Nie wyrażam zgody
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
            {errorText && (
              <Box>
                <Typography className={classes.errorText}>
                  {errorText}
                </Typography>
              </Box>
            )}
            <Box
              className={classes.buttonContainer}
              style={{ justifyContent: "flex-end" }}
            >
              <Box>
                <Button
                  style={{ height: "33px", width: "140px" }}
                  disabled={!formData.isSharedWithOwner}
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  Aktywuj
                </Button>
              </Box>
            </Box>
          </form>
        </Grid>
      </Grid>
    </Box>
  );
};

const ActivateSet = (props) => {
  const me = useSelector((s) => s.my);
  const [personDetails, setPersonDetails] = useState();
  const organizationNameState = useState();
  const [organizationName, setOrganizationName] = organizationNameState;
  const [termsLink, setTermsLink] = useState();
  const codeState = useState("");
  const [code, setCode] = codeState;
  const errorTextState = useState("");
  const { classes } = props;

  if (!me) {
    return <Loader loading={true} text="Wczytywanie danych" />;
  } else if (!personDetails) {
    return (
      <PersonDetails
        classes={classes}
        me={me}
        setPersonDetails={setPersonDetails}
      />
    );
  } else if (!organizationName) {
    return (
      <ActivateCode
        classes={classes}
        me={me}
        errorTextState={errorTextState}
        personDetails={personDetails}
        codeState={codeState}
        setOrganizationName={setOrganizationName}
        setTermsLink={setTermsLink}
      />
    );
  } else {
    return (
      <AcceptConditions
        classes={classes}
        errorTextState={errorTextState}
        personDetails={personDetails}
        code={code}
        organizationNameState={organizationNameState}
        termsLink={termsLink}
      />
    );
  }
};

const ActivateSetWithContainer = (props) => {
  const classes = useStyles();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Can
      permission="add-test-set:page"
      ok={() => (
        <Box className={classes.root}>
          <AppBarTitle value="Aktywuj zestaw" />
          <h1
            aria-hidden="false"
            aria-label="Aktywuj nowy zestaw"
            style={{ display: "none" }}
          >
            Aktywuj nowy zestaw
          </h1>
          <Grid container justify="center">
            <Grid item xs={12} sm={8} md={6}>
              <ActivateSet classes={classes} {...props} />
            </Grid>
          </Grid>
        </Box>
      )}
      not={() => <Redirect to="/login" />}
    />
  );
};

const ActivateSetWithTheme = (props) => (
  <Themed>
    <ActivateSetWithContainer {...props} />
  </Themed>
);

export default ActivateSetWithTheme;
