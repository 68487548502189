import React, { useEffect, useState } from "react";
import { NavLink, Redirect } from "react-router-dom";
import Can from "../../components/can";
import * as classnames from "classnames";
import { updateConfigGlobal } from "../../redux/admin/actions";
import { useDispatch, useSelector } from "react-redux";
import { fetchConfigGlobal } from "../../redux/actions";
import { Box } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";

const AdminSettingsPage = ({ alertAdd }) => {
  const configGlobal = useSelector((s) => s.configGlobal);

  const [androidClientVersion, setAndroidClientVersion] = useState("");
  const [iosClientVersion, setIosClientVersion] = useState("");
  const [androidClientDate, setAndroidClientDate] = useState(null);
  const [iosClientDate, setIosClientDate] = useState(null);
  const [linkToResultsSharingTerms, setLinkToResultsSharingTerms] = useState(
    ""
  );

  const [busy, setBusy] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchConfigGlobal());
  }, []);

  useEffect(() => {
    setAndroidClientVersion(configGlobal && configGlobal.androidClientVersion);
    setIosClientVersion(configGlobal && configGlobal.iosClientVersion);
    setAndroidClientDate(configGlobal && configGlobal.androidClientDate);
    setIosClientDate(configGlobal && configGlobal.iosClientDate);
    setLinkToResultsSharingTerms(
      configGlobal && configGlobal.linkToResultsSharingTerms
    );
  }, [configGlobal]);

  const save = async () => {
    // const iosDate = new Date(iosClientDate);
    // const iosISODateTime = new Date(
    //   iosDate.getTime() - iosDate.getTimezoneOffset() * 60000
    // ).toISOString();
    //
    // const androidDate = new Date(androidClientDate);
    // const androidISODateTime = new Date(
    //   androidDate.getTime() - androidDate.getTimezoneOffset() * 60000
    // ).toISOString();

    setBusy(true);
    await dispatch(
      updateConfigGlobal({
        // androidClientVersion: androidClientVersion,
        // iosClientVersion: iosClientVersion,
        // androidClientDate: androidISODateTime,
        // iosClientDate: iosISODateTime,
        linkToResultsSharingTerms,
      })
    );
    setBusy(false);
  };

  const page = () => (
    <div className="container is-fluid">
      <div className="title">Ustawienia</div>
      <div className="columns">
        <div className={"column is-two-thirds"}>
          {/*<table className={`table is-fullwidth ${s.noborder}`}>*/}
          {/*  <tbody>*/}
          {/*    <tr>*/}
          {/*      <td>Wersja aplikacji Android:</td>*/}
          {/*      <td>*/}
          {/*        <input*/}
          {/*          type="text"*/}
          {/*          className="input"*/}
          {/*          value={androidClientVersion}*/}
          {/*          onChange={({ target: { value: version } }) => {*/}
          {/*            setAndroidClientVersion(version);*/}
          {/*          }}*/}
          {/*        />*/}
          {/*      </td>*/}
          {/*      <td>Aktywność wersji Android:</td>*/}
          {/*      <td>*/}
          {/*        <Datepicker*/}
          {/*          className={classnames("input")}*/}
          {/*          dateFormat="dd/MM/yyyy"*/}
          {/*          selected={androidClientDate}*/}
          {/*          value={androidClientDate ? androidClientDate : ""}*/}
          {/*          onChange={(value) => {*/}
          {/*            setAndroidClientDate(value);*/}
          {/*          }}*/}
          {/*        />*/}
          {/*      </td>*/}
          {/*    </tr>*/}
          {/*    <br />*/}
          {/*    <tr>*/}
          {/*      <td>Wersja aplikacji iOS:</td>*/}
          {/*      <td>*/}
          {/*        <input*/}
          {/*          type="text"*/}
          {/*          className="input"*/}
          {/*          value={iosClientVersion}*/}
          {/*          onChange={({ target: { value: version } }) => {*/}
          {/*            setIosClientVersion(version);*/}
          {/*          }}*/}
          {/*        />*/}
          {/*      </td>*/}
          {/*      <td>Aktywność wersji iOS:</td>*/}
          {/*      <td>*/}
          {/*        <Datepicker*/}
          {/*          className={classnames("input")}*/}
          {/*          dateFormat="dd/MM/yyyy"*/}
          {/*          selected={iosClientDate}*/}
          {/*          value={iosClientDate ? iosClientDate : ""}*/}
          {/*          onChange={(value) => {*/}
          {/*            setIosClientDate(value);*/}
          {/*          }}*/}
          {/*        />*/}
          {/*      </td>*/}
          {/*    </tr>*/}
          {/*  </tbody>*/}
          {/*</table>*/}
          <Box>
            <Box mt={1}>
              <TextField
                label="Link do globalnego regulaminu RODO"
                variant="outlined"
                value={linkToResultsSharingTerms}
                fullWidth
                onChange={({ target: { value: link } }) => {
                  setLinkToResultsSharingTerms(link);
                }}
              />
            </Box>
          </Box>
        </div>
      </div>
      <hr />
      <div className="field is-grouped is-grouped-centered">
        <div className="control">
          <button className="button">
            <NavLink to="/">Anuluj</NavLink>
          </button>
        </div>

        <div className="control">
          <button
            className={classnames("button is-primary", { "is-loading": busy })}
            onClick={save}
            disabled={!linkToResultsSharingTerms}
          >
            Zapisz
          </button>
        </div>
      </div>
    </div>
  );
  const redirect = () => <Redirect to="/login" />;

  return <Can permission="adminSettings:view" ok={page} not={redirect} />;
};

export default AdminSettingsPage;
