import React, { useEffect, useRef, useState } from 'react'
import * as classnames from 'classnames'
import s from './index.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ColorBox } from '../color-box'
import { ColorPicker } from '../color-picker'

const OPERATORS = {
  GREATER_THAN: 'większa niż',
  LESS_THAN: 'mniejsza niż',
  EQUALS: 'równa',
  BETWEEN: 'pomiędzy',
}

export const RuleEditor = ({onDone, onCancel}) => {
  const ref = useRef()

  const [active, setActive] = useState(false)
  const [operator, setOperator] = useState()
  const [color, setColor] = useState('')
  const [text, setText] = useState('')
  const [arg1, setArg1] = useState('')
  const [arg2, setArg2] = useState('')
  const [showColorPicker, setshowColorPicker] = useState(false)
  const [valid, setValid] = useState(false)

  useEffect(() => {
    if (arg1 !== '' && (text || color) && operator &&
      (operator !== 'BETWEEN' || arg2 !== '')) {
      setValid(true)
    } else {
      setValid(false)
    }
  }, [text, color, arg1, arg2, operator])

  const setOperatorWrapper = (e, operatorId) => {
    e.preventDefault()

    setOperator(operatorId)
  }

  useEffect(() => {
    setshowColorPicker(false)
  }, [color])

  const doneHandler = () => {
    const ruleData = {
      operator,
      arg1: parseInt(arg1, 10),
      ...operator === 'BETWEEN' ? {arg2: parseInt(arg2, 10)} : {},
      ...color ? {color} : {},
      ...text ? {text} : {},
    }

    onDone(ruleData)
  }

  return (
    <div ref={ref} className='field is-horizontal'>
      Jeśli suma punktów formularza jest &nbsp;

      <div className='control'>
        <div className={classnames('dropdown is-hoverable')}>
          <div className='dropdown-trigger'>
          <span className={s.pointer} onClick={() => setActive(!active)}>
            {
              operator ? (
                <span>{OPERATORS[operator]} </span>
              ) : (
                <span>wybierz </span>
              )
            }
            <FontAwesomeIcon icon='angle-down'/>
          </span>
          </div>

          <div className='dropdown-menu' id="dropdown-menu" role="menu">
            <div className='dropdown-content'>
              {
                Object.keys(OPERATORS).map(operatorId => (
                  <a key={operatorId} href='#' className='dropdown-item'
                     onClick={e => setOperatorWrapper(e, operatorId)}>
                    {OPERATORS[operatorId]}
                  </a>
                ))
              }
            </div>
          </div>
        </div>
      </div>
      {
        operator && (
          <>
            <div className='control'>
              &nbsp;
              <input className='input is-inline' type='number'
                     value={arg1}
                     onChange={({target: {value: arg1}}) => setArg1(
                       arg1)}/>
            </div>

            {
              operator === 'BETWEEN' && (
                <div className='control'>
                  &nbsp;
                  a <input className='input is-inline' type='number'
                           value={arg2}
                           onChange={({target: {value: arg2}}) => setArg2(
                             arg2)}/>
                </div>
              )
            }

            &nbsp; ustaw kolor na &nbsp;
            <div className='control is-relative'>
              {
                showColorPicker && (
                  <ColorPicker value={color} onChange={c => setColor(c)}/>
                )
              }
              <ColorBox style={{position: 'absolute'}}
                        onClick={() => setshowColorPicker(true)} color={color}/>
            </div>

            &nbsp; oraz tekst na &nbsp;

            <div className='control'>
              &nbsp;
              <input className='input is-inline' type='text'
                     value={text}
                     onChange={({target: {value: text}}) => setText(
                       text)}/>
            </div>
          </>
        )
      }

      <div className='control'>
        &nbsp;
        <button className='button is-small is-primary'
                disabled={!valid}
                onClick={doneHandler}>Dodaj
        </button>
      </div>

      <div className='control'>
        &nbsp;
        <button className='button is-small' onClick={onCancel}>Anuluj</button>
      </div>
    </div>
  )
}
