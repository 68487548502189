import React, { useEffect, useState } from "react";
import { normalizeZIP } from "./shipping-info";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  greenLabel: {
    color: "green",
  },

  redLabel: {
    color: "red",
  },

  cssOutlinedInputGreen: {
    "&$cssFocused $notchedOutlineGreen": {
      borderColor: `${theme.palette.primary.main} !important`,
    },
  },

  cssOutlinedInputRed: {
    "&$cssFocused $notchedOutlineRed": {
      borderColor: `${theme.palette.primary.main} !important`,
    },
  },

  cssFocused: {},

  notchedOutlineGreen: {
    borderWidth: "1px",
    borderColor: "green !important",
  },
  notchedOutlineRed: {
    borderWidth: "1px",
    borderColor: "red !important",
  },
}));

const checkZipCode = (zipCode) => zipCode && /^\d{2}-\d{3}$/.test(zipCode);

const ZipInput = ({
  globalTheme,
  zipCode,
  handleZIPCodeChange,
  isValidListener,
  disabled,
  setDataChanged,
}) => {
  const classes = useStyles();

  const [valid, setValid] = useState(checkZipCode(zipCode));
  useEffect(() => {
    let isOk;
    if (
      (zipCode && /^\d{2}-\d{3}$/.test(zipCode)) ||
      zipCode === "" ||
      zipCode === undefined
    ) {
      isOk = true;
    } else {
      isOk = false;
    }

    setValid(isOk);

    isValidListener && isValidListener(isOk);
  }, [zipCode]);

  return (
    <TextField
      label="Kod pocztowy"
      variant="outlined"
      value={zipCode || ""}
      error={!disabled && (!zipCode || zipCode.length !== 6)}
      helperText={!zipCode || (zipCode.length !== 6 && "Nieprawidłowy format.")}
      required={true}
      disabled={disabled}
      inputProps={{ "aria-label": "Kod pocztowy" }}
      InputLabelProps={{
        classes: globalTheme === "light" && {
          root: valid ? classes.greenLabel : classes.redLabel,
          focused: classes.focused,
        },
      }}
      InputProps={
        globalTheme === "light" &&
        !disabled && {
          classes: {
            root:
              valid && !(!zipCode || zipCode.length !== 6)
                ? classes.cssOutlinedInputGreen
                : classes.cssOutlinedInputRed,
            focused: classes.cssFocused,
            notchedOutline:
              valid && !(!zipCode || zipCode.length !== 6)
                ? classes.notchedOutlineGreen
                : classes.notchedOutlineRed,
          },
          inputMode: "numeric",
        }
      }
      fullWidth
      onChange={({ target: { value: newZipCode } }) => {
        setDataChanged(true);
        handleZIPCodeChange(newZipCode);
      }}
    />
  );
};

export default ZipInput;
