import jsPDF from "jspdf";
import QRCode from "qrcode";

const defaultOptions = {
  codesPerPage: 6,
  codesPerRow: 2,
  pageFormat: "a4",
  fontName: "Courier",
  fontSize: 12,
  orientation: "portrait",
  margin: {left: 0.10, top: 0.10, right: 0.10, bottom: 0.10},
  codePrependText: "Kod zestawu: ",
  fileName: "Kody QR.pdf"
}

const generateQrCodes = (codes, options = {}) => {
  const {codesPerPage,codesPerRow, pageFormat, fontName, fontSize, orientation, margin, codePrependText, fileName }
    = {...defaultOptions, ...options};
  const codesRows = Math.ceil(codesPerPage / codesPerRow);
  const pagesCount = Math.ceil(codes.length / codesPerPage);

  const doc = new jsPDF();
  doc.deletePage(1);
  doc.setFont(fontName);
  doc.setFontSize(fontSize);

  for (let page = 0; page < pagesCount; page++) {
    doc.addPage(pageFormat, orientation);
    const sectionWidth = (doc.internal.pageSize.width / codesPerRow);
    const sectionHeight = (doc.internal.pageSize.height / codesRows);
    const w = sectionWidth * (1.0 - margin.left - margin.right);
    const h = sectionHeight * (1.0 - margin.top - margin.bottom);

    codes.slice(page * codesPerPage, Math.min(page * codesPerPage + codesPerPage, codes.length)).forEach((code, index) => {
      QRCode.toCanvas(
        code,
        { errorCorrectionLevel: "H", margin: 0 },
        (err, canvas) => {
          if (err) throw err;
          const x = sectionWidth * (index % codesPerRow) + (sectionWidth * margin.left);
          const y = sectionHeight * Math.floor(index / codesPerRow) + (sectionHeight * margin.top);
          doc.addImage(canvas, x, y, w, h);

          doc.text(`${codePrependText}${code}`, x, y + h, {baseline: "top", maxWidth: w});
        }
      )
    });
  }

  doc.save(fileName);
}

export default generateQrCodes;
