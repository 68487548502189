import { ItemSubtypes } from '../DnDTypes'
import { DateRenderer } from './date-renderer'
import { TimeRenderer } from './time-renderer'
import { DefaultRenderer } from './default-renderer'
import { NumberRenderer } from './number-renderer'
import { LocationRenderer } from './location-renderer'
import { FormStatusRenderer } from './formstatus-renderer'

export const getValueRenderer = dataType => {
  switch (dataType) {
    case ItemSubtypes.DATE: return DateRenderer
    case ItemSubtypes.TIME: return TimeRenderer
    case ItemSubtypes.NUMBER: return NumberRenderer
    case ItemSubtypes.LOCATION: return LocationRenderer
    case 'x-formstatus': return FormStatusRenderer
    default: return DefaultRenderer
  }
}
