import React, { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  activateForm,
  duplicateForm,
  fetchActivatedKits,
  fetchFilledForms,
  fetchForms,
  fetchScheduledForms,
  removeForm,
  updateForm,
  updateRequestedSharingByOwner,
} from "../../redux/actions";
import { Link, NavLink, Redirect } from "react-router-dom";
import Can from "../../components/can";
import PageTitle from "../../components/page-title";
import { Box, Paper } from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import styled from "styled-components";
import { checkPermission } from "../../rbac";
import Checkbox from "@material-ui/core/Checkbox";
import SingleForm from './single-form'

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const GreenButton = styled(Button)`
  background-color: #4caf50;
`;

const Page = ({
  history,
  loggedIn,
  removeForm,
  fetchFilledForms,
  filledForms,
  updateForm,
  activateForm,
  duplicateForm,
}) => {
  const dispatch = useDispatch();
  const scheduledForms = useSelector((s) => s.scheduledForms);
  const forms = useSelector((s) => s.orgForms);
  const roles = useSelector((s) => s.roles);
  const canFetchForms = checkPermission("forms:fetch", roles);

  const activatedKits = useSelector((s) => s.activatedKits);

  useEffect(() => {
    dispatch(fetchActivatedKits());
  }, []);

  useEffect(() => {
    if (loggedIn) {
      dispatch(fetchScheduledForms());
    }
  }, [loggedIn, fetchScheduledForms]);

  useEffect(() => {
    if (loggedIn && canFetchForms) {
      dispatch(fetchForms());
    }
  }, [loggedIn, fetchForms, canFetchForms]);

  const fill = (formId, scheduledFormId) => {
    // history.push(`/testdata/add/${id}`);
    history.push(`/testdata/fill-scheduled-test/${formId}/${scheduledFormId}`);
  };
  const edit = (id) => {
    history.push(`/tests/edit/${id}`);
  };
  const remove = (id) => {
    removeForm(id);
  };

  const classes = useStyles();

  // const columns = [
  //   { field: "orderNumber", headerName: "Nr zamówienia" },
  //   { field: "code", headerName: "Nr zestawu" },
  //   {
  //     field: "isSharingWithOwnerRequested",
  //     headerName: "Chcę widzieć wyniki",
  //     renderer: ({ id, isSharingWithOwnerRequested }) => {
  //       return (
  //         <Checkbox
  //           checked={Boolean(isSharingWithOwnerRequested)}
  //           onChange={() => {
  //             dispatch(
  //               updateRequestedSharingByOwner(id, !isSharingWithOwnerRequested)
  //             );
  //           }}
  //         />
  //       );
  //     },
  //   },
  //   {
  //     headerName: "Opcje",
  //     renderer: ({ id }) => (
  //       <Link to={`/fill-scheduled-test/${id}`}>Wypełnij</Link>
  //     ),
  //   },
  // ];
  //
  // const rows = [];
  //
  const page = () => (
  //   <Box>
  //     <PageTitle title="Aktywowane zestawy" />
  //
  //     <TableContainer component={Paper}>
  //       <Table className={classes.table} aria-label="simple table">
  //         <TableHead>
  //           <TableRow>
  //             {columns.map((col, index) => (
  //               <TableCell key={index}>{col.headerName}</TableCell>
  //             ))}
  //           </TableRow>
  //         </TableHead>
  //         <TableBody>
  //           {activatedKits.map((r, index) => (
  //             <TableRow key={index}>
  //               {columns.map((c, cIndex) => {
  //                 const value = r[c.field];
  //                 const renderer = c.renderer || ((v) => value);
  //
  //                 return <TableCell key={cIndex}>{renderer(r)}</TableCell>;
  //               })}
  //             </TableRow>
  //           ))}
  //         </TableBody>
  //       </Table>
  //     </TableContainer>
    <Box>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Nazwa</TableCell>
              <TableCell align="right">Typ</TableCell>
              <TableCell align="right">Kod</TableCell>
              {/*/!*<TableCell align="right">Status</TableCell>*!/*/}
              {/*/!*<TableCell align="right">Użytkownicy</TableCell>*!/*/}
              {/*/!*<TableCell align="right">Odpowiedzi</TableCell>*!/*/}
              <TableCell align="right">Opcje</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {forms.map((form, index) => {
              return (
                <SingleForm
                  key={index}
                  form={form}
                  remove={remove}
                  edit={edit}
                  fill={fill}
                  fetchFilledForms={fetchFilledForms}
                  filledForms={filledForms}
                  updateForm={updateForm}
                  activateForm={activateForm}
                  duplicateForm={duplicateForm}
                  history={history}
                />
              );
            })}
            {scheduledForms.map((scheduledForm, index) => {
              return (
                <SingleForm
                  key={index}
                  form={scheduledForm.form}
                  scheduledForm={scheduledForm}
                  remove={remove}
                  edit={edit}
                  fill={fill}
                  fetchFilledForms={fetchFilledForms}
                  filledForms={filledForms}
                  updateForm={updateForm}
                  activateForm={activateForm}
                  duplicateForm={duplicateForm}
                  history={history}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <Can
        permission="form-add-page:view"
        ok={() => (
          <Box position="fixed" bottom={20} right={20}>
            <NavLink to="/tests/add">
              <GreenButton variant="contained" color="primary">
                + Stwórz Nowy formularz
              </GreenButton>
            </NavLink>
          </Box>
        )}
      />
    </Box>
    // <div className='container is-fluid'>
    //   <div className="title">Formularze</div>
    //   <NavLink to="/forms/add">Dodaj nowy formularz</NavLink>
    //   <ul className="">
    //     {
    //       orgForms && orgForms.map(form => (
    //         <div key={form.id}>
    //           <SingleForm
    //             key={form.id}
    //             form={form}
    //             remove={remove}
    //             edit={edit}
    //             fill={fill}
    //             fetchFilledForms={fetchFilledForms}
    //             filledForms={filledForms}
    //             updateForm={updateForm}
    //             activateForm={activateForm}
    //             history={history}/>
    //         </div>
    //         )
    //       )}
    //   </ul>
    //
    // </div>
  );

  const redirect = () => <Redirect to="/tests/active" />;

  return <Can permission="activated-kits-page:view" ok={page} not={redirect} />;
};

const mapStateToProps = (state) => ({
  organization: state.organization,
  loggedIn: state.loggedIn,
  filledForms: state.filledForms,
});

const mapDispatchToProps = (dispatch) => ({
  activateForm: (form) => dispatch(activateForm(form)),
  removeForm: (formId) => dispatch(removeForm(formId)),
  fetchFilledForms: (formId) => dispatch(fetchFilledForms(formId)),
  updateForm: (id, payload) => dispatch(updateForm(id, payload)),
  duplicateForm: (id) => dispatch(duplicateForm(id)),
});

const FormsPage = connect(mapStateToProps, mapDispatchToProps)(Page);
export default FormsPage;
