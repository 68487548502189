import React from "react";
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

const createTheme = (outerTheme) => createMuiTheme({
  ...outerTheme,
  typography: {
    ...outerTheme.typography,
    subtitle1: {
      ...outerTheme.typography.subtitle1,
      //fontFamily: 'Rubik',
      fontSize: '16px',
      letterSpacing: 0,
      lineHeight: '19px',
    },
  },
});

const Themed = (props) => (
  <ThemeProvider theme={outerTheme => createTheme(outerTheme)}>
    {props.children}
  </ThemeProvider>
);

export default Themed;
