import React, {useState} from 'react'
import * as classnames from 'classnames'

const DictionaryFilter = ({id, onChange, dictionaries}) => {
    const [selectedDicValues, setSelectedDicValues] = useState([])
    const dictionary = dictionaries.find(d => d.id === id)

    const onSelect = value => {
        if(onChange){
            onChange({
                id,value: [...selectedDicValues, value]})
        }
        setSelectedDicValues([...selectedDicValues, value])
      }
    
      const onUnselect = value => {
        if(onChange){
            onChange({
                id,value: selectedDicValues.filter(u => u !== value)})
        }
        setSelectedDicValues(selectedDicValues.filter(u => u !== value))
      }

    return (
        <div>
        {
            dictionary && dictionary.dictionaryValues.map(({stringValue,intValue},index) => (
                <div key={index} className="panel-block">
                    <label>{stringValue || intValue}</label>
                        <input
                        type="checkbox"
                        value={stringValue || intValue}
                        checked={selectedDicValues.indexOf(stringValue || intValue) !== -1}
                        onChange={e => e.target.checked ? onSelect(stringValue || intValue) : onUnselect(stringValue || intValue)}
                        />
                </div>
            ))
        }
        </div>
    )
}

export default DictionaryFilter