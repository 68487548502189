import React, { useEffect, useState } from "react";
import { DictionaryProperties } from "../property-creators/dictionary-properties";
import { LabelProperties } from "../property-creators/label-properties";
import s from "./index.module.css";

// model:
// const sampleModel = {
//   size: 10,
//   text: 'laksdjflksdjf',
//   alignment: 'left',
//   fontSize: '20px',
//   bold: true,
//   italic: false,
//   label: 'some label',
//   labelPosition: 'left',
//   required: true,
//   border: true,
//   header: 'some header',
// }

export const defaultState = {
  size: 10,
  text: "",
  alignment: "left",
  fontSize: "20",
  bold: false,
  italic: false,
  label: "",
  labelPosition: "left",
  required: false,
  border: false,
  header: "",
};

const getDataTypeText = (dataType) => {
  switch (dataType) {
    case "TEXT":
      return "pole tekstowe";
    case "NUMBER":
      return "pole liczbowe";
    case "IMAGE":
      return "zdjęcie";
    case "SIGN":
      return "podpis";
    case "LOCATION":
      return "lokalizacja";
    case "DICTIONARY":
      return "słownik";
    case "TIME":
      return "czas";
    case "DATE":
      return "data";
    case "DATETIME":
      return "data i czas";
    case "GROUP":
      return "grupa";
    case "REPEATING_GROUP":
      return "grupa";
    case "BOOL":
      return "prawda/fałsz";
    case "PESEL":
      return "pesel";
    case "QRCODE":
      return "kod QR";
    case "LABEL":
      return "etykieta";
  }
};

const ControlProperties = ({
  controlId,
  dataType,
  configuration: { label = "", text = "", dictionary, ...rest },
  update,
  formEdited,
}) => {
  const disabled = ""; //configuration ? "" : "disabled"

  // const [label, setLabel] = useState('');

  // useEffect(() => {
  //   setLabel(initialLabel);
  // }, [initialLabel])

  // const updateConfig = config => {
  //   setState({
  //     ...state,
  //     ...config
  //   })
  // if (update) {
  //   update({
  //     ...configuration,
  //     ...config,
  //   })
  //
  // }

  const [state, setState] = useState({
    ...defaultState,
    text,
    label,
  });
  const [dataTypeText, setDataTypeText] = useState("");

  // const [extraValues, setExtraValues] = useState(state)
  //
  // const setExtraValues2 = values => {
  //   // setExtraValues(values)
  //
  //   setState({
  //     ...state,
  //     ...values
  //   })
  // }

  useEffect(() => {
    setState((state) => ({
      ...state,
      label,
      text,
      dictionary,
      ...rest,
    }));
  }, [label, text, dictionary]);

  useEffect(() => {
    update(state);
  }, [state]);

  // useEffect(() => {
  //   if (controlId) {
  //     update(state)
  //   }
  // }, [state, controlId])

  useEffect(() => {
    setDataTypeText(getDataTypeText(dataType));
  }, [dataType]);

  return (
    <div className={s.scroll}>
      <p className="menu-label">Właściwości</p>

      {
        <ul>
          {/*<li>*/}
          {/*  Maksymalna długość:*/}
          {/*  <input*/}
          {/*    disabled={disabled} type="number"*/}
          {/*    className="input is-small"*/}
          {/*    value={state.size}*/}
          {/*    onChange={({target: {value: size}}) => setState(*/}
          {/*      {...state, size})}/>*/}
          {/*</li>*/}

          {/*<li>*/}
          {/*  Położenie:*/}
          {/*  <input*/}
          {/*    disabled={disabled} type="text"*/}
          {/*    className="input is-small"*/}
          {/*    value={state.alignment}*/}
          {/*    onChange={({target: {value: alignment}}) => setState({...state, alignment})}/>*/}
          {/*</li>*/}
          {/*<li>*/}
          {/*  Rozmiar czcionki:*/}
          {/*  <input disabled={disabled} type="number"*/}
          {/*         className="input is-small"*/}
          {/*         value={state.fontSize}*/}
          {/*         onChange={({target: {value: fontSize}}) => setState({...state, fontSize})}/>*/}
          {/*</li>*/}
          <li>
            Typ: {dataTypeText}
            {typeof state.dictionary !== "undefined" &&
              (state.dictionary.type === "string"
                ? " typu tekstowego"
                : " typu liczbowego")}
          </li>
          <li>
            Etykieta:
            <input
              disabled={disabled}
              type="text"
              className="input is-small"
              value={state.label}
              onChange={({ target: { value: label } }) => setState({ label })}
            />
          </li>
          <li>
            Podpowiedź:
            <input
              disabled={disabled}
              type="text"
              className="input is-small"
              value={state.text}
              onChange={({ target: { value: text } }) =>
                setState({ ...state, text })
              }
            />
          </li>

          {dataType === "DICTIONARY" && (
            <DictionaryProperties
              values={state}
              setValues={setState}
              formEdited={formEdited}
            />
          )}
          {dataType === "LABEL" && (
            <LabelProperties values={state} setValues={setState} />
          )}
          {/*{propertyCreators && propertyCreators({values: state, setValues: setState})}*/}

          {/* <li>
              Nagłówek:
              <input disabled={disabled} type="text"
                     className="input is-small"/>
            </li>
            <li>
              Położenie etykiety:
              <input disabled={disabled} type="text"
                     className="input is-small"/>
            </li>

            <li>
              <label className="checkbox">
                Wymagane <input disabled={disabled} type="checkbox"/>
              </label>
            </li>
            <li>
              <label className="checkbox">
                Ramka <input disabled={disabled} type="checkbox"/>
              </label>
            </li>
            <li>
              <label className="checkbox">
                Kursywa <input disabled={disabled} type="checkbox"/>
              </label>
            </li>
            <li>
              <label className="checkbox">
                Pogrubienie
                <input
                  disabled={disabled}
                  type="checkbox"
                  value={state.bold}
                  onChange={({target: {checked: bold}}) => setState({...state, bold})}/>
              </label>
            </li> */}
        </ul>
      }
    </div>
  );
};

export default ControlProperties;
