import FillFormPage from "./pages/fill-form";
import AddNewFormPage from "./pages/add-new-form";
import RegisterOrganisation from "./pages/register-organization";
import LoginPage from "./pages/login-page";
import StartPage from "./pages/start-page";
import StartPageDetails from "./pages/start-page-flow-details-page";
import Contact from "./pages/contact";
import OrderPage from "./pages/order";
import MessageSent from "./pages/message-sent";
import PasswordResetPage from "./pages/password-reset";

import AddNewPersonsPage from "./pages/add-new-persons";
import FormsPage from "./pages/FormsPage";
import ActiveFormsPage from "./pages/active-forms-page";
import Main from "./pages/main";
import ProfilePage from "./pages/profile";
import { Persons } from "./persons";
import { FilledFormData } from "./components/filled-form-data";
import PasswordChangePage from "./pages/password-change";
import FilledFormDetails from "./pages/filled-form-details";
import AdminSettingsPage from "./pages/admin-settings-page";
import AdminAllUsersPage from "./pages/admin-all-users-page";
import ShoppingCart from "./pages/shopping-cart-page";
import OrderInProgress from "./pages/order-in-progess-page";
import OrderDetailsPage from "./pages/order-details-page";
import EditTestKit from "./pages/test-page/edit-test-kit";
import TestKitPage from "./pages/test-page/new-test-kit";
import ViewAllData from "./pages/view-all-tests";
import FilledKits from "./pages/filled-kits";
import TestMain from "./pages/sat-form-page";
import AddNewSet from "./pages/sat-form-page/add-new-set";
import AddNewTest from "./pages/sat-form-page/add-new-test";
import FillTest from "./pages/sat-form-page/fill-test";
import TestResult from "./pages/sat-form-page/result";
import OrganizationTerms from "./pages/organization-terms";
import PasswordResetMailPage from "./pages/password-reset-mail";
import PasswordResetMailLinkExpiredPage from "./pages/password-reset-link-expired";
import { AccountActivatedPage } from "./pages/account-activation";
import { Version } from "./pages/version";
import Proteges from "./pages/proteges";
import PaymentAcceptedPage from "./pages/payment-accepted";
import PaymentDeclinedPage from "./pages/payment-declined";
import ContactLoggedUser from "./pages/contact-logged-user";
import HowItWorks from "./pages/how-it-works-page";
import HowItWorksDetails from "./pages/how-it-works-details-page";
import MessageSentLoggedUser from "./pages/message-sent-logged-user";

export const noHeaderRoutes = [
  {
    path: "/start",
    component: StartPage,
  },
  {
    path: "/start-details",
    component: StartPageDetails,
  },
  {
    path: "/login",
    component: LoginPage,
  },
  {
    path: "/register",
    component: LoginPage,
  },
  {
    path: "/contact",
    component: Contact,
  },
  {
    path: "/order-test-kit",
    component: OrderPage,
  },
  {
    path: "/message-sent",
    component: MessageSent,
  },
  {
    path: "/password-reset",
    component: PasswordResetPage,
  },
  {
    path: "/password-set-new/:token",
    component: PasswordResetMailPage,
  },
  {
    path: "/password-reset-link-expired",
    component: PasswordResetMailLinkExpiredPage,
  },
  {
    path: "/account-activated",
    component: AccountActivatedPage,
  },
  {
    path: "/account-:param-activated",
    component: AccountActivatedPage,
  },
];

export const routes = [
  {
    path: "/",
    component: Main,
  },
  {
    path: "/settings",
    component: AdminSettingsPage,
  },
  {
    path: "/users",
    component: AdminAllUsersPage,
  },
  {
    path: "/alltests",
    component: FormsPage,
  },
  {
    path: "/filled-kits",
    component: FilledKits,
  },
  {
    path: "/order-details/:orderId",
    component: OrderDetailsPage,
  },
  {
    path: "/order-details/:orderId/:external",
    component: OrderDetailsPage,
  },
  {
    path: "/tests",
    component: TestMain,
  },
  {
    path: "/tests/set/add",
    component: AddNewSet,
  },
  {
    path: "/tests/add/:scheduledId",
    component: AddNewTest,
  },
  {
    path: "/tests/fill/:scheduledId",
    component: FillTest,
  },
  {
    path: "/tests/active",
    component: ActiveFormsPage,
  },
  {
    path: "/tests/add",
    component: AddNewFormPage,
  },
  {
    path: "/tests/edit/:id",
    component: AddNewFormPage,
  },
  {
    path: "/tests/result/:resultId",
    component: TestResult,
  },
  {
    path: "/testdata/add/:testId",
    component: FillFormPage,
  },
  {
    path: "/testdata/fill-scheduled-test/:testId/:scheduledTestId",
    component: FillFormPage,
  },
  {
    path: "/testdata/edit/:testdataId",
    component: FillFormPage,
  },
  {
    path: "/persons/invite",
    component: AddNewPersonsPage,
  },
  {
    path: "/profile",
    component: ProfilePage,
  },
  {
    path: "/password-change",
    component: PasswordChangePage,
  },
  {
    path: "/persons",
    component: Persons,
  },
  {
    path: "/proteges",
    component: Proteges,
  },
  {
    path: "/testdata/all/:testId",
    component: FilledFormData,
  },
  {
    path: "/testdata/:testId",
    component: FilledFormDetails,
  },
  {
    path: "/testdata",
    component: ViewAllData,
  },
  {
    path: "/test-kit",
    component: TestKitPage,
  },
  {
    path: "/edit-test-kit",
    component: EditTestKit,
  },
  {
    path: "/shopping-cart",
    component: ShoppingCart,
  },
  {
    path: "/orders",
    component: OrderInProgress,
  },
  {
    path: "/orders/:orderId",
    component: OrderDetailsPage,
  },
  {
    path: "/terms",
    component: OrganizationTerms,
  },
  {
    path: "/version",
    component: Version,
  },
  {
    path: "/payment-accepted",
    component: PaymentAcceptedPage,
  },
  {
    path: "/payment-declined",
    component: PaymentDeclinedPage,
  },
  {
    path: "/account-activated",
    component: AccountActivatedPage,
  },
  {
    path: "/account-:param-activated",
    component: AccountActivatedPage,
  },
  {
    path: "/send-message",
    component: ContactLoggedUser,
  },
  {
    path: "/how-it-works",
    component: HowItWorks,
  },
  {
    path: "/how-it-works-details",
    component: HowItWorksDetails,
  },
  {
    path: "/sent",
    component: MessageSentLoggedUser,
  },
];
