import React from "react";
import s from "./index.module.css";
import classnames from "classnames";
import { useDrop } from "react-dnd";
import uuid from "uuid4";
import { createControl, ItemTypes } from "../../../DnDTypes";
import Tooltip from "@material-ui/core/Tooltip";

const ItemSeparator = ({
  visible = false,
  dragMode,
  rowIndex,
  index,
  canDrop,
  moveItemToIndex,
}) => {
  const [{ isOver }, drop] = useDrop({
    accept: ItemTypes.COMPONENT,
    drop: (item, rest) => {
      const isClone = item.dragMode === "clone";
      const isMove = item.dragMode === "move";

      const targetRow = rowIndex;
      const targetIndex = index;
      const sourceRow = item.rowIndex;
      const sourceIndex = item.index;

      if (isClone) {
      } else if (isMove) {
        moveItemToIndex &&
          moveItemToIndex({
            item,
            targetRow,
            targetIndex,
            sourceRow,
            sourceIndex,
          });
      }
    },
    collect: (monitor, props) => {
      return {
        isOver: monitor.isOver(),
      };
    },
  });

  // only in form edit mode
  if (dragMode === "move" || dragMode === "clone") {
    return (
      <Tooltip title="Przeciągnij tu element formularza aby zmienić jego pozycję">
        <div
          className={classnames(s.separator, { [s.show]: isOver })}
          ref={drop}
        ></div>
      </Tooltip>
    );
  }

  return null;
};

const ItemRowSeparator = ({ dragMode }) => {
  const [{ isOver }, dropItem] = useDrop({
    accept: ItemTypes.COMPONENT,
    drop: (item, rest) => {},
    collect: (monitor, props) => {
      return {
        isOver: monitor.isOver(),
      };
    },
  });

  // only in form edit mode
  if (dragMode === "move" || dragMode === "clone") {
    return (
      <Tooltip title="Przeciągnij tu element formularza aby zmienić jego pozycję">
        <div
          className={classnames(s.separatorRow, { [s.show]: isOver })}
          ref={dropItem}
        />
      </Tooltip>
    );
  }

  return null;
};

// model = null | 'fill'
const RowControl = ({
  update,
  updateValues,
  markSelectedConfiguration,
  items = [],
  dragMode,
  selectedId,
  formCreatorMode,
  removeItem,
  moveItemToIndex,
  rowIndex,
  showDropMarkers,
  setIsDragging,
  rowValues,
  filledFormMode,
  disabled,
  rowsLength,
}) => {
  const [, drop] = useDrop({
    accept: ItemTypes.COMPONENT,
    drop: (item, rest) => {
      const isClone = item.dragMode === "clone";

      const targetRow = rowIndex;
      const sourceRow = item.rowIndex;
      const sourceIndex = item.index;

      if (isClone && update) {
        update([
          ...items,
          {
            id: uuid(),
            dataType: item.subtype,
            configuration: {
              label: item.defaultLabel || "",
            },
            // componentType: TODO in case we have multiple possible renderers
            // this field could be used to specify which one we want to use
          },
        ]);
      } else {
        if (!(targetRow < items.length)) {
          const targetIndex = 0;
          moveItemToIndex &&
            moveItemToIndex({
              item,
              targetRow,
              targetIndex,
              sourceRow,
              sourceIndex,
            });
        }
      }
    },
    collect: (connect, monitor) => ({}),
  });

  const rowIsEmpty = items.length === 0;

  const cardSource = {
    beginDrag(props) {
      return {
        id: props.id,
        index: props.index,
      };
    },
  };

  const updateValue = ({ id, value }) => {
    if (updateValues) {
      updateValues({ id, value });
    }
  };

  return (
    <div
      className={classnames(s.main, "columns", {
        [s.alignCenter]: rowIsEmpty,
      })}
      ref={drop}
    >
      {Object.keys(items).forEach((key) =>
        items[key] === undefined ? delete items[key] : {}
      )}
      {items.map((item, index) => {
        const filledValue =
          rowValues && rowValues[index] ? rowValues[index].value : "";

        const control = createControl({
          id: item.id,
          subtype: item.dataType,
          configuration: item.configuration,
          markSelected: markSelectedConfiguration,
          isSelected: item.id === selectedId,
          dragMode,
          formCreatorMode,
          onChange: updateValue,
          removable: formCreatorMode,
          onRemove: () => removeItem(index),
          rowIndex,
          index,
          setIsDragging: setIsDragging,
          filledValue: filledValue,
          filledFormMode,
          disabled,
        });
        return (
          <React.Fragment key={index}>
            {showDropMarkers && index === 0 && (
              <ItemSeparator
                rowIndex={rowIndex}
                index={index}
                dragMode={dragMode}
                moveItemToIndex={moveItemToIndex}
              />
            )}
            <div className="column">{control}</div>
            {showDropMarkers && (
              <ItemSeparator
                rowIndex={rowIndex}
                index={index + 1}
                dragMode={dragMode}
                moveItemToIndex={moveItemToIndex}
              />
            )}
          </React.Fragment>
        );
      })}
      {rowIsEmpty && !(rowsLength - 1 === rowIndex) && (
        <div className="column">
          <ItemRowSeparator dragMode={dragMode} />
        </div>
      )}
      {dragMode !== "none" && rowIsEmpty && rowsLength - 1 === rowIndex && (
        <div className="column" style={{ textAlign: "center" }}>
          Przeciągnij i upuść elementy formularza
        </div>
      )}
    </div>
  );
};

export default RowControl;
