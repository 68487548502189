import React, { useState, useEffect } from "react";
import { getAxiosInstance } from "../redux/common";
import {
  CssBaseline,
  AppBar,
  Toolbar,
  Button,
  IconButton,
  Typography,
  Paper,
  Tabs,
  Tab,
  Box,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { makeStyles } from "@material-ui/core/styles";
import LoginForm from "./login";
import {
  BrowserRouter as Router,
  Route,
  Link as RouterLink,
  Switch,
  Redirect,
  useRouteMatch,
  useParams,
  useHistory,
} from "react-router-dom";
import Loader from "../components/loader";
import Tenants from "./tenants";
import Orders from "./orders";
import Employees from "./employees";
import Codes from "./codes";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

/*
 function CenteredTabs(props) {
 const classes = useStyles();

 const [value, setValue] = React.useState(props.value);

 if (value != props.value) {
 setValue(props.value);
 }

 return (
 <Paper className={classes.root}>
 <Tabs
 value={value}
 indicatorColor="primary"
 textColor="primary"
 centered
 >
 <Tab component={RouterLink} label="Użytkownicy" to="/owner-panel/tenants"  />
 <Tab component={RouterLink} label="Zamówienia" to="/owner-panel/orders"  />
 </Tabs>
 </Paper>
 );
 }
 */

function CenteredTabs() {
  const classes = useStyles();

  const [value, setValue] = React.useState(0);
  const history = useHistory();

  switch (history.location.pathname) {
    case "/owner-panel/tenants":
      if (value != 0) {
        setValue(0);
      }
      break;
    case "/owner-panel/orders":
      if (value != 1) {
        setValue(1);
      }
      break;
    case "/owner-panel/codes":
      if (value != 2) {
        setValue(2);
      }
      break;
    case "/owner-panel/employees":
      if (value != 3) {
        setValue(3);
      }
      break;
    default:
      return null;
  }

  return (
    <Paper className={classes.root}>
      <Tabs value={value} indicatorColor="primary" textColor="primary" centered>
        <Tab
          component={RouterLink}
          label="Użytkownicy"
          to="/owner-panel/tenants"
        />
        <Tab
          component={RouterLink}
          label="Zamówienia"
          to="/owner-panel/orders"
        />
        <Tab
          component={RouterLink}
          label="Kody"
          to="/owner-panel/codes"
        />
        <Tab
          component={RouterLink}
          label="Pracownicy"
          to="/owner-panel/employees"
        />
      </Tabs>
    </Paper>
  );
}

function Main(props) {
  const { path, url } = useRouteMatch();
  const [prices, setPrices] = useState();
  const [selectedTab, setSelectedTab] = useState(0);
  const { userState } = props;
  const [user, setUser] = userState;
  const classes = useStyles();

  async function loadPrices() {
    try {
      const pricesResult = await getAxiosInstance().get("/payment/v1/price", {
        params: {
          country: "PL",
        },
      });
      setPrices(pricesResult.data);
    } catch (e) {
      console.error(e);
      setPrices(null);
    }
  }

  async function logout() {
    try {
      await getAxiosInstance().post("/owner/v1/logout", {});
      setUser(null);
    } catch (e) {
      console.error(e);
    }
  }

  useEffect(() => {
    if (prices === undefined) {
      loadPrices();
    }
  });

  if (prices === undefined) {
    return <Loader loading={true} text="Wczytywanie danych" />;
  } else if (prices === null) {
    return <div>Błąd wczytywania danych</div>;
  } else {
    return (
      <>
        <div className={classes.root}>
          <AppBar position="static">
            <Toolbar>
              {/* <IconButton
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="menu"
              >
                <MenuIcon />
              </IconButton> */}
              <Typography variant="h6" className={classes.title}>
                Panel administracyjny
              </Typography>
              <Button color="inherit" onClick={logout}>
                Wyloguj się
              </Button>
            </Toolbar>
          </AppBar>
        </div>
        <CenteredTabs />
        <Box pt={0.1}>
          <Switch>
            <Route exact path={path}>
              <Redirect to={`${path}/tenants`} />
            </Route>
            <Route path={`${path}/tenants`}>
              <Tenants />
            </Route>
            <Route path={`${path}/orders`}>
              <Orders prices={prices} />
            </Route>
            <Route path={`${path}/codes`}>
              <Codes />
            </Route>
            <Route path={`${path}/employees`}>
              <Employees userState={userState} />
            </Route>
            <Route path="*">
              <div>No match!!!</div>
            </Route>
          </Switch>
        </Box>
      </>
    );
  }
}

function Protected(props) {
  const { userState } = props;
  const [user, setUser] = userState;

  async function checkLogin() {
    if (user === undefined) {
      try {
        const authResult = await getAxiosInstance().get("/owner/v1/auth");
        setUser(authResult.data);
      } catch (e) {
        setUser(null);
      }
    }
  }

  useEffect(() => {
    checkLogin();
  });

  if (user === undefined) {
    return <Loader loading={true} text="Uwierzytelnianie" />;
  } else if (user === null) {
    return <Redirect to="/owner-panel/login" />;
  } else {
    return <Main userState={userState} />;
  }
}

function OwnerApp() {
  const userState = useState();
  const [user, setUser] = userState;

  return (
    <>
      <CssBaseline />
      <Router>
        <Switch>
          <Route exact path="/owner-panel/login">
            <LoginForm onUserChange={setUser} />
          </Route>
          <Route path="/owner-panel">
            <Protected userState={userState} />
          </Route>
        </Switch>
      </Router>
    </>
  );
}

export default OwnerApp;
