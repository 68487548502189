import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { activateUser, fetchOrgUsers, removeUser } from "../redux/actions";
import { checkPermission } from "../rbac";
import { ConfirmDialog } from "../components/confirm-dialog";
import { NavLink } from "react-router-dom";
import PageTitle from "../components/page-title";
import Box from "@material-ui/core/Box";

const stateToProps = (state) => ({
  persons: state.orgUsers,
  roles: state.roles,
});

const dispatchToProps = (dispatch) => ({
  fetchOrgUsers: () => dispatch(fetchOrgUsers()),
  removeUser: (id) => dispatch(removeUser(id)),
  activateUser: (id) => dispatch(activateUser(id)),
});

const roleToDisplay = (authorities) => {
  return authorities
    .split(",")
    .map((r) => {
      switch (r) {
        case "ROLE_OWNER":
          return "właściciel organizacji";
        case "ROLE_EDITOR":
          return "redaktor";
        case "ROLE_USER":
          return "użytkownik";
        default:
          return "";
      }
    })
    .filter((name) => !!name)
    .join(", ");
};

const Person = ({ data, removeFunction, activateFunction }) => {
  const isOwner = data.user.authorities.indexOf("ROLE_OWNER") !== -1;

  return (
    <div className="columns">
      <div className="column">
        {data.firstName} {data.surname}
      </div>
      <div className="column">{data.mail}</div>
      <div className="column has-text-grey">
        {roleToDisplay(data.user.authorities)}
      </div>
      <div className="column">
        {!isOwner && (
          <button
            className="button is-small"
            onClick={() => removeFunction(data)}
          >
            Usuń
          </button>
        )}
        {!data.user.activated && (
          <button
            className="button is-small"
            onClick={() => activateFunction(data)}
          >
            Aktywuj
          </button>
        )}
      </div>
    </div>
  );
};

export const Persons = connect(
  stateToProps,
  dispatchToProps
)(({ persons, fetchOrgUsers, roles, history, removeUser, activateUser }) => {
  const [busy, setBusy] = useState(false);
  const [userToDelete, setUserToDelete] = useState();
  const [userToActivate, setUserToActivate] = useState();
  const removeFunction = setUserToDelete;
  const activateFunction = setUserToActivate;

  const deleteUser = async () => {
    if (userToDelete) {
      setBusy(true);
      const result = await removeUser(userToDelete.id);
      setUserToDelete(null);
      setBusy(false);
    }
  };

  const setUserActive = async () => {
    if (userToActivate) {
      setBusy(true);
      const result = await activateUser(userToActivate.id);
      setUserToActivate(null);
      setBusy(false);
    }
  };

  ////////////////////////

  useEffect(() => {
    if (checkPermission("admin:fetchOrgUsers", roles)) {
      fetchOrgUsers();
    } else {
      history.push("/");
    }
  }, [roles]);

  return (
    <div className="container is-fluid">
      {userToDelete && (
        <ConfirmDialog
          noAction={() => setUserToDelete(null)}
          yesAction={deleteUser}
          text={`Czy na pewno usunąć użytkownika ${userToDelete.mail}?`}
          busy={busy}
        />
      )}
      {userToActivate && (
        <ConfirmDialog
          noAction={() => setUserToActivate(null)}
          yesAction={setUserActive}
          text={`Czy na pewno aktywować użytkownika ${userToActivate.mail}?`}
          busy={busy}
        />
      )}
      <PageTitle title="Członkowie organizacji" />

      <div className="">
        <Box m={1}>
          <NavLink to="/persons/invite">Dodaj nowego użytkownika</NavLink>
        </Box>
        {persons.map(
          (person) =>
            person.user.removed != true && (
              <Person
                key={person.id}
                data={person}
                removeFunction={removeFunction}
                activateFunction={activateFunction}
              ></Person>
            )
        )}
      </div>
    </div>
  );
});
