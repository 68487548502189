import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  fetchOrganization,
  logout,
  logout as loogutAction,
} from "../../redux/actions";
import Typography from "@material-ui/core/Typography";
import MuiIconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import AppBar from "@material-ui/core/AppBar";
import MenuIcon from "@material-ui/icons/Menu";
import { AccountCircle, Category } from "@material-ui/icons";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import clsx from "clsx";
import HomeIcon from "@material-ui/icons/Home";
import AssignmentIcon from "@material-ui/icons/Assignment";
import NotificationsIcon from "@material-ui/icons/Notifications";
import PeopleIcon from "@material-ui/icons/People";
import SettingsIcon from "@material-ui/icons/Settings";
import GroupIcon from "@material-ui/icons/Group";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
// import { routes } from '../../routes'
import Can from "../can";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import Badge from "@material-ui/core/Badge";
import Alert from "@material-ui/lab/Alert";
import Tooltip from "@material-ui/core/Tooltip";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import ColorizeIcon from "@material-ui/icons/Colorize";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHouseUser,
  faCog,
  faFileAlt,
} from "@fortawesome/free-solid-svg-icons";
import { faUserCircle as faUserCircleRegular } from "@fortawesome/free-regular-svg-icons";
import { faLemon as faLemonRegular } from "@fortawesome/free-regular-svg-icons";
// import { faWind as faWindRegular } from "@fortawesome/free-regular-svg-icons";
import { faEnvelope as faEnvelopeRegular } from "@fortawesome/free-regular-svg-icons";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";
import s from "./index.module.css";
import MessageIcon from "@material-ui/icons/Message";
import { ReactComponent as ShoppinCartIcon } from "../../assets/shopping-cart.svg";
import { NavLink, useLocation, useHistory } from "react-router-dom";
import image1 from "../../assets/logo_smak.jpg";
// import image11 from "../../assets/logo_smak_resized.jpg";
import image11 from "../../assets/logo_smak.svg";
import imageShoppingCart from "../../assets/shopping-cart-orange.jpg";
import ContrastIcon from "@material-ui/icons/BrightnessMedium";
import TextFormatIcon from "@material-ui/icons/TextFormat";
import { setFontSize, toggleGlobalTheme } from "../../redux/actions";
import withStyles from "@material-ui/core/styles/withStyles";

const drawerWidth = 220;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    // backgroundImage: "linear-gradient(to right, #1a1c80 , #000557)",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    boxShadow: "none",
  },
  appBarShift: {
    [theme.breakpoints.up("sm")]: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  },
  mainContent: {
    flexGrow: 1,
    padding: theme.spacing(0),
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(12),
      minHeight: `calc(100vh - ${theme.spacing(12)}px)`,
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(10),
      minHeight: `calc(100vh - ${theme.spacing(10)}px)`,
    },
  },
  mainContentShift: {
    [theme.breakpoints.up("sm")]: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  },
  mainContentShiftLarge: {
    [theme.breakpoints.up("sm")]: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    backgroundColor: "#353535",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(9) + 1,
  },
  drawerActive: {
    backgroundColor: "#ffbe00",
  },
  drawerPaper: {
    width: drawerWidth,
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    // backgroundColor: theme.palette.background.default,
    color: "#102655",
    borderBottom: `2px solid ${theme.palette.text.alternative}`,
    ...theme.mixins.toolbar,
  },
  title: {
    flexGrow: 1,
    textAlign: "center",
  },
  customBadge: {
    right: -7,
    top: 5,
    width: 5,
    height: 15,
    fontSize: 11,
  },
  menuLabelLeft: {
    color: "black",
    fontSize: "16px",

    marginRight: 30,
    "&:hover": {
      color: "orange",
    },
  },
  headerLabel: {
    fontStyle: "normal",
    fontVariant: "normal",
    fontWeight: "400",
    fontSize: theme.typography.menu,
    letterSpacing: "0px",
    color: theme.palette.text.primary,
    textTransform: "uppercase",
    "&:hover": {
      textDecoration: `underline ${theme.palette.text.alternative}`,
      textUnderlineOffset: "2px",
      fontWeight: "400",
      color: theme.palette.text.primary,
    },
  },
  headerLabelActive: {
    color: theme.palette.text.alternative,
    textDecoration: `underline ${theme.palette.text.alternative} 3.5px`,
    textUnderlineOffset: theme.typography.underline,
  },

  headerLabelRight: {
    color: theme.palette.text.primary,
    textTransform: "uppercase",
    "&:hover": {
      textDecoration: `underline ${theme.palette.text.alternative}`,
      textUnderlineOffset: "2px",
      fontWeight: "400",
      color: theme.palette.text.primary,
    },
  },

  headerLabelRightIcon: {
    "&:hover": {
      borderBottom: `1.5px solid ${theme.palette.text.alternative}`,
    },
  },
  headerLabelActiveRight: {
    color: theme.palette.text.alternative,
    textDecoration: `underline ${theme.palette.text.alternative} ${theme.typography.underline}`,
    textUnderlineOffset: "16px",
    "-webkit-text-decoration-line": "underline !important",
    "-webkit-text-decoration-color": `${theme.palette.text.alternative} !important`,
    "-webkit-text-decoration-style": "solid !important",
    "text-decoration-thickness": "4px",
  },

  headerMobileLabel: {
    color: theme.palette.text.primary,
    textTransform: "uppercase",
  },
  headerLabelMobileActive: {
    fontWeight: 500,
    color: theme.palette.text.alternative,
  },
}));
const IconButton = withStyles({
  root: {
    padding: "4px",
    marginTop: "10px",
    height: "25px",
    borderRadius: "0%",
    marginRight: "10px",
  },
})(MuiIconButton);
const Header = ({
  // history,
  loggedIn,
  my,
  cartItems,
  // location,
  children,
  ...rest
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const organization = useSelector((s) => s.organization);
  const accountType = useSelector((s) => s.organization?.accountType);
  const appBarTitle = useSelector((s) => s.appBarTitle);
  const globalTheme = useSelector((s) => s.globalTheme || "light");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();
  const history = useHistory();
  const [menuOpened, setMenuOpened] = useState(false);

  const mobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const imageDisplay = useMediaQuery((theme) => theme.breakpoints.down(1920));
  const mediumScreen = useMediaQuery((theme) => theme.breakpoints.down(1563));
  const fontSize = useSelector((s) => s.fontSize || "normal");

  const location = useLocation();
  useEffect(() => {
    if (!mobile) {
      setDrawerOpen(false);
    }
  }, [mobile]);

  const navigationLinks = [
    {
      name: ["how-it-works"],
      label: "Jak to działa?",
      href: "/how-it-works",
      allowedToDisplay:
        accountType === "PERSONAL" || accountType === "ORGANIZATION",
      newTab: false,
    },
    {
      name: ["start"],
      label: "Start",
      href: "/",
      allowedToDisplay:
        accountType === "PERSONAL" ||
        accountType === "ORGANIZATION" ||
        my?.user.authorities?.includes("ROLE_ADMIN"),
      newTab: false,
    },
    {
      name: ["orders", "order-details"],
      label: "Zamówienia",
      href: "/orders",
      allowedToDisplay:
        accountType === "PERSONAL" ||
        accountType === "ORGANIZATION" ||
        my?.user.authorities?.includes("ROLE_ADMIN"),
      newTab: false,
    },
    {
      name: ["tests"],
      label: "Testy",
      href: "/tests",
      allowedToDisplay: accountType === "PERSONAL",
      newTab: false,
    },
    {
      name: ["send-message"],
      label: "Kontakt",
      href: "/send-message",
      allowedToDisplay:
        accountType === "PERSONAL" || accountType === "ORGANIZATION",
      newTab: false,
    },
    {
      name: ["settings"],
      label: "Ustawienia",
      href: "/settings",
      allowedToDisplay: my?.user.authorities?.includes("ROLE_ADMIN"),
      newTab: false,
    },
    {
      name: ["terms"],
      label: "Regulaminy",
      href: "/terms",
      allowedToDisplay: my?.user.authorities?.includes("ROLE_ADMIN"),
      newTab: false,
    },
    {
      name: ["alltests"],
      label: "Wszystkie testy",
      href: "/alltests",
      allowedToDisplay: my?.user.authorities?.includes("ROLE_ADMIN"),
      newTab: false,
    },
  ];

  const navigationRightLinks = [
    {
      name: ["shopping-cart", "test-kit"],
      label: (active) => {
        if (active) {
          return (
            <Badge badgeContent={cartItems.length} color="secondary">
              <img
                alt="Ikonka koszyka. Zawartość:"
                src={imageShoppingCart}
                style={{ height: "27px" }}
              />
            </Badge>
          );
        } else {
          return (
            <Badge badgeContent={cartItems.length} color="secondary">
              <ShoppinCartIcon
                role="img"
                aria-hidden="false"
                alt="Ikonka koszyka. Zawartość:"
                title="Ikonka koszyka. Zawartość:"
                style={{ height: "25px" }}
              />
            </Badge>
          );
        }
      },
      href: "/shopping-cart",
    },
    {
      name: "profile",
      label: (
        <Avatar
          onClick={() => setMenuOpened(!menuOpened)}
          src={my?.avatarPath}
          onKeyDown={(e) => {
            if (e.keyCode == 13) setMenuOpened(!menuOpened);
          }}
        >
          <AccountCircle />
        </Avatar>
      ),
      // href: "/profile",
    },
  ];

  const [
    displayHomeNotificationIcon,
    setDisplayHomeNotificationIcon,
  ] = useState(false);
  const [
    displayShoppingCartNotificationIcon,
    setDisplayShoppingCartNotificationIcon,
  ] = useState(false);
  const [
    displayFormNotificationIcon,
    setDisplayFormNotificationIcon,
  ] = useState(false);
  const [
    displayOrderNotificationIcon,
    setDisplayOrderNotificationIcon,
  ] = useState(false);
  const [
    displaySettingsNotificationIcon,
    setDisplaySettingsNotificationIcon,
  ] = useState(false);

  useEffect(() => {
    if (loggedIn && !organization) {
      dispatch(fetchOrganization());
    }
  }, [organization, loggedIn]);

  const logoutWrapper = async () => {
    dispatch(loogutAction());
  };

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const drawer = (
    <Grid container style={{ height: "100vh" }}>
      <Grid item xs={12}>
        <div className={classes.toolbar}></div>
        <Divider />

        <List>
          {navigationLinks.map(
            (link, id) =>
              link.allowedToDisplay && (
                <ListItem key={id}>
                  <Link
                    color="textPrimary"
                    variant="button"
                    // href={link.href}
                    className={classes.menuLabelLeft}
                    target={link.newTab ? "_blank" : ""}
                    onClick={() => {
                      window.screen.width < 960 && setDrawerOpen(false);
                      history.push(link.href);
                    }}
                  >
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      {/* <span style={{ marginRight: 5, fontSize: 14 }}>
                        {link.icon}
                      </span> */}

                      <span
                        style={{ whiteSpace: "nowrap" }}
                        className={clsx(classes.headerMobileLabel, {
                          [classes.headerLabelMobileActive]:
                            location.pathname.length === 1 &&
                            link.name[0] === "start"
                              ? true
                              : location.pathname.match(`^/${link.name[0]}`)
                              ? location.pathname.match(`^/${link.name[0]}`)
                              : location.pathname.match(`^/${link.name[1]}`),
                        })}
                      >
                        {link.label}
                      </span>
                    </div>
                  </Link>
                </ListItem>
              )
          )}
        </List>
      </Grid>
      {/* <Grid
        item
        xs={12}
        style={{
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "center",
          margin: "12px auto",
        }}
      >
        <a
          href="http://www.africau.edu/images/default/sample.pdf"
          target="_blank"
        >
          <Button variant="contained" color="primary" size="small">
            POMOC
          </Button>
        </a>
      </Grid> */}
    </Grid>
  );

  const container =
    window !== undefined ? () => window.document.body : undefined;

  return (
    <>
      {loggedIn && (
        <Grid container>
          <Grid item xs={12}>
            <AppBar
              position="fixed"
              className={clsx(classes.appBar, {
                [classes.appBarShift]: drawerOpen,
              })}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                color: "#102655",
                backgroundColor: theme.drawer.backgroundColor,
                borderBottom: `2px solid ${theme.palette.text.alternative}`,
                boxShadow: "none",
              }}
            >
              <Grid
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "1px",
                }}
              >
                <Tooltip title="Przełącz kontrastowy tryb strony">
                  <IconButton>
                    <ContrastIcon
                      onClick={() => dispatch(toggleGlobalTheme())}
                    />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Zmień rozmiar czcionki">
                  <IconButton>
                    <TextFormatIcon
                      style={{ border: fontSize === "normal" && "1px solid" }}
                      fontSize="small"
                      onClick={() => dispatch(setFontSize("normal"))}
                    />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Zmień rozmiar czcionki">
                  <IconButton>
                    <TextFormatIcon
                      style={{ border: fontSize === "medium" && "1px solid" }}
                      fontSize="medium"
                      onClick={() => dispatch(setFontSize("medium"))}
                    />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Zmień rozmiar czcionki">
                  <IconButton>
                    <TextFormatIcon
                      style={{ border: fontSize === "large" && "1px solid" }}
                      fontSize="large"
                      onClick={() => dispatch(setFontSize("large"))}
                    />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Hidden mdUp>
                <Toolbar className={classes.toolbar}>
                  <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    onClick={handleDrawerToggle}
                  >
                    <MenuIcon />
                  </IconButton>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                      alt="Logotyp Test Smaku i Węchu"
                      style={{ cursor: "pointer" }}
                      onClick={() => history.push("/")}
                      src={image1}
                      style={{ height: 55 }}
                    />
                  </div>
                  <Typography variant="h6" className={classes.title}>
                    {/* {appBarTitle} */}
                  </Typography>
                  <Can
                    permission="shopping-cart:page"
                    ok={() => (
                      <a
                        style={{
                          marginRight: "15px",
                          height: "63px",
                          display: "flex",
                          alignItems: "center",
                          borderBottom:
                            (location.pathname.match(`^/shopping-cart`) ||
                              location.pathname.match(`^/test-kit`)) &&
                            `3.5px solid ${theme.palette.text.alternative}`,
                        }}
                        onClick={() => history.push("/shopping-cart")}
                      >
                        <div
                          // className={clsx(s.headerLabelRight, {
                          //   [s.headerLabelMobileActiveRight]: location.pathname.match(
                          //     `^/shopping-cart`
                          //   ),
                          //   [s.headerLabelMobileActiveRight1]: location.pathname.match(
                          //     `^/test-kit`
                          //   ),
                          // })}
                          style={{}}
                        >
                          <span>
                            <Badge
                              badgeContent={cartItems.length}
                              color="secondary"
                            >
                              {location.pathname.match(`^/shopping-cart`) ||
                              location.pathname.match(`^/test-kit`) ? (
                                <img
                                  src={imageShoppingCart}
                                  alt="Ikonka koszyka. Zawartość:"
                                  style={{ height: "27px" }}
                                />
                              ) : (
                                <ShoppinCartIcon
                                  role="img"
                                  aria-hidden="false"
                                  alt="Ikonka koszyka. Zawartość:"
                                  title="Ikonka koszyka. Zawartość:"
                                  style={{ height: "25px" }}
                                />
                              )}
                            </Badge>
                          </span>
                        </div>
                      </a>
                    )}
                  />

                  <Box
                    ml={1}
                    position="relative"
                    className={clsx(classes.headerLabelRight, {
                      [classes.headerLabelMobileActiveRight]: location.pathname.match(
                        `^/profile`
                      ),
                      [classes.headerLabelMobileActiveRight1]: location.pathname.match(
                        `^/proteges`
                      ),
                    })}
                    style={{
                      height: "63px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Avatar
                      onClick={() => setMenuOpened(!menuOpened)}
                      src={my.avatarPath}
                    >
                      <AccountCircle />
                    </Avatar>

                    {menuOpened && (
                      <ClickAwayListener
                        onClickAway={() => setMenuOpened(false)}
                      >
                        <Box position="absolute" right={0} top={64}>
                          {/* <Card variant="outlined" p={1}>
                            <CardContent p={1}> */}
                          <List
                            style={{
                              background: theme.palette.background.default,
                            }}
                          >
                            <ListItem
                              button
                              onClick={() => {
                                history.push("/profile");
                                setMenuOpened(false);
                              }}
                            >
                              <ListItemText>
                                <span
                                  style={{
                                    color: theme.palette.text.alternative3,
                                    fontSize: theme.typography.letfMenuLabel,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    fontWeight: "300",
                                  }}
                                >
                                  Mój profil
                                </span>
                              </ListItemText>
                            </ListItem>
                            {accountType === "PERSONAL" && (
                              <ListItem
                                button
                                onClick={() => {
                                  history.push("/proteges");
                                  setMenuOpened(false);
                                }}
                              >
                                <ListItemText>
                                  <span
                                    style={{
                                      color: theme.palette.text.alternative3,
                                      fontSize: theme.typography.letfMenuLabel,
                                      fontStyle: "normal",
                                      fontVariant: "normal",
                                      fontWeight: "300",
                                    }}
                                  >
                                    Profil dziecka
                                  </span>
                                </ListItemText>
                              </ListItem>
                            )}
                            {/* <Divider /> */}
                            <ListItem button>
                              <ListItemText>
                                <a
                                  style={{
                                    color: theme.palette.text.alternative3,
                                    fontSize: theme.typography.letfMenuLabel,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    fontWeight: "300",
                                  }}
                                  href="http://www.africau.edu/images/default/sample.pdf"
                                  target="_blank"
                                >
                                  Pomoc
                                </a>
                              </ListItemText>
                            </ListItem>
                            <ListItem
                              button
                              onClick={() => {
                                dispatch(logout());
                                setMenuOpened(false);
                              }}
                            >
                              <ListItemText>
                                <span
                                  style={{
                                    color: theme.palette.text.alternative3,
                                    fontSize: theme.typography.letfMenuLabel,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    fontWeight: "500",
                                  }}
                                >
                                  Wyloguj
                                </span>
                              </ListItemText>
                            </ListItem>
                          </List>
                          {/* </CardContent>
                          </Card> */}
                        </Box>
                      </ClickAwayListener>
                    )}
                  </Box>
                </Toolbar>
              </Hidden>

              <Hidden smDown>
                <Grid
                  container
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    color: "#102655",
                    // backgroundColor: "white",
                    borderBottom: `2px solid ${theme.typography.underline}`,
                  }}
                >
                  <Grid item container sm={12} lg={11}>
                    <Grid item xs={10} sm={11} style={{ display: "flex" }}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <img
                          alt="Logotyp Test Smaku i Węchu"
                          tabindex={1}
                          src={image11}
                          style={{
                            cursor: "pointer",
                            height: "60px",
                          }}
                          onClick={() => history.push("/")}
                          onKeyDown={(e) => {
                            if (e.keyCode == 13) history.push("/");
                          }}
                        />
                      </div>
                      <Toolbar>
                        {navigationLinks.map(
                          (link, id) =>
                            link.allowedToDisplay && (
                              <a
                                tabindex={1 + id}
                                key={id}
                                className={clsx(classes.headerLabel, {
                                  [classes.headerLabelActive]:
                                    location.pathname.length === 1 &&
                                    link.name[0] === "start"
                                      ? true
                                      : location.pathname.match(
                                          `^/${link.name[0]}`
                                        )
                                      ? location.pathname.match(
                                          `^/${link.name[0]}`
                                        )
                                      : location.pathname.match(
                                          `^/${link.name[1]}`
                                        ),
                                })}
                                target={link.newTab ? "_blank" : ""}
                                onClick={() => history.push(link.href)}
                                onKeyDown={(e) => {
                                  if (e.keyCode == 13) history.push(link.href);
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    marginTop: "10px",
                                    marginRight: "20px",
                                  }}
                                >
                                  <span style={{ whiteSpace: "nowrap" }}>
                                    {link.label}
                                  </span>
                                </div>
                              </a>
                            )
                        )}
                        {my.anonimizationRequestedAt && !mediumScreen && (
                          <span color="inherit" style={{ marginRight: "15px" }}>
                            <Alert
                              severity="error"
                              style={{
                                backgroundColor:
                                  globalTheme === "high-contrast" && "#000000",
                                border:
                                  globalTheme === "high-contrast" &&
                                  "1px solid #ffff00",
                                color:
                                  globalTheme === "high-contrast" && "#ffff00",
                              }}
                            >
                              Twoje konto zostanie wkrótce usunięte!
                            </Alert>
                          </span>
                        )}
                      </Toolbar>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      sm={1}
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                      }}
                    >
                      {navigationRightLinks.map((link, id) => (
                        <a
                          tabindex={navigationLinks.length + id}
                          key={id}
                          // className={clsx(s.headerLabel, {
                          //   [s.headerLabelActive]:
                          //     location.pathname.length === 1 &&
                          //     link.name === "start"
                          //       ? true
                          //       : location.pathname.match(`^/${link.name[0]}`)
                          //       ? location.pathname.match(`^/${link.name[0]}`)
                          //       : location.pathname.match(`^/${link.name[1]}`),
                          // })}
                          style={{ textDecoration: "none" }}
                          onClick={() => history.push(link.href)}
                          onKeyDown={(e) => {
                            if (e.keyCode == 13 && link.name !== "profile") {
                              history.push(link.href);
                            } else if (
                              e.keyCode == 13 &&
                              link.name === "profile"
                            ) {
                              setMenuOpened(!menuOpened);
                            }
                          }}
                          style={{
                            height: "63px",
                            display: "flex",
                            alignItems: "center",
                            borderBottom:
                              (location.pathname.length === 1 &&
                              link.name === "start"
                                ? true
                                : location.pathname.match(`^/${link.name[0]}`)
                                ? location.pathname.match(`^/${link.name[0]}`)
                                : location.pathname.match(
                                    `^/${link.name[1]}`
                                  )) &&
                              `3.5px solid ${theme.palette.text.alternative}`,
                          }}
                        >
                          <div
                            className={classes.headerLabelRightIcon}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              marginTop: "10px",
                              marginRight:
                                link.name[0] !== "shopping-cart" &&
                                link.name[1] !== "test-kit"
                                  ? "20px"
                                  : "35px",
                              alignItems: "center",
                            }}
                          >
                            {link.name[0] !== "shopping-cart" &&
                            link.name[1] !== "test-kit"
                              ? link.label
                              : link.label(
                                  location.pathname.length === 1 &&
                                    link.name === "start"
                                    ? true
                                    : location.pathname.match(
                                        `^/${link.name[0]}`
                                      )
                                    ? location.pathname.match(
                                        `^/${link.name[0]}`
                                      )
                                    : location.pathname.match(
                                        `^/${link.name[1]}`
                                      )
                                )}
                          </div>
                        </a>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
                {menuOpened && (
                  <div>
                    <ClickAwayListener onClickAway={() => setMenuOpened(false)}>
                      <Box
                        position="absolute"
                        right={0}
                        style={{ zIndex: "99" }}
                      >
                        <List
                          style={{
                            background: theme.palette.background.default,
                          }}
                        >
                          <ListItem
                            button
                            onClick={() => {
                              history.push("/profile");
                              setMenuOpened(false);
                            }}
                          >
                            <ListItemText>
                              <span
                                style={{
                                  color: theme.palette.text.alternative3,
                                  fontSize: theme.typography.letfMenuLabel,
                                  fontStyle: "normal",
                                  fontVariant: "normal",
                                  fontWeight: "300",
                                  textTransform: "none",
                                }}
                              >
                                Mój profil
                              </span>
                            </ListItemText>
                          </ListItem>
                          {accountType === "PERSONAL" && (
                            <Tooltip title="Dodanie profilu Twoim dzieciom lub podopiecznym umożliwia im wykonie Testu Smaku i Węchu">
                              <ListItem
                                button
                                onClick={() => {
                                  history.push("/proteges");
                                  setMenuOpened(false);
                                }}
                              >
                                <ListItemText>
                                  <span
                                    style={{
                                      color: theme.palette.text.alternative3,
                                      fontSize: theme.typography.letfMenuLabel,
                                      fontStyle: "normal",
                                      fontVariant: "normal",
                                      fontWeight: "300",
                                    }}
                                  >
                                    Profil dziecka
                                  </span>
                                </ListItemText>
                              </ListItem>
                            </Tooltip>
                          )}
                          <ListItem button>
                            <ListItemText>
                              <a
                                style={{
                                  color: theme.palette.text.alternative3,
                                  fontSize: theme.typography.letfMenuLabel,
                                  fontStyle: "normal",
                                  fontVariant: "normal",
                                  fontWeight: "300",
                                }}
                                href="http://www.africau.edu/images/default/sample.pdf"
                                target="_blank"
                              >
                                Pomoc
                              </a>
                            </ListItemText>
                          </ListItem>
                          <ListItem
                            button
                            onClick={() => {
                              dispatch(logout());
                              setMenuOpened(false);
                            }}
                          >
                            <ListItemText>
                              <span
                                style={{
                                  color: theme.palette.text.alternative3,
                                  fontSize: theme.typography.letfMenuLabel,
                                  fontStyle: "normal",
                                  fontVariant: "normal",
                                  fontWeight: "500",
                                }}
                              >
                                Wyloguj
                              </span>
                            </ListItemText>
                          </ListItem>
                        </List>
                      </Box>
                    </ClickAwayListener>
                  </div>
                )}

                {/* <Typography variant="h6" className={classes.title}>
                  {appBarTitle}
                </Typography> */}
              </Hidden>
            </AppBar>

            <Hidden mdUp>
              <Drawer
                container={container}
                variant="temporary"
                anchor={theme.direction === "rtl" ? "right" : "left"}
                open={drawerOpen}
                onClose={handleDrawerToggle}
                classes={{
                  paper: classes.drawerPaper,
                }}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
              >
                {drawer}
              </Drawer>
              {/* TestResult */}
            </Hidden>
          </Grid>

          <Grid
            item
            xs={12}
            className={clsx(classes.mainContent, {
              [classes.mainContentShiftLarge]: !drawerOpen,
              [classes.mainContentShiftLarge]: drawerOpen,
            })}
            style={{
              background:
                window.location.pathname.includes("tests/") ||
                window.location.pathname.includes("test/")
                  ? theme.palette.background.default
                  : theme.palette.background.default,
            }}
          >
            {children}
          </Grid>
        </Grid>
      )}
      {!loggedIn && children}
    </>
  );
};

export default connect(
  (state) => ({
    loggedIn: state.loggedIn,
    my: state.my,
    cartItems: state.cartItems,
  }),
  (dispatch) => ({
    logout: () => dispatch(loogutAction()),
  })
)(Header);
