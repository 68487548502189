import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useHistory } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles, withStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 250,
    borderRadius: "1rem",
    border: "0.5px solid gainsboro",
    boxShadow: "none",
  },
  colorText: {
    color: theme.palette.text.alternative2,
  },
  textHeader: {
    fontSize: theme.typography.headerLarge,
    "@media (max-width: 600px)": {
      fontSize: theme.typography.header,
    },
  },
  text: {
    fontSize: theme.typography.text,
  },
}));

const MessageSent = () => {
  const history = useHistory();
  const classes = useStyles();
  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => history.push("/start"), 5000);
  }, []);
  return (
    <Box style={{ height: "100vh" }}>
      <Grid
        item
        container
        spacing={2}
        xs={12}
        sm={8}
        xl={8}
        style={{
          padding: "18px",
          margin: "24px auto",
          display: "flex",
        }}
        direction="column"
      >
        <Grid item xs={12} sm={10} md={8}>
          <h1
            aria-hidden="false"
            aria-label="Dziękujemy za skorzystanie z formularza kontaktowego"
            style={{ display: "none" }}
          >
            Dziękujemy za skorzystanie z formularza kontaktowego
          </h1>
          <Typography
            variant="h3"
            gutterBottom
            className={`${classes.colorText} ${classes.textHeader}`}
          >
            Dziękujemy za skorzystanie z formularza kontaktowego
          </Typography>
          <Typography
            variant="h3"
            gutterBottom
            className={`${classes.colorText} ${classes.text}`}
            style={{
              // fontSize: "18px",
              letterSpacing: 0.25,
            }}
          >
            Odpowiemy w ciągu 24 godzin
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MessageSent;
