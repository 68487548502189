import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  saveForm,
  saveFormData,
  fetchForm,
  fetchFormData,
  updateForm,
  updateFormData,
  alertAdd,
} from "../../redux/actions";
import FormEditor from "../../components/form-editor/form-editor";
import { Redirect, Prompt } from "react-router-dom";
import Can from "../../components/can";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RuleEditor } from "../../components/rule-editor";
import s from "./index.module.css";
import { ColorBox } from "../../components/color-box";
import Typography from "@material-ui/core/Typography";
import { Box } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

const loToRows = (formData) => {
  return formData.layoutElementObject.children.map((c) => {
    return c.children.map((c) => {
      return c;
    });
  });
};

const OPERATORS = {
  GREATER_THAN: "większa niż",
  LESS_THAN: "mniejsza niż",
  EQUALS: "równa",
  BETWEEN: "pomiędzy",
}; // TODO - extract

const AddNewFormPage = ({
  saveForm,
  saveFormData,
  updateForm,
  updateFormData,
  history,
  match: {
    params: { id, scheduledFormId },
  },
  fetchForm,
  orgForms,
  fetchFormData,
  existingFormData,
  alertAdd,
}) => {
  const [fetching, setFetching] = useState();
  const [formData, setFormData] = useState();
  const [addMode, setAddMode] = useState();
  const [formMetadata, setFormMetadata] = useState();
  const [initialRows, setInitialRows] = useState();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [rules, setRules] = useState([]);
  const [showNewRule, setShowNewRule] = useState(false);
  const [formEdited, isFormEdited] = useState(false);

  const [selectedTestType, setSelectedTestType] = useState("");
  const [selectedTestCode, setSelectedTestCode] = useState("");

  useEffect(() => {
    setAddMode(id === undefined);
  }, [id]);

  useEffect(() => {
    if (formMetadata) {
      setRules(formMetadata.formRules || []);
    }
  }, [formMetadata]);

  useEffect(() => {
    if (!addMode && !fetching) {
      const formId = parseInt(id);

      if (formId && fetching === undefined) {
        setFetching(true);
        fetchForm(formId).then((fff) => {
          setFetching(false);
        });
      }
    }
  }, [addMode, fetching, id]);

  useEffect(() => {
    const meta = orgForms.find((f) => f.id === parseInt(id));
    setFormMetadata(meta);
  }, [orgForms, id]);

  useEffect(() => {
    if (formMetadata) {
      fetchFormData(formMetadata.layoutId);
      setSelectedTestType(formMetadata.testType || "");
      setSelectedTestCode(formMetadata.testCode || "");
    }
  }, [formMetadata]);

  useEffect(() => {
    if (formMetadata) {
      setName(formMetadata.name);
      setDescription(formMetadata.description);
    }
  }, [formMetadata]);

  useEffect(() => {
    if (id && existingFormData) {
      setInitialRows(loToRows(existingFormData));

      setFetching(false);
    }
  }, [existingFormData]);

  const addRule = () => {
    setShowNewRule(true);
  };

  const acceptRule = (rule) => {
    setRules([...rules, rule]);

    setShowNewRule(false);
  };

  const removeRule = (index) => {
    setRules(rules.filter((_, ind) => ind !== index));
  };

  const rowsToLayoutElementObject = (rows) => {
    return {
      dataType: "FORM",
      children: rows
        .filter((r) => r.length)
        .map((r) => ({
          dataType: "ROW",
          children: r.map((item) => ({
            ...item,
          })),
        })),
    };
  };

  const save = async (e) => {
    isFormEdited(false);
    const payload = {
      name,
      description,
      ...(rules.length > 0 ? { formRules: rules } : {}),
      testType: selectedTestType,
      testCode: selectedTestCode,
    };
    if (addMode) {
      const data = await saveForm(payload);
      if (data) {
        const { id: formId } = data;

        const templatePayload = {
          name, // TODO -> unecessary, remove from backend?
          formId,
          layoutElementObject: rowsToLayoutElementObject(formData),
        };

        await saveFormData(templatePayload, formId /* TODO: redundant... */);
        alertAdd({ text: "Zapisano formularz" });

        history.push(`/tests`);
      }
    } else {
      const data = await updateForm(formMetadata.id, payload);
      if (data) {
        const { id: formId } = data;

        const templatePayload = {
          id: formMetadata.layoutId,
          // formId: formMetadata.id,
          name, // TODO -> unecessary, remove from backend?
          formId,
          layoutElementObject: rowsToLayoutElementObject(formData),
          testType: selectedTestType,
        };
        const result = await updateFormData(templatePayload, formId);
        alertAdd({ text: "Zaktualizowano formularz" });
        history.push(`/tests`);
      }
    }
  };

  const cancel = (e) => {
    history.push("/tests");
  };

  const [showAdvanced, setShowAdvanced] = useState(false);

  const page = () => (
    <div className="container is-fluid">
      <Prompt
        when={formEdited}
        message={() => "Czy na pewno chcesz opuścić edycję formularza?"}
      ></Prompt>
      <FontAwesomeIcon
        className={s.advancedIcon}
        icon="angle-down"
        rotation={showAdvanced ? 180 : undefined}
        title="Pokaż ustawienia zaawansowane"
        onClick={() => setShowAdvanced(!showAdvanced)}
      />

      {addMode ? (
        <div className="title">Nowy formularz</div>
      ) : (
        <div className="title">Edycja "{name}"</div>
      )}

      <div>
        {showAdvanced && (
          <div className="box">
            <div className="is-size-5">Ustawienia zaawansowane</div>

            <p>
              {rules.length === 0 ? "Brak reguł" : "Reguły:"}
              &nbsp;{" "}
              <a href="#" onClick={addRule}>
                Dodaj
              </a>
            </p>

            <ul>
              {rules.map((r, index) => (
                <li key={index}>
                  Jeśli suma punktów formularza jest {OPERATORS[r.operator]}{" "}
                  {r.arg1} {r.operator === "BETWEEN" ? `a ${r.arg2}` : ""}
                  {r.color && (
                    <>
                      ustaw kolor na &nbsp; <ColorBox color={r.color} />
                    </>
                  )}
                  {r.text && `ustaw tekst na "${r.text}"`}
                  &nbsp;{" "}
                  <a href="#" onClick={() => removeRule(index)}>
                    Usuń
                  </a>
                </li>
              ))}
            </ul>

            {showNewRule && (
              <RuleEditor
                onDone={acceptRule}
                onCancel={() => setShowNewRule(false)}
              />
            )}

            <br />
            <FormControl fullWidth variant="outlined">
              <InputLabel id="select-outlined-label">Rodzaj testu</InputLabel>
              <Select
                labelId="select-outlined-label"
                id="select-outlined"
                value={selectedTestType}
                style={{ width: 300 }}
                label="Rodzaj testu"
                onChange={(event) => setSelectedTestType(event.target.value)}
              >
                <MenuItem value="">
                  <em>Brak</em>
                </MenuItem>
                <MenuItem value="TASTE_TEST">Test smaku</MenuItem>
                <MenuItem value="SMELL_TEST">Test węchu</MenuItem>
              </Select>
            </FormControl>

            <br />
            <FormControl fullWidth variant="outlined">
              <InputLabel id="select-outlined-label">Kod testu</InputLabel>
              <Select
                labelId="select-outlined-label"
                id="select-outlined"
                value={selectedTestCode}
                style={{ width: 300 }}
                label="Kod testu"
                onChange={(event) => setSelectedTestCode(event.target.value)}
              >
                <MenuItem value="">
                  <em>Brak</em>
                </MenuItem>
                <MenuItem value="W0001">W0001</MenuItem>
                <MenuItem value="W0002">W0002</MenuItem>
                <MenuItem value="W0003">W0003</MenuItem>
                <MenuItem value="W0004">W0004</MenuItem>
                <MenuItem value="S0001">S0001</MenuItem>
                <MenuItem value="S0002">S0002</MenuItem>
                <MenuItem value="S0003">S0003</MenuItem>
                <MenuItem value="S0004">S0004</MenuItem>
              </Select>
            </FormControl>
          </div>
        )}
      </div>

      <div>
        <label htmlFor="form-name">Nazwa formularza</label>

        <input
          id="form-name"
          type="text"
          className="input"
          placeholder="np. Dane osobowe"
          value={name}
          onChange={({ target: { value: name } }) => {
            setName(name);
            isFormEdited(true);
          }}
        />
      </div>

      <div>
        <label htmlFor="form-name">Opis formularza</label>

        <input
          id="form-description"
          type="text"
          className="input"
          value={description}
          onChange={({ target: { value: name } }) => {
            setDescription(name);
            isFormEdited(true);
          }}
        />
      </div>

      <hr />

      <FormEditor
        onUpdate={setFormData}
        formId={id}
        initialRows={initialRows}
        formEdited={isFormEdited}
      />

      <hr />

      <div className="field is-grouped is-grouped-centered">
        <div className="control">
          <button className="button " onClick={cancel}>
            Anuluj
          </button>
        </div>

        <div className="control">
          <button className="button is-primary " onClick={save}>
            Zapisz
          </button>
        </div>
      </div>
    </div>
  );

  return (
    <Can
      permission="form-add-page:view"
      ok={page}
      not={() => <Redirect to="/" />}
    />
  );
};

const mapStateToProps = (state) => ({
  organization: state.organization,
  orgForms: state.orgForms,
  existingFormData: state.formData,
});

const mapDispatchToProps = (dispatch) => ({
  fetchForm: (id) => dispatch(fetchForm(id)),
  fetchFormData: (id) => dispatch(fetchFormData(id)),
  saveForm: (payload) => dispatch(saveForm(payload)),
  updateForm: (id, payload) => dispatch(updateForm(id, payload)),
  saveFormData: (payload, formId) => dispatch(saveFormData(payload, formId)),
  updateFormData: (payload, formId) =>
    dispatch(updateFormData(payload, formId)),
  alertAdd: (payload) => dispatch(alertAdd(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddNewFormPage);
