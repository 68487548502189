import React, { useEffect, useRef, useState } from "react";
import { getAxiosInstance } from "../../redux/common";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Can from "../../components/can";
import { Redirect, useHistory, useParams } from "react-router-dom";
import Themed from "./themed";
import AppBarTitle from "../../components/app-bar-title";
import fromEntries from "object.fromentries";

import imageGuideSmell1 from "../../assets/guide-smell-1.svg";
import imageGuideSmell2 from "../../assets/guide-smell-2.svg";
import imageGuideSmell3 from "../../assets/guide-smell-3.svg";
import imageGuideSmell4 from "../../assets/guide-smell-4.svg";
import imageGuideTaste1 from "../../assets/guide-taste-1.svg";
import imageGuideTaste2 from "../../assets/guide-taste-2.svg";
import imageGuideTaste3 from "../../assets/guide-taste-3.svg";
import imageGuideTaste4 from "../../assets/guide-taste-4.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "inherit",
  },
  container: {
    backgroundColor: "white",
    "& input": {
      height: "auto",
    },
  },
  flexContainer: {
    display: "flex",
    flexDirection: "column",
    minHeight: "inherit",
    alignItems: "center",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignContent: "center",
    alignSelf: "flex-end",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      borderRadius: "10px 10px 0 0",
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(0, 1, 0, 1),
    },
    [theme.breakpoints.down("xs")]: {
      position: "fixed",
      bottom: 0,
    },
    "&>div": {
      flex: "1",
      padding: theme.spacing(1),
    },
  },
  secondaryButton: {
    background: theme.palette.background.default,
    border: `1px solid ${theme.palette.text.item}`,
  },
  imageContainer: {
    width: "100%",
    minHeight: 200,
    backgroundColor: theme.palette.background.default,
    flex: "1",
    display: "flex",
    justifyContent: "center",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundOrigin: "content-box",
    objectFit: "fill",
    backgroundColor: "rgb(238, 237, 237, 1)",
  },
  header: {
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.up("sm")]: {
      borderRadius: "0 0 10px 10px",
    },
    color: "white",
    padding: 30,
    textAlign: "center",
    letterSpacing: 0,
    alignSelf: "flex-start",
    width: "100%",
  },
  answersForm: {
    width: "100%",
    minHeight: "inherit",
  },
  answers: {
    width: "100%",
    padding: theme.spacing(2, 2, 6, 2),
    [theme.breakpoints.up("sm")]: {
      width: "80%",
      padding: theme.spacing(2),
    },
    flex: "1",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    "&>fieldset": {
      display: "block",
      width: "100%",
    },
  },
  radioRoot: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      borderRadius: 12,
    },
    backgroundColor: "white",
    boxShadow:
      "0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2)",
    padding: theme.spacing(1),
    margin: "10px 0px 0px 0px",
  },
  radioLabel: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    minHeight: 60,
    flex: "1",
    "&>img": {
      height: 60,
    },
  },
  timer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    position: "relative",
  },
}));

const Demo = (props) => {
  const { classes, testType, setShowDemo } = props;

  const theme = useTheme();

  const pagesMap = new Map();
  pagesMap.set("TASTE_TEST", [
    {
      text:
        "Wyjmij próbnik z próbkami z koperty, następnie wyjmij z zestawu pasek oznaczony cyfrą 0 (pasek bez smaku).",
      img: imageGuideTaste1,
    },
    {
      text:
        "Połóż na języku pasek testowy (na środkowej części języka), a następnie zamknij usta i przełknij ślinę. Czynność tę wykonujemy  w celu przećwiczenia procedury testu.",
      img: imageGuideTaste2,
    },
    {
      text:
        "Po wykonaniu testu paskiem nr 0, odczekaj 30 sekund i powtórz czynność z pozostałymi paskami (nr 1, 2, 3, 4).",
      img: imageGuideTaste3,
    },
    {
      text: (
        <>
          Pasek może mieć smak słony, słodki, gorzki lub kwaśny. Wybierz smak,
          jaki rozpoznałeś i zaznacz odpowiednio na ekranie odpowiedzi.
          <br />
          <b>
            Aby otrzymać wiarygodne wyniki test powinien być przeprowadzony
            maksymalnie w ciągu 10 minut
          </b>
        </>
      ),
      img: imageGuideTaste4,
    },
  ]);
  pagesMap.set("SMELL_TEST", [
    {
      text:
        "W trakcie wykonywania testu, po wyjęciu zestawu próbek węchowych w formie wachlarza z koperty, rozwiń wachlarz zapachów testowych i ustaw listek na jednej z dostępnych cyfr.",
      img: imageGuideSmell1,
    },
    {
      text:
        "Delikatnie potrzyj zakropkowane pole, a następnie zbliż listek do nosa i powąchaj.",
      img: imageGuideSmell2,
    },
    {
      text:
        "Wybierz zapach, jaki rozpoznałeś i zaznacz odpowiednio na ekranie odpowiedzi.",
      img: imageGuideSmell3,
    },
    {
      text: (
        <>
          Czynność wykonaj przy użyciu wszystkich dostępnych listków zapachowych
          (nr 1, 2, 3, 4, 5, 6).
          <br />
          <b>
            Aby otrzymać wiarygodne wyniki test powinien być przeprowadzony
            maksymalnie w ciągu 10 minut.
          </b>
        </>
      ),
      img: imageGuideSmell4,
    },
  ]);

  const [pageIndex, setPageIndex] = useState(0);
  const pages = pagesMap.get(testType);
  const page = pages[pageIndex];

  const isLastPage = pageIndex + 1 >= pages.length;

  const onPrev = () => {
    setPageIndex(pageIndex - 1);
  };

  const onNext = () => {
    if (isLastPage) {
      setShowDemo(false);
    } else {
      setPageIndex(pageIndex + 1);
    }
  };

  let prevButton =
    pageIndex > 0 ? (
      <Button
        fullWidth
        onClick={onPrev}
        variant="outlined"
        className={classes.secondaryButton}
      >
        Poprzedni krok
      </Button>
    ) : null;
  let nextButton = (
    <Button
      fullWidth
      onClick={onNext}
      variant="contained"
      color={isLastPage ? "secondary" : "primary"}
    >
      {isLastPage ? "Rozpocznij test" : "Dalej"}
    </Button>
  );

  return (
    <Box className={classes.flexContainer}>
      <AppBarTitle
        value={`Jak wykonać test ${
          testType === "TASTE_TEST" ? "smaku" : "węchu"
        }`}
      />
      <Box className={classes.header}>
        <Typography
          variant="subtitle1"
          style={{
            color: theme.palette.background.default,
            fontSize: theme.typography.leftMenuLabeL,
          }}
        >
          <b>Krok {pageIndex + 1}</b> z <b>{pages.length}</b>
        </Typography>
        <Typography
          variant="subtitle1"
          style={{
            color: theme.palette.background.default,
            fontSize: theme.typography.leftMenuLabeL,
          }}
        >
          {page.text}
        </Typography>
      </Box>
      <Box
        title=""
        className={classes.imageContainer}
        style={{ backgroundImage: `url(${page.img})` }}
      />
      <Box className={classes.buttonContainer}>
        <Box>{prevButton}</Box>
        <Box>{nextButton}</Box>
      </Box>
    </Box>
  );
};

const Canvas = (props) => {
  const canvasRef = useRef(null);
  const timer = props.timer;

  const theme = useTheme();

  const draw = (ctx, startAngle) => {
    const half = ctx.canvas.width / 2;
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

    let r = half;
    ctx.fillStyle = theme.palette.background.default;
    ctx.beginPath();
    ctx.arc(half, half, r, 0, 2 * Math.PI);
    ctx.fill();

    r = half * 0.54;
    ctx.strokeStyle = theme.palette.text.primary2;
    ctx.beginPath();
    ctx.arc(half, half, r, 0, 2 * Math.PI);
    ctx.stroke();

    r = half * 0.49;
    ctx.fillStyle = theme.palette.text.alternative3;
    ctx.beginPath();
    ctx.moveTo(half, half - r);
    ctx.lineTo(half, half);
    ctx.arc(half, half, r, startAngle, 1.5 * Math.PI);
    ctx.closePath();
    ctx.fill();
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    context.fillStyle = "green";

    draw(context, 1.5 * Math.PI - (timer / 60) * 2 * Math.PI);
  }, [timer]);

  return <canvas ref={canvasRef} {...props} />;
};

const Timer = (props) => {
  const [timer, setTimer] = props.timerState;
  const { classes, currentQuestionIndex, questionsCount, testType } = props;
  const [initialValue] = useState(timer);

  const theme = useTheme();

  useEffect(() => {
    let actualTime = new Date();
    let executeTime;
    if (testType === "TASTE_TEST") {
      executeTime = new Date(
        actualTime.setSeconds(actualTime.getSeconds() + 30)
      );
    } else {
      executeTime = new Date(
        actualTime.setSeconds(actualTime.getSeconds() + 5)
      );
    }

    const interval = setInterval(() => {
      let dif = executeTime.getTime() - new Date().getTime();
      var Seconds_from_T1_to_T2 = dif / 1000;
      var Seconds_Between_Dates = Seconds_from_T1_to_T2;
      setTimer((timer) => Seconds_Between_Dates - 0.1);
    }, 100);
    return () => clearInterval(interval);
  }, []);

  return (
    <Box className={classes.flexContainer}>
      <AppBarTitle
        value={testType === "TASTE_TEST" ? "Test smaku" : "Test węchu"}
      />
      <Box className={classes.header}>
        <Typography
          variant="subtitle1"
          style={{
            color: theme.palette.background.default,
            fontSize: theme.typography.leftMenuLabeL,
          }}
        >
          Pasek {currentQuestionIndex} z {questionsCount}
        </Typography>
        <Typography
          variant="subtitle1"
          style={{
            color: theme.palette.background.default,
            fontSize: theme.typography.leftMenuLabeL,
          }}
        >
          {testType !== "TASTE_TEST"
            ? `Odczekaj `
            : `Wyjmij pasek ${currentQuestionIndex} z ust i odczekaj `}
          <b>{initialValue} sekund</b>.
        </Typography>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        flex="1"
        justifyContent="center"
      >
        <Box component="span" position="relative">
          <Canvas timer={timer} width={200 * 1.5} height={200 * 1.5} />
          <Box position="absolute" top={85 * 1.5} left={110 * 1.5}>
            <Typography
              variant="h6"
              style={{ color: theme.palette.text.primary2 }}
            >
              {Math.ceil(timer)} s
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const Question = (props) => {
  const {
    classes,
    template,
    scheduledForm,
    answers,
    currentQuestionIndex,
  } = props;

  const questions = template.layoutElementObject.children.map(
    (child) => child.children[0]
  );
  const question = questions[currentQuestionIndex];

  const onNext = props.onNext;

  const theme = useTheme();

  const [value, setValue] = useState("");
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState(" ");
  const [disabled, setDisabled] = useState(false);

  React.useEffect(() => {
    setValue(answers.get(question.id) || "");
  }, [currentQuestionIndex, answers]);

  const handleRadioChange = (event) => {
    setValue(event.target.value);
    setHelperText(" ");
    setError(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (value === "") {
      setHelperText("Wybierz odpowiedź!");
      setError(true);
    } else {
      setHelperText(" ");
      setError(false);
      if (onNext) {
        setDisabled(true);
        Promise.resolve(onNext(question.id, value))
          .catch((e) => {
            setError(true);
            setHelperText(e.message);
          })
          .finally(() => {
            setDisabled(false);
          });
      }
    }
  };

  return (
    <form className={classes.answersForm} onSubmit={handleSubmit}>
      <Box className={classes.flexContainer}>
        <AppBarTitle
          value={
            scheduledForm.testType === "TASTE_TEST"
              ? "Test smaku"
              : "Test węchu"
          }
        />
        <Box className={classes.header}>
          <Typography
            variant="subtitle1"
            style={{
              color: theme.palette.background.default,
              fontSize: theme.typography.leftMenuLabeL,
            }}
          >
            Pasek {currentQuestionIndex + 1} z {questions.length}
          </Typography>
          <Typography
            variant="subtitle1"
            style={{
              color: theme.palette.background.default,
              fontSize: theme.typography.leftMenuLabeL,
            }}
          >
            Rozwiń wachlarz{" "}
            {scheduledForm.testType === "TASTE_TEST" ? "smaków" : "zapachów"}{" "}
            testowych i ustaw{" "}
            {scheduledForm.testType === "TASTE_TEST" ? "pasek" : "listek"} z{" "}
            <b>cyfrą {currentQuestionIndex + 1}</b>.
          </Typography>
        </Box>
        <Box className={classes.answers}>
          <FormControl component="fieldset" error={error} disabled={disabled}>
            <RadioGroup
              aria-label="test"
              name="test"
              value={value}
              onChange={handleRadioChange}
            >
              {question.configuration.dictionary.dictionaryValues.map(
                (value) => (
                  <FormControlLabel
                    classes={{
                      root: classes.radioRoot,
                      label: classes.radioLabel,
                    }}
                    style={{
                      background: theme.palette.background.default,
                      border: `0.5px solid ${theme.palette.background.borderTest}`,
                    }}
                    key={value.stringValue}
                    value={value.stringValue}
                    control={<Radio />}
                    label={
                      <>
                        {value.stringValue}
                        {value.image && <img src={value.image} alt="" />}
                      </>
                    }
                  />
                )
              )}
            </RadioGroup>
            <FormHelperText>{helperText}</FormHelperText>
          </FormControl>
        </Box>
        <Box className={classes.buttonContainer}>
          <Box>
            <Button
              fullWidth
              disabled={disabled}
              type="submit"
              variant="contained"
              color="primary"
            >
              Kontynuuj
            </Button>
          </Box>
        </Box>
      </Box>
    </form>
  );
};

const FillTestPage = (props) => {
  const { classes } = props;

  const { scheduledId } = useParams();
  const [scheduledForm, setScheduledForm] = useState();
  const [template, setTemplate] = useState();

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

  const timerState = useState(0);
  const [timer, setTimer] = timerState;

  const [answers, setAnswers] = useState();
  const [showDemo, setShowDemo] = useState(true);

  const [formDataId, setFormDataId] = useState(null);

  const history = useHistory();

  const loadTest = async () => {
    try {
      const scheduledResult = (
        await getAxiosInstance().get(`/api/forms/scheduled/${scheduledId}`)
      ).data;
      if (scheduledResult.allDataFilled && scheduledResult.formDataId) {
        history.replace(`/tests/result/${scheduledResult.formDataId}`);
      }
      setScheduledForm(scheduledResult);
    } catch (e) {
      console.error("loadTest error", e);

      setScheduledForm(null);
    }
  };

  const loadFormData = async () => {
    if (scheduledForm && template) {
      if (scheduledForm.formDataId) {
        try {
          const formData = (
            await getAxiosInstance().get(
              `/api/form_data/by_id_extended/${scheduledForm.formDataId}`
            )
          ).data;
          if (formData && formData.data) {
            const newAnswers = new Map(Object.entries(formData.data));
            const questions = template.layoutElementObject.children.map(
              (child) => child.children[0]
            );

            setAnswers(newAnswers);
            if (newAnswers.size > 0) {
              setShowDemo(false);
            }
            setCurrentQuestionIndex(
              Math.min(newAnswers.size, questions.length - 1)
            );
            setFormDataId(formData.id);
          } else {
            setAnswers(new Map());
          }
        } catch (e) {
          console.error("loadFormData error", e);

          setAnswers(new Map());
        }
      } else {
        setAnswers(new Map());
      }
    }
  };

  const saveTest = async (answers) => {
    if (formDataId) {
      const formData = {
        data: fromEntries(answers),
      };
      return (
        await getAxiosInstance().put(`/api/form_data/${formDataId}`, formData)
      ).data;
    } else {
      const metadata = (
        await getAxiosInstance().post("/api/form_metadata", {
          formId: { id: scheduledForm.form.id },
        })
      ).data;

      const formData = {
        data: fromEntries(answers),
        formId: scheduledForm.form.id,
        formMetadataId: metadata.id,
        scheduledTestId: scheduledForm.id,
      };
      return (await getAxiosInstance().post("/api/form_data/", formData)).data;
    }
  };

  const onNext = async (questionId, value) => {
    const questions = template.layoutElementObject.children.map(
      (child) => child.children[0]
    );

    const newAnswers = new Map(answers);
    newAnswers.set(questionId, value);
    setAnswers(newAnswers);

    try {
      const result = await saveTest(newAnswers);
      setFormDataId(result.id);
      if (currentQuestionIndex + 1 < questions.length) {
        if (scheduledForm.testType === "TASTE_TEST") {
          setTimer(30);
        } else {
          setTimer(5);
        }
        setCurrentQuestionIndex(currentQuestionIndex + 1);
      } else {
        history.replace(`/tests/result/${result.id}`);
      }
    } catch (e) {
      console.error("saving test error", e);
      throw new Error("Coś poszło nie tak. Spróbuj ponownie później");
    }
  };

  const loadTemplate = async () => {
    try {
      const scheduledResult = await getAxiosInstance().get(
        `/api/form_template/${scheduledForm.form.layoutId}`
      );
      setTemplate(scheduledResult.data);
    } catch (e) {
      console.error("sets error", e);

      setTemplate(null);
    }
  };

  useEffect(() => {
    if (scheduledForm === undefined) {
      loadTest();
    } else if (scheduledForm && template === undefined) {
      loadTemplate();
    } else if (scheduledForm && answers === undefined) {
      loadFormData();
    }
  });

  if (template && scheduledForm && answers) {
    const questions = template.layoutElementObject.children.map(
      (child) => child.children[0]
    );
    if (showDemo) {
      return (
        <Demo
          classes={classes}
          setShowDemo={setShowDemo}
          testType={scheduledForm.testType}
        />
      );
    }
    if (timer > 0) {
      return (
        <Timer
          classes={classes}
          timerState={timerState}
          currentQuestionIndex={currentQuestionIndex}
          questionsCount={questions.length}
          testType={scheduledForm.testType}
        />
      );
    }

    return (
      <Question
        classes={classes}
        currentQuestionIndex={currentQuestionIndex}
        scheduledForm={scheduledForm}
        template={template}
        answers={answers}
        onNext={onNext}
      />
    );
  }

  return <>Loading...</>;
};

const FillTestPageWithContainer = () => {
  const classes = useStyles();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box className={classes.root}>
      <h1
        aria-hidden="false"
        aria-label="Wypełnianie TESTU"
        style={{ display: "none" }}
      >
        Wypełnianie TESTU
      </h1>
      <Grid container justify="center" style={{ minHeight: "inherit" }}>
        <Grid item xs={12} sm={8} md={6} style={{ minHeight: "inherit" }}>
          <FillTestPage classes={classes} />
        </Grid>
      </Grid>
    </Box>
  );
};

const FillTestPageWithTheme = () => (
  <Themed>
    <FillTestPageWithContainer />
  </Themed>
);

const RedirectToLogin = () => <Redirect to="/login" />;
const FillTestPageWithPermission = () => (
  <Can
    permission="sat-form:page"
    ok={FillTestPageWithTheme}
    not={RedirectToLogin}
  />
);

export default FillTestPageWithPermission;
