import React, { useState, useEffect, useRef } from "react";
import {
  setAppBarTitle,
} from "../../redux/actions";
import { useDispatch } from "react-redux";

const AppBarTitle = ({value}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setAppBarTitle(value));
  }, [value]);

  return null;
};

export default AppBarTitle;
