import React, { useEffect, useState } from "react";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import { CardContent } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { ProductTypeToName } from "./const";
import { useSelector } from "react-redux";
import s from "./index.module.css";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveOutlinedIcon from "@material-ui/icons/RemoveOutlined";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";

import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import Grid from "@material-ui/core/Grid";
import SvgIcon from "@material-ui/core/SvgIcon";
import IconButton from "@material-ui/core/IconButton";
import green from "@material-ui/core/colors/green";
import { red } from "@material-ui/core/colors";
import InputAdornment from "@material-ui/core/InputAdornment";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useTheme from "@material-ui/core/styles/useTheme";
import Typography from "@material-ui/core/Typography";
import { ReactComponent as SmellIcon } from "../../../assets/weather-windy.svg";
import { ReactComponent as TasteIcon } from "../../../assets/food-apple.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLemon as faLemonRegular } from "@fortawesome/free-regular-svg-icons";
import image1 from "../../../assets/shopping-cart2.PNG";
import image2 from "../../../assets/smell.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 250,
    borderRadius: "1rem",
    border: `0.5px solid ${theme.palette.hr.primary}`,
    boxShadow: "none",
  },
  icon: {
    marginRight: "12px",
    fontSize: "1.5rem",
    color: theme.palette.text.alternative2,
  },
  title: {
    color: theme.palette.text.alternative2,
    letterSpacing: "0.72px",
    fontWeight: "500",
  },
  text: {
    color: theme.palette.text.alternative3,
    letterSpacing: "0.48px",
  },
  incrementIcon: {
    border: `1px solid ${theme.palette.text.primary}`,
    opacity: "1",
    borderBottomLeftRadius: "5px",
    borderTopLeftRadius: "5px",
  },
}));

const TestCardComponent = (props) => {
  const theme = useTheme();
  const price = useSelector((s) => s.priceList[props.productType] || {});

  const [priceText, setPriceText] = useState("");

  useEffect(() => {
    if (price) {
      const taxPrice =
        Math.round(
          ((price.price / 100).toFixed(2) * (price.vat / 100).toFixed(2) +
            Number.EPSILON) *
            100
        ) / 100;
      const grossPrice = (price.price / 100 + taxPrice).toFixed(2);

      setPriceText(`${grossPrice} ${price.currency}`);
    }
  }, [price]);

  const classes = useStyles();
  return (
    <Card className={classes.root} style={{ flex: "1" }}>
      <CardContent style={{ height: "100%", display: "flex" }}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            container
            direction="row"
            alignItems="center"
            spacing={3}
          >
            {/* <Grid item>{props.iconRender()}</Grid> */}
            <Grid item style={{ display: "flex", minHeight: "92px" }}>
              {(props.productType === "TASTE_TEST" ||
                props.productType === "SMELL_TEST") &&
                (props.productType == "TASTE_TEST" ? (
                  <FontAwesomeIcon
                    alt=""
                    icon={faLemonRegular}
                    className={classes.icon}
                  />
                ) : (
                  // <SvgIcon
                  //   component={SmellIcon}
                  //   style={{ marginRight: "12px", fontSize: "2rem" }}
                  // />
                  <img
                    alt=""
                    src={image2}
                    style={{ height: "36px", marginRight: "12px" }}
                  ></img>
                ))}
              <Typography
                gutterBottom
                variant="h5"
                component="h2"
                className={classes.title}
              >
                {props.title}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" className={classes.text} component="p">
              {props.text}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <IconButton
              disabled={props.disabled}
              onClick={() => {
                props.value > 0 && props.setValue(props.value - 1);
                props.setDataChanged(true);
              }}
            >
              <RemoveOutlinedIcon
                role="img"
                aria-hidden="false"
                focusable="true"
                alt="zmniejsza wartość w koszyku o 1"
                title="zmniejsza wartość w koszyku o 1"
                className={classes.incrementIcon}
              />
            </IconButton>
            <span
              style={{
                color: theme.palette.text.alternative2,
                letterSpacing: "0px",
              }}
            >
              <TextField
                disabled={props.disabled}
                style={{ width: "60px" }}
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                value={props.value}
                variant="outlined"
                onFocus={(event) => {
                  event.target.select();
                }}
                onChange={(e) => {
                  let input = e.target.value;
                  if (
                    !input ||
                    (input[input.length - 1].match("[0-9]") &&
                      input[0].match("[0-9]"))
                  ) {
                    if (!input) {
                      props.setValue(0);
                    } else {
                      props.setValue(input);
                    }
                  }
                }}
                type="text"
              />
              {` szt.`}
            </span>
            <IconButton
              disabled={props.disabled}
              onClick={() => {
                props.setDataChanged(true);
                props.value === ""
                  ? props.setValue(0)
                  : props.setValue(parseInt(props.value) + 1);
              }}
            >
              <AddOutlinedIcon
                style={{ transform: "rotate(180deg)" }}
                role="img"
                aria-hidden="false"
                alt="zwiększa wartość w koszyku o 1"
                title="zwiększa wartość w koszyku o 1"
                className={classes.incrementIcon}
              />
            </IconButton>
          </Grid>
          <Grid item xs={12} container>
            <Grid item xs={9} lg={11}>
              <span
                style={{
                  color: theme.palette.text.alternative3,
                  letterSpacing: "0.54px",
                }}
              >
                {price.price ? (
                  <p>
                    Cena jednostkowa:
                    <br />
                    {(price.price / 100).toFixed(2).replace(/\./g, ",")}{" "}
                    {price.currency} netto
                    <b>
                      <br />
                      {priceText.replace(/\./g, ",")} brutto
                    </b>
                  </p>
                ) : (
                  <CircularProgress size={14} style={{ color: "blue" }} />
                )}
              </span>
            </Grid>
            <Grid
              item
              xs={3}
              lg={1}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <img alt="" src={image1} style={{ height: "30px" }}></img>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default TestCardComponent;
