import React, { useEffect, useState } from "react";
import { getAxiosInstance } from "../../redux/common";
import {
  Box,
  Button,
  Grid,
  Paper,
  SvgIcon,
  Typography,
  Container,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MailIcon from "@material-ui/icons/Mail";
import { ReactComponent as SmellIcon } from "../../assets/weather-windy.svg";
import { ReactComponent as TasteIcon } from "../../assets/food-apple.svg";
import Can from "../../components/can";
import { Link as RouterLink, Redirect, NavLink } from "react-router-dom";
import Themed from "./themed";
import AppBarTitle from "../../components/app-bar-title";
import { useSelector } from "react-redux";
import Loader from "../../components/loader";
import TablePagination from "@material-ui/core/TablePagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLemon as faLemonRegular } from "@fortawesome/free-regular-svg-icons";
import withStyles from "@material-ui/core/styles/withStyles";
import useTheme from "@material-ui/core/styles/useTheme";
import { ButtonBase } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  setBox: {
    padding: theme.spacing(2),
    "&>svg": {
      marginTop: 4,
      float: "right",
    },
  },
  testBox: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    boxSizing: "border-box",
    border: `1px solid ${theme.palette.background.borderTest}`,
    "&>svg": {
      float: "right",
      "& path": {
        fill: theme.palette.primary.main,
      },
    },
    borderRadius: "10px",
    display: "flex",
    justifyContent: "space-between",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  mainGrid: {
    height: "100vh",
    padding: theme.spacing(2),
  },
  contentGrid: {
    display: "flex",
    //   // flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
  },
  itemGrid: {
    display: "flex",
    justifyContent: "center",
  },
  label: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "17px",
    lineHeight: "20px",
    color: "#757575",
  },
  button: {
    // background: "#0000FF",
    borderRadius: "4px",
  },
  buttonCustom: {
    height: "33px",
    width: "140px",
    borderRadius: "10px",
  },
  container: {
    maxWidth: "3000px",
    minWidth: "300px",
    padding: "0px",
    "@media (max-width: 379px)": {
      paddingTop: "50px",
    },
  },
  headerGrid: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "12px 8px",
    boxShadow: `0px 1px 0px ${theme.palette.text.primary2}20`,
    position: "sticky",
    top: "6rem",
    [theme.breakpoints.down("sm")]: {
      top: "6rem",
    },
    background: theme.palette.background.default,
    zIndex: "100",
  },
  bottomGrid: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  select: {
    "&:after": {
      borderBottomColor: theme.palette.text.item,
    },
    "& .MuiSvgIcon-root": {
      color: theme.palette.text.item,
    },
  },
}));

const CustomButton = withStyles((theme) => ({
  root: {
    height: "33px",
    width: "153px",
    borderRadius: "10px",
    "&:hover": {
      color: theme.palette.background.default,
      transform: "scale(1.05)",
    },
  },
}))(Button);

const AllSets = (props) => {
  const { classes, sets } = props;
  const theme = useTheme();

  if (sets) {
    return (
      <Grid container spacing={3}>
        {[...sets.values()]
          .sort((a, b) => {
            const aCreatedAt = a[0].createdAt;
            const bCreatedAt = b[0].createdAt;
            return (
              new Date(
                bCreatedAt[0],
                bCreatedAt[1],
                bCreatedAt[2],
                bCreatedAt[3],
                bCreatedAt[4],
                bCreatedAt[5]
              ) -
              new Date(
                aCreatedAt[0],
                aCreatedAt[1],
                aCreatedAt[2],
                aCreatedAt[3],
                aCreatedAt[4],
                aCreatedAt[5]
              )
            );
          })
          .map((tests) => {
            const personDetails = tests[0].personDetails;
            return (
              <Grid
                key={tests[0].id}
                item
                xs={12}
                sm={12}
                md={6}
                lg={4}
                style={{ display: "flex" }}
              >
                <Paper
                  elevation={2}
                  style={{
                    boxShadow: "none",
                    border: `1px solid ${theme.palette.background.borderTest}`,
                    borderRadius: "10px",
                    flex: "1",
                  }}
                >
                  <Box className={classes.setBox}>
                    {/* <MailIcon color="primary" /> */}
                    <Typography
                      variant="h6"
                      style={{
                        fontWeight: "600",
                        color: theme.palette.text.alternative2,
                      }}
                    >
                      Zestaw: {tests[0].code}
                    </Typography>
                    <br />
                    <Typography
                      variant="subtitle1"
                      style={{ color: theme.palette.text.alternative3 }}
                    >
                      Zamawiający: {tests[0].ownerTenantName}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      style={{ color: theme.palette.text.alternative3 }}
                    >
                      Wypełniany przez: {personDetails.firstName}{" "}
                      {personDetails.surname}
                    </Typography>
                    {/* <Typography variant="subtitle1">
                      Typ konta: {personDetails.firstName}{" "}
                      {personDetails.surname}
                    </Typography> */}
                  </Box>
                  <Box p={2}>
                    {tests
                      .sort((a, b) => b.testType.localeCompare(a.testType))
                      .map((test) => {
                        let button;
                        if (test.formDataId && test.allDataFilled) {
                          button = (
                            <CustomButton
                              variant="contained"
                              color="primary"
                              component={RouterLink}
                              to={`/tests/result/${test.formDataId}`}
                            >
                              Pokaż wynik
                            </CustomButton>
                          );
                        } else if (test.testCode === null) {
                          button = (
                            <Tooltip title="Aby przystąpić do wykonania testu aktywuj go, wpisując 5-znakowy numer, który znajduje się na kopercie z testem.">
                              <CustomButton
                                variant="contained"
                                color="primary"
                                component={RouterLink}
                                to={{
                                  pathname: `/tests/add/${test.id}`,
                                  state: {
                                    testType:
                                      test.testType == "TASTE_TEST"
                                        ? "taste"
                                        : "smell",
                                  },
                                }}
                              >
                                Aktywuj
                              </CustomButton>
                            </Tooltip>
                          );
                        } else {
                          button = (
                            <Tooltip title="Przystępujesz do wykonania testu.">
                              <CustomButton
                                variant="contained"
                                color="primary"
                                component={RouterLink}
                                to={`/tests/fill/${test.id}`}
                              >
                                Wypełnij
                              </CustomButton>
                            </Tooltip>
                          );
                        }

                        return (
                          <Box key={test.id} className={classes.testBox}>
                            {/* {test.testType == "TASTE_TEST" ? (
                            <FontAwesomeIcon
                              icon={faLemonRegular}
                              style={{
                                // marginRight: "12px",
                                fontSize: "1.2rem",
                                color: "rgb(16, 38, 85)",
                              }}
                            />
                          ) : (
                            <SvgIcon component={SmellIcon} />
                          )} */}
                            <Typography
                              variant="h6"
                              style={{ color: theme.palette.text.alternative2 }}
                            >
                              {test.testType == "TASTE_TEST"
                                ? "Test smaku"
                                : "Test węchu"}
                            </Typography>
                            <Box>
                              {/* <Box className={classes.buttonContainer}> */}
                              {button}
                            </Box>
                          </Box>
                        );
                      })}
                  </Box>
                </Paper>
              </Grid>
            );
          })}
      </Grid>
    );
  } else {
    return null;
  }
};

const SetsMain = () => {
  const [sets, setSets] = useState();
  const [scheduledResultLength, setScheduledResultLength] = useState(null);
  const [fetchingSets, setFetchingSets] = useState(false);
  const accountType = useSelector((s) => s.organization?.accountType);

  const history = useHistory();

  const pages = [50, 100, 200, 300, 500];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(null); //not handled

  const classes = useStyles();
  const theme = useTheme();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const loadSets = async () => {
    setFetchingSets(true);
    try {
      const scheduledResult = await getAxiosInstance().get(
        "/api/v2/forms/scheduled",
        {
          params: {
            page,
            ...(rowsPerPage ? { pageSize: rowsPerPage } : {}),
            ...(orderBy ? { order: orderBy } : {}),
          },
        }
      );

      setScheduledResultLength(scheduledResult?.data?.pagination.totalElements);

      const newSets = new Map();
      for (let test of scheduledResult.data.content) {
        let testArray = newSets.get(test.code);
        if (!testArray) {
          testArray = [];
          newSets.set(test.code, testArray);
        }
        testArray.push(test);
      }

      setSets(newSets);
      if (newSets.size === 0) {
        history.push("/tests/set/add");
      }
      setFetchingSets(false);
    } catch (e) {
      console.error("sets error", e);
      setFetchingSets(false);
      setSets(new Map());
    }
  };

  useEffect(() => {
    // if (sets === undefined) {
    loadSets();
    // }
  }, [page, rowsPerPage]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return sets?.size > 0 ? (
    <Container className={classes.container}>
      <h1 aria-hidden="false" aria-label="Testy" style={{ display: "none" }}>
        Testy
      </h1>
      <Box className={classes.root}>
        <AppBarTitle value="Wykonaj test lub sprawdź wynik" />
        <Grid
          item
          xs={12}
          direction={"row"}
          className={classes.headerGrid}
          elevation={15}
        >
          <Box mb={2}>
            <Tooltip title="Każde zamówienie odpowiada nowemu zestawowi składającemu się z testu lub testów. Możesz aktywować zestaw wpisując 12-cyfrowy kod z koperty zewnętrznej, która została do Ciebie dostarczona.">
              <Button
                variant="contained"
                // color="primary"
                // component={RouterLink}
                // to={"/tests/set/add"}
                // className={classes.button}
                color="primary"
                // style={{ color: "white" }}
              >
                <NavLink
                  to="/tests/set/add"
                  style={{ color: theme.palette.background.default }}
                >
                  Aktywuj nowy zestaw
                </NavLink>
              </Button>
            </Tooltip>
          </Box>
        </Grid>

        <AllSets sets={sets} classes={classes} />
        <Grid item xs={12} className={classes.bottomGrid}>
          <TablePagination
            component="div"
            page={page}
            rowsPerPageOptions={pages}
            rowsPerPage={rowsPerPage}
            count={scheduledResultLength}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelRowsPerPage={"Testów na stronę:"}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} z ${count}`
            }
            className={classes.select}
          />
        </Grid>
      </Box>
    </Container>
  ) : fetchingSets ? (
    <Box p={3}>
      <Loader loading={true} text="Wczytywanie zestawów" />
    </Box>
  ) : (
    <Grid container className={classes.mainGrid}>
      <AppBarTitle value="Wykonaj test lub sprawdź wynik" />
      <Grid item container xs={12} spacing={3} className={classes.contentGrid}>
        <Grid item xs={12} className={classes.itemGrid}>
          <p className={classes.label}>
            Nie masz jeszcze żadnego aktywnego zestawu.
          </p>
        </Grid>
        <Grid item xs={12} className={classes.itemGrid}>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
          >
            <NavLink to="/tests/set/add" style={{ color: "white" }}>
              AKTYWUJ ZESTAW
            </NavLink>
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

const ThemedSets = () => (
  <Themed>
    <SetsMain />
  </Themed>
);
const RedirectToLogin = () => <Redirect to="/login" />;
const SetsWithPermission = () => (
  <Can permission="sat-form:page" ok={ThemedSets} not={RedirectToLogin} />
);

export default SetsWithPermission;
