import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import { CircularProgress, Button } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import plLocale from "date-fns/locale/pl";
import * as moment from "moment";
import useMediaQuery from "@material-ui/core/useMediaQuery";

function getModalStyle() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  modalStyle1: {
    position: "absolute",
    top: "10%",
    left: "10%",
    overflow: "scroll",
    height: "100%",
    display: "block",
  },
  paper: {
    width: "80vh",
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.alternative,
  },
  grid: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "@media (min-width: 600px)": {
      padding: "20px",
    },
    // "@media (max-width: 600px)": {
    //   height: "100vh",
    // },
    // height: "70vh",
  },
  select: {
    "&:after": {
      borderBottomColor: theme.palette.text.item,
    },
    "& .MuiSvgIcon-root": {
      color: theme.palette.text.item,
    },
  },
}));

const genderTypes = [
  {
    type: "Female",
    description: "Kobieta",
  },
  {
    type: "Male",
    description: "Mężczyzna",
  },
];

const ProtegesModal = ({
  openProtegesModal,
  protegeHandlerType,
  handleClose,
  protegeToEdit,
  addProtege,
  editProtege,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [modalStyle] = useState(getModalStyle);

  const [busy, setBusy] = useState(false);

  const [firstName, setFirstName] = useState(protegeToEdit?.firstName || "");
  const [surname, setSurname] = useState(protegeToEdit?.surname || "");
  const [yearOfBirth, setYearOfBirth] = useState(
    protegeToEdit?.yearOfBirth.toString() || ""
  );
  const [gender, setGender] = useState(protegeToEdit?.gender || "");

  const [isValid, setIsValid] = useState(false);
  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  useEffect(
    () =>
      setIsValid(
        firstName &&
          surname &&
          yearOfBirth &&
          yearOfBirth?.length === 4 &&
          gender
      ),
    [firstName, surname, yearOfBirth, gender]
  );

  const title = (
    <Box mt={1} style={{ display: "flex" }}>
      <Typography
        variant="h6"
        gutterBottom
        style={{
          color: theme.palette.text.alternative2,
          letterSpacing: "0.72px",
          fontSize: "24px",
        }}
      >
        {protegeHandlerType === "add"
          ? "Dodaj profil dziecka"
          : `Edytuj profil dziecka: ${protegeToEdit.firstName} ${protegeToEdit.surname}`}
      </Typography>
    </Box>
  );
  const body = (
    <Box>
      <Box mt={2}>
        <Box mt={1}>
          <TextField
            label="Imię"
            variant="outlined"
            required
            inputProps={{ maxLength: 255 }}
            value={firstName}
            fullWidth
            onChange={({ target: { value: firstName } }) => {
              setFirstName(firstName);
            }}
            inputProps={{ "aria-label": "Imię" }}
          />
        </Box>
        <Box mt={1}>
          <TextField
            label="Nazwisko"
            variant="outlined"
            required
            inputProps={{ maxLength: 255 }}
            value={surname}
            fullWidth
            onChange={({ target: { value: lastName } }) => {
              setSurname(lastName);
            }}
            inputProps={{ "aria-label": "Nazwisko" }}
          />
        </Box>
        <Box mt={1}>
          <MuiPickersUtilsProvider locale={plLocale} utils={DateFnsUtils}>
            <KeyboardDatePicker
              id="start-date-picker-dialog"
              label="Rok urodzenia"
              inputVariant="outlined"
              required
              fullWidth
              maxDate={new Date()}
              helperText={""}
              value={yearOfBirth ? yearOfBirth : null}
              onChange={(value) => {
                let insertedYear = moment(value, "DD/MM/YYYY").year();
                if (insertedYear < 1900) {
                  insertedYear = 1900;
                } else if (new Date().getFullYear() < insertedYear) {
                  insertedYear = new Date().getFullYear();
                }
                setYearOfBirth(insertedYear.toString());
              }}
              views={["year"]}
              okLabel="Zatwierdź"
              clearLabel="Wyczyść"
              cancelLabel="Anuluj"
              KeyboardButtonProps={{
                "aria-label": "Zmień datę urodzenia",
              }}
              inputProps={{ "aria-label": "Rok urodzenia" }}
              disable
            />
          </MuiPickersUtilsProvider>
        </Box>
        <Box mt={1}>
          <FormControl fullWidth variant="outlined">
            <InputLabel id="select-outlined-label" required>
              Płeć
            </InputLabel>
            <Select
              className={classes.select}
              labelId="select-outlined-label"
              id="select-outlined"
              value={gender}
              fullWidth
              onChange={(event) => setGender(event.target.value)}
              label="Płeć *"
            >
              {genderTypes.map((genderType, index) => {
                return (
                  <MenuItem key={index} value={genderType.type}>
                    {genderType.description}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>
      </Box>
    </Box>
  );

  const foot = (
    <Grid>
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Button
          variant={"contained"}
          onClick={() => handleClose()}
          style={{
            color: theme.palette.background.default,
            height: !smallScreen && "30px",
            width: !smallScreen && "224px",
          }}
        >
          Anuluj
        </Button>
        &nbsp;&nbsp;&nbsp;{" "}
        <Button
          style={{
            height: !smallScreen && "30px",
            width: !smallScreen && "224px",
          }}
          variant={"contained"}
          color="primary"
          onClick={() => {
            setBusy(true);
            if (protegeHandlerType === "add") {
              addProtege({
                firstName,
                surname,
                yearOfBirth: parseInt(yearOfBirth),
                gender,
              });
            } else {
              editProtege(protegeToEdit.id, {
                firstName,
                surname,
                yearOfBirth: parseInt(yearOfBirth),
                gender,
              });
            }
            setBusy(false);
            handleClose();
          }}
          disabled={!isValid}
        >
          {busy && <CircularProgress size={14} />}
          Zapisz
        </Button>
      </Box>
    </Grid>
  );

  return (
    <Modal open={openProtegesModal} className={classes.modalStyle1}>
      <Grid container>
        <Grid item xs={12} className={classes.grid}>
          <Paper className={classes.paper}>
            {" "}
            {title}
            {body}
            <hr
              style={{
                margin: "24px 0px",
                backgroundColor: theme.palette.hr.primary,
              }}
            />
            {foot}
          </Paper>
        </Grid>
      </Grid>
      {/* 
       
      </Box> */}
    </Modal>
  );
};
export default ProtegesModal;
