import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Alert from "@material-ui/lab/Alert";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { NavLink } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { alertAdd, sendContact } from "../../redux/actions";
import { useDispatch } from "react-redux";
import TestCardComponent from "../../pages/test-page/new-test-kit/price-component";
import { ProductType } from "../../pages/test-page/new-test-kit/const";
import makeStyles from "@material-ui/core/styles/makeStyles";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  fetchPriceList,
  fetchCartItems,
  removeCartItem,
} from "../../redux/shopping-cart/actions";
import { addItemToCart, editItemCart } from "../../redux/actions";
import { useSelector } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const useStyles = makeStyles((theme) => ({
  gridCard: {
    margin: "auto",
    display: "flex",
    flexDirection: "row",
    "@media (max-width: 1000px)": {
      flexDirection: "column",
      flexWrap: "nowrap",
    },
    marginTop: "20px",
  },
  spanText: {
    color: theme.palette.text.primary,
  },
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

const NO_ID_TO_REMOVE = -1;

const AgreeButton = withStyles((theme) => ({
  root: {
    color: theme.palette.background.default,
    background: theme.palette.text.alternative,
    variant: "outlined",
    paddingRight: "40px",
    paddingLeft: "40px",
    "&:hover": {
      color: theme.palette.text.primary2,
    },
  },
}))(Button);

const DisagreeButton = withStyles((theme) => ({
  root: {
    color: theme.palette.background.default,
    background: theme.palette.text.primary,
    variant: "outlined",
    paddingRight: "40px",
    paddingLeft: "40px",
    "&:hover": {
      color: theme.palette.text.primary2,
    },
  },
}))(Button);

const OrderPage = ({ cartItems }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();

  const [dataChanged, setDataChanged] = useState(false);
  const cartItem = useSelector((s) => (s.cartItems && s.cartItems[0]) || null);

  const [smellTestAmount, setSmellTestAmount] = useState(0);
  const [tasteTestAmount, setTasteTestAmount] = useState(0);
  const [tasteAndSmellTestAmount, setTasteAndSmellTestAmount] = useState(0);

  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const [alert, setAlert] = useState(false);

  const [idToRemove, setIdToRemove] = useState(NO_ID_TO_REMOVE);
  const [openCartItemRemovingPanel, setOpenCartItemRemovingPanel] = useState(
    false
  );

  useEffect(() => {
    dispatch(fetchPriceList());
  }, []);

  useEffect(() => {
    setTasteTestAmount(
      cartItem?.elements?.find((e) => e.testType === "TASTE_TEST")
        ? cartItem?.elements?.find((e) => e.testType === "TASTE_TEST").amount
        : 0
    );
    setSmellTestAmount(
      cartItem?.elements?.find((e) => e.testType === "SMELL_TEST")
        ? cartItem?.elements?.find((e) => e.testType === "SMELL_TEST").amount
        : 0
    );
    setTasteAndSmellTestAmount(
      cartItem?.elements?.find((e) => e.testType === "SMELLTASTE_TEST")
        ? cartItem?.elements?.find((e) => e.testType === "SMELLTASTE_TEST")
            .amount
        : 0
    );
  }, [cartItem]);

  useEffect(() => {
    if (alert) {
      setTimeout(() => setAlert(false), 3000);
    }
  }, [alert]);

  const cartTestItems = [
    {
      title: "Test smaku",
      iconRender: () => <></>,
      url: "/test-kit",
      text:
        "Test do samodzielnego wykonania, oceniający stan zmysłu smaku (1 szt.)",
      productType: ProductType.TASTE_TEST,
      value: tasteTestAmount,
      setValue: (value) => setTasteTestAmount(value),
    },
    {
      title: "Test węchu",
      iconRender: () => <></>,
      url: "/tests/set/add",
      text:
        "Test do samodzielnego wykonania, oceniający stan zmysłu węchu (1 szt.)",
      productType: ProductType.SMELL_TEST,
      value: smellTestAmount,
      setValue: (value) => setSmellTestAmount(value),
    },
    {
      title: "Zestaw testów smaku i węchu",
      iconRender: () => <></>,
      url: "/tests",
      text:
        "Zestaw składający się z 1 szt. testu smaku i 1 szt. testu węchu, oceniający stan tych zmysłów. Testy do samodzielnego wykonania.",
      productType: ProductType.SMELLTASTE_TEST,
      value: tasteAndSmellTestAmount,
      setValue: (value) => setTasteAndSmellTestAmount(value),
    },
  ];

  const handleClose = () => {
    setOpenCartItemRemovingPanel(false);
  };

  const askToRemove = (cartItemId) => {
    setIdToRemove(cartItemId);
  };

  const reset = (e) => {
    e.preventDefault();
    if (cartItem) {
      askToRemove(cartItem.id);
      setOpenCartItemRemovingPanel(true);
    } else {
      setTasteTestAmount(0);
      setSmellTestAmount(0);
      setTasteAndSmellTestAmount(0);
    }
  };

  const submit = async (e) => {
    e.preventDefault();

    const tasteAmount = parseInt(tasteTestAmount, 10);
    const smellAmount = parseInt(smellTestAmount, 10);
    const tasteAndSmellAmount = parseInt(tasteAndSmellTestAmount, 10);
    const payload = {
      elements: [
        ...(tasteAmount > 0
          ? [
              {
                testType: "TASTE_TEST",
                amount: tasteAmount,
              },
            ]
          : []),
        ...(smellAmount > 0
          ? [
              {
                testType: "SMELL_TEST",
                amount: smellAmount,
              },
            ]
          : []),
        ...(tasteAndSmellAmount > 0
          ? [
              {
                testType: "SMELLTASTE_TEST",
                amount: tasteAndSmellAmount,
              },
            ]
          : []),
        {
          testType: "DELIVERY",
          amount: 1,
        },
      ],
    };

    let data = false;
    if (!cartItem) {
      data = await dispatch(addItemToCart(payload));
    } else {
      data = await dispatch(editItemCart(cartItem.id, payload));
    }

    if (data) {
      history.push({
        pathname: "/login",
        state: {
          orderRedirection: true,
        },
      });
    } else {
      setAlert(true);
    }
  };

  return (
    <Box>
      <Grid
        item
        container
        spacing={5}
        xs={12}
        sm={8}
        xl={8}
        className={classes.gridCard}
      >
        <h1
          aria-hidden="false"
          aria-label="Składanie zamówienia"
          style={{ display: "none" }}
        >
          Składanie zamówienia
        </h1>
        {alert && (
          <Grid item xs={12}>
            <Alert severity="error">Nie udało się dodać do koszyka</Alert>
          </Grid>
        )}
        {cartTestItems.map((item, index) => (
          <Grid
            item
            xs={12}
            md={8}
            lg={4}
            key={index}
            style={{ display: "flex" }}
          >
            <TestCardComponent
              title={item.title}
              text={item.text}
              url={item.url}
              iconRender={() => item.iconRender()}
              setValue={(p) => item.setValue(p)}
              value={item.value}
              productType={item.productType}
              setDataChanged={setDataChanged}
            />
          </Grid>
        ))}
        {/* <Grid
          container
          item
          xs={4}
          spacing={2}
          direction={smallScreen ? "column" : "row"}
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Grid item style={{ display: "flex", alignItems: "center" }}>
            <Button
              size="small"
              variant="contained"
              // color="primary"
              // style={{ height: 40, width: 150, borderRadius: 8 }}
              style={{ height: 40, borderRadius: 8 }}
              onClick={reset}
            >
              Wyczyść koszyk
            </Button>
          </Grid>
          <Grid item style={{ display: "flex", alignItems: "center" }}>
            <Button
              type="submit"
              size="small"
              variant="contained"
              color="primary"
              style={{ height: 40, width: 150, borderRadius: 8 }}
              disabled={
                smellTestAmount === 0 &&
                tasteTestAmount === 0 &&
                tasteAndSmellTestAmount === 0
              }
              onClick={submit}
            >
              Dalej
            </Button>
          </Grid>
        </Grid> */}
        <Grid item xs={12}>
          <div
            className={classes.root}
            direction={smallScreen ? "column" : "row"}
            style={{
              display: "flex",
              justifyContent: !smallScreen && "flex-end",
            }}
          >
            <Grid item style={{ display: "flex", alignItems: "center" }}>
              <Button
                size="small"
                variant="contained"
                // color="primary"
                // style={{ height: 40, width: 150, borderRadius: 8 }}
                style={{ height: 50, borderRadius: 8 }}
                onClick={reset}
              >
                Wyczyść koszyk
              </Button>
            </Grid>
            <Grid item style={{ display: "flex", alignItems: "center" }}>
              <Button
                type="submit"
                size="small"
                variant="contained"
                color="primary"
                style={{ height: 40, width: 150, borderRadius: 8 }}
                disabled={
                  smellTestAmount === 0 &&
                  tasteTestAmount === 0 &&
                  tasteAndSmellTestAmount === 0
                }
                onClick={submit}
              >
                Dalej
              </Button>
            </Grid>
          </div>
        </Grid>
      </Grid>
      {idToRemove !== NO_ID_TO_REMOVE && (
        <div>
          <Dialog
            open={openCartItemRemovingPanel}
            onClose={handleClose}
            maxWidth={"md"}
            fullWidth={true}
          >
            <DialogTitle>{`Usuń zamówienie`}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                <span
                  className={classes.spanText}
                >{`Czy na pewno usunąć całe zamówienie?`}</span>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <DisagreeButton
                onClick={() => {
                  setIdToRemove(NO_ID_TO_REMOVE);
                  handleClose();
                }}
              >
                Nie usuwaj
              </DisagreeButton>
              <AgreeButton
                onClick={async () => {
                  setTasteTestAmount(0);
                  setSmellTestAmount(0);
                  setTasteAndSmellTestAmount(0);
                  await dispatch(removeCartItem(idToRemove));
                  setIdToRemove(NO_ID_TO_REMOVE);
                }}
                autoFocus
              >
                Tak, usuń
              </AgreeButton>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </Box>
  );
};

const mapStateToProps = (state) => ({
  cartItems: state.cartItems,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(OrderPage);
