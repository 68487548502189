import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { NavLink, Redirect, useHistory } from "react-router-dom";
import Can from "../../components/can";
import Loader from "../../components/loader";
import { alertAdd, login } from "../../redux/actions";
import Cookies from "js-cookie";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import { LockOpen, Visibility } from "@material-ui/icons";
import { Box } from "@material-ui/core";
import styled from "styled-components";
import s from "./index.module.css";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Grid, Paper, TextField, Button } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";

const RegisterButton = styled(Button)`
  color: white;
  background-color: orange;
`;

const MuiNavLink = withStyles((theme) => ({
  root: {
    color: theme.palette.anchor.primary,
    underline: "always",
    "&:hover": {
      color: theme.palette.text.primary,
    },
  },
}))(Link);

const useStyles = makeStyles((theme) => ({
  input: {
    background: "white",
    "&:hover": {
      background: "white",
    },
    "&.Mui-focused": {
      background: "white",
    },
  },
  formHint: {
    fontSize: theme.typography.hintText,
    lineHeight: "1.4",
    margin: "-5px auto 5px",
    color: theme.palette.text.primary,
  },
  paperStyle: {
    padding: "12px",
    // height: "90vh",
    width: "100%",
    background: theme.palette.background.tab,
    border: `1px solid ${theme.palette.background.border}`,
    borderBottomLeftRadius: "1rem",
    borderBottomRightRadius: "1rem",
    borderTopRightRadius: "0px",
    borderTopLeftRadius: "0px",
  },
}));

const SignIn = ({
  login,
  alertAdd,
  loggingIn,
  loggedIn,
  errorMessage = "",
}) => {
  const cartItems = useSelector((s) => s.cartItems);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [valid, setValid] = useState(false);

  const [invalidEmailFormat, setInvalidEmailFormat] = useState(false);

  const rememberMeLogin = Cookies.get("rememberMeLogin");

  const classes = useStyles();
  const history = useHistory();

  useState(() => {
    if (loggedIn) {
      if (cartItems?.length > 0) {
        history.push({
          pathname: "/test-kit",
          state: {
            detail: cartItems[0],
            cartItemEdition: true,
          },
        });
      } else {
        history.push("/");
      }
    } else {
      setUsername(rememberMeLogin);
    }
  }, [loggedIn]);

  useEffect(() => {
    if (!invalidEmailFormat && password.trim()) {
      setValid(true);
    } else {
      setValid(false);
    }
  }, [username, password]);

  const handleEmailFormat = () => {
    const pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    if (!pattern.test(username)) {
      setInvalidEmailFormat(true);
    } else {
      setInvalidEmailFormat(false);
    }
  };

  const doLogin = async (e) => {
    e.preventDefault();

    const loggedIn = await login({
      username,
      password,
    });

    if (loggedIn) {
      if (cartItems?.length > 0) {
        history.push({
          pathname: "/test-kit",
          state: {
            detail: cartItems[0],
            cartItemEdition: true,
          },
        });
      } else {
        history.push("/");
      }
    } else if (errorMessage === "not activated") {
      alertAdd({
        text: "Użytkownik nie został aktywowany",
      });
    } else if (errorMessage.includes("User is blocked")) {
      alertAdd({
        text: "Konto zostało zablokowane na 10 minut",
        isError: true,
      });
    } else {
      alertAdd({
        text: "Niepoprawny adres email lub hasło",
        isWarning: true,
      });
    }
  };

  const cancel = (e) => {
    e.preventDefault();

    history.push("/");
  };

  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const avatarStyle = { backgroundColor: "#1bbd7e" };
  const btnstyle = { margin: "8px 0" };

  return (
    <Paper className={classes.paperStyle}>
      <Grid
        container
        spacing={2}
        style={{ display: "flex", justifyContent: "center" }}
      >
        {/*<Grid item xs={12}>
           <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <LockOpen />
            <span style={{ fontSize: "20px" }}> Zaloguj się</span>
          </Box>
        </Grid> */}

        <Grid item xs={10}>
          <Box>
            <form onSubmit={doLogin} noValidate autoComplete="off">
              <Box>
                <TextField
                  label="Email"
                  variant="filled"
                  required
                  value={username || ""}
                  fullWidth
                  error={invalidEmailFormat}
                  helperText={
                    invalidEmailFormat && "Nieprawidłowy format adresu email"
                  }
                  onChange={(e) =>
                    setUsername(e.target.value.split(" ").join(""))
                  }
                  onFocus={(e) => setInvalidEmailFormat(false)}
                  onBlur={handleEmailFormat}
                  // InputProps={{ className: classes.input }}
                  inputProps={{ "aria-label": "Email" }}
                />
              </Box>
              <Box mt={1}>
                <TextField
                  label="Hasło"
                  variant="filled"
                  required
                  type={showPassword ? "text" : "password"}
                  value={password || ""}
                  onChange={(e) =>
                    setPassword(e.target.value.split(" ").join(""))
                  }
                  fullWidth
                  inputProps={{ "aria-label": "hasło" }}
                  InputProps={{
                    // className: classes.input,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowPassword(!showPassword)}
                          aria-label="toggle password visibility"
                        >
                          <Visibility />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Box mt={1}></Box>
              <Grid container>
                <Grid item xs={12}>
                  <span style={{ float: "left" }} className={classes.formHint}>
                    * Pola wymagane
                  </span>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <MuiNavLink href="/password-reset">
                    Zapomniałem hasła
                  </MuiNavLink>
                </Grid>
              </Grid>
              <Box textAlign="right" fontSize={12} m={1}></Box>
              <Grid container spacing={0} alignItems="center" justify="center">
                <Grid item xs={6}>
                  <Button
                    type="submit"
                    variant={"contained"}
                    color="primary"
                    fullWidth
                    disabled={!valid}
                  >
                    Zaloguj się
                  </Button>
                </Grid>
              </Grid>
              <Loader loading={loggingIn} text="Logowanie..." />
            </form>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

const mapStateToProps = (state) => ({
  loggingIn: state.loggingIn,
  loggedIn: state.loggedIn,
  errorMessage: state.errorMessage,
});

const mapDispatchToProps = (dispatch) => ({
  login: (data) => dispatch(login(data)),
  alertAdd: (payload) => dispatch(alertAdd(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
