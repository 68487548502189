import axios from "axios";

let axiosInstance;

export const refreshAxios = () => {
  axiosInstance = axios.create({
    headers: {
      "x-tenantid-1": "main",
    },
  });
};

export const getAxiosInstance = () => {
  return axiosInstance;
};
