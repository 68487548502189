import {
  DONE,
  FAILED,
  START,
  REMOVE_TERM_DONE,
  REMOVE_TERM_FAILED,
  REMOVE_TERM_START,
} from "../../constants";
import { getAxiosInstance } from "../common";

export const UPDATE_MY_ORGANIZATION = "UPDATE_MY_ORGANIZATION";
export const UPLOAD_TERM_ORGANIZATION = "UPLOAD_TERM_ORGANIZATION";
export const FETCH_TERMS_ORGANIZATION = "FETCH_TERMS_ORGANIZATION";

export const updateMyOrganization = (payload) => async (dispatch) => {
  dispatch({ type: UPDATE_MY_ORGANIZATION + START });
  try {
    const result = await getAxiosInstance().put(`/api/organizations`, payload);
    dispatch({ type: UPDATE_MY_ORGANIZATION + DONE, payload: result.data });

    return result.data;
  } catch (payload) {
    dispatch({ type: UPDATE_MY_ORGANIZATION + FAILED, payload });
    return false;
  }
};

export const uploadOrganizationTerm = (payload) => async (dispatch) => {
  dispatch({ type: UPLOAD_TERM_ORGANIZATION + START });
  try {
    const { termFile, ...properties } = payload;

    const termData = new FormData();
    termData.append("file", termFile);
    termData.append(
      "properties",
      new Blob([JSON.stringify(properties)], {
        type: "application/json",
      })
    );

    const result = await getAxiosInstance().post(
      `/api/organizations/terms`,
      termData
    );
    dispatch({ type: UPLOAD_TERM_ORGANIZATION + DONE, payload: result.data });

    return result.data;
  } catch (payload) {
    dispatch({ type: UPLOAD_TERM_ORGANIZATION + FAILED, payload });
    return false;
  }
};

export const fetchOrganizationTerms = (termId) => async (dispatch) => {
  dispatch({ type: FETCH_TERMS_ORGANIZATION + START });
  try {
    const result = await getAxiosInstance().get(
      `/api/organizations/${termId}/terms`
    );
    dispatch({ type: FETCH_TERMS_ORGANIZATION + DONE, payload: result.data });

    return result;
  } catch (payload) {
    dispatch({ type: FETCH_TERMS_ORGANIZATION + FAILED, payload });
    return false;
  }
};

export const removeTerm = (termId) => async (dispatch) => {
  dispatch(removeTermStart());
  try {
    const result = await getAxiosInstance().delete(
      `api/organizations/terms/${termId}`
    );

    dispatch(removeTermDone(result));
    return true;
  } catch (e) {
    dispatch(removeTermFailed(e));
    return false;
  }
};

export const removeTermStart = (payload) => ({
  type: REMOVE_TERM_START,
  payload,
});
export const removeTermDone = (payload) => ({
  type: REMOVE_TERM_DONE,
  payload: payload.data,
});
export const removeTermFailed = (payload) => ({
  type: REMOVE_TERM_FAILED,
  payload,
});
