import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import ZipInput from "../ZipInput";
import s from "./index.module.css";
import MuiPhoneNumber from "material-ui-phone-number";
import { useSelector } from "react-redux";

export const normalizeZIP = (value, previousValue) => {
  if (!value) return value;
  const currentValue = value.replace(/[^\d]/g, "");
  const cvLength = currentValue.length;

  if (!previousValue || value.length > previousValue.length) {
    if (cvLength < 3) return currentValue;
    return `${currentValue.slice(0, 2)}-${currentValue.slice(2, 5)}`;
  }
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
  formHint: {
    fontSize: theme.typography.hintText,
    lineHeight: "1.4",
    margin: "-5px auto 5px",
    color: theme.palette.text.primary,
  },

  cssLabelGreen: {
    color: "green",
  },

  cssLabelRed: {
    color: "red",
  },

  cssOutlinedInputGreen: {
    "&$cssFocused $notchedOutlineGreen": {
      borderColor: `${theme.palette.primary.main} !important`,
    },
  },

  cssOutlinedInputRed: {
    "&$cssFocused $notchedOutlineRed": {
      borderColor: `${theme.palette.primary.main} !important`,
    },
  },

  cssFocused: {},

  notchedOutlineGreen: {
    borderWidth: "1px",
    borderColor: "green !important",
  },
  notchedOutlineRed: {
    borderWidth: "1px",
    borderColor: "red !important",
  },
}));

const ShippingAddressForm = ({
  provideShippingData,
  cartItemEdition,
  name,
  setName,
  street,
  setStreet,
  city,
  setCity,
  zip,
  setZip,
  post,
  setPost,
  phone,
  setPhone,
  notes,
  setNotes,
  showNotes = true,
  deliveryInfo,
  setDeliveryInfo,
  isValidListener,
  setDataChanged,
}) => {
  const classes = useStyles();

  const globalTheme = useSelector((s) => s.globalTheme || "light");

  const [valid, setValid] = useState(false);
  const [phoneLostFocus, setPhoneLostFocus] = useState(false);
  const [zipValid, setZipValid] = useState(false);
  useEffect(() => {
    const isOK = zipValid;
    setValid(isOK);
    isValidListener && isValidListener(isOK);
  }, [zipValid]);

  const handleZIPCodeChange = (newZip) => {
    setZip(normalizeZIP(newZip, zip));
  };

  return (
    <Grid item container xs={12} spacing={1}>
      <Grid item xs={12}>
        <TextField
          label="Imię i nazwisko"
          variant="outlined"
          fullWidth
          value={name || ""}
          disabled={!provideShippingData}
          inputProps={{ maxLength: 255 }}
          required={true}
          InputLabelProps={
            globalTheme === "light" &&
            provideShippingData && {
              classes: {
                root: name !== "" ? classes.cssLabelGreen : classes.cssLabelRed,
                focused: classes.cssFocused,
              },
            }
          }
          InputProps={
            globalTheme === "light" &&
            provideShippingData && {
              classes: {
                root:
                  name !== ""
                    ? classes.cssOutlinedInputGreen
                    : classes.cssOutlinedInputRed,
                focused: classes.cssFocused,
                notchedOutline:
                  name !== ""
                    ? classes.notchedOutlineGreen
                    : classes.notchedOutlineRed,
              },
              inputMode: "numeric",
            }
          }
          inputProps={{ "aria-label": "Imię i nazwisko" }}
          onChange={({ target: { value: name } }) => {
            setDataChanged(true);
            setName(name);
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Ulica"
          variant="outlined"
          helperText={"Przykład: Jasna 7A / 4"}
          value={street || ""}
          disabled={!provideShippingData}
          required={true}
          fullWidth
          inputProps={{ "aria-label": "Ulica" }}
          InputLabelProps={
            globalTheme === "light" &&
            provideShippingData && {
              classes: {
                root:
                  street !== "" ? classes.cssLabelGreen : classes.cssLabelRed,
                focused: classes.cssFocused,
              },
            }
          }
          InputProps={
            globalTheme === "light" &&
            provideShippingData && {
              classes: {
                root:
                  street !== ""
                    ? classes.cssOutlinedInputGreen
                    : classes.cssOutlinedInputRed,
                focused: classes.cssFocused,
                notchedOutline:
                  street !== ""
                    ? classes.notchedOutlineGreen
                    : classes.notchedOutlineRed,
              },
              inputMode: "numeric",
            }
          }
          onChange={({ target: { value: street } }) => {
            setDataChanged(true);
            setStreet(street);
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          inputProps={{ "aria-label": "Miasto" }}
          label="Miasto"
          variant="outlined"
          value={city || ""}
          disabled={!provideShippingData}
          required={true}
          fullWidth
          InputLabelProps={
            globalTheme === "light" &&
            provideShippingData && {
              classes: {
                root: city !== "" ? classes.cssLabelGreen : classes.cssLabelRed,
                focused: classes.cssFocused,
              },
            }
          }
          InputProps={
            globalTheme === "light" &&
            provideShippingData && {
              classes: {
                root:
                  city !== ""
                    ? classes.cssOutlinedInputGreen
                    : classes.cssOutlinedInputRed,
                focused: classes.cssFocused,
                notchedOutline:
                  city !== ""
                    ? classes.notchedOutlineGreen
                    : classes.notchedOutlineRed,
              },
              inputMode: "numeric",
            }
          }
          fullWidth
          onChange={({ target: { value: city } }) => {
            setDataChanged(true);
            setCity(city);
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <ZipInput
          globalTheme={globalTheme}
          zipCode={zip || ""}
          handleZIPCodeChange={handleZIPCodeChange}
          isValidListener={setZipValid}
          disabled={!provideShippingData}
          setDataChanged={setDataChanged}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Poczta"
          variant="outlined"
          value={post || ""}
          disabled={!provideShippingData}
          fullWidth
          inputProps={{ "aria-label": "Poczta" }}
          onChange={({ target: { value: post } }) => {
            setDataChanged(true);
            setPost(post);
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <MuiPhoneNumber
          name="phone"
          label="Telefon"
          variant="outlined"
          data-cy="user-phone"
          defaultCountry={"pl"}
          regions={"europe"}
          fullWidth
          disabled={!provideShippingData}
          autoFormat={false}
          countryCodeEditable={false}
          required={false}
          error={phoneLostFocus && !(phone.length === 12 || phone === "+48")}
          helperText={
            phoneLostFocus && !(phone.length === 12 || phone === "+48")
              ? "Nieprawidłowy numer telefonu."
              : ""
          }
          inputProps={{ "aria-label": "Telefon" }}
          InputLabelProps={
            provideShippingData && {
              classes: {
                root:
                  phone?.length === 12
                    ? globalTheme === "light" && classes.cssLabelGreen
                    : globalTheme === "light" && classes.cssLabelRed,
                focused: globalTheme === "light" && classes.cssFocused,
              },
            }
          }
          value={phone || ""}
          onChange={(e) => {
            setDataChanged(true);
            setPhoneLostFocus(false);
            setPhone(e);
          }}
          onFocus={() => setPhoneLostFocus(false)}
          onBlur={() => setPhoneLostFocus(true)}
        />
      </Grid>
      {showNotes && (
        <Grid item xs={12}>
          <TextField
            label="Uwagi"
            variant="outlined"
            multiline
            disabled={!provideShippingData}
            rows={3}
            rowsMax={8}
            value={notes || ""}
            fullWidth
            inputProps={{ "aria-label": "Uwagi" }}
            onChange={({ target: { value: notes } }) => {
              setDataChanged(true);
              setNotes(notes);
            }}
            inputProps={{
              maxlength: 199,
            }}
            helperText={
              provideShippingData &&
              `Znaków do wprowadzenia: ${199 - notes.length}`
            }
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <span className={classes.formHint}>
          * Pola będą wymagane w późniejszym etapie przy składaniu zamówienia
        </span>
      </Grid>
    </Grid>
  );
};

export default ShippingAddressForm;
