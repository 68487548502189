import React, {useEffect, useState} from 'react'
import { useDrag } from 'react-dnd'
import { ItemSubtypes, ItemTypes } from '../../../DnDTypes'

const PeselControl = ({
                        name, id, disabled, isSelected = false, dragMode = 'move',
                        configuration = {}, markSelected, onChange, showSelfDescription,
                        rowIndex, index, setIsDragging, filledValue
                      }) => {
  const validator = (pesel) => {
    let weight = [1, 3, 7, 9, 1, 3, 7, 9, 1, 3]
    let sum = 0
    let controlNumber = parseInt(pesel.substring(10, 11))
    for (let i = 0; i < weight.length; i++) {
      sum += (parseInt(pesel.substring(i, i + 1)) * weight[i])
    }
    sum = sum % 10
    return 10 - sum === controlNumber
  }

  const validate = (v) => {
    if (/^\d+$/.test(v) && v.length <= 11) {
      setValue(v)
    }

    if (validator(v) || v.length === 0) {
      setValid(true)
      setValue(v)
    } else {
      setValid(false)
    }
  }

  const [value, setValue] = useState(filledValue || '')
  useEffect(() => {
    setValue(filledValue)
  }, filledValue)
  const [valid, setValid] = useState(true)

  const [, drag] = useDrag({
    item: {
      type: ItemTypes.COMPONENT, subtype: ItemSubtypes.PESEL, dragMode,
      id,
      rowIndex,
      index,
    },
    collect: (monitor, props) => {
      setIsDragging && setIsDragging(!!monitor.isDragging())

      return {
        isDragging: !!monitor.isDragging(),
      }
    },
    canDrag: () => {
      return dragMode === 'move' || dragMode === 'clone'
    },
  })

  const clicked = () => markSelected && markSelected({
    id,
    configuration,
  })

  const placeholder = showSelfDescription ? 'PESEL' : configuration.text

  return (
    <div ref={drag}>
      {configuration && configuration.label && (
        <label>{configuration.label}</label>
      )}
      <input
        maxLength={11}
        type="text"
        disabled={disabled}
        className={['input', valid ? '' : 'has-background-danger'].join(' ')}
        name={name}
        placeholder={placeholder}
        id={id}
        onClick={clicked}
        value={value}
        onChange={e => validate(e.target.value)}
      />
    </div>)
}

export default PeselControl
