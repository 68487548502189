import React from "react";
import image1 from "../assets/fe.png";
import image2 from "../assets/rp.png";
import image3 from "../assets/ncbr.png";
import image4 from "../assets/ue.png";
import image5 from "../assets/magnusson-logo.png";
import image6 from "../assets/envelope.svg";
import image7 from "../assets/ifps.jpg";
import { Box } from "@material-ui/core";
import styled from "styled-components";
import { CardMedia } from "@material-ui/core";
import { Label } from "@material-ui/icons";

const StyledBox = styled(Box)`
  height: 100vh;
`;

const FeatureBox = styled(({ image, number, text, subtext, height }) => (
  <Box display="block" color="#fff" textAlign="center" m={1}>
    <CardMedia>
      <img src={image} style={{ height: height }} />
    </CardMedia>

    <Box fontWeight="bold">
      {number && (
        <Box display="inline" as="span" color="orange">
          {number}.&nbsp;
        </Box>
      )}
      {text}
    </Box>
    <Box fontSize={10}>{subtext}</Box>
  </Box>
))`
  width: 300px;
  height: 300px;
`;

const LeftComponentMui = () => (
  <StyledBox display="flex" flexDirection="row" bgcolor="#001171" width="100%">
    <Box display="block" width="100%">
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-around"
        style={{
          height: "50px",
          backgroundColor: "white",
        }}
      >
        <FeatureBox image={image1} height={42} />
        <FeatureBox image={image2} height={42} />
        <FeatureBox image={image3} height={42} />{" "}
        <FeatureBox image={image4} height={42} />
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-around"
        mt={5}
      >
        <FeatureBox image={image5} />
      </Box>{" "}
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-around"
        mt={5}
      >
        <FeatureBox image={image7} height={75} />
      </Box>{" "}
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-around"
        mt={5}
      >
        <FeatureBox image={image6} height={250} />
      </Box>{" "}
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-around"
        mt={5}
      >
        <label style={{ color: "white", fontWeight: "bold", fontSize: "30px" }}>
          Przesiewowe badanie smaku i węchu
        </label>
      </Box>
    </Box>
  </StyledBox>
);

export default LeftComponentMui;
