import React, { useState, useEffect } from "react";
import { getAxiosInstance } from "../../redux/common";
import Loader from "../../components/loader";
import { Button } from "@material-ui/core";
import OrderDetails from "../orders/order-new";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

function Tenants() {
  const [tenants, setTenants] = useState();
  const [search, setSearch] = useState("");
  const [displayPasswordResetAlert, setDisplayPasswordResetAlert] = useState(
    false
  );
  const [removableTenants, setRemovableTenants] = useState({});
  const history = useHistory();
  const classes = useStyles();

  const resetPassword = async (email) => {
    setDisplayPasswordResetAlert(true);
    setTimeout(() => setDisplayPasswordResetAlert(false), 3000);

    await getAxiosInstance().post("/employees/v1/password/reset/ask", {
      email,
      lang: "pl",
    });
  };

  const removeAccount = async (email) => {
    await getAxiosInstance().put(`/api/owner/persons/${email}/anonimize`);
  };

  async function loadTenants() {
    try {
      const tenantsResult = await getAxiosInstance().get(
        "/tenants/v1/admin/tenants/all"
      );

      const removableTenants = (
        await getAxiosInstance().post(
          "/api/owner/persons/to-be-removed",
          tenantsResult.data.map(({ tenantId }) => tenantId)
        )
      ).data.reduce((all, current) => {
        return {
          ...all,
          [current]: true,
        };
      }, {});

      setRemovableTenants(removableTenants);

      setTenants(tenantsResult.data);
    } catch (e) {
      if (e.response.status == 401 || e.response.status == 403) {
        history.push("/owner-panel/login");
      }
      setTenants(null);
    }
  }

  function handleSearchChange(event) {
    setSearch(event.target.value);
  }

  useEffect(() => {
    if (tenants === undefined) {
      loadTenants();
    }
  });

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setDisplayPasswordResetAlert(false);
  };

  if (tenants) {
    return (
      <TableContainer component={Paper}>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={displayPasswordResetAlert}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="info">
            Hasło zostało zresetowane
          </Alert>
        </Snackbar>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Imię i nazwisko</TableCell>
              <TableCell align="right">email</TableCell>
              <TableCell align="right">
                <TextField
                  fullWidth
                  id="standard-basic"
                  label="Szukaj"
                  value={search}
                  onChange={handleSearchChange}
                />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tenants.map((tenant) => {
              const searchLowerCased = search ? search.toLowerCase() : null;
              if (
                !searchLowerCased ||
                tenant.name.toLowerCase().includes(searchLowerCased) ||
                tenant.email.toLowerCase().includes(searchLowerCased)
              ) {
                return (
                  <TableRow key={tenant.tenantId}>
                    <TableCell component="th" scope="row">
                      {tenant.name}
                    </TableCell>
                    <TableCell align="right">{tenant.email}</TableCell>
                    <TableCell align="right">
                      <Button
                        color="primary"
                        component={RouterLink}
                        to={`/owner-panel/orders/new/${tenant.tenantId}/${tenant.email}`}
                      >
                        Dodaj nowe zamówienie
                      </Button>

                      <Button
                        color="primary"
                        onClick={() => resetPassword(tenant.email)}
                      >
                        Resetuj hasło
                      </Button>

                      {removableTenants[tenant.tenantId] === true && (
                        <Button
                          color="primary"
                          onClick={() => removeAccount(tenant.email)}
                        >
                          Usuń konto
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                );
              } else {
                return null;
              }
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  } else {
    return <Loader loading={true} text="Wczytywanie danych" />;
  }
}

export default Tenants;
