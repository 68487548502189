import React, { useEffect, useState } from "react";
import Can from "../../../components/can";
import { Redirect, useLocation } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { Box } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import PriceComponent from "../new-test-kit/price-component";
import { ProductType } from "../new-test-kit/const";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import { editItemCart } from "../../../redux/actions";
import Button from "@material-ui/core/Button";
import { fetchPriceList } from "../../../redux/shopping-cart/actions";
import makeStyles from "@material-ui/core/styles/makeStyles";
import s from "./index.module.css";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },

  cssLabelGreen: {
    color: "green",
  },

  cssLabelRed: {
    color: "red",
  },

  cssOutlinedInputGreen: {
    "&$cssFocused $notchedOutlineGreen": {
      borderColor: `${theme.palette.primary.main} !important`,
    },
  },

  cssOutlinedInputRed: {
    "&$cssFocused $notchedOutlineRed": {
      borderColor: `${theme.palette.primary.main} !important`,
    },
  },

  cssFocused: {},

  notchedOutlineGreen: {
    borderWidth: "1px",
    borderColor: "green !important",
  },
  notchedOutlineRed: {
    borderWidth: "1px",
    borderColor: "red !important",
  },
}));

const EditTestKit = ({ history, my }) => {
  const location = useLocation();

  const [smellTestAmount, setSmellTestAmount] = useState(0);
  const [tasteTestAmount, setTasteTestAmount] = useState(0);
  const [tasteAndSmellTestAmount, setTasteAndSmellTestAmount] = useState(0);

  const [provideShippingData, setProvideShippingData] = useState(true);
  const [provideFVData, setProvideFVData] = useState(false);

  const [deliveryInfo, setDeliveryInfo] = useState("");
  const [billingInfo, setBillingInfo] = useState("");

  const [valid, setValid] = useState(false);

  const dispatch = useDispatch();

  const classes = useStyles();

  const prices = useSelector((s) => s.priceList);
  const pricesLength = Object.keys(prices).length;

  useEffect(() => {
    if (pricesLength === 0) {
      dispatch(fetchPriceList());
    }
  }, [pricesLength]);

  useEffect(() => {
    setDeliveryInfo({});
  }, [provideShippingData]);

  useEffect(() => {
    setSmellTestAmount(retriveTestAmount("SMELL_TEST"));
    setTasteTestAmount(retriveTestAmount("TASTE_TEST"));
    setTasteAndSmellTestAmount(retriveTestAmount("SMELLTASTE_TEST"));

    setProvideShippingData(
      (location.state.detail.deliveryInfo && true) || false
    );
    setProvideFVData((location.state.detail.billingInfo && true) || false);

    location.state.detail.deliveryInfo &&
      setDeliveryInfo(location.state.detail.deliveryInfo);

    location.state.detail.billingInfo &&
      setBillingInfo(location.state.detail.billingInfo);
  }, [location]);

  useEffect(() => {
    if (
      tasteTestAmount > 0 ||
      smellTestAmount > 0 ||
      tasteAndSmellTestAmount > 0
    ) {
      setValid(true);
    } else {
      setValid(false);
    }
  }, [tasteTestAmount, smellTestAmount, tasteAndSmellTestAmount]);

  let retriveTestAmount = (testType) => {
    return (
      (location.state.detail.elements.find(
        (test) => test.testType === testType
      ) &&
        location.state.detail.elements.find(
          (test) => test.testType === testType
        ).amount) ||
      0
    );
  };

  const normalizeZIP = (value, previousValue) => {
    if (!value) return value;
    const currentValue = value.replace(/[^\d]/g, "");
    const cvLength = currentValue.length;

    if (!previousValue || value.length > previousValue.length) {
      if (cvLength < 3) return currentValue;
      return `${currentValue.slice(0, 2)}-${currentValue.slice(2, 5)}`;
    }
  };

  const maxNIPLengthCheck = (object) => {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(
        0,
        object.target.maxLength
      );
    }
  };

  const submit = async () => {
    const tasteAmount = parseInt(tasteTestAmount, 10);
    const smellAmount = parseInt(smellTestAmount, 10);
    const tasteAndSmellAmount = parseInt(tasteAndSmellTestAmount, 10);
    const payload = {
      elements: [
        ...(tasteAmount > 0
          ? [
              {
                testType: "TASTE_TEST",
                amount: tasteAmount,
              },
            ]
          : []),
        ...(smellAmount > 0
          ? [
              {
                testType: "SMELL_TEST",
                amount: smellAmount,
              },
            ]
          : []),
        ...(tasteAndSmellAmount > 0
          ? [
              {
                testType: "SMELLTASTE_TEST",
                amount: tasteAndSmellAmount,
              },
            ]
          : []),
      ],
      invoiceNeeded: provideFVData,
      ...(provideShippingData
        ? {
            deliveryInfo,
          }
        : {}),
      ...(provideFVData
        ? {
            billingInfo,
          }
        : {}),
    };

    const data = await dispatch(
      editItemCart(location.state.detail.id, payload)
    );
    history.push("/shopping-cart");
  };

  const grid = {
    xs: 12,
    sm: 10,
    md: 6,
  };

  const filledShippingDataFromProfile = () => {
    if (provideShippingData) {
      setDeliveryInfo((prevState) => ({
        ...prevState,
        ...my,
        name: `${my.firstName} ${my.surname}`,
        zipCode: my.zip,
        post: my.mail,
      }));
    }

    if (provideFVData) {
      setBillingInfo((prevState) => ({
        ...prevState,
        ...my,
        name: `${my.firstName} ${my.surname}`,
        zipCode: my.zip,
        post: my.mail,
      }));
    }
  };

  const page = () => (
    <Grid container>
      <Grid item xs={12}>
        <Box>
          <Typography variant="h5" gutterBottom>
            Edycja zamówienia
          </Typography>
        </Box>

        <Box mt={2}>
          <Typography variant="subtitle2">
            Wybierz ilość poszczególnych zestawów, które chcesz zamówić:
          </Typography>
        </Box>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4} md={4}>
          <PriceComponent
            productType={ProductType.SMELL_TEST}
            setValue={setSmellTestAmount}
            value={smellTestAmount}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <PriceComponent
            productType={ProductType.TASTE_TEST}
            setValue={setTasteTestAmount}
            value={tasteTestAmount}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <PriceComponent
            productType={ProductType.SMELLTASTE_TEST}
            setValue={setTasteAndSmellTestAmount}
            value={tasteAndSmellTestAmount}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Checkbox
            name="provideShippingData"
            color="primary"
            checked={provideShippingData}
            onChange={() => {
              setProvideShippingData(!provideShippingData);
            }}
          />
          <label style={{ marginLeft: "5px", fontWeight: "600" }}>
            Chcę teraz podać dane do wysyłki
          </label>
        </Grid>
        <Grid item xs={6}>
          <Checkbox
            name="FVAddress"
            color="primary"
            checked={provideFVData}
            onChange={() => {
              setProvideFVData(!provideFVData);
            }}
          />
          <label style={{ marginLeft: "5px" }}>Chcę FV</label>
        </Grid>
      </Grid>
      <hr />
      <Box mt={1}>
        <a onClick={() => filledShippingDataFromProfile()}>
          Skopiuj dane z profilu
        </a>
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={6} {...grid}>
          <Typography variant="h6" gutterBottom>
            Dane do wysyłki
          </Typography>
          <TextField
            label="Imię i nazwisko / nazwa"
            variant="outlined"
            value={deliveryInfo.name || ""}
            required={true}
            disabled={!provideShippingData}
            InputLabelProps={
              provideShippingData && {
                classes: {
                  root:
                    deliveryInfo.name !== "" && deliveryInfo.name != null
                      ? classes.cssLabelGreen
                      : classes.cssLabelRed,
                  focused: classes.cssFocused,
                },
              }
            }
            InputProps={
              provideShippingData && {
                classes: {
                  root:
                    deliveryInfo.name !== "" && deliveryInfo.name != null
                      ? classes.cssOutlinedInputGreen
                      : classes.cssOutlinedInputRed,
                  focused: classes.cssFocused,
                  notchedOutline:
                    deliveryInfo.name !== "" && deliveryInfo.name != null
                      ? classes.notchedOutlineGreen
                      : classes.notchedOutlineRed,
                },
                inputMode: "numeric",
              }
            }
            style={{
              width: "350px",
              borderColor: !provideShippingData ? "grey" : "",
              backgroundColor: !provideShippingData ? "lightgrey" : "",
            }}
            onChange={({ target: { value: name } }) => {
              setDeliveryInfo((prevState) => ({
                ...prevState,
                name: name,
              }));
            }}
          />
          <Box mt={1}>
            <TextField
              label="Ulica"
              variant="outlined"
              value={deliveryInfo.street || ""}
              required={true}
              disabled={!provideShippingData}
              InputLabelProps={
                provideShippingData && {
                  classes: {
                    root:
                      deliveryInfo.street !== "" && deliveryInfo.street != null
                        ? classes.cssLabelGreen
                        : classes.cssLabelRed,
                    focused: classes.cssFocused,
                  },
                }
              }
              InputProps={
                provideShippingData && {
                  classes: {
                    root:
                      deliveryInfo.street !== "" && deliveryInfo.street != null
                        ? classes.cssOutlinedInputGreen
                        : classes.cssOutlinedInputRed,
                    focused: classes.cssFocused,
                    notchedOutline:
                      deliveryInfo.street !== "" && deliveryInfo.street != null
                        ? classes.notchedOutlineGreen
                        : classes.notchedOutlineRed,
                  },
                  inputMode: "numeric",
                }
              }
              style={{
                width: "350px",
                borderColor: !provideShippingData ? "grey" : "",
                backgroundColor: !provideShippingData ? "lightgrey" : "",
              }}
              onChange={({ target: { value: street } }) => {
                setDeliveryInfo((prevState) => ({
                  ...prevState,
                  street: street,
                }));
              }}
            />
          </Box>
          <Box mt={1}>
            <TextField
              label="Miasto"
              variant="outlined"
              value={deliveryInfo.city || ""}
              required={true}
              disabled={!provideShippingData}
              InputLabelProps={
                provideShippingData && {
                  classes: {
                    root:
                      deliveryInfo.city !== "" && deliveryInfo.city != null
                        ? classes.cssLabelGreen
                        : classes.cssLabelRed,
                    focused: classes.cssFocused,
                  },
                }
              }
              InputProps={
                provideShippingData && {
                  classes: {
                    root:
                      deliveryInfo.city !== "" && deliveryInfo.city != null
                        ? classes.cssOutlinedInputGreen
                        : classes.cssOutlinedInputRed,
                    focused: classes.cssFocused,
                    notchedOutline:
                      deliveryInfo.city !== "" && deliveryInfo.city != null
                        ? classes.notchedOutlineGreen
                        : classes.notchedOutlineRed,
                  },
                  inputMode: "numeric",
                }
              }
              style={{
                width: "350px",
                borderColor: !provideShippingData ? "grey" : "",
                backgroundColor: !provideShippingData ? "lightgrey" : "",
              }}
              onChange={({ target: { value: city } }) => {
                setDeliveryInfo((prevState) => ({
                  ...prevState,
                  city: city,
                }));
              }}
            />
          </Box>
          <Box mt={1}>
            <TextField
              label="Kod pocztowy"
              variant="outlined"
              value={deliveryInfo.zipCode || ""}
              required={true}
              disabled={!provideShippingData}
              InputLabelProps={
                provideShippingData && {
                  classes: {
                    root:
                      deliveryInfo.zipCode !== "" &&
                      deliveryInfo.zipCode != null
                        ? classes.cssLabelGreen
                        : classes.cssLabelRed,
                    focused: classes.cssFocused,
                  },
                }
              }
              InputProps={
                provideShippingData && {
                  classes: {
                    root:
                      deliveryInfo.zipCode !== "" &&
                      deliveryInfo.zipCode != null
                        ? classes.cssOutlinedInputGreen
                        : classes.cssOutlinedInputRed,
                    focused: classes.cssFocused,
                    notchedOutline:
                      deliveryInfo.zipCode !== "" &&
                      deliveryInfo.zipCode != null
                        ? classes.notchedOutlineGreen
                        : classes.notchedOutlineRed,
                  },
                  inputMode: "numeric",
                }
              }
              style={{
                width: "350px",
                borderColor: !provideShippingData ? "grey" : "",
                backgroundColor: !provideShippingData ? "lightgrey" : "",
              }}
              onChange={({ target: { value: newZip } }) => {
                setDeliveryInfo((prevState) => ({
                  ...prevState,
                  zipCode: normalizeZIP(newZip, deliveryInfo.zipCode),
                }));
              }}
            />
          </Box>
          <Box mt={1}>
            <TextField
              label="Poczta"
              variant="outlined"
              value={deliveryInfo.post || ""}
              disabled={!provideShippingData}
              style={{
                width: "350px",
                borderColor: !provideShippingData ? "grey" : "",
                backgroundColor: !provideShippingData ? "lightgrey" : "",
              }}
              onChange={({ target: { value: post } }) => {
                setDeliveryInfo((prevState) => ({
                  ...prevState,
                  post: post,
                }));
              }}
            />
          </Box>
          <Box mt={1}>
            <TextField
              label="Telefon"
              variant="outlined"
              value={deliveryInfo.phone || ""}
              disabled={!provideShippingData}
              style={{
                width: "350px",
                borderColor: !provideShippingData ? "grey" : "",
                backgroundColor: !provideShippingData ? "lightgrey" : "",
              }}
              onChange={({ target: { value: phone } }) => {
                setDeliveryInfo((prevState) => ({
                  ...prevState,
                  phone: phone,
                }));
              }}
            />
          </Box>
        </Grid>
        {provideFVData && (
          <Grid item xs={6} {...grid}>
            <Typography variant="h6" gutterBottom>
              Dane do Faktury
            </Typography>
            <TextField
              label="Nazwa"
              variant="outlined"
              value={billingInfo.name || ""}
              required={true}
              InputLabelProps={{
                classes: {
                  root:
                    billingInfo.name !== "" && billingInfo.name != null
                      ? classes.cssLabelGreen
                      : classes.cssLabelRed,
                  focused: classes.cssFocused,
                },
              }}
              InputProps={{
                classes: {
                  root:
                    billingInfo.name !== "" && billingInfo.name != null
                      ? classes.cssOutlinedInputGreen
                      : classes.cssOutlinedInputRed,
                  focused: classes.cssFocused,
                  notchedOutline:
                    billingInfo.name !== "" && billingInfo.name != null
                      ? classes.notchedOutlineGreen
                      : classes.notchedOutlineRed,
                },
                inputMode: "numeric",
              }}
              style={{
                width: "350px",
              }}
              onChange={({ target: { value: name } }) => {
                setBillingInfo((prevState) => ({
                  ...prevState,
                  name: name,
                }));
              }}
            />
            <Box mt={1}>
              <TextField
                label="Ulica"
                variant="outlined"
                value={billingInfo.street || ""}
                required={true}
                InputLabelProps={{
                  classes: {
                    root:
                      billingInfo.street !== "" && billingInfo.street != null
                        ? classes.cssLabelGreen
                        : classes.cssLabelRed,
                    focused: classes.cssFocused,
                  },
                }}
                InputProps={{
                  classes: {
                    root:
                      billingInfo.street !== "" && billingInfo.street != null
                        ? classes.cssOutlinedInputGreen
                        : classes.cssOutlinedInputRed,
                    focused: classes.cssFocused,
                    notchedOutline:
                      billingInfo.street !== "" && billingInfo.street != null
                        ? classes.notchedOutlineGreen
                        : classes.notchedOutlineRed,
                  },
                  inputMode: "numeric",
                }}
                style={{
                  width: "350px",
                }}
                onChange={({ target: { value: street } }) => {
                  setBillingInfo((prevState) => ({
                    ...prevState,
                    street: street,
                  }));
                }}
              />
            </Box>
            <Box mt={1}>
              <TextField
                id="cityFV"
                name="cityFV"
                label="Miasto"
                variant="outlined"
                value={billingInfo.city || ""}
                required={true}
                InputLabelProps={{
                  classes: {
                    root:
                      billingInfo.city !== "" && billingInfo.city != null
                        ? classes.cssLabelGreen
                        : classes.cssLabelRed,
                    focused: classes.cssFocused,
                  },
                }}
                InputProps={{
                  classes: {
                    root:
                      billingInfo.city !== "" && billingInfo.city != null
                        ? classes.cssOutlinedInputGreen
                        : classes.cssOutlinedInputRed,
                    focused: classes.cssFocused,
                    notchedOutline:
                      billingInfo.city !== "" && billingInfo.city != null
                        ? classes.notchedOutlineGreen
                        : classes.notchedOutlineRed,
                  },
                  inputMode: "numeric",
                }}
                style={{
                  width: "350px",
                }}
                onChange={({ target: { value: city } }) => {
                  setBillingInfo((prevState) => ({
                    ...prevState,
                    city: city,
                  }));
                }}
              />
            </Box>
            <Box mt={1}>
              <TextField
                id="zipFV"
                name="zipFV"
                label="Kod pocztowy"
                variant="outlined"
                value={billingInfo.zipCode || ""}
                required={true}
                InputLabelProps={{
                  classes: {
                    root:
                      billingInfo.zipCode !== "" && billingInfo.zipCode != null
                        ? classes.cssLabelGreen
                        : classes.cssLabelRed,
                    focused: classes.cssFocused,
                  },
                }}
                InputProps={{
                  classes: {
                    root:
                      billingInfo.zipCode !== "" && billingInfo.zipCode != null
                        ? classes.cssOutlinedInputGreen
                        : classes.cssOutlinedInputRed,
                    focused: classes.cssFocused,
                    notchedOutline:
                      billingInfo.zipCode !== "" && billingInfo.zipCode != null
                        ? classes.notchedOutlineGreen
                        : classes.notchedOutlineRed,
                  },
                  inputMode: "numeric",
                }}
                style={{
                  width: "350px",
                }}
                onChange={({ target: { value: newZip } }) => {
                  setBillingInfo((prevState) => ({
                    ...prevState,
                    zipCode: normalizeZIP(newZip, deliveryInfo.zipCode),
                  }));
                }}
              />
            </Box>
            <Box mt={1}>
              <TextField
                label="Poczta"
                variant="outlined"
                value={billingInfo.post || ""}
                style={{
                  width: "350px",
                }}
                onChange={({ target: { value: post } }) => {
                  setBillingInfo((prevState) => ({
                    ...prevState,
                    post: post,
                  }));
                }}
              />
            </Box>
            <Box mt={1}>
              <TextField
                id="cityFV"
                name="cityFV"
                label="NIP"
                variant="outlined"
                inputProps={{
                  maxLength: 10,
                }}
                onInput={maxNIPLengthCheck}
                type="number"
                value={billingInfo.taxId || ""}
                required={true}
                InputLabelProps={{
                  classes: {
                    root:
                      billingInfo.taxId !== "" && billingInfo.taxId != null
                        ? classes.cssLabelGreen
                        : classes.cssLabelRed,
                    focused: classes.cssFocused,
                  },
                }}
                InputProps={{
                  classes: {
                    root:
                      billingInfo.taxId !== "" && billingInfo.taxId != null
                        ? classes.cssOutlinedInputGreen
                        : classes.cssOutlinedInputRed,
                    focused: classes.cssFocused,
                    notchedOutline:
                      billingInfo.taxId !== "" && billingInfo.taxId != null
                        ? classes.notchedOutlineGreen
                        : classes.notchedOutlineRed,
                  },
                  inputMode: "numeric",
                }}
                style={{
                  width: "350px",
                }}
                onChange={({ target: { value: taxId } }) => {
                  setBillingInfo((prevState) => ({
                    ...prevState,
                    taxId: taxId,
                  }));
                }}
              />
            </Box>
          </Grid>
        )}
      </Grid>
      <Box mt={2} display="flex" justifyContent="center">
        <span className={s.formHint}>
          * Pola te nie są wymagane, nie blokują akceptacji danych z tego
          formularza, jednak będą wymagane w trakcie faktycznego dokonywania
          zakupu.
        </span>
      </Box>

      <Grid container spacing={2}>
        <Box mt={1} display="flex" justifyContent="center">
          <Button
            type="submit"
            variant={"contained"}
            style={{
              backgroundColor: valid ? "blue" : "#cccccc",
              color: valid ? "white" : "#666666",
            }}
            onClick={submit}
          >
            Edytuj zamównienie
          </Button>
          <Button
            type="cancel"
            variant={"contained"}
            style={{
              backgroundColor: "grey",
              color: "white",
              marginLeft: "10px",
            }}
            onClick={() => history.push("/shopping-cart")}
          >
            Anuluj
          </Button>
        </Box>
      </Grid>
    </Grid>
  );

  const redirect = () => <Redirect to="/login" />;

  return <Can permission="edit-test-kit:page" ok={page} not={redirect} />;
};

const mapStateToProps = (state) => ({
  my: state.my,
});
export default connect(mapStateToProps, null)(EditTestKit);
