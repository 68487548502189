import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { alertTimeout } from "../../redux/actions";
import Alert from "@material-ui/lab/Alert";

const Alert2 = ({
  id,
  isError,
  isSuccess,
  isWarning,
  isInfo,
  text,
  timeout = 3000,
  timeoutFunc,
  alertTimeout,
}) => {
  const [timer, setTimer] = useState();
  const [color, setColor] = useState("info");

  const globalTheme = useSelector((s) => s.globalTheme || "light");

  useEffect(() => {
    if (isError) {
      setColor("error");
    }
    if (isWarning) {
      setColor("warning");
    }
    if (isSuccess) {
      setColor("success");
    }
    if (isInfo) {
      setColor("info");
    }
  }, [isError, isSuccess, isWarning]);

  useEffect(() => {
    if (timeout) {
      setTimeout(() => {
        alertTimeout(id);
      }, timeout);

      setTimer(id);
    }
  }, [timeout]);

  return globalTheme === "high-contrast" ? (
    <Alert
      color={color}
      style={{
        backgroundColor: "#000000",
        border: "1px solid #ffff00",
        color: "#ffff00",
      }}
      onClick={() => alertTimeout(id)}
    >
      {text}
    </Alert>
  ) : (
    <Alert color={color} onClick={() => alertTimeout(id)}>
      {text}
    </Alert>
  );
};

export default connect(
  () => ({}),
  (dispatch) => ({
    alertTimeout: (payload) => dispatch(alertTimeout(payload)),
  })
)(Alert2);
