import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  TextField,
  Button,
  FormControlLabel,
  Switch,
  Checkbox,
  Box,
  Grid,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { getAxiosInstance } from "../../redux/common";
import { useHistory } from "react-router-dom";
import Loader from "../../components/loader";
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import { nipValidator } from "../../pages/profile";
import MuiPhoneNumber from "material-ui-phone-number";

function NewOrder(props) {
  const { tenantId, email } = useParams();
  const [tenants, setTenants] = useState();
  const history = useHistory();
  const { prices } = props;
  const [formData, setFormData] = useState({
    tenant: null,
    smelltaste: 0,
    smell: 0,
    taste: 0,
    hasDeliveryInfo: false,
    hasBillingInfo: false,
    deliveryInfo: {},
    billingInfo: {},
  });

  const [displayStreetDetailText, setDisplayStreetDetailText] = useState(false);
  const [
    displayBillingStreetDetailText,
    setDisplayBillingStreetDetailText,
  ] = useState(false);

  const [copyDataFromProfile, setCopyDataFromProfile] = useState("");

  const [nipLostFocus, setNipLostFocus] = useState(false);
  const [valid, setValid] = useState(false);

  useEffect(() => {
    const isTaxIdValid = formData.hasBillingInfo
      ? nipValidator(formData.billingInfo.taxId) ||
        !formData.billingInfo.hasOwnProperty("taxId") ||
        formData.billingInfo.taxId === ""
      : true;

    if (
      (formData.smelltaste > 0 || formData.smell > 0 || formData.taste > 0) &&
      isTaxIdValid
    ) {
      setValid(true);
    } else {
      setValid(false);
    }
  }, [formData]);

  async function loadTenants() {
    try {
      const tenantsResult = await getAxiosInstance().get(
        "/tenants/v1/admin/tenants/all"
      );
      const tenants = tenantsResult.data;
      setTenants(tenants);

      if (tenantId) {
        const selected = tenants.find((t) => t.tenantId == tenantId);
        setFormData((formData) => ({
          ...formData,
          tenant: selected,
        }));
      }
    } catch (e) {
      if (e.response.status == 401 || e.response.status == 403) {
        history.push("/owner-panel/login");
      }
      setTenants(null);
    }
  }

  useEffect(() => {
    if (tenants === undefined) {
      loadTenants();
    }
  });

  function handleChange(event) {
    const target = event.target;
    let value = target.value;
    const name = target.name;
    const type = target.type;

    if (name === "billingInfo.taxId") {
      setNipLostFocus(false);
      value = value.replace(/[^0-9]/g, "");
    }

    if (type == "number") {
      value = parseInt(value, 10);
      if (isNaN(value) || value < 0) {
        value = 0;
      }
    } else if (type == "checkbox") {
      value = target.checked;
    }

    const names = name.split(".");

    if (names.length == 2) {
      setFormData((formData) => ({
        ...formData,
        [names[0]]: { ...formData[names[0]], [names[1]]: value },
      }));
    } else {
      setFormData((formData) => ({
        ...formData,
        [name]: value,
      }));
    }
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setCopyDataFromProfile("");
  };

  const maxNIPLengthCheck = (object) => {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(
        0,
        object.target.maxLength
      );
    }
  };

  const filledDeliveryDataFromProfile = async () => {
    const response = await getAxiosInstance().get(
      `/api/owner/persons/${email}/addresses`
    );

    const address = response.data.address;

    if (address) {
      setFormData({
        ...formData,
        deliveryInfo: {
          name: address.name || "",
          street: address.street || "",
          zipCode: address.zipCode || "",
          city: address.city || "",
          post: address.post || "",
          phone: address.phone || "",
        },
      });
      setCopyDataFromProfile("success");
      setTimeout(() => setCopyDataFromProfile(""), 3000);
    } else {
      setCopyDataFromProfile("failure");
      setTimeout(() => setCopyDataFromProfile(""), 3000);
    }
  };
  const filledBillingDataFromProfile = async () => {
    const response = await getAxiosInstance().get(
      `/api/owner/persons/${email}/addresses`
    );

    const billingInfo = response.data.billingInfo;

    if (billingInfo) {
      setFormData({
        ...formData,
        billingInfo: {
          taxId: billingInfo.taxId || "",
          name: billingInfo.name || "",
          street: billingInfo.street || "",
          zipCode: billingInfo.zipCode || "",
          city: billingInfo.city || "",
          post: billingInfo.post || "",
          notes: billingInfo.notes || "",
        },
      });
      setCopyDataFromProfile("success");
      setTimeout(() => setCopyDataFromProfile(""), 3000);
    } else {
      setCopyDataFromProfile("failure");
      setTimeout(() => setCopyDataFromProfile(""), 3000);
    }
  };

  async function handleSubmit(event) {
    event.preventDefault();

    let order = {
      items: [],
    };
    if (formData.smelltaste > 0) {
      order.items.push({
        product: "SMELLTASTE_TEST",
        quantity: formData.smelltaste,
      });
    }
    if (formData.smell > 0) {
      order.items.push({
        product: "SMELL_TEST",
        quantity: formData.smell,
      });
    }
    if (formData.taste > 0) {
      order.items.push({
        product: "TASTE_TEST",
        quantity: formData.taste,
      });
    }

    if (formData.hasDeliveryInfo) {
      order.deliveryInfo = formData.deliveryInfo;
    }
    if (formData.hasBillingInfo) {
      order.billingInfo = formData.billingInfo;
    }

    try {
      const tenantId = formData.tenant.tenantId;
      const createOrderResult = await getAxiosInstance().post(
        "/order/v1/admin/order",
        order,
        {
          params: {
            tenantId: tenantId,
          },
        }
      );
      const orderId = createOrderResult.data;
      history.push(`/owner-panel/orders/${tenantId}&${orderId}`);
    } catch (e) {
      console.error(e);
    }
  }

  if (tenants === undefined) {
    return <Loader loading={true} text="Wczytywanie danych" />;
  } else if (tenants === null) {
    return <div>Błąd wczytywania danych</div>;
  } else {
    const deliveryInfo = (() => {
      if (formData.hasDeliveryInfo) {
        return (
          <>
            <TextField
              fullWidth
              required
              name="deliveryInfo.name"
              label="Imię i nazwisko"
              value={formData.deliveryInfo.name || ""}
              onChange={handleChange}
            />
            <TextField
              fullWidth
              required
              name="deliveryInfo.street"
              label="Ulica i numer domu"
              value={formData.deliveryInfo.street || ""}
              onChange={handleChange}
              onFocus={() => setDisplayStreetDetailText(true)}
              onBlur={() => setDisplayStreetDetailText(false)}
              helperText={
                displayStreetDetailText ? "Przykład: Jasna 10A/3" : ""
              }
            />
            <TextField
              fullWidth
              required
              name="deliveryInfo.zipCode"
              label="Kod pocztowy"
              value={formData.deliveryInfo.zipCode || ""}
              onChange={handleChange}
            />
            <TextField
              fullWidth
              required
              name="deliveryInfo.city"
              label="Miasto"
              value={formData.deliveryInfo.city || ""}
              onChange={handleChange}
            />
            <TextField
              fullWidth
              name="deliveryInfo.post"
              label="Poczta"
              value={formData.deliveryInfo.post || ""}
              onChange={handleChange}
            />
            <MuiPhoneNumber
              fullWidth
              name="deliveryInfo.phone"
              label="Telefon"
              value={formData.deliveryInfo.phone || ""}
              data-cy="user-phone"
              defaultCountry={"pl"}
              regions={"europe"}
              autoFormat={false}
              countryCodeEditable={false}
              enableLongNumbers={true}
              onChange={(value) =>
                setFormData({
                  ...formData,
                  deliveryInfo: { ...formData.deliveryInfo, phone: value },
                })
              }
            />
            <TextField
              fullWidth
              name="deliveryInfo.notes"
              label="Dodatkowe uwagi"
              value={formData.deliveryInfo.notes || ""}
              onChange={handleChange}
            />
          </>
        );
      } else {
        return null;
      }
    })();

    const billingInfo = (() => {
      if (formData.hasBillingInfo) {
        return (
          <>
            <TextField
              fullWidth
              required
              name="billingInfo.taxId"
              label="NIP"
              value={formData.billingInfo.taxId || ""}
              onChange={handleChange}
              onFocus={() => setNipLostFocus(false)}
              onBlur={() => setNipLostFocus(true)}
              inputProps={{
                maxLength: 10,
              }}
              onInput={maxNIPLengthCheck}
              error={
                nipLostFocus &&
                !(
                  nipValidator(formData.billingInfo.taxId) ||
                  formData.billingInfo.taxId === ""
                )
              }
              helperText={
                nipLostFocus &&
                !(
                  nipValidator(formData.billingInfo.taxId) ||
                  formData.billingInfo.taxId === ""
                ) &&
                "Wprowadzony numer NIP jest nieprawidłowy"
              }
            />
            <TextField
              fullWidth
              required
              name="billingInfo.name"
              label="Nazwa firmy"
              value={formData.billingInfo.name || ""}
              onChange={handleChange}
            />
            <TextField
              fullWidth
              required
              name="billingInfo.street"
              label="Ulica"
              value={formData.billingInfo.street || ""}
              onChange={handleChange}
              onFocus={() => setDisplayBillingStreetDetailText(true)}
              onBlur={() => setDisplayBillingStreetDetailText(false)}
              helperText={
                displayBillingStreetDetailText ? "Przykład: Jasna 10A/3" : ""
              }
            />
            <TextField
              fullWidth
              required
              name="billingInfo.zipCode"
              label="Kod pocztowy"
              value={formData.billingInfo.zipCode || ""}
              onChange={handleChange}
            />
            <TextField
              fullWidth
              required
              name="billingInfo.city"
              label="Miasto"
              value={formData.billingInfo.city || ""}
              onChange={handleChange}
            />
            <TextField
              fullWidth
              name="billingInfo.post"
              label="Poczta"
              value={formData.billingInfo.post || ""}
              onChange={handleChange}
            />
          </>
        );
      } else {
        return null;
      }
    })();

    return (
      <Box m={2}>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          style={{ minHeight: "100vh" }}
        >
          <Grid item xs={4}>
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              open={copyDataFromProfile !== ""}
              autoHideDuration={3000}
              onClose={handleClose}
            >
              {copyDataFromProfile !== "" && (
                <Alert
                  onClose={handleClose}
                  severity={
                    copyDataFromProfile !== "" &&
                    copyDataFromProfile === "success"
                      ? "info"
                      : "warning"
                  }
                >
                  {copyDataFromProfile === "success"
                    ? "Dane zostały skopiowane"
                    : "Nie udało sie skopiować danych"}
                </Alert>
              )}
            </Snackbar>
            <form onSubmit={handleSubmit}>
              <Autocomplete
                disabled
                autoSelect
                onChange={(event, newValue) => {
                  setFormData((formData) => ({
                    ...formData,
                    tenant: newValue,
                  }));
                }}
                value={formData.tenant}
                options={tenants}
                getOptionLabel={(tenant) => tenant.name}
                renderInput={(params) => (
                  <TextField {...params} label="Zamawiający" required />
                )}
              />
              <TextField
                fullWidth
                name="smelltaste"
                label="Test smaku i węchu"
                type="number"
                value={formData.smelltaste}
                onChange={handleChange}
              />
              <TextField
                fullWidth
                name="smell"
                label="Test węchu"
                type="number"
                value={formData.smell}
                onChange={handleChange}
              />
              <TextField
                fullWidth
                name="taste"
                label="Test smaku"
                type="number"
                value={formData.taste}
                onChange={handleChange}
              />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.hasDeliveryInfo}
                      onChange={handleChange}
                      name="hasDeliveryInfo"
                      color="primary"
                    />
                  }
                  label="Dane do wysyłki"
                />
                {formData.hasDeliveryInfo && (
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      filledDeliveryDataFromProfile();
                    }}
                  >
                    Skopiuj dane z profilu
                  </a>
                )}
              </div>
              {deliveryInfo}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.hasBillingInfo}
                      onChange={handleChange}
                      name="hasBillingInfo"
                      color="primary"
                    />
                  }
                  label="Dane do faktury"
                />
                {formData.hasBillingInfo && (
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      filledBillingDataFromProfile();
                    }}
                  >
                    Skopiuj dane z profilu
                  </a>
                )}
              </div>
              {billingInfo}
              <div style={{ width: "100%" }}>
                <Grid container spacing={3}>
                  <Grid item xs>
                    <Button
                      fullWidth
                      variant="contained"
                      onClick={history.goBack}
                    >
                      Anuluj
                    </Button>
                  </Grid>
                  <Grid item xs>
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={!valid}
                    >
                      Stwórz zamówienie
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </form>
          </Grid>
        </Grid>
      </Box>
    );
  }
}

export default NewOrder;
