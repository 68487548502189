import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import TableCell from "@material-ui/core/TableCell";
import Tooltip from "@material-ui/core/Tooltip";
import TableRow from '@material-ui/core/TableRow'

const SingleForm = ({test, fill, fetchFilledForms, filledForms, history}) => {
  const [number, setNumber] = useState()

  useEffect(() => {
    if (fetchFilledForms && test && test.id) {
      fetchFilledForms(test.id)
    }
  }, [fetchFilledForms, test])

  useEffect(() => {
    if (filledForms && filledForms[test.id]) {
      setNumber(filledForms[test.id].length)
    }
  }, [filledForms, setNumber, test])

  return (
      <TableRow>
        <TableCell component="th" scope="row">
          {test.name}
        </TableCell>
          <TableCell>
              <Tooltip title='Wypełnione formularze. Kliknij by obejrzeć.'>
                  <button
                      onClick={() => {history.push(`/testdata/all/${test.id}`)}}
                      className="button"
                      title="Wypełnione formularze">
                      {number || 0}
                  </button>
              </Tooltip>
          </TableCell>
          <TableCell align="right">
              <Tooltip title='Kliknij by wypełnić formularz.'>
                  <button onClick={() => fill(test.id)} className="button" >
                      Wypełnij
                  </button>
              </Tooltip>
          </TableCell>
      </TableRow>
  )
}

export default SingleForm
