import { Box } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import { Visibility } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import Can from "../../components/can";
import Loader from "../../components/loader";
import { alertAdd, logout, resetByMailPassword } from "../../redux/actions";
import s from "./index.module.css";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Paper from "@material-ui/core/Paper";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles, withStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  input: {
    background: "white",
    "&:hover": {
      background: "white",
    },
    "&.Mui-focused": {
      background: "white",
    },
  },
  paperStyle: {
    padding: "12px",
    // height: "90vh",
    width: "100%",
    background: theme.palette.background.tab,
    border: `1px solid ${theme.palette.background.border}`,
    borderBottomLeftRadius: "1rem",
    borderBottomRightRadius: "1rem",
    borderTopRightRadius: "0px",
    borderTopLeftRadius: "0px",
  },
  formHint: {
    fontSize: theme.typography.hintText,
    lineHeight: "1.4",
    margin: "-5px auto 5px",
    color: theme.palette.text.primary,
  },
  labelStrengthPassword: {
    display: "block",
    posistion: "relative",
    fontSize: theme.typography.hintText,
    fontWeight: "500",
    width: "100%",
    color: theme.palette.text.primary,
  },
  errorFormHint: {
    fontSize: theme.typography.hintText,
    lineHeight: "1.4",
    margin: "-5px auto 5px",
    color: theme.palette.error.main,
  },
}));

const PasswordResetMailPage = ({
  resetByMailPassword,
  resettingByMailPassword,
  logout,
  match: {
    params: { token },
  },
}) => {
  const [newPassword, setNewPassword] = useState("");
  const [newPassword2, setNewPassword2] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showNewPassword2, setShowNewPassword2] = useState(false);
  const [formIsValid, setFormIsValid] = useState(false);

  const [passwordScore, setPasswordScore] = useState(0);
  const [passwordMissingElements, setPasswordMissingElements] = useState([]);
  const [typingTimer, setTypingTimer] = useState(null);

  const [encodedToken, setEncodedToken] = useState(null);

  const [invalidPasswordInputChar, setInvalidPasswordInputChar] = useState(
    false
  );
  const [newPasswordsMatch, setNewPasswordsMatch] = useState(true);
  const [passwordValid, setPasswordValid] = useState(false);

  const my = useSelector((s) => s.my);

  const classes = useStyles();

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const t = JSON.parse(atob(decodeURIComponent(token)));
    setEncodedToken(t);

    const now = new Date();
    const expires = new Date(t.expires);
    expires.setHours(expires.getHours() + 1);

    const tokenIsValid = expires > now;
    if (!tokenIsValid) {
      history.push("/password-reset-link-expired");
    } else {
      history.push(`/password-set-new/${token}`);
    }
  }, [token]);

  useEffect(() => {
    setFormIsValid(
      newPassword &&
        newPassword2 &&
        newPassword === newPassword2 &&
        passwordScore > 4
    );
  }, [newPassword, newPassword2, passwordScore]);

  useEffect(() => {
    if (my) {
      logout();
      history.push(`/password-set-new/${token}`);
    }
    clearTimeout(typingTimer);
  }, []);

  const TabStyle = withStyles((theme) => ({
    root: {
      // "&:hover": {
      //   backgroundColor: "rgba(250, 250, 250,  .8)",
      //   color: "#EEEDED",
      //   opacity: 0.5,
      // },
      "&$selected": {
        backgroundColor: "rgba(250, 250, 250,  .8)",
        color: "black",
        borderTopRightRadius: "1rem",
        borderTopLeftRadius: "1rem",
        opacity: 1,
        color: "white",
      },
      color: "black",
      backgroundColor: "#EEEDED",
      opacity: 0.6,
      borderTopRightRadius: "1rem",
      borderTopLeftRadius: "1rem",
    },
    // tab: {
    //   padding: "0.5rem",

    //   opacity: 0.1,
    //   "&:hover": {
    //     opacity: 0.1,
    //   },
    // },
    selected: {},
  }))((props) => <Tab {...props} />);

  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const passwordMissingElementFilter = (
    tmpPasswordMissingElements,
    element
  ) => {
    return tmpPasswordMissingElements.filter(
      (missingElement) => missingElement !== element
    );
  };

  const testStrengthPassword = (e) => {
    let pass = e.target.value;
    let tmpPasswordScore = 0;
    let tmpPasswordMissingElements = [
      "Przynajmniej jedną małą literę",
      "Przynajmniej jedną wielką literę",
      "Przynajmniej jeden znak specjalny: ! @ # $ % & * _ + = ^",
      "Przynajmniej jedeną cyfrę",
      "Długość znaków między 8, a 32.",
    ];
    if (pass.length > 7 && pass.length < 33) {
      tmpPasswordScore = tmpPasswordScore + 1;
      tmpPasswordMissingElements = passwordMissingElementFilter(
        tmpPasswordMissingElements,
        "Długość znaków między 8, a 32."
      );
    }
    if (/[a-z]/.test(pass)) {
      tmpPasswordScore = tmpPasswordScore + 1;
      tmpPasswordMissingElements = passwordMissingElementFilter(
        tmpPasswordMissingElements,
        "Przynajmniej jedną małą literę"
      );
    }
    if (/[A-Z]/.test(pass)) {
      tmpPasswordScore = tmpPasswordScore + 1;
      tmpPasswordMissingElements = passwordMissingElementFilter(
        tmpPasswordMissingElements,
        "Przynajmniej jedną wielką literę"
      );
    }
    if (/[0-9]/.test(pass)) {
      tmpPasswordScore = tmpPasswordScore + 1;
      tmpPasswordMissingElements = passwordMissingElementFilter(
        tmpPasswordMissingElements,
        "Przynajmniej jedeną cyfrę"
      );
    }
    if (/[!@#$%^&*+=_]+/.test(pass)) {
      tmpPasswordScore = tmpPasswordScore + 1;
      tmpPasswordMissingElements = passwordMissingElementFilter(
        tmpPasswordMissingElements,
        "Przynajmniej jeden znak specjalny: ! @ # $ % & * _ + = ^"
      );
    }
    clearTimeout(typingTimer);
    if (tmpPasswordScore === 0) {
      setPasswordScore(tmpPasswordScore);
    }

    setPasswordValid(tmpPasswordScore === 5);

    setTypingTimer(
      setTimeout(() => {
        if (tmpPasswordScore) {
          setPasswordScore(tmpPasswordScore);
          setPasswordMissingElements(tmpPasswordMissingElements);
        }
      }, 1000)
    );
  };
  const submit = async (e) => {
    e.preventDefault();
    const response = await resetByMailPassword({
      email: encodedToken.email,
      resetId: encodedToken.resetId,
      newPassword,
    });

    if (response.status === 200) {
      history.push("/login");
    } else {
      if (response.data.name === "InvalidTokenError") {
        dispatch(
          alertAdd({
            text:
              "Link do resetu hasła jest nieprawidłowy, możliwe że został już użyty.",
            isError: true,
          })
        );
      }
    }
  };

  const cancel = (e) => {
    e.preventDefault();

    history.push("/login");
  };

  useEffect(() => {
    setNewPasswordsMatch(newPassword === newPassword2);
  }, [newPassword, newPassword2]);

  const page = () => {
    return (
      <Grid
        container
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Grid
          item
          xs={12}
          sm={8}
          md={6}
          lg={4}
          style={{ margin: smallScreen ? 25 : 50 }}
        >
          <h1
            aria-hidden="false"
            aria-label="Resetowanie hasła - formularz"
            style={{ display: "none" }}
          >
            Resetowanie hasła - formularz
          </h1>
          <Paper
            style={{
              background: "transparent",
              borderBottomLeftRadius: "2rem",
              borderBottomRightRadius: "2rem",
              margin: "12px",
            }}
          >
            <Tabs
              value={0}
              textColor="primary"
              aria-label="disabled tabs example"
              inkBarStyle={{ background: "blue" }}
              TabIndicatorProps={{
                style: {
                  display: "none",
                },
              }}
              style={{ background: "transparent" }}
            >
              <Tab
                label="Resetowanie hasła"
                style={{ minWidth: "100%", color: "black" }}
              />
            </Tabs>

            <Paper className={classes.paperStyle}>
              <Grid
                container
                spacing={2}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Grid item xs={10}>
                  <Box p={1}>
                    <Loader
                      loading={resettingByMailPassword}
                      text="Resetuję hasło..."
                    />

                    <form onSubmit={submit} noValidate autoComplete="off">
                      <Box>
                        <TextField
                          label="Nowe hasło"
                          variant="filled"
                          type={showNewPassword ? "text" : "password"}
                          value={newPassword}
                          error={
                            invalidPasswordInputChar ||
                            (!passwordValid && newPassword)
                          }
                          helperText={
                            invalidPasswordInputChar
                              ? "Spacja nie jest dozwolona."
                              : !passwordValid && newPassword
                              ? "Hasło nie spełnia wymogów"
                              : ""
                          }
                          required
                          fullWidth
                          onChange={(e) => {
                            if (e.target.value.includes(" ")) {
                              setInvalidPasswordInputChar(true);
                              setTimeout(
                                () => setInvalidPasswordInputChar(false),
                                3000
                              );
                            } else {
                              setInvalidPasswordInputChar(false);
                            }
                            setNewPassword(e.target.value.split(" ").join(""));
                            testStrengthPassword(e);
                          }}
                          inputProps={{ "aria-label": "Nowe hasło" }}
                          InputProps={{
                            // className: classes.input,
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={() =>
                                    setShowNewPassword(!showNewPassword)
                                  }
                                  aria-label="toggle password visibility"
                                >
                                  <Visibility />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>
                      <Box mt={2}>
                        <TextField
                          label="Powtórz nowe hasło"
                          variant="filled"
                          type={showNewPassword2 ? "text" : "password"}
                          value={newPassword2}
                          required
                          error={newPassword2 && !newPasswordsMatch}
                          helperText={
                            newPassword2 &&
                            !newPasswordsMatch &&
                            "Hasła nie pasują do siebie"
                          }
                          fullWidth
                          onChange={(e) =>
                            setNewPassword2(e.target.value.split(" ").join(""))
                          }
                          inputProps={{ "aria-label": "Powtórz nowe hasło" }}
                          InputProps={{
                            // className: classes.input,
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={() =>
                                    setShowNewPassword2(!showNewPassword2)
                                  }
                                  aria-label="toggle password visibility"
                                >
                                  <Visibility />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>
                      <Box mt={1} display="flex">
                        <span className={classes.formHint}>
                          Aby spełnić zalożenie polityki Silnego Hasła prosi się
                          o podanie co najmniej jednej wielkiej litery, małej
                          litery, cyfry oraz znaku specjanlego. Hasło powinno
                          być dłuższe niż 7 znaków.
                        </span>
                      </Box>
                      {passwordScore > 0 && (
                        <div>
                          <label className={classes.labelStrengthPassword}>
                            Siła hasła:
                          </label>
                          <Box mt={1} display="flex">
                            <span
                              className={s.strengthPassword}
                              datascore={passwordScore}
                            />
                          </Box>
                          {passwordMissingElements.length > 0 && (
                            <label
                              className={classes.errorFormHint}
                              style={{ marginTop: "10px" }}
                            >
                              Aby hasło było silne, należy zawrzeć:
                            </label>
                          )}
                          <span className={classes.errorFormHint}>
                            {passwordMissingElements.map((el, index) => {
                              return <li key={index}>{el}</li>;
                            })}
                          </span>
                        </div>
                      )}
                      <Box mt={1} display="flex" justifyContent="space-between">
                        <Button
                          variant={"contained"}
                          fullWidth
                          onClick={cancel}
                        >
                          Anuluj
                        </Button>
                        <Button
                          type="submit"
                          variant={"contained"}
                          color="primary"
                          disabled={
                            !formIsValid || newPassword !== newPassword2
                          }
                          fullWidth
                          md={7}
                        >
                          Zresetuj hasło
                        </Button>
                      </Box>
                    </form>
                  </Box>
                </Grid>
              </Grid>
            </Paper>
          </Paper>
        </Grid>
      </Grid>
    );
  };
  return (
    <Can
      permission="password-reset-email:view"
      ok={page}
      not={() => {
        return <Redirect to="/" />;
      }}
    />
  );
};

const mapStateToProps = (state) => ({
  resettingByMailPassword: state.resettingByMailPassword,
});

const mapDispatchToProps = (dispatch) => ({
  resetByMailPassword: (newPassword) =>
    dispatch(resetByMailPassword(newPassword)),
  logout: (newPassword) => dispatch(logout()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PasswordResetMailPage);
