import React, { useEffect, useState, useRef } from "react";
import { connect, useSelector } from "react-redux";
import { NavLink, Redirect } from "react-router-dom";
import Can from "../../components/can";
import Loader from "../../components/loader";
import { alertAdd, login } from "../../redux/actions";
import Cookies from "js-cookie";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import { LockOpen, Visibility } from "@material-ui/icons";
import { Box } from "@material-ui/core";
import styled from "styled-components";
import s from "./index.module.css";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Grid, Paper, TextField, Button } from "@material-ui/core";
import { recoverPassword } from "../../redux/actions";
import emailValidator from "email-validator";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";

import { makeStyles, withStyles } from "@material-ui/core/styles";

const WhiteButton = styled(Button)`
  background-color: white;
`;

const useStyles = makeStyles((theme) => ({
  input: {
    background: "white",
    "&:hover": {
      background: "white",
    },
    "&.Mui-focused": {
      background: "white",
    },
  },
  paperStyle: {
    padding: "12px",
    // height: "90vh",
    width: "100%",
    background: theme.palette.background.tab,
    border: `1px solid ${theme.palette.background.border}`,
    borderBottomLeftRadius: "1rem",
    borderBottomRightRadius: "1rem",
    borderTopRightRadius: "0px",
    borderTopLeftRadius: "0px",
  },
}));

const PasswordResetPage = ({
  recoverPassword,
  recoveringPassword,
  history,
}) => {
  const [mail, setMail] = useState("");
  const [emailValid, setEmailValid] = useState(false);
  const [sent, setSent] = useState(false);
  const classes = useStyles();
  const input = useRef();
  const globalTheme = useSelector((s) => s.globalTheme || "light");

  useEffect(() => {
    if (input.current) {
      input.current.focus();
    }
  });

  const reset = async (e) => {
    e.preventDefault();

    if (emailValid) {
      await recoverPassword(mail);

      setSent(true);
      setTimeout(() => history.push("/login"), 3000);
    }
  };

  useEffect(() => {
    if (mail) {
      let polishChars1 = String(mail)
        .toLowerCase()
        .normalize("NFD")
        .search(/[\u0300-\u036f]/g);
      let polishChars2 = String(mail)
        .toLowerCase()
        .normalize("NFD")
        .search(/\u0142/g);

      setEmailValid(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          String(mail).toLowerCase()
        ) &&
          (polishChars2 !== 0
            ? polishChars1 + polishChars2 < 0
            : polishChars1 + polishChars2 < -1)
      );
    }
  }, [mail]);

  const TabStyle = withStyles((theme) => ({
    root: {
      // "&:hover": {
      //   backgroundColor: "rgba(250, 250, 250,  .8)",
      //   color: "#EEEDED",
      //   opacity: 0.5,
      // },
      "&$selected": {
        backgroundColor: "rgba(250, 250, 250,  .8)",
        color: "black",
        borderTopRightRadius: "1rem",
        borderTopLeftRadius: "1rem",
        opacity: 1,
        color: "white",
      },
      color: "black",
      backgroundColor: "#EEEDED",
      opacity: 0.6,
      borderTopRightRadius: "1rem",
      borderTopLeftRadius: "1rem",
    },
    // tab: {
    //   padding: "0.5rem",

    //   opacity: 0.1,
    //   "&:hover": {
    //     opacity: 0.1,
    //   },
    // },
    selected: {},
  }))((props) => <Tab {...props} />);

  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const avatarStyle = { backgroundColor: "#1bbd7e" };
  const btnstyle = { margin: "8px 0" };

  return (
    <Grid
      container
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Grid item xs={12} sm={8} md={6} lg={4} style={{ margin: 50 }}>
        <h1
          aria-hidden="false"
          aria-label="Resetowanie hasła"
          style={{ display: "none" }}
        >
          Resetowanie hasła
        </h1>
        <Paper
          style={{
            // background: "transparent",
            borderBottomLeftRadius: "2rem",
            borderBottomRightRadius: "2rem",
            margin: "12px",
          }}
        >
          <Tabs
            value={0}
            textColor="primary"
            aria-label="disabled tabs example"
            inkBarStyle={{ background: "blue" }}
            TabIndicatorProps={{
              style: {
                display: "none",
              },
            }}
            style={{ background: "transparent" }}
          >
            <Tab
              label="Odzyskaj hasło"
              style={{ minWidth: "100%", color: "black" }}
            />
          </Tabs>

          <Paper className={classes.paperStyle}>
            <Grid
              container
              spacing={2}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Grid item xs={10}>
                {sent && (
                  <Box mb={2}>
                    <Alert
                      severity="success"
                      style={{
                        backgroundColor:
                          globalTheme === "high-contrast" && "#000000",
                        border:
                          globalTheme === "high-contrast" &&
                          "1px solid #ffff00",
                        color: globalTheme === "high-contrast" && "#ffff00",
                      }}
                    >
                      Link do zmiany hasła został wysłany na wybrany email
                    </Alert>
                  </Box>
                )}
                <Box>
                  <form onSubmit={reset} noValidate autoComplete="off">
                    <Box>
                      <TextField
                        label="Email"
                        variant="filled"
                        value={mail}
                        fullWidth
                        onChange={(e) => setMail(e.target.value)}
                        inputProps={{ "aria-label": "Email" }}
                        // InputProps={{
                        //   className: classes.input,
                        // }}
                      />
                    </Box>
                    <Box mt={1}></Box>
                    <Box textAlign="right" fontSize={12} m={1}></Box>
                    <Grid
                      container
                      spacing={1}
                      alignItems="center"
                      justify="center"
                    >
                      <Grid item xs={12} sm={6}>
                        <Button
                          variant={"contained"}
                          onClick={() => history.push("/login")}
                          fullWidth
                          md={5}
                        >
                          Powrót do logowania
                        </Button>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Button
                          variant={"contained"}
                          color="primary"
                          onClick={reset}
                          disabled={!emailValid}
                          fullWidth
                          md={7}
                        >
                          Zresetuj hasło
                        </Button>
                      </Grid>
                    </Grid>
                    <Loader
                      loading={recoveringPassword}
                      text="Resetuję hasło..."
                    />
                  </form>
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </Paper>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  recoveringPassword: state.recoveringPassword,
});

const mapDispatchToProps = (dispatch) => ({
  recoverPassword: (email) => dispatch(recoverPassword(email)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PasswordResetPage);
