import React, {useEffect, useState} from "react";
import Datepicker, { registerLocale } from "react-datepicker";
import { useDrag } from "react-dnd";
import { ItemSubtypes, ItemTypes } from "../../../DnDTypes";
import s from "./index.module.css";

import "react-datepicker/dist/react-datepicker.css";
import pl from "date-fns/locale/pl";
import * as classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";

registerLocale("pl", pl);

const DateControl = ({
  name,
  id,
  disabled,
  isSelected = false,
  dragMode = "move",
  configuration = {},
  markSelected,
  onChange,
  showSelfDescription,
  sidebarMode,
  formCreatorMode,
  removable,
  onRemove,
  rowIndex,
  index,
  setIsDragging,
  filledValue,
  filledFormMode,
}) => {
  const [value, setValue] = useState(filledValue || "");
  useEffect(() => {
    setValue(filledValue)
  }, filledValue)

  const [, drag] = useDrag({
    item: {
      type: ItemTypes.COMPONENT,
      subtype: ItemSubtypes.DATE,
      dragMode,
      defaultLabel: "Data",
      id,
      rowIndex,
      index,
    },
    collect: (monitor, props) => {
      setIsDragging && setIsDragging(!!monitor.isDragging());

      return {
        isDragging: !!monitor.isDragging(),
      };
    },
    canDrag: () => {
      return dragMode === "move" || dragMode === "clone";
    },
  });

  const clicked = () => {
    markSelected &&
      markSelected({
        id,
        configuration,
      });
  };

  const updateValue = (value) => {
    setValue(value);

    const s = moment(value).format("YYYY/MM/DD");

    if (onChange) {
      onChange({
        id,
        value: s,
      });
    }
  };

  if (sidebarMode) {
    return (
      <div ref={drag}>
        <input
          readOnly={true}
          type="text"
          className="input"
          placeholder="Data"
        />
      </div>
    );
  }

  const placeholder = showSelfDescription
    ? "Data"
    : (configuration && configuration.text) || "";
  const label = (configuration && configuration.label) || "";

  if (formCreatorMode) {
    return (
      <div ref={drag}>
        {label && <label className={classnames("label")}>{label}</label>}
        {!label && (
          <label
            dangerouslySetInnerHTML={{ __html: "&nbsp;" }}
            className={classnames("label")}
          ></label>
        )}
        <div ref={drag} className={s.relative}>
          <input
            placeholder={placeholder}
            className={classnames("input", {
              [s.hasActiveConfiguration]: isSelected,
            })}
            name={name}
            id={id}
            onClick={clicked}
            readOnly
          />
          <FontAwesomeIcon
            className={classnames(s.trashIcon, "has-text-grey")}
            onClick={onRemove}
            icon="trash"
            title="Usuń"
          />
        </div>
      </div>
    );
  }

  if (filledFormMode) {
    return (
      <div>
        {label && <label className={classnames("label")}>{label}</label>}
        {!label && (
          <label
            dangerouslySetInnerHTML={{ __html: "&nbsp;" }}
            className={classnames("label")}
          ></label>
        )}

        <input
          disabled={disabled}
          type="text"
          className={classnames("input")}
          name={name}
          id={id}
          value={value}
        />
      </div>
    );
  }

  return (
    <div ref={drag}>
      {label && <label className={classnames("label")}>{label}</label>}
      {!label && (
        <label
          dangerouslySetInnerHTML={{ __html: "&nbsp;" }}
          className={classnames("label")}
        ></label>
      )}

      <Datepicker
        locale={pl}
        className={classnames("input")}
        disabled={disabled}
        selected={value}
        value={value ? value : ""}
        onFocus={clicked}
        onChange={(value) => updateValue(value)}
      />
    </div>
  );
};

export default DateControl;
