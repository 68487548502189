import React, { useState, useEffect } from "react";
import { getAxiosInstance } from "../../redux/common";
import { TextField, Button, Box, Grid } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Link as RouterLink,
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom";
import Themed from "./themed";
import imageEnvelope from "../../assets/envelope3.png";
import smellEnvelopeIcon from "../../assets/smellEnvelope.jpg";
import tasteEnvelopeIcon from "../../assets/tasteEnvelope.jpg";
import AppBarTitle from "../../components/app-bar-title";
import { QRScanner } from "../../components/qr-scanner";
import Can from "../../components/can";
import { Redirect } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(1),
    background: theme.palette.background.default,
    paddingTop: "12px",
  },
  container: {
    backgroundColor: theme.palette.background.default,
    "& form": {
      padding: theme.spacing(0, 2, 0, 2),
    },
    "& input": {
      height: "auto",
    },
    paddingBottom: 24,
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    paddingTop: theme.spacing(1),
  },
  imageContainer: {
    width: "100%",
    backgroundColor: theme.palette.background.default,
    paddingBottom: theme.spacing(3),
    textAlign: "center",
    "&>img": {
      width: "80%",
    },
  },
}));

const ActivateCode = () => {
  const [code, setCode] = useState("");
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState("");
  const [qrOpen, setQrOpen] = useState(false);
  const history = useHistory();
  const theme = useTheme();
  const { scheduledId } = useParams();
  const classes = useStyles();
  const location = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChange = (event) => {
    const target = event.target;
    const value = target.value;

    setError(false);
    setHelperText("");

    setCode(value);
  };

  const changeCode = (data) => {
    setHelperText("");
    setCode(data);
    setQrOpen(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      await getAxiosInstance().patch(
        `/api/forms/scheduled/${scheduledId}/${code.toUpperCase()}`
      );

      history.push("/tests");
    } catch (e) {
      console.error(e.response);

      setError(true);
      if (e.response.status === 400 || e.response.status === 404 || !code) {
        setHelperText("Nieprawidłowy numer testu");
      } else {
        setHelperText("Coś poszło nie tak. Spróbuj ponownie później.");
      }
    }
  };

  return (
    <Can
      permission="add-test-set:page"
      ok={() => (
        <Box className={classes.root}>
          <AppBarTitle value="Aktywuj TEST" />
          <h1
            aria-hidden="false"
            aria-label="Aktywuj TEST"
            style={{ display: "none" }}
          >
            Aktywuj TEST
          </h1>
          <Grid className={classes.root} container justify="center">
            <Grid item xs={12} sm={8} md={6}>
              <Box className={classes.container}>
                <Grid container justify="center" spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <Box className={classes.imageContainer}>
                      <img
                        src={
                          location?.state?.testType === "smell"
                            ? smellEnvelopeIcon
                            : tasteEnvelopeIcon
                        }
                        alt=""
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <form onSubmit={handleSubmit}>
                      <Box style={{ display: "flex" }}>
                        <TextField
                          name="code"
                          label="Wprowadź 5-znakowy NUMER TESTU"
                          variant="outlined"
                          fullWidth
                          value={code}
                          error={error}
                          helperText={helperText}
                          onChange={handleChange}
                          InputLabelProps={{
                            style: {
                              paddingRight: "7px",
                            },
                          }}
                          inputProps={{
                            "aria-label": "Wprowadź pięcio-znakowy NUMER TESTU",
                          }}
                        />
                        <Grid
                          item
                          style={{
                            margin: "auto 10px",
                            fontSize: "15px",
                          }}
                        >
                          lub
                        </Grid>
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={() => {
                            setQrOpen(true);
                          }}
                          style={{ color: theme.palette.text.qr }}
                        >
                          Zeskanuj kod QR
                        </Button>
                      </Box>
                      <Grid item xs={12}>
                        {qrOpen && (
                          <div>
                            <QRScanner changeCode={changeCode}></QRScanner>
                          </div>
                        )}
                      </Grid>
                      <Box mt={2}>
                        <Grid container justify="space-between">
                          <Grid item xs={5}>
                            <Button
                              component={RouterLink}
                              to={"/tests"}
                              variant="contained"
                              fullWidth
                            >
                              Anuluj
                            </Button>
                          </Grid>
                          <Grid item xs={5}>
                            <Button
                              variant="contained"
                              color="primary"
                              fullWidth
                              type="submit"
                            >
                              Kontynuuj
                            </Button>
                          </Grid>
                        </Grid>
                      </Box>
                    </form>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
      not={() => <Redirect to="/login" />}
    />
  );
};

const ActivateCodeWithTheme = () => (
  <Themed>
    <ActivateCode />
  </Themed>
);

export default ActivateCodeWithTheme;
