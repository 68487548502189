import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, Redirect, Prompt } from "react-router-dom";
import Can from "../../components/can";
import s from "./index.module.css";
import {
  updateMyProfile,
  updateMyProfileExt,
  anonimizationRequest,
  removeAvatar,
} from "../../redux/person/actions";
import * as classnames from "classnames";
import { updateMyOrganization } from "../../redux/organization/actions";
import { checkPermission } from "../../rbac";
import { Box, Container } from "@material-ui/core";
import { alertAdd } from "../../redux/actions";
import ShippingAddressForm from "../../components/address-form/shipping-info";
import BillingAddressForm from "../../components/address-form/billing-info";
import makeStyles from "@material-ui/core/styles/makeStyles";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import MuiTypography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Link from "@material-ui/core/Link";
import AppBarTitle from "../../components/app-bar-title";
import {
  DatePicker,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

import DateFnsUtils from "@date-io/date-fns";
import plLocale from "date-fns/locale/pl";
import * as moment from "moment";
import Input from "@material-ui/core/Input";
import { ConfirmDialogMUI } from "../../components/confirm-dialog";
import Loader from "../../components/loader";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: "24px",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.primary,
  },
  container: {
    width: "80%",
    maxWidth: "5000px",
    minWidth: "300px",
    paddingTop: "24px",
  },
  customSpan: {
    color: theme.palette.text.primary2,
  },
  bottomGrid: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "12px 8px",
    background: theme.palette.background.default,
    boxShadow: `0px -2px 2px ${theme.palette.text.primary}`,
    position: "sticky",
    bottom: "0rem",
    [theme.breakpoints.down("sm")]: {
      bottom: "0.1rem",
    },
    zIndex: "100",
  },
}));

const MuiNavLink = withStyles((theme) => ({
  root: {
    color: theme.palette.anchor.primary,
    textDecoration: "none",
    "&:hover": {
      color: theme.palette.anchor.primary,
      textDecoration: "none",
    },
  },
}))(Link);

const Typography = withStyles((theme) => ({
  root: {
    color: theme.palette.text.primary2,
  },
}))(MuiTypography);

const genderTypes = [
  {
    type: "Female",
    description: "Kobieta",
  },
  {
    type: "Male",
    description: "Mężczyzna",
  },
];

export const nipValidator = (nip) => {
  if (typeof nip !== "string") {
    return false;
  }

  const nipWithoutDashes = nip.replace(/-/g, "");
  const reg = /^[0-9]{10}$/;
  if (reg.test(nipWithoutDashes) === false) {
    return false;
  }

  const dig = ("" + nipWithoutDashes).split("");
  const control =
    (6 * parseInt(dig[0], 10) +
      5 * parseInt(dig[1], 10) +
      7 * parseInt(dig[2], 10) +
      2 * parseInt(dig[3], 10) +
      3 * parseInt(dig[4], 10) +
      4 * parseInt(dig[5], 10) +
      5 * parseInt(dig[6], 10) +
      6 * parseInt(dig[7], 10) +
      7 * parseInt(dig[8], 10)) %
    11;

  if (parseInt(dig[9], 10) === control) {
    return true;
  }

  return false;
};

const ProfilePage = ({ history }) => {
  const my = useSelector((s) => s.my);
  const organization = useSelector((s) => s.organization);
  const accountType = useSelector((s) => s.organization?.accountType);
  const roles = useSelector((s) => s.roles);
  const isSimpleUser = useSelector(
    (s) => s.my && s.my.user && s.my.user.authorities === "ROLE_USER"
  );

  const allDataReady = organization && roles;

  const classes = useStyles();

  const [firstName, setFirstName] = useState((my && my.firstName) || "");
  const [lastName, setLastName] = useState((my && my.surname) || "");
  const [selectedGender, setSelectedGender] = useState((my && my.gender) || "");
  const [yearOfBirth, setYearOfBirth] = useState(
    (my && my.yearOfBirth && my.yearOfBirth.toString()) || ""
  );
  const [username, setUsername] = useState(
    (my && my.user && my.user.username) || ""
  );
  const [email, setEmail] = useState((my && my.user && my.user.email) || "");
  const avatarPath = (my && my.avatarPath) || "";
  const [newAvatarPath, setNewAvatarPath] = useState(null);
  const [avatarImage, setAvatarImage] = useState();

  const [name, setName] = useState("");
  const [street, setStreet] = useState("");
  const [zip, setZip] = useState("");
  const [city, setCity] = useState("");
  const [post, setPost] = useState("");
  const [phone, setPhone] = useState("");

  const [nameFV, setNameFV] = useState("");
  const [streetFV, setStreetFV] = useState("");
  const [zipFV, setZipFV] = useState("");
  const [cityFV, setCityFV] = useState("");
  const [nipFV, setNipFV] = useState("");
  const [postFV, setPostFV] = useState("");

  const [orgName, setOrgName] = useState("");
  const [orgMessage, setOrgMessage] = useState("");
  const [orgCodeRegistration, setOrgCodeRegistration] = useState("");
  const [selfRegistration, setSelfRegistration] = useState(false);
  const [userActivation, setUserActivation] = useState(false);
  const [orgCodeRequired, setOrgCodeRequired] = useState(false);
  const [busy, setBusy] = useState(false);
  const [displayHint, setDisplayHint] = useState(false);

  const [shippingInfoValid, setShippingInfoValid] = useState(false);
  const [billingInfoValid, setBillingInfoValid] = useState(false);

  const [openFormRemovingPanel, setOpenFormRemovingPanel] = useState(false);

  const [dataChanged, setDataChanged] = useState(false);

  const [disabledUploadButton, setDisabledUploadButton] = useState(true);
  const [disabledSaveProfileButton, setDisabledSaveProfileButton] = useState(
    false
  );

  useEffect(() => {
    setTimeout(() => {
      setDisabledUploadButton(false);
    }, [3500]);
  }, []);

  const dispatch = useDispatch();

  const removeMyAvatar = async () => {
    if (
      window.confirm(
        "Czy chcesz usunąć avatar? Niezapisane zmiany w profilu zostaną utracone."
      )
    ) {
      await dispatch(removeAvatar());

      dispatch(alertAdd({ text: "Usunięto avatar" }));
    }
  };

  const handleUploadedFiles = (files) => {
    if (files.length > 0) {
      const file = files[0];

      let reader = new FileReader();
      reader.onloadend = () => {
        setAvatarImage(file);
        setNewAvatarPath(reader.result);
      };

      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    if (organization) {
      setOrgName(organization.orgName || "");

      setOrgMessage(organization.orgMessage || "");
      setOrgCodeRegistration(organization.orgCodeRegistration || "");
      setOrgCodeRequired(organization.enableCodeRegistration || false);
      setUserActivation(organization.enableUserActivation || false);
      setSelfRegistration(organization.enableSelfRegistration || false);

      if (organization.billingInfo) {
        setNameFV(organization.billingInfo.name || "");
        setStreetFV(organization.billingInfo.street || "");
        setZipFV(organization.billingInfo.zipCode || "");
        setCityFV(organization.billingInfo.city || "");
        setNipFV(organization.billingInfo.taxId || "");
        setPostFV(organization.billingInfo.post || "");
      }
    }
  }, [organization]);

  useEffect(() => {
    if (my) {
      if (my.address) {
        setName(my.address.name || "");
        setStreet(my.address.street || "");
        setZip(my.address.zipCode || "");
        setCity(my.address.city || "");
        setPost(my.address.post || "");
        setPhone(my.address.phone || "");
      }
    }
  }, [my]);

  const save = async () => {
    setBusy(true);

    const avatarPath = newAvatarPath;
    if (avatarImage) {
      setDisabledSaveProfileButton(true);
      await dispatch(
        updateMyProfileExt({
          firstName,
          surname: lastName,
          ...(yearOfBirth ? { yearOfBirth: parseInt(yearOfBirth, 10) } : {}),
          ...(selectedGender ? { gender: selectedGender } : {}),
          phone,
          street,
          city,
          post,
          zip,
          avatarImage,
          address: {
            name,
            street,
            zipCode: zip,
            city,
            post,
            phone,
          },
        })
      );
    } else {
      await dispatch(
        updateMyProfile({
          firstName,
          surname: lastName,
          ...(yearOfBirth ? { yearOfBirth: parseInt(yearOfBirth, 10) } : {}),
          ...(selectedGender ? { gender: selectedGender } : {}),
          phone,
          street,
          city,
          post,
          zip,
          // avatarImage,
          address: {
            name,
            street,
            zipCode: zip,
            city,
            post,
            phone,
          },
        })
      );
    }

    if (checkPermission("orgDetails:change", roles)) {
      await dispatch(
        updateMyOrganization({
          accountType,
          orgName,
          orgMessage,
          orgCodeRegistration,
          enableUserActivation: userActivation,
          enableSelfRegistration: selfRegistration,
          enableCodeRegistration: orgCodeRequired,
          billingInfo: {
            name: nameFV,
            street: streetFV,
            zipCode: zipFV,
            city: cityFV,
            post: postFV,
            taxId: nipFV,
          },
        })
      );
    }

    setBusy(false);
    setDisabledSaveProfileButton(false);
    dispatch(alertAdd({ text: "Zaktualizowano profil" }));
  };

  const page = () =>
    !allDataReady ? (
      <div className={classes.root}>
        <AppBarTitle value="Profil" />
        <Loader
          loading={!allDataReady}
          text="Wczytywanie danych profilowych..."
        />
      </div>
    ) : (
      <Container className={classes.container}>
        <AppBarTitle value="Profil" />
        <h1 aria-hidden="false" aria-label="Profil" style={{ display: "none" }}>
          Profil
        </h1>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={8} lg={6} spacing={3}>
            <Typography variant="h6">
              {accountType === "PERSONAL"
                ? "Dane personalne"
                : "Dane osoby do kontaktu"}
            </Typography>
            <Grid item container sm={12} lg={8} xl={6} spacing={1}>
              <Grid item xs={12}>
                <TextField
                  label="Imię"
                  variant="outlined"
                  inputProps={{ maxLength: 255 }}
                  value={firstName}
                  fullWidth
                  inputProps={{ "aria-label": "Imię" }}
                  onChange={({ target: { value: firstName } }) => {
                    setDataChanged(true);
                    setFirstName(firstName);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Nazwisko"
                  variant="outlined"
                  fullWidth
                  inputProps={{ maxLength: 255 }}
                  value={lastName}
                  inputProps={{ "aria-label": "Nazwisko" }}
                  onChange={({ target: { value: lastName } }) => {
                    setDataChanged(true);
                    setLastName(lastName);
                  }}
                />
              </Grid>
              {accountType === "PERSONAL" && (
                <Grid item xs={12}>
                  <MuiPickersUtilsProvider
                    locale={plLocale}
                    utils={DateFnsUtils}
                  >
                    <KeyboardDatePicker
                      id="start-date-picker-dialog"
                      label="Rok urodzenia"
                      inputVariant="outlined"
                      maxDate={new Date()}
                      helperText={""}
                      fullWidth
                      value={yearOfBirth ? yearOfBirth : null}
                      onChange={(value) => {
                        setDataChanged(true);
                        let insertedYear = moment(value, "DD/MM/YYYY").year();
                        if (insertedYear < 1900) {
                          insertedYear = 1900;
                        } else if (new Date().getFullYear() < insertedYear) {
                          insertedYear = new Date().getFullYear();
                        }
                        setYearOfBirth(insertedYear.toString());
                      }}
                      inputProps={{ "aria-label": "Rok urodzenia" }}
                      views={["year"]}
                      okLabel="Zatwierdź"
                      clearLabel="Wyczyść"
                      cancelLabel="Anuluj"
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              )}
              <Grid item xs={12}>
                {accountType === "PERSONAL" && (
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id="select-outlined-label">Płeć</InputLabel>
                    <Select
                      labelId="select-outlined-label"
                      id="select-outlined"
                      value={selectedGender}
                      onChange={(event) => {
                        setDataChanged(true);
                        setSelectedGender(event.target.value);
                      }}
                      label="Płeć"
                    >
                      {genderTypes.map((genderType, index) => {
                        return (
                          <MenuItem key={index} value={genderType.type}>
                            {genderType.description}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                )}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Nazwa użytkownika"
                  variant="outlined"
                  disabled
                  fullWidth
                  value={username}
                  onChange={({ target: { value: username } }) => {
                    setUsername(username);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Email"
                  variant="outlined"
                  disabled
                  fullWidth
                  value={email}
                  onChange={({ target: { value: email } }) => {
                    setEmail(email);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container justify="left" alignItems="left">
                  <InputLabel style={{ color: "black" }}>
                    <span className={classes.customSpan}>
                      {" "}
                      Aktualne zdjęcie:{" "}
                    </span>
                    {avatarPath ? (
                      <img
                        style={{ marginLeft: "130px" }}
                        className={s.avatarImg}
                        src={avatarPath}
                      />
                    ) : (
                      <span style={{ fontWeight: "bold" }}>Brak</span>
                    )}
                  </InputLabel>
                </Grid>
              </Grid>
              {newAvatarPath && (
                <Grid item xs={12}>
                  <Grid container justify="left" alignItems="left">
                    <InputLabel style={{ color: "black" }}>
                      Nowe zdjęcie:{" "}
                      <img
                        style={{ marginLeft: "151px" }}
                        className={s.avatarImg}
                        src={newAvatarPath}
                      />
                    </InputLabel>
                  </Grid>
                </Grid>
              )}
              <Grid item xs={12}>
                <Grid container justify="left" alignItems="left">
                  <Button
                    variant="contained"
                    component="label"
                    disabled={disabledUploadButton}
                  >
                    {!disabledUploadButton && `Wgraj zdjęcie`}
                    {disabledUploadButton && <CircularProgress size={20} />}
                    <input
                      accept="image/*"
                      type="file"
                      hidden
                      onChange={(event) => {
                        const files = event.target.files;
                        setDataChanged(true);
                        const fileSize = files[0].size / 1024 / 1024;
                        if (files) {
                          if (!files[0].name.match(/.(jpg|jpeg|png|gif)$/i)) {
                            alert(
                              "Nieodpowiedni typ pliku. Prosimy wgrać plik z rozszerzeniem: jpg, jpeg, png, gif"
                            );
                            event.target.value = null;
                          } else if (fileSize > 20) {
                            alert("Zdjęcie przekracza 20 MB");
                            event.target.value = null;
                          } else {
                            handleUploadedFiles(files);
                            event.target.value = null;
                          }
                        }
                      }}
                    />
                  </Button>

                  <Button
                    onClick={removeMyAvatar}
                    disabled={!newAvatarPath && !avatarPath}
                  >
                    Usuń zdjęcie
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={8} lg={6} spacing={3}>
            <Typography variant="h6">Dane adresowe</Typography>
            <Grid item sm={12} lg={8} xl={6}>
              <ShippingAddressForm
                provideShippingData={true}
                name={name}
                setName={setName}
                street={street}
                setStreet={setStreet}
                city={city}
                setCity={setCity}
                zip={zip}
                setZip={setZip}
                post={post}
                setPost={setPost}
                phone={phone}
                setPhone={setPhone}
                showNotes={false}
                isValidListener={setShippingInfoValid}
                setDataChanged={setDataChanged}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} sm={8} lg={6} spacing={3}>
            <Typography variant="h6">Dane do faktury</Typography>
            <Grid item sm={12} lg={8} xl={6}>
              <BillingAddressForm
                nameFV={nameFV}
                setNameFV={setNameFV}
                streetFV={streetFV}
                setStreetFV={setStreetFV}
                cityFV={cityFV}
                setCityFV={setCityFV}
                zipFV={zipFV}
                setZipFV={setZipFV}
                postFV={postFV}
                setPostFV={setPostFV}
                nipFV={nipFV}
                setNipFV={setNipFV}
                showNotes={false}
                isValidListener={setBillingInfoValid}
                setDataChanged={setDataChanged}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={3} direction="row-reverse">
          {isSimpleUser && (
            <Can
              permission="account:remove"
              ok={() => (
                <Grid
                  item
                  xs={12}
                  sm={8}
                  lg={6}
                  spacing={3}
                  style={{ paddingBottom: "24px" }}
                >
                  {!my.anonimizationRequestedAt ? (
                    <Grid item xs={12} container justify="flex-end">
                      <MuiNavLink
                        href="#"
                        onClick={() => setOpenFormRemovingPanel(true)}
                      >
                        Poproś o usunięcie konta
                      </MuiNavLink>
                    </Grid>
                  ) : (
                    `Konto w trakcie usuwania`
                  )}

                  <ConfirmDialogMUI
                    handleClose={() => {
                      setOpenFormRemovingPanel(false);
                    }}
                    open={openFormRemovingPanel}
                    text={"Czy na pewno usunąć konto?"}
                    textDetails={
                      "Dostęp do danych zostanie bezpowrotnie utracony. Usunięcie konta może zająć kilka dni."
                    }
                    yesAction={() => dispatch(anonimizationRequest())}
                    noAction={() => setOpenFormRemovingPanel(false)}
                  />
                </Grid>
              )}
            />
          )}
        </Grid>
        <Grid
          item
          container
          xs={12}
          direction={"row"}
          className={classes.bottomGrid}
          elevation={15}
        >
          <Grid item>
            <Button
              type="cancel"
              variant={"contained"}
              // style={{
              //   backgroundColor: "lightgrey",
              //   color: "black",
              // }}
              onClick={() => history.push("/")}
            >
              Anuluj
            </Button>
          </Grid>
          <Grid item>
            <Button
              type="submit"
              color="primary"
              variant={"contained"}
              style={{
                // backgroundColor: "blue",
                // color: "white",
                marginLeft: "10px",
              }}
              disabled={
                // (!nipValidator(nipFV.toString()) && nipFV !== "") ||
                !shippingInfoValid || !billingInfoValid || busy
              }
              onClick={() => {
                if (!nipValidator(nipFV.toString()) && nipFV !== "") {
                  dispatch(
                    alertAdd({
                      text: "Podany numer NIP jest nieprawidłowy",
                      isError: true,
                    })
                  );
                } else {
                  if (
                    orgCodeRegistration.length >= 8 ||
                    orgCodeRequired === false
                  ) {
                    save();
                    setDisplayHint(false);
                    setDataChanged(false);
                  } else {
                    setDisplayHint(true);
                  }
                }
              }}
            >
              {!busy && "Zapisz"}
              {busy && (
                <CircularProgress style={{ color: "#ffffff" }} size={20} />
              )}
            </Button>
          </Grid>
        </Grid>
        <Prompt
          when={dataChanged}
          message={(params) =>
            params.pathname !== window.location.pathname && dataChanged
              ? "Wprowadzone dane nie zostały zapisane. Czy na pewno chcesz opuścić stronę?"
              : true
          }
        ></Prompt>
      </Container>
    );

  const redirect = () => <Redirect to="/login" />;

  return <Can permission="profilePage:view" ok={page} not={redirect} />;
};

export default ProfilePage;
