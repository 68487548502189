import React, { useState, useEffect } from "react";
import { getAxiosInstance } from "../../redux/common";
import Loader from "../../components/loader";
import Button from "@material-ui/core/Button";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import {
  InputLabel,
  MenuItem,
  FormControl,
  FormControlLabel,
  Select,
  TextField,
  Checkbox,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    display: "block",
  },
}));

const Orders = () => {
  const [orders, setOrders] = useState();
  const [status, setStatus] = useState("paid");
  const [search, setSearch] = useState("");
  const [showCodes, setShowCodes] = useState(false);
  const history = useHistory();
  const classes = useStyles();

  const loadOrders = async () => {
    try {
      const ordersResult = await getAxiosInstance().get(
        "/order/v1/admin/order/filter",
        {
          params: {
            loadCodes: showCodes,
            status: status,
            rows: 1000,
            orderField: "created",
            orderDirection: "desc",
          },
        }
      );
      setOrders(ordersResult.data.orders);
    } catch (e) {
      console.error("orders-list error", e);
      // should be 401 only
      if (
        e.response &&
        (e.response.status === 401 || e.response.status === 403)
      ) {
        history.push("/owner-panel/login");
      }
      setOrders(null);
    }
  };

  useEffect(() => {
    if (orders === undefined) {
      loadOrders();
    }
  });

  useEffect(() => {
    if (showCodes) {
      loadOrders();
    }
  }, [showCodes]);

  const handleChange = (event) => {
    setStatus(event.target.value);
    setShowCodes(false);
    setOrders(undefined);
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const handleShowCodesChange = (event) => {
    setShowCodes(event.target.checked);
  };

  const OrderRows = () => {
    const DateFormatter = (props) => {
      const string = props.value;

      var options = {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      };
      const date =
        string[string.length - 1] !== "Z" ? string.concat("Z") : string;

      return new Date(date).toLocaleDateString(
        [new Intl.Locale("pl-PL")],
        options
      );
    };

    if (orders) {
      return orders.map((order) => {
        if (
          !search ||
          order.tenantName.toLowerCase().includes(search.toLowerCase()) ||
          (showCodes &&
            order.codes &&
            order.codes.find((code) =>
              code.toLowerCase().includes(search.toLowerCase())
            ))
        ) {
          return (
            <TableRow key={order.orderId}>
              <TableCell component="th" scope="row">
                {order.orderNumber}
              </TableCell>
              <TableCell align="right">
                <DateFormatter value={order.createdTs} />
              </TableCell>
              <TableCell align="right">{order.tenantName}</TableCell>
              <TableCell align="right">
                {(() => {
                  switch (order.status) {
                    case "paid":
                      return "Opłacone";
                    case "ready":
                      return "Skompletowany";
                    case "completed":
                      return "Wysłane";
                    case "created":
                      return "Nieopłacone";
                    case "cancelled":
                      return "Anulowane";
                    default:
                      return order.status;
                  }
                })()}
              </TableCell>
              {showCodes && (
                <TableCell align="right">
                  {(() => {
                    if (!order.codes) return null;
                    return order.codes.join(", ");
                  })()}
                </TableCell>
              )}
              <TableCell align="right">
                <Button
                  color="primary"
                  component={RouterLink}
                  to={`/owner-panel/orders/${order.tenantId}&${order.orderId}`}
                >
                  Szczegóły
                </Button>
              </TableCell>
            </TableRow>
          );
        } else {
          return null;
        }
      });
    } else {
      return null;
    }
  };

  if (orders !== undefined) {
    return (
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="orders list">
          <TableHead>
            <TableRow>
              <TableCell>Nr zamówienia</TableCell>
              <TableCell align="right">Data</TableCell>
              <TableCell align="right">Zamawiający</TableCell>
              <TableCell align="right">
                Status zamówienia
                <FormControl className={classes.formControl}>
                  <InputLabel id="status-filter-label">Filtruj</InputLabel>
                  <Select
                    labelId="status-filter-label"
                    id="status-filter-select"
                    value={status}
                    onChange={handleChange}
                  >
                    <MenuItem value={"paid"}>opłacone</MenuItem>
                    <MenuItem value={"ready"}>skompletowane</MenuItem>
                    <MenuItem value={"completed"}>wysłane</MenuItem>
                    <MenuItem value={"created"}>nieopłacone</MenuItem>
                    <MenuItem value={"cancelled"}>anulowane</MenuItem>
                  </Select>
                </FormControl>
              </TableCell>
              {showCodes && <TableCell>Przypisane kody</TableCell>}
              <TableCell align="right">
                <TextField
                  fullWidth
                  id="standard-basic"
                  label="Szukaj"
                  value={search}
                  onChange={handleSearchChange}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showCodes}
                      onChange={handleShowCodesChange}
                      name="checkedA"
                    />
                  }
                  label="Pokaż kody"
                />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <OrderRows />
          </TableBody>
        </Table>
      </TableContainer>
    );
  } else {
    return <Loader loading={true} text="Wczytywanie danych" />;
  }
};

export default Orders;
