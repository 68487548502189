import React from 'react'

import RowControl from '../form-controls/row-control'

const FormFiller = ({onUpdate, rows}) => {
  // const [selectedItem, setSelectedItem] = useState()
  // const [rowsWithData, setRowsWithData] = useState([])
  // const [newRows, setNewRows] = useState([])

  // useEffect(() => {
  //   onUpdate && onUpdate(rows)
  // }, [onUpdate, rows])

  // useEffect(() => {
  //   setRowsWithData(JSON.parse(JSON.stringify(rows)))
  // }, [rows])

  // useEffect(() => {
  //   onUpdate(newRows)
  // }, [onUpdate, newRows])

  //
  // useEffect(() => {
  //   if (form) {
  //
  //   }
  // }, [form])

  const updateValues = ({id, value}) => {
    if (onUpdate) {
      onUpdate({id, value})
    }

    // setRows(rows.map((row, index) => {
    //   if (index === rowIndex) {
    //     return rowItems
    //   }
    //   return row;
    // }))
  }

  // const rowsToJson = rows => {
  //   return {
  //     children: rows,
  //   }
  // }

  return (
    <div className="columns">
      <div className="column">
        <div>
          {
            rows.map((row, rowIndex) => {
              return <RowControl
                key={rowIndex}
                updateValues={updateValues}
                items={row}
                dragMode="none"
              />
            })
          }
        </div>
      </div>
    </div>
  )
}

export default FormFiller
