import React, { useEffect, useState } from "react";
import { NavLink, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import Can from "../../components/can";
import {
  fetchProteges,
  addProtege,
  editProtege,
  removeProtege,
} from "../../redux/actions";
import { Box } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useTheme from "@material-ui/core/styles/useTheme";
import Grid from "@material-ui/core/Grid";
// import TableCell from "@material-ui/core/TableCell";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "@material-ui/core/Button";
import { buyCartItem, removeCartItem } from "../../redux/shopping-cart/actions";
import { connect } from "react-redux";
import { alertAdd } from "../../redux/actions";
import AppBarTitle from "../../components/app-bar-title";
import GroupIcon from "@material-ui/icons/Group";
import DeleteIcon from "@material-ui/icons/Delete";
import TextField from "@material-ui/core/TextField";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import ProtegesModal from "./proteges-handler-modal";
import { ConfirmDialogMUI } from "../../components/confirm-dialog";
import LinearProgress from "@material-ui/core/LinearProgress";
import Container from "@material-ui/core/Container";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TablePagination from "@material-ui/core/TablePagination";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import MenuItem from "@material-ui/core/MenuItem";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import plLocale from "date-fns/locale/pl";
import * as moment from "moment";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import InputLabel from "@material-ui/core/InputLabel";
import { CircularProgress } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import { LockOpen, Visibility } from "@material-ui/icons";
import Loader from "../../components/loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "@material-ui/core/Select";
import { ReactComponent as TrashIcon } from "../../assets/trash.svg";
const genderTypes = [
  {
    type: "Female",
    description: "Kobieta",
  },
  {
    type: "Male",
    description: "Mężczyzna",
  },
];

const MuiHeaderTableCell = withStyles((theme) => ({
  root: {
    borderBottom: "none",
    padding: "16px 16px 0px 16px",
    fontSize: "16px",
    color: theme.palette.text.alternative3,
    letterSpacing: "0.24px",
  },
}))(TableCell);

const MuiBodyTableCell = withStyles((theme) => ({
  root: {
    borderBottom: "none",
    color: theme.palette.text.alternative2,
    letterSpacing: "0.27px",
    fontSize: "18px",
    fontWeight: "bold",
  },
}))(TableCell);

const NO_ID_TO_REMOVE = -1;

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  rootProgress: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  protegeButton: {
    // backgroundColor: "#0000FF",
    borderRadius: "4px",
  },

  gridTable: {
    margin: "12px auto",
  },

  protegeGrid: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "12px 8px",
    boxShadow: `0px 1px 0px ${theme.palette.text.primary}20`,
    position: "sticky",
    top: "6rem",
    [theme.breakpoints.down("sm")]: {
      top: "6rem",
    },
    background: theme.palette.background.default,
    zIndex: "100",
  },
  protegeTable: {
    padding: theme.spacing(2),
  },
  pad: {
    padding: "10px",
    "@media (min-width: 600px)": {
      padding: "20px",
    },
  },
  mainGrid: {
    height: "86vh",
    padding: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
  },
  contentGrid: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
  },
  itemGrid: {
    display: "flex",
    justifyContent: "center",
  },
  label: {
    fontSize: "24px",
    lineHeight: "20px",
    color: "#707070",
  },
  button: {
    background: "#0000FF",
    borderRadius: "4px",
  },
  select: {
    "&:after": {
      borderBottomColor: theme.palette.text.item,
    },
    "& .MuiSvgIcon-root": {
      color: theme.palette.text.item,
    },
  },
  focused: {
    "& label": {
      color: theme.palette.text.item,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: theme.palette.text.item,
        color: theme.palette.text.alternative3,
      },
      "& .Mui-focused": {
        borderColor: theme.palette.text.alternative3,
        color: theme.palette.text.alternative3,
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.text.alternative3,
        color: theme.palette.text.alternative3,
      },
    },
  },
}));

const headerCells = [
  { id: "firstName", label: "Imię" },
  { id: "surname", label: "Nazwisko" },
  { id: "yearOfBirth", label: "Rok urodzenia" },
  { id: "gender", label: "Płeć" },
];

const Proteges = ({
  alertAdd,
  fetchProteges,
  addProtege,
  editProtege,
  removeProtege,
  history,
  protegesFetching,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const proteges = useSelector((s) => s.proteges);
  const protegeAdded = useSelector((s) => s.protegeAdded);
  const protegeRemoved = useSelector((s) => s.protegeRemoved);
  const protegeEdited = useSelector((s) => s.protegeEdited);
  const protegesAll = useSelector((s) => s.protegesAll);

  const [protegeToEdit, setProtegeToEdit] = useState({});
  const [protegeRemoving, setProtegeRemoving] = useState(false);

  const [openProtegesModal, setOpenProtegesModal] = useState(false);
  const [protegeHandlerType, setProtegeHandlerType] = useState("");
  const [tmpProteges, setTmpProteges] = useState([]);

  const [openProtegeRemovingPanel, setOpenProtegeRemovingPanel] = useState(
    false
  );

  const [idToRemove, setIdToRemove] = useState(NO_ID_TO_REMOVE);

  const [busy, setBusy] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [surname, setSurname] = useState("");
  const [yearOfBirth, setYearOfBirth] = useState("");
  const [gender, setGender] = useState("");

  const [isValid, setIsValid] = useState(false);

  const pages = [30, 50, 100, 200, 500];
  const [pageNr, setPageNr] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(null);

  useEffect(() => setIsValid(firstName && surname && yearOfBirth && gender), [
    firstName,
    surname,
    yearOfBirth,
    gender,
  ]);

  useEffect(() => {
    fetchProteges({
      page: pageNr,
      pageSize: rowsPerPage,
      ...(orderBy
        ? { sort: `${order === "asc" ? orderBy : "-" + orderBy}` }
        : {}),
    });

    // if (protegeRemoved) {
    //   setProtegeRemoving(false);
    // }
  }, [
    protegeAdded,
    protegeEdited,
    protegeRemoved,
    pageNr,
    rowsPerPage,
    orderBy,
    order,
  ]);

  useEffect(() => {
    setTmpProteges(
      proteges
      // !orderBy ? proteges.sort((p1, p2) => p2.id - p1.id) : proteges
    );
  }, [proteges]);

  const askToRemove = (protegeId) => {
    setIdToRemove(protegeId);
  };

  const handleChangePage = (event, newPage) => {
    setPageNr(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPageNr(0);
  };

  const handleSortRequest = (cellId) => {
    const isAsc = orderBy === cellId && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(cellId);
  };

  const page = () =>
    protegeRemoving || protegesFetching ? (
      <Box className={classes.root}>
        {" "}
        <AppBarTitle value="Podopieczni" />
        <h1
          aria-hidden="false"
          aria-label="Profil dziecka"
          style={{ display: "none" }}
        >
          Profil dziecka
        </h1>
        <Box display="flex" justifyContent="center">
          {
            <>
              <div className={classes.rootProgress}>
                <LinearProgress />
              </div>
            </>
          }
        </Box>
      </Box>
    ) : (
      <Container style={{ maxWidth: "3000px", minWidth: "300px" }}>
        {tmpProteges?.length > 0 ? (
          <Grid container>
            <h1
              aria-hidden="false"
              aria-label="Profil dziecka"
              style={{ display: "none" }}
            >
              Profil dziecka
            </h1>
            <Grid xs={12} item className={classes.protegeGrid} elevation={15}>
              <Button
                variant="contained"
                color="primary"
                className={classes.protegeButton}
                onClick={() => {
                  setProtegeToEdit(null);
                  setProtegeHandlerType("add");
                  setOpenProtegesModal(true);
                }}
              >
                Dodaj profil dziecka
              </Button>
              {openProtegesModal && (
                <ProtegesModal
                  openProtegesModal={openProtegesModal}
                  protegeHandlerType={protegeHandlerType}
                  handleClose={() => {
                    setOpenProtegesModal(false);
                    // setProtegeRemoving(true);
                    // setTimeout(() => {
                    //   setProtegeRemoving(false);
                    // }, 3000);
                  }}
                  protegeToEdit={protegeToEdit}
                  addProtege={addProtege}
                  editProtege={editProtege}
                />
              )}
            </Grid>
            <Grid item xs={10} xl={8} className={classes.gridTable}>
              <TableContainer style={{ marginBottom: "50px" }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <MuiHeaderTableCell>Lp.</MuiHeaderTableCell>
                      {headerCells.map(({ label, id }) => (
                        <MuiHeaderTableCell key={id}>
                          <TableSortLabel
                            active={orderBy === id}
                            direction={orderBy === id ? order : "asc"}
                            onClick={() => {
                              handleSortRequest(id);
                            }}
                          >
                            {label}
                          </TableSortLabel>
                        </MuiHeaderTableCell>
                      ))}
                      <MuiHeaderTableCell>Opcje</MuiHeaderTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tmpProteges.map((protege, index) => (
                      <TableRow key={index}>
                        <MuiBodyTableCell component="th" scope="row">
                          {index + 1 + pageNr * rowsPerPage}
                        </MuiBodyTableCell>
                        <MuiBodyTableCell align="right">
                          {protege.firstName}
                        </MuiBodyTableCell>
                        <MuiBodyTableCell align="right">
                          {protege.surname}
                        </MuiBodyTableCell>
                        <MuiBodyTableCell align="right">
                          {protege.yearOfBirth}
                        </MuiBodyTableCell>
                        <MuiBodyTableCell align="right">
                          {
                            genderTypes.find(
                              ({ type }) => type === protege.gender
                            ).description
                          }
                        </MuiBodyTableCell>
                        <MuiBodyTableCell align="right">
                          <Tooltip
                            placement="top"
                            title="Edytuj profil dziecka"
                          >
                            <Button
                              variant="outlined"
                              size="small"
                              aria-label="Edytuj profil dziecka"
                              style={{
                                color: theme.palette.text.alternative2,
                                borderRadius: "8px",
                                border: `1px solid ${theme.palette.text.alternative2}`,
                                fontWeight: "bold",
                              }}
                              onClick={() => {
                                setProtegeToEdit(protege);
                                setProtegeHandlerType("edit");
                                setOpenProtegesModal(true);
                              }}
                            >
                              EDYTUJ
                            </Button>
                          </Tooltip>

                          <Tooltip placement="top" title="Usuń profil dziecka">
                            <IconButton
                              aria-label="Usuń profil dziecka"
                              size="small"
                              onClick={() => {
                                askToRemove(protege.id);
                                setOpenProtegeRemovingPanel(true);
                              }}
                            >
                              <TrashIcon
                                role="img"
                                aria-hidden="false"
                                alt="Usuń profil dziecka"
                                title="Usuń profil dziecka"
                              />
                            </IconButton>
                          </Tooltip>
                          {idToRemove !== NO_ID_TO_REMOVE &&
                            idToRemove === protege.id && (
                              <ConfirmDialogMUI
                                handleClose={() => {
                                  setOpenProtegeRemovingPanel(false);
                                }}
                                open={openProtegeRemovingPanel}
                                text={`Czy na pewno usunąć profil dziecka: ${protege.firstName} ${protege.surname}?`}
                                yesAction={() => {
                                  // setProtegeRemoving(true);
                                  removeProtege(idToRemove);
                                  setIdToRemove(NO_ID_TO_REMOVE);
                                }}
                                noAction={() => setIdToRemove(NO_ID_TO_REMOVE)}
                              />
                            )}
                        </MuiBodyTableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <TablePagination
                  component="div"
                  page={pageNr}
                  rowsPerPageOptions={pages}
                  rowsPerPage={rowsPerPage}
                  count={protegesAll}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  labelRowsPerPage={"Wierszy na stronę:"}
                  labelDisplayedRows={({ from, to, count }) =>
                    `${from}-${to} z ${count}`
                  }
                  className={classes.select}
                />
              </TableContainer>
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "64px",
            }}
          >
            <AppBarTitle value="Podopieczni" />
            <Grid
              item
              container
              xs={12}
              sm={10}
              md={6}
              lg={4}
              spacing={3}
              // className={classes.contentGrid}
            >
              <Grid item xs={12}>
                {/* // className={classes.itemGrid}> */}
                <Box>
                  <Box mt={2}>
                    <Box mt={1}>
                      <Typography
                        variant="h5"
                        style={{
                          fontWeight: "500",
                          fontSize: "18",
                          color: theme.palette.item,
                        }}
                      >
                        Nie masz jeszcze podopiecznego
                      </Typography>
                    </Box>
                    <Box mt={1}>
                      <Typography
                        variant="h5"
                        style={{
                          fontWeight: "600",
                          // fontSize: theme.typography.text,
                          color: theme.palette.text.alternative2,
                        }}
                      >
                        Dodaj profil dziecka
                      </Typography>
                    </Box>
                    <Box mt={1}>
                      <TextField
                        label="Imię"
                        className={classes.focused}
                        variant="outlined"
                        inputProps={{ maxLength: 255 }}
                        value={firstName}
                        fullWidth
                        onChange={({ target: { value: firstName } }) => {
                          setFirstName(firstName);
                        }}
                      />
                    </Box>
                    <Box mt={1}>
                      <TextField
                        label="Nazwisko"
                        className={classes.focused}
                        variant="outlined"
                        inputProps={{ maxLength: 255 }}
                        value={surname}
                        fullWidth
                        onChange={({ target: { value: lastName } }) => {
                          setSurname(lastName);
                        }}
                      />
                    </Box>
                    <Box mt={1}>
                      <MuiPickersUtilsProvider
                        locale={plLocale}
                        utils={DateFnsUtils}
                      >
                        <KeyboardDatePicker
                          id="start-date-picker-dialog"
                          className={classes.focused}
                          label="Rok urodzenia"
                          inputVariant="outlined"
                          fullWidth
                          maxDate={new Date()}
                          helperText={""}
                          value={yearOfBirth ? yearOfBirth : null}
                          onChange={(value) => {
                            let insertedYear = moment(
                              value,
                              "DD/MM/YYYY"
                            ).year();
                            if (insertedYear < 1900) {
                              insertedYear = 1900;
                            } else if (
                              new Date().getFullYear() < insertedYear
                            ) {
                              insertedYear = new Date().getFullYear();
                            }
                            setYearOfBirth(insertedYear.toString());
                          }}
                          views={["year"]}
                          okLabel="Zatwierdź"
                          clearLabel="Wyczyść"
                          cancelLabel="Anuluj"
                        />
                      </MuiPickersUtilsProvider>
                    </Box>
                    <Box mt={1}>
                      <FormControl
                        fullWidth
                        variant="outlined"
                        className={classes.focused}
                      >
                        <InputLabel id="select-outlined-label">Płeć</InputLabel>
                        <Select
                          labelId="select-outlined-label"
                          id="select-outlined"
                          value={gender}
                          fullWidth
                          onChange={(event) => setGender(event.target.value)}
                          label="Płeć"
                        >
                          {genderTypes.map((genderType, index) => {
                            return (
                              <MenuItem key={index} value={genderType.type}>
                                {genderType.description}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                style={{ display: "flex", justifyContent: "center" }}
              >
                {/* <Grid item xs={12} className={classes.itemGrid}> */}
                <Button
                  // className={classes.button}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setFirstName("");
                    setSurname("");
                    setYearOfBirth("");
                    setGender("");
                    addProtege({
                      firstName,
                      surname,
                      yearOfBirth: parseInt(yearOfBirth),
                      gender,
                    });
                  }}
                  disabled={!isValid}
                >
                  DODAJ PROFIL DZIECKA
                </Button>
              </Grid>{" "}
              {openProtegesModal && (
                <ProtegesModal
                  openProtegesModal={openProtegesModal}
                  protegeHandlerType={protegeHandlerType}
                  handleClose={() => {
                    setOpenProtegesModal(false);
                    // setProtegeRemoving(true);
                    // setTimeout(() => {
                    //   setProtegeRemoving(false);
                    // }, 3000);
                  }}
                  protegeToEdit={protegeToEdit}
                  addProtege={addProtege}
                  editProtege={editProtege}
                />
              )}
            </Grid>
          </Grid>
        )}
      </Container>
    );
  const redirect = () => <Redirect to="/login" />;

  return <Can permission="proteges:page" ok={page} not={redirect} />;
};

const mapStateToProps = (state) => ({
  // proteges: state.orgUsers,
  protegesFetching: state.protegesFetching,
});

const mapDispatchToProps = (dispatch) => ({
  fetchProteges: (payload) => dispatch(fetchProteges(payload)),
  addProtege: (payload) => dispatch(addProtege(payload)),
  editProtege: (protegeId, payload) =>
    dispatch(editProtege(protegeId, payload)),
  removeProtege: (protegeId) => dispatch(removeProtege(protegeId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Proteges);
