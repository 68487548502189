const ProductType = {
  SMELLTASTE_TEST: "SMELLTASTE_TEST",
  SMELL_TEST: "SMELL_TEST",
  TASTE_TEST: "TASTE_TEST",
};

const ProductTypeToName = {
  SMELLTASTE_TEST: "Węch i smak",
  SMELL_TEST: "Węch",
  TASTE_TEST: "Smak",
};

export { ProductType, ProductTypeToName };
