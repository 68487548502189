import { useEffect } from "react";

const OrderStatusWebsocket = ({ start, onPaid }) => {
  let ws;

  useEffect(() => {
    if (start) {
      const domain = window.location.hostname;
      const proto = window.location.protocol === "https:" ? "wss" : "ws";
      const port = window.location.port ? ":" + window.location.port : "";

      ws = new WebSocket(`${proto}://${domain}${port}/order/v1/websocket`);

      ws.onmessage = function (e) {
        const data = JSON.parse(e.data);
        if (data._type === "OrderStatusChangedWSMessage") {
          if (data.newStatus === "paid") {
            onPaid(data);
          }
        }
      };
    }

    return () => {
      if (ws) {
        ws.close();
      }
    };
  }, [start]);

  return null;
};

export default OrderStatusWebsocket;
