import React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import NewOrder from "./order-new";
import OrderDetails from "./order-details";
import OrdersList from "./orders-list";

function Orders(props) {
  const { path, url } = useRouteMatch();
  const { prices } = props;

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <OrdersList />
      </Route>
      <Route exact path={`${path}/new`}>
        <NewOrder prices={prices} />
      </Route>
      <Route path={`${path}/new/:tenantId/:email`}>
        <NewOrder prices={prices} />
      </Route>
      <Route path={`${path}/:tenantId&:orderId`}>
        <OrderDetails />
      </Route>
      <Route path="*">
        <Redirect to={`${path}`} />
      </Route>
    </Switch>
  );
}

export default Orders;
