import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { nipValidator } from "../../pages/profile";

const useStyles = makeStyles((theme) => ({
  cssLabelGreen: {
    color: "green",
  },

  cssLabelRed: {
    color: "red",
  },

  cssOutlinedInputGreen: {
    "&$cssFocused $notchedOutlineGreen": {
      borderColor: `${theme.palette.primary.main} !important`,
    },
  },

  cssOutlinedInputRed: {
    "&$cssFocused $notchedOutlineRed": {
      borderColor: `${theme.palette.primary.main} !important`,
    },
  },

  cssFocused: {},

  notchedOutlineGreen: {
    borderWidth: "1px",
    borderColor: "green !important",
  },
  notchedOutlineRed: {
    borderWidth: "1px",
    borderColor: "red !important",
  },
}));

const NipInput = ({
  globalTheme,
  nipCode,
  setNipCode,
  isValidListener,
  disabled,
  setDataChanged,
}) => {
  const classes = useStyles();

  const [valid, setValid] = useState(nipValidator(nipCode));
  const [nipLostFocus, setNipLostFocus] = useState(false);
  useEffect(() => {
    let isOk;
    if (nipValidator(nipCode) || nipCode === "") {
      isOk = true;
    } else {
      isOk = false;
    }

    setValid(isOk);

    isValidListener && isValidListener(isOk);
  }, [nipCode]);

  const maxNIPLengthCheck = (object) => {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(
        0,
        object.target.maxLength
      );
    }
  };

  return (
    <TextField
      label="NIP"
      variant="outlined"
      inputProps={{
        maxLength: 10,
        "aria-label": "NIP",
      }}
      onInput={maxNIPLengthCheck}
      // type="number"
      value={nipCode || ""}
      required={true}
      error={nipLostFocus && !nipValidator(nipCode)}
      helperText={
        nipLostFocus &&
        !(nipValidator(nipCode) || nipCode === "") &&
        "Wprowadzony numer NIP jest nieprawidłowy"
      }
      InputLabelProps={{
        classes: globalTheme === "light" && {
          root: nipValidator(nipCode)
            ? classes.cssLabelGreen
            : classes.cssLabelRed,
          focused: nipLostFocus && classes.cssFocused,
        },
      }}
      InputProps={{
        classes: globalTheme === "light" && {
          root: nipValidator(nipCode)
            ? classes.cssOutlinedInputGreen
            : classes.cssOutlinedInputRed,
          focused: classes.cssFocused,
          notchedOutline: nipValidator(nipCode)
            ? classes.notchedOutlineGreen
            : classes.notchedOutlineRed,
        },
        inputMode: "numeric",
      }}
      fullWidth
      onChange={({ target: { value: nip } }) => {
        const regex = /^[0-9\b]+$/;

        if (nip === "" || regex.test(nip)) {
          setDataChanged(true);
          setNipLostFocus(false);
          setNipCode(nip);
        }
      }}
      onFocus={() => setNipLostFocus(false)}
      onBlur={() => setNipLostFocus(true)}
    />
  );
};

export default NipInput;
