import React, { useEffect, useState } from "react";
import Can from "../../../components/can";
import { Redirect, useLocation, Prompt } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import { Box } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { useDispatch, useSelector } from "react-redux";

import {
  addItemToCart,
  editItemCart,
  fetchOrganization,
} from "../../../redux/actions";
import TestCardComponent from "./price-component";
import { ProductType } from "./const";
import { fetchPriceList } from "../../../redux/shopping-cart/actions";
import ShippingAddressForm from "../../../components/address-form/shipping-info";
import BillingAddressForm from "../../../components/address-form/billing-info";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import s from "./index.module.css";
import AppBarTitle from "../../../components/app-bar-title";
import { fetchMyProfile } from "../../../redux/person/actions";
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import makeStyles from "@material-ui/core/styles/makeStyles";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: "5000px",
    minWidth: "300px",
    padding: "24px 0px 0px 0px",
  },
  bottomGrid: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "12px 8px",
    background: theme.palette.background.default,
    boxShadow: `0px -2px 2px ${theme.palette.text.primary}`,
    position: "sticky",
    bottom: "0rem",
    zIndex: "100",
  },
  bottomGrid1: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "12px 20px",
    background: "#FFFFFF",
  },
  gridCard: {
    margin: "12px auto",
    display: "flex",
    flexDirection: "row",
    "@media (max-width: 1000px)": {
      flexDirection: "column",
      flexWrap: "nowrap",
    },
    marginTop: "20px",
  },
  typography: {
    color: theme.palette.text.alternative3,
  },
}));

const TestKitPage = ({ history }) => {
  const location = useLocation();
  const classes = useStyles();

  const [smellTestAmount, setSmellTestAmount] = useState(0);
  const [tasteTestAmount, setTasteTestAmount] = useState(0);
  const [tasteAndSmellTestAmount, setTasteAndSmellTestAmount] = useState(0);

  const [name, setName] = useState("");
  const [street, setStreet] = useState("");
  const [zip, setZip] = useState("");
  const [city, setCity] = useState("");
  const [post, setPost] = useState("");
  const [phone, setPhone] = useState("");

  const [notes, setNotes] = useState("");

  const [nameFV, setNameFV] = useState("");
  const [streetFV, setStreetFV] = useState("");
  const [zipFV, setZipFV] = useState("");
  const [cityFV, setCityFV] = useState("");
  const [nipFV, setNipFV] = useState("");
  const [postFV, setPostFV] = useState("");
  const [notesFV, setNotesFV] = useState("");

  const [deliveryInfo, setDeliveryInfo] = useState("");
  const [billingInfo, setBillingInfo] = useState("");

  const [provideShippingData, setProvideShippingData] = useState(true);
  const [provideFVData, setProvideFVData] = useState(false);

  const [valid, setValid] = useState(false);

  const [shippingInfoValid, setShippingInfoValid] = useState(false);
  const [billingInfoValid, setBillingInfoValid] = useState(false);

  const [
    saveDeliveryInfoAsAddressData,
    setSaveDeliveryInfoAsAddressData,
  ] = useState(false);
  const [
    saveBillingInfoInOrganization,
    setSaveBillingInfoInOrganization,
  ] = useState(false);

  const [cartItemEdition, setCartItemEdition] = useState(false);

  const [copyDataFromProfile, setCopyDataFromProfile] = useState("");

  const dispatch = useDispatch();

  const [dataChanged, setDataChanged] = useState(false);

  const [copyShippingData, setCopyShippingData] = useState(false);
  const [copyBillingData, setCopyBillingData] = useState(false);

  const prices = useSelector((s) => s.priceList);
  const pricesLength = Object.keys(prices).length;

  const my = useSelector((s) => s.my);
  const accountType = useSelector((s) => s.organization?.accountType);
  const billingInfoFromOrganization = useSelector(
    (s) => (s.organization && s.organization.billingInfo) || ""
  );

  const globalTheme = useSelector((s) => s.globalTheme || "light");
  const [loaderOn, setLoaderOn] = useState(false);

  const cartTestItems = [
    {
      title: "Test smaku",
      iconRender: () => (
        <></>
        // <ShoppingCartOutlinedIcon fontSize="large" style={{ color: "#00346A" }} />
      ),
      url: "/test-kit",
      text:
        "Test do samodzielnego wykonania, oceniający stan zmysłu smaku (1 szt.)",
      productType: ProductType.TASTE_TEST,
      value: tasteTestAmount,
      setValue: (value) => setTasteTestAmount(value),
    },
    {
      title: "Test węchu",
      iconRender: () => (
        <></>
        // <FormatListBulletedOutlinedIcon
        //   fontSize="large"
        //   style={{ color: "#00346A" }}
        // />
      ),
      url: "/tests/set/add",
      text:
        "Test do samodzielnego wykonania, oceniający stan zmysłu węchu (1 szt.)",
      productType: ProductType.SMELL_TEST,
      value: smellTestAmount,
      setValue: (value) => setSmellTestAmount(value),
    },
    {
      title: "Zestaw testów smaku i węchu",
      iconRender: () => (
        <></>
        // <FormatListNumberedOutlinedIcon
        //   fontSize="large"
        //   style={{ color: "#00346A" }}
        // />
      ),
      url: "/tests",
      text:
        "Zestaw składający się z 1 szt. testu smaku i 1 szt. testu węchu, oceniający stan tych zmysłów. Testy do samodzielnego wykonania.",
      productType: ProductType.SMELLTASTE_TEST,
      value: tasteAndSmellTestAmount,
      setValue: (value) => setTasteAndSmellTestAmount(value),
    },
  ];

  useEffect(() => {
    if (
      location.state &&
      location.state.cartItemEdition &&
      my?.address &&
      copyShippingData
    ) {
      setDeliveryInfo({
        name: my.address.name,
        street: my.address.street,
        city: my.address.city,
        zipCode: my.address.zipCode,
        post: my.address.post,
        phone: my.address.phone,
      });
    }

    if (copyShippingData && my.address) {
      setDataChanged(true);
      setName(my.address?.name);
      setStreet(my.address?.street);
      setCity(my.address?.city);
      setZip(my.address?.zipCode);
      setPost(my.address?.post);
      setPhone(my.address?.phone);
    } else if (!copyShippingData) {
      setName("");
      setStreet("");
      setCity("");
      setZip("");
      setPost("");
      setPhone("");
    }

    let addressToCheck = {};
    if (my?.address) {
      const { phone, ...address } = my?.address;
      addressToCheck = address;
    } else {
      addressToCheck = my?.address;
    }

    const addressToCopyIsEmpty = addressToCheck
      ? !Object.values(addressToCheck).some(
          (addressProperty) =>
            addressProperty !== null && addressProperty !== ""
        )
      : true;

    if (copyShippingData) {
      if (my.address && provideShippingData && !addressToCopyIsEmpty) {
        setCopyDataFromProfile("Dane adresowe zostały skopiowane");
        setTimeout(() => setCopyDataFromProfile(""), 3000);
      } else {
        setCopyDataFromProfile(
          "Brak danych w profilu, dane nie zostały skopiowane"
        );
        setTimeout(() => setCopyDataFromProfile(""), 3000);
      }
    }
  }, [copyShippingData]);

  useEffect(() => {
    if (copyBillingData && billingInfoFromOrganization) {
      setDataChanged(true);
      setNameFV(billingInfoFromOrganization.name);
      setStreetFV(billingInfoFromOrganization.street);
      setCityFV(billingInfoFromOrganization.city);
      setZipFV(billingInfoFromOrganization.zipCode);
      setPostFV(billingInfoFromOrganization.post);
      setNipFV(billingInfoFromOrganization.taxId);
    } else if (!copyBillingData) {
      setNameFV("");
      setStreetFV("");
      setCityFV("");
      setZipFV("");
      setPostFV("");
      setNipFV("");
    }

    const billingToCopyIsEmpty = billingInfoFromOrganization
      ? !Object.values(billingInfoFromOrganization).some(
          (billingProperty) =>
            billingProperty !== null && billingProperty !== ""
        )
      : false;

    if (copyBillingData) {
      if (
        billingInfoFromOrganization &&
        provideFVData &&
        !billingToCopyIsEmpty
      ) {
        setCopyDataFromProfile("Dane do faktury zostały skopiowane");
        setTimeout(() => setCopyDataFromProfile(""), 3000);
      } else {
        setCopyDataFromProfile(
          "Brak danych w profilu, dane nie zostały skopiowane"
        );
        setTimeout(() => setCopyDataFromProfile(""), 3000);
      }
    }
  }, [copyBillingData]);

  useEffect(() => {
    if (
      location.state &&
      location.state.cartItemEdition &&
      my?.address &&
      copyShippingData
    ) {
      setDeliveryInfo({
        name: my.address.name,
        street: my.address.street,
        city: my.address.city,
        zipCode: my.address.zipCode,
        post: my.address.post,
        phone: my.address.phone,
      });

      if (provideFVData) {
        setBillingInfo({
          name: billingInfoFromOrganization.name,
          street: billingInfoFromOrganization.street,
          city: billingInfoFromOrganization.city,
          zipCode: billingInfoFromOrganization.zipCode,
          post: billingInfoFromOrganization.post,
          taxId: billingInfoFromOrganization.taxId,
          notes: billingInfoFromOrganization.notes,
        });
      }
    }
  }, [copyShippingData, copyBillingData]);

  useEffect(() => {
    if (pricesLength === 0) {
      dispatch(fetchPriceList());
    }
  }, [pricesLength]);

  useEffect(() => {
    if (
      tasteTestAmount > 0 ||
      smellTestAmount > 0 ||
      tasteAndSmellTestAmount > 0
    ) {
      if (provideShippingData && !shippingInfoValid) {
        setValid(false);
      } else if (provideFVData && !billingInfoValid) {
        setValid(false);
      } else {
        setValid(true);
      }
    } else {
      setValid(false);
    }
  }, [
    tasteTestAmount,
    smellTestAmount,
    tasteAndSmellTestAmount,
    shippingInfoValid,
    billingInfoValid,
    provideShippingData,
    provideFVData,
  ]);

  useEffect(() => {
    if (location.state !== null) {
      if (location.state?.cartItemEdition) {
        setSmellTestAmount(retriveTestAmount("SMELL_TEST"));
        setTasteTestAmount(retriveTestAmount("TASTE_TEST"));
        setTasteAndSmellTestAmount(retriveTestAmount("SMELLTASTE_TEST"));

        setProvideShippingData(
          (location.state.detail.deliveryInfo && true) || false
        );
        setProvideFVData((location.state.detail.billingInfo && true) || false);

        if (location?.state?.detail?.deliveryInfo) {
          const deliveryInfo = location?.state?.detail?.deliveryInfo;
          setName(deliveryInfo.name);
          setStreet(deliveryInfo.street);
          setCity(deliveryInfo.city);
          setZip(deliveryInfo.zipCode);
          setPost(deliveryInfo.post);
          setPhone(deliveryInfo.phone);
          setNotes(deliveryInfo.notes);
        }

        if (location?.state?.detail?.billingInfo) {
          const billingInfo = location?.state?.detail?.billingInfo;
          setNameFV(billingInfo.name);
          setStreetFV(billingInfo.street);
          setCityFV(billingInfo.city);
          setZipFV(billingInfo.zipCode);
          setPostFV(billingInfo.post);
          setNotesFV(billingInfo.notes);
          setNipFV(billingInfo.taxId);
        }

        setCartItemEdition(location.state.cartItemEdition);
      }
    } else {
      setSmellTestAmount(0);
      setTasteTestAmount(0);
      setTasteAndSmellTestAmount(0);

      setCartItemEdition(false);
    }
  }, [location]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setCopyDataFromProfile("");
  };

  let retriveTestAmount = (testType) => {
    return (
      (location.state.detail.elements.find(
        (test) => test.testType === testType
      ) &&
        location.state.detail.elements.find(
          (test) => test.testType === testType
        ).amount) ||
      0
    );
  };

  const normalizeZIP = (value, previousValue) => {
    if (!value) return value;
    const currentValue = value.replace(/[^\d]/g, "");
    const cvLength = currentValue.length;

    if (!previousValue || value.length > previousValue.length) {
      if (cvLength < 3) return currentValue;
      return `${currentValue.slice(0, 2)}-${currentValue.slice(2, 5)}`;
    }
  };

  const maxNIPLengthCheck = (object) => {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(
        0,
        object.target.maxLength
      );
    }
  };

  const submit = async () => {
    setLoaderOn(true);
    const tasteAmount = parseInt(tasteTestAmount, 10);
    const smellAmount = parseInt(smellTestAmount, 10);
    const tasteAndSmellAmount = parseInt(tasteAndSmellTestAmount, 10);
    const payload = {
      elements: [
        ...(tasteAmount > 0
          ? [
              {
                testType: "TASTE_TEST",
                amount: tasteAmount,
              },
            ]
          : []),
        ...(smellAmount > 0
          ? [
              {
                testType: "SMELL_TEST",
                amount: smellAmount,
              },
            ]
          : []),
        ...(tasteAndSmellAmount > 0
          ? [
              {
                testType: "SMELLTASTE_TEST",
                amount: tasteAndSmellAmount,
              },
            ]
          : []),
        {
          testType: "DELIVERY",
          amount: 1,
        },
      ],
      invoiceNeeded: provideFVData,
      ...(provideShippingData
        ? {
            deliveryInfo: {
              name,
              street,
              zipCode: zip,
              city,
              post,
              phone,
              notes,
            },
            saveDeliveryInfoAsAddressData,
          }
        : {}),
      ...(provideFVData
        ? {
            billingInfo: {
              name: nameFV,
              street: streetFV,
              zipCode: zipFV,
              city: cityFV,
              post: postFV,
              taxId: nipFV,
              notes: notesFV,
            },
            saveBillingInfoInOrganization,
          }
        : {}),
    };

    if (cartItemEdition) {
      const data = await dispatch(
        editItemCart(location.state.detail.id, payload)
      );

      if (saveDeliveryInfoAsAddressData) {
        dispatch(fetchMyProfile());
      }

      if (saveBillingInfoInOrganization) {
        dispatch(fetchOrganization());
      }
      setDataChanged(false);

      history.push({
        pathname: "/shopping-cart",
        state:
          location?.state?.detail?.id && cartItemEdition
            ? { id: location.state.detail.id }
            : null,
      });
    } else {
      setDataChanged(false);
      const data = await dispatch(addItemToCart(payload));

      if (saveDeliveryInfoAsAddressData) {
        dispatch(fetchMyProfile());
      }

      if (saveBillingInfoInOrganization) {
        dispatch(fetchOrganization());
      }

      history.push({
        pathname: "/shopping-cart",
        state:
          location?.state?.detail?.id && cartItemEdition
            ? { id: location.state.detail.id }
            : null,
      });
    }
  };

  const filledShippingDataFromProfile = () => {
    if (location.state && location.state.cartItemEdition) {
      setDeliveryInfo({
        name: my.address.name,
        street: my.address.street,
        city: my.address.city,
        zipCode: my.address.zipCode,
        post: my.address.post,
        phone: my.address.phone,
      });

      if (provideFVData) {
        setBillingInfo({
          name: billingInfoFromOrganization.name,
          street: billingInfoFromOrganization.street,
          city: billingInfoFromOrganization.city,
          zipCode: billingInfoFromOrganization.zipCode,
          post: billingInfoFromOrganization.post,
          taxId: billingInfoFromOrganization.taxId,
          notes: billingInfoFromOrganization.notes,
        });
      }
    }

    if (my.address !== undefined && provideShippingData) {
      setName(my.address.name);
      setStreet(my.address.street);
      setCity(my.address.city);
      setZip(my.address.zipCode);
      setPost(my.address.post);
      setPhone(my.address.phone);
    }

    if (provideFVData) {
      setNameFV(billingInfoFromOrganization.name);
      setStreetFV(billingInfoFromOrganization.street);
      setCityFV(billingInfoFromOrganization.city);
      setZipFV(billingInfoFromOrganization.zipCode);
      setPostFV(billingInfoFromOrganization.post);
      setNotesFV(billingInfoFromOrganization.notes);
      setNipFV(billingInfoFromOrganization.taxId);
    }

    const addressToCopyIsEmpty = my.address
      ? !Object.values(my.address).some(
          (addressProperty) =>
            addressProperty !== null && addressProperty !== ""
        )
      : true;

    const billingToCopyIsEmpty = billingInfoFromOrganization
      ? !Object.values(billingInfoFromOrganization).some(
          (billingProperty) =>
            billingProperty !== null && billingProperty !== ""
        )
      : false;

    if (
      my.address &&
      billingInfoFromOrganization &&
      provideFVData &&
      provideShippingData &&
      !addressToCopyIsEmpty &&
      !billingToCopyIsEmpty
    ) {
      setCopyDataFromProfile("Dane zostały skopiowane");
      setTimeout(() => setCopyDataFromProfile(""), 3000);
    } else if (my.address && provideShippingData && !addressToCopyIsEmpty) {
      setCopyDataFromProfile("Dane adresowe zostały skopiowane");
      setTimeout(() => setCopyDataFromProfile(""), 3000);
    } else if (
      billingInfoFromOrganization &&
      provideFVData &&
      !billingToCopyIsEmpty
    ) {
      setCopyDataFromProfile("Dane do faktury zostały skopiowane");
      setTimeout(() => setCopyDataFromProfile(""), 3000);
    } else {
      setCopyDataFromProfile(
        "Brak danych w profilu, dane nie zostały skopiowane"
      );
      setTimeout(() => setCopyDataFromProfile(""), 3000);
    }
  };

  const page = () => (
    <Container className={classes.container}>
      <h1
        aria-hidden="false"
        aria-label={
          !cartItemEdition ? "Składanie zamówienia" : "Edycja zamówienia"
        }
        style={{ display: "none" }}
      >
        {!cartItemEdition ? "Składanie zamówienia" : "Edycja zamówienia"}
      </h1>
      <Grid
        container
        style={{
          margin: "24px auto",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Grid item xs={12}>
          <Box>
            <AppBarTitle
              value={
                !cartItemEdition ? "Składanie zamówienia" : "Edycja zamówienia"
              }
            />
          </Box>
        </Grid>
        <Grid
          item
          xs={10}
          sm={8}
          xl={8}
          container
          spacing={2}
          className={classes.gridCard}
        >
          {cartTestItems.map((item, index) => (
            <Grid
              item
              xs={12}
              md={8}
              lg={4}
              key={index}
              style={{ display: "flex" }}
            >
              <TestCardComponent
                title={item.title}
                text={item.text}
                url={item.url}
                iconRender={() => item.iconRender()}
                setValue={(p) => item.setValue(p)}
                value={item.value}
                productType={item.productType}
                setDataChanged={setDataChanged}
              />
            </Grid>
          ))}
        </Grid>

        <Grid item xs={10} xl={8}>
          <FormControlLabel
            style={{ marginBottom: "16px" }}
            control={
              <Checkbox
                name="provideShippingData"
                color="primary"
                checked={provideShippingData}
                onChange={() => {
                  setProvideShippingData(!provideShippingData);
                }}
              />
            }
            label={
              <Typography
                style={{ fontWeight: "600" }}
                className={classes.typography}
              >
                Chcę teraz podać dane do wysyłki
              </Typography>
            }
          />
        </Grid>

        <Grid
          container
          item
          xs={10}
          xl={8}
          spacing={2}
          style={{ padding: "0px 0px 0px 12px" }}
        >
          <Grid item xs={12} md={6}>
            <Typography
              variant="h6"
              gutterBottom
              className={classes.typography}
            >
              Dane do wysyłki
            </Typography>
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              open={copyDataFromProfile !== ""}
              autoHideDuration={3000}
              onClose={handleClose}
            >
              {copyDataFromProfile !== "" && (
                <Alert
                  onClose={handleClose}
                  severity={
                    copyDataFromProfile !==
                    "Brak danych w profilu, dane nie zostały skopiowane"
                      ? "info"
                      : "warning"
                  }
                  style={{
                    backgroundColor:
                      globalTheme === "high-contrast" && "#000000",
                    border:
                      globalTheme === "high-contrast" && "1px solid #ffff00",
                    color: globalTheme === "high-contrast" && "#ffff00",
                  }}
                >
                  {copyDataFromProfile}
                </Alert>
              )}
            </Snackbar>
            <FormControlLabel
              control={
                <Checkbox
                  name="ShippingAddressFormCopy"
                  color="primary"
                  disabled={!provideShippingData}
                  checked={copyShippingData}
                  onChange={() => {
                    setCopyShippingData(!copyShippingData);
                  }}
                />
              }
              className={classes.typography}
              label={"Skopiuj dane z profilu"}
            />
            <ShippingAddressForm
              provideShippingData={provideShippingData}
              cartItemEdition={cartItemEdition}
              name={name}
              setName={setName}
              street={street}
              setStreet={setStreet}
              city={city}
              setCity={setCity}
              zip={zip}
              setZip={setZip}
              post={post}
              setPost={setPost}
              phone={phone}
              setPhone={setPhone}
              notes={notes || ""}
              setNotes={setNotes}
              deliveryInfo={deliveryInfo}
              setDeliveryInfo={setDeliveryInfo}
              isValidListener={setShippingInfoValid}
              setDataChanged={setDataChanged}
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="FVAddress"
                  color="primary"
                  checked={provideFVData}
                  onChange={() => {
                    setProvideFVData(!provideFVData);
                  }}
                />
              }
              label={"Chcę Fakturę"}
              className={classes.typography}
            />
            <br />
            <FormControlLabel
              control={
                <Checkbox
                  checked={saveDeliveryInfoAsAddressData}
                  onChange={(e) =>
                    setSaveDeliveryInfoAsAddressData(e.target.checked)
                  }
                  color="primary"
                />
              }
              label="Zapamiętaj dane do wysyłki"
              className={classes.typography}
            />
          </Grid>
          {provideFVData && (
            <Grid item xs={12} md={6}>
              <Typography
                variant="h6"
                gutterBottom
                className={classes.typography}
              >
                Dane do Faktury
              </Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    name="BillingAddressFormCopy"
                    color="primary"
                    checked={copyBillingData}
                    onChange={() => {
                      setCopyBillingData(!copyBillingData);
                    }}
                  />
                }
                label={"Skopiuj dane z profilu"}
              />
              <BillingAddressForm
                cartItemEdition={cartItemEdition}
                nameFV={nameFV}
                setNameFV={setNameFV}
                streetFV={streetFV}
                setStreetFV={setStreetFV}
                cityFV={cityFV}
                setCityFV={setCityFV}
                zipFV={zipFV}
                setZipFV={setZipFV}
                postFV={postFV}
                setPostFV={setPostFV}
                nipFV={nipFV}
                setNipFV={setNipFV}
                notesFV={notesFV || ""}
                setNotesFV={setNotesFV}
                showNotes={false}
                billingInfo={billingInfo}
                setBillingInfo={setBillingInfo}
                isValidListener={setBillingInfoValid}
                setDataChanged={setDataChanged}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={saveBillingInfoInOrganization}
                    onChange={(e) =>
                      setSaveBillingInfoInOrganization(e.target.checked)
                    }
                    color="primary"
                  />
                }
                label="Zapamiętaj dane do faktury"
                className={classes.typography}
              />
            </Grid>
          )}
        </Grid>

        <Grid
          item
          container
          xs={12}
          direction={"row"}
          className={classes.bottomGrid}
          elevation={15}
        >
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            {" "}
            <Box mr={2}>
              <Button
                type="cancel"
                variant={"contained"}
                // style={{
                //   backgroundColor: "grey",
                //   color: "#FFFFFF",
                //   marginLeft: "10px",
                // }}
                onClick={() => history.push("/shopping-cart")}
              >
                Anuluj
              </Button>
            </Box>{" "}
            {!cartItemEdition ? (
              <Button
                type="submit"
                variant={"contained"}
                disabled={!valid}
                color="primary"
                // style={{
                //   backgroundColor: !valid && "#cccccc",
                //   color: !valid && "#666666",
                // }}
                onClick={submit}
              >
                Dodaj zamówienie do koszyka
              </Button>
            ) : (
              <Button
                type="submit"
                variant={"contained"}
                disabled={!valid || loaderOn}
                color="primary"
                // style={{
                //   backgroundColor: valid && !loaderOn ? "#0000FF" : "#cccccc",
                //   color: valid && !loaderOn ? "#FFFFFF" : "#666666",
                // }}
                onClick={submit}
              >
                {loaderOn && <CircularProgress size={20} />}
                {!loaderOn && "Kontynnuj zamównienie"}
              </Button>
            )}
          </Grid>
        </Grid>

        <Prompt
          when={dataChanged}
          message={(params) =>
            params.pathname !== window.location.pathname && dataChanged
              ? "Wprowadzone dane nie zostały zapisane. Czy na pewno chcesz opuścić stronę?"
              : true
          }
        ></Prompt>
      </Grid>
    </Container>
  );

  const redirect = () => <Redirect to="/login" />;

  return <Can permission="test-kit:page" ok={page} not={redirect} />;
};

export default TestKitPage;
