import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) => ({
  blue: {
    color: '#0021db',
  },
}))

const PageTitle = ({title}) => {
  const classes = useStyles()

  return (
    <Typography variant='h6' component='h1' className={classes.blue}>
      {title}
    </Typography>
  )
}

export default PageTitle
