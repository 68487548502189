import React, { useEffect, useState } from "react";
import FormPermissions from "../../components/form-permissions";
import Can from "../../components/can";
import { ConfirmDialog } from "../../components/confirm-dialog";
import { useDispatch, useSelector } from "react-redux";
import {
  deactivateForm,
  fetchOldFormEditions,
  updateEntitledUsers,
} from "../../redux/actions";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import PauseIcon from "@material-ui/icons/Pause";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import PersonIcon from "@material-ui/icons/Person";
import { NavLink } from "react-router-dom";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import Tooltip from "@material-ui/core/Tooltip";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import { setTestCode } from "../../redux/orders/actions";

const NO_ID_TO_REMOVE = -1;

const statusToText = (status) => {
  switch (status) {
    // case 'ZERO': return 'ZERO';
    // case 'TODO': return 'TODO';
    case "PAUSED":
      return "Wstrzymany";
    case "DRAFT":
      return "Szkic";
    case "SENT":
      return "Wysłany";
    case "ACTIVE":
      return "Aktywny";
    case "ARCHIVE":
      return "Zarchiwizowany";
    case "DELETED":
      return "Usunięty";
  }
};

const statusToTip = (status) => {
  switch (status) {
    // case 'ZERO':
    //   return 'nieuzywany w widoku';
    // case 'TODO':
    //   return 'nieuzywany w widoku';
    case "PAUSED":
      return "Aktywny formularz został zatrzymany i obecnie nie jest dostępny do wypełnienia";
    case "DRAFT":
      return "Formularz jest w trakcie tworzenia lub edycji";
    // case 'SENT':
    //   return 'nieuzywany w widoku'
    case "ACTIVE":
      return "Formularz jest dostępny do wypełnienia dla wskazanych osób";
    case " PREVIOUS_VERSION":
      return "Poprzednia wersja formularza";
    // case 'ARCHIVE':
    //   return 'nieuzywany w widoku'
    // case 'DELETED':
    //   return 'nieuzywany w widoku'
  }
};

const useStyles = makeStyles(() => ({
  nonCurrentRow: {
    backgroundColor: "#e4e4e4",
  },
}));

const SingleVersionRow = ({
  scheduledForm,
  form,
  setShowOtherVersions,
  showOtherVersions,
  setShowPermissions,
  showPermissions,
  fill,
  isActivated,
  dispatch,
  activateForm,
  duplicateForm,
  edit,
  askToRemove,
  remove,
  isCurrent = false,
}) => {
  const classes = useStyles();

  const testTypes = {
    SMELL_TEST: "Test węchu",
    TASTE_TEST: "Test smaku",
  };

  return (
    <TableRow className={isCurrent ? "" : classes.nonCurrentRow}>
      {form ? (
        <TableCell component="th" scope="row">
          {form.name}
          {isCurrent && form.edition > 1 && (
            <Tooltip title="Dostępnych jest kilka wersji formularza. Kliknij aby pokazać dostępne opcje.">
              <Link
                onClick={(e) => {
                  setShowOtherVersions(!showOtherVersions);
                }}
              >
                {" "}
                (v.{form.edition})
              </Link>
            </Tooltip>
          )}
          {!isCurrent && <span> (v.{form.edition})</span>}
        </TableCell>
      ) : (
        <TableCell component="th" scope="row">
          ---
        </TableCell>
      )}

      {form ? (
        <TableCell align="right">
          <span>{form && form.testType ? testTypes[form.testType] : ""}</span>
        </TableCell>
      ) : (
        <TableCell align="right">
          <span>
            {scheduledForm && scheduledForm.testType
              ? testTypes[scheduledForm.testType]
              : ""}
          </span>
        </TableCell>
      )}

      <TableCell align="right">
        {form ? (
          form.testCode
        ) : (
          <>
            Uzupełnij kod:{" "}
            <input
              type="text"
              onKeyUp={(e) => {
                if (e.keyCode === 13) {
                  dispatch(
                    setTestCode({
                      scheduledFormId: scheduledForm.id,
                      code: e.target.value,
                    })
                  );
                }
              }}
            />
          </>
        )}
      </TableCell>

      {/*<TableCell align="right">*/}
      {/*  <Tooltip*/}
      {/*    title={statusToTip(form.status) ? statusToTip(form.status) : ""}*/}
      {/*  >*/}
      {/*    <span>{statusToText(form.status)}</span>*/}
      {/*  </Tooltip>*/}
      {/*</TableCell>*/}
      {/*<TableCell align="right">*/}
      {/*  {form.entitledUsers !== undefined &&*/}
      {/*    (*/}
      {/*      form.entitledUsers.filter(*/}
      {/*        (entitledUser) => entitledUser.removed != true*/}
      {/*      ) || []*/}
      {/*    ).length}*/}
      {/*</TableCell>*/}
      {/*<TableCell align="right">*/}
      {/*  <NavLink to={`/testdata/all/${form.id}`}>*/}
      {/*    {form.filledFormCount}*/}
      {/*  </NavLink>*/}
      {/*</TableCell>*/}
      <TableCell align="right">
        {isCurrent && (
          <>
            {scheduledForm && scheduledForm.readyToFill && (
              <Tooltip placement="top" title="Wypełnij">
                <span>
                  <IconButton
                    size="small"
                    onClick={() => fill(form.id, scheduledForm.id)}
                  >
                    <NoteAddIcon />
                  </IconButton>
                </span>
              </Tooltip>
            )}

            {/*<Can*/}
            {/*  permission="test:deactivate"*/}
            {/*  ok={() => {*/}
            {/*    const color = isActivated ? "green" : "red";*/}
            {/*    const text1 = "Formularz aktywny";*/}
            {/*    const text2 =*/}
            {/*      "Aktywuj formularz aby inne osoby mogły go zobaczyć";*/}

            {/*    if (isActivated) {*/}
            {/*      return (*/}
            {/*        <Tooltip placement="top" title="Wstrzymaj formularz">*/}
            {/*          <IconButton*/}
            {/*            size="small"*/}
            {/*            onClick={() => {*/}
            {/*              isActivated && dispatch(deactivateForm(form.id));*/}
            {/*            }}*/}
            {/*          >*/}
            {/*            <PauseIcon />*/}
            {/*          </IconButton>*/}
            {/*        </Tooltip>*/}
            {/*      );*/}
            {/*    } else {*/}
            {/*      return (*/}
            {/*        <Tooltip placement="top" title="Aktywuj formularz">*/}
            {/*          <IconButton*/}
            {/*            size="small"*/}
            {/*            onClick={() => {*/}
            {/*              !isActivated && activateForm(form);*/}
            {/*            }}*/}
            {/*          >*/}
            {/*            <PlayArrowIcon style={{ color: "green" }} />*/}
            {/*          </IconButton>*/}
            {/*        </Tooltip>*/}
            {/*      );*/}
            {/*    }*/}
            {/*  }}*/}
            {/*/>*/}

            <Can
              permission="test:duplicate"
              ok={() => (
                <Tooltip placement="top" title="Duplikuj">
                  <span>
                    <IconButton
                      size="small"
                      onClick={() => duplicateForm(form.id)}
                    >
                      <FileCopyIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              )}
            />

            {edit && (
              <Tooltip
                placement="top"
                title={
                  isActivated
                    ? "Nie możesz aktualnie edytować formularz ponieważ jest aktywny. Dezaktywuj by edytować."
                    : "Edytuj formularz"
                }
              >
                <span>
                  <IconButton
                    onClick={() => edit(form.id)}
                    size="small"
                    disabled={isActivated}
                  >
                    <EditIcon />
                  </IconButton>
                </span>
              </Tooltip>
            )}

            {remove && (
              <Tooltip
                placement="top"
                title={
                  isActivated
                    ? "Nie możesz aktualnie usunąć formularzae - jest aktywny dla innych użytkowników"
                    : "Usuń formularz"
                }
              >
                <span>
                  <IconButton
                    onClick={() => askToRemove(form.id)}
                    size="small"
                    disabled={isActivated}
                  >
                    <DeleteIcon />
                  </IconButton>
                </span>
              </Tooltip>
            )}

            {/*<Tooltip*/}
            {/*  placement="top"*/}
            {/*  title="Zarządzanie uprawnieniami użytkowników mogących wypełnić ten formularz"*/}
            {/*>*/}
            {/*  <IconButton*/}
            {/*    size="small"*/}
            {/*    onClick={() => {*/}
            {/*      setShowPermissions(!showPermissions);*/}
            {/*    }}*/}
            {/*  >*/}
            {/*    <PersonIcon />*/}
            {/*  </IconButton>*/}
            {/*</Tooltip>*/}
          </>
        )}
      </TableCell>
    </TableRow>
  );
};

const SingleForm = ({
  scheduledForm,
  form,
  fill,
  remove,
  edit,
  filledForms,
  updateForm,
  activateForm,
  history,
  duplicateForm,
}) => {
  const [showPermissions, setShowPermissions] = useState(false);
  const [idToRemove, setIdToRemove] = useState(NO_ID_TO_REMOVE);
  const [showOtherVersions, setShowOtherVersions] = useState(false);
  const [otherVersions, setOtherVersions] = useState(null);
  const dispatch = useDispatch();
  const oldVersions = useSelector((s) =>
    s.orgOldFromEditions.filter(
      (e) => e.bag.id === form.bag.id && e.id !== form.id
    )
  );

  console.log("form", form);
  console.log("sched form", scheduledForm);

  useEffect(() => {
    if (showOtherVersions && otherVersions === null) {
      dispatch(fetchOldFormEditions(form.id));
    }
  }, [showOtherVersions, otherVersions, form]);

  const askToRemove = (formId) => {
    setIdToRemove(formId);
  };

  const isActivated = form ? form.status === "ACTIVE" : false;

  return (
    <>
      {idToRemove !== NO_ID_TO_REMOVE && (
        <ConfirmDialog
          text="Czy na pewno usunąć ten element?"
          yesAction={() => {
            remove(idToRemove);
            setIdToRemove(NO_ID_TO_REMOVE);
          }}
          noAction={() => setIdToRemove(NO_ID_TO_REMOVE)}
        />
      )}

      <SingleVersionRow
        activateForm={activateForm}
        askToRemove={askToRemove}
        dispatch={dispatch}
        duplicateForm={duplicateForm}
        edit={edit}
        fill={fill}
        form={form}
        scheduledForm={scheduledForm}
        isActivated={isActivated}
        remove={remove}
        setShowOtherVersions={setShowOtherVersions}
        setShowPermissions={setShowPermissions}
        showOtherVersions={showOtherVersions}
        showPermissions={showPermissions}
        isCurrent={true}
      ></SingleVersionRow>

      {showOtherVersions &&
        oldVersions &&
        oldVersions.length > 0 &&
        oldVersions.map((f, index) => (
          <SingleVersionRow
            key={index}
            activateForm={activateForm}
            askToRemove={askToRemove}
            dispatch={dispatch}
            duplicateForm={duplicateForm}
            edit={edit}
            fill={fill}
            scheduledForm={scheduledForm}
            form={f}
            isActivated={isActivated}
            setShowOtherVersions={() => {}}
            setShowPermissions={() => {}}
            showOtherVersions={false}
            showPermissions={() => {}}
          ></SingleVersionRow>
        ))}

      {showPermissions && (
        <FormPermissions
          selected={(form.entitledUsers || []).map((u) => u.username)}
          hide={() => setShowPermissions(false)}
          savePermissions={(emails) => {
            dispatch(updateEntitledUsers(form.id, emails));

            setShowPermissions(false);
          }}
          className="column is-full"
        />
      )}
    </>
  );
};

export default SingleForm;
