import React, { useEffect, useMemo, useRef, useState } from "react";
import { NavLink, Redirect, useRouteMatch } from "react-router-dom";
import Can from "../../components/can";
import {
  Box,
  CircularProgress,
  FormControl,
  IconButton,
  MenuItem,
  Paper,
  Select,
  TableSortLabel,
  TextField,
  Grid,
  Button,
  Modal,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  Typography,
} from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import Table from "@material-ui/core/Table";
import { useDispatch } from "react-redux";
import { alertAdd, fetchOrderDetailsByOrderId } from "../../redux/actions";
import format from "date-fns/format";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useTheme from "@material-ui/core/styles/useTheme";
import withStyles from "@material-ui/core/styles/withStyles";
import AppBarTitle from "../../components/app-bar-title";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import PriorityHighIcon from "@material-ui/icons/PriorityHigh";
import Tooltip from "@material-ui/core/Tooltip";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import plLocale from "date-fns/locale/pl";
import DateFnsUtils from "@date-io/date-fns";
import ClearIcon from "@material-ui/icons/Clear";
import {
  fullFilter,
  partialFilter,
  partialFilter2,
  partialFilter3,
} from "./filtering";
import TablePagination from "@material-ui/core/TablePagination";
import { getAxiosInstance } from "../../redux/common";
// import { ReactComponent as PDFIcon } from "../../assets/pdf.svg";
import imgPDF from "../../assets/pdf.svg";
import s from "./index.module.css";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import magnifierImage from "../../assets/magnifier.jpg";

const MuiFirstHeaderTableCell = withStyles((theme) => ({
  root: {
    padding: "0px",
    color: theme.palette.text.alternative3,
  },
}))(TableCell);

const PaddingTableCell = withStyles((theme) => ({
  root: {
    padding: "8px",
    color: theme.palette.text.alternative2,
  },
}))(TableCell);

const NO_NUMBER_ASSIGNED_DISPLAY = "Oczekiwanie na numer";

const TEST_TYPE_TO_NAME_MAP = {
  TASTE_TEST: "Test smaku",
  SMELL_TEST: "Test węchu",
  SMELLTASTE_TEST: "Test smaku i węchu",
  DELIVERY: "-",
};

const useStyles = makeStyles((theme) => ({
  positiveResult: {
    color: theme.palette.checkIcon.primary,
  },
  negativeResult: {
    color: theme.palette.error.main,
    display: "flex",
    alignItems: "center",
  },
  removedAccount: {
    color: "gray",
    fontStyle: "italic",
  },
  waitingForCode: {
    color: theme.palette.text.item,
    fontStyle: "italic",
  },
  // Fully visible for active icons
  activeSortIcon: {
    opacity: 1,
  },
  // Half visible for inactive icons
  inactiveSortIcon: {
    opacity: 0.4,
  },
  filter: {
    backgroundColor: theme.palette.background.filter,
    border: `1px solid ${theme.palette.background.filterBorder} `,
  },
}));

const NegativeResultTooltip = withStyles((theme) => ({
  tooltip: {
    fontSize: "1em",
    lineHeight: "1em",
    color: theme.palette.text.primary2,
    backgroundColor: theme.palette.background.filter,
    border: `1px solid ${theme.palette.error.main}`,
  },
}))(Tooltip);

const FilledKits = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const classes = useStyles();
  const {
    params: { orderId, external },
  } = useRouteMatch();

  const isExternal = !!external;

  const [filledTests, setFilledTests] = useState([]);
  const [order, setOrder] = useState({});

  const [
    orderItemsWaitingToAssignCode,
    setOrderItemsWaitingToAssignCode,
  ] = useState([]);
  const [orderItemsToActivate, setOrderItemsToActivate] = useState([]);
  const [orderItemsAlreadyActivated, setOrderItemsAlreadyActivated] = useState(
    []
  );

  const [orderWithTestResults, setOrderWithTestResults] = useState();

  const [fetchingData, setFetchingData] = useState(false);

  const [sortDirection, setSortDirection] = useState("asc");
  const [activeSortCol, setActiveSortCol] = useState("");

  const [filterQueries, setFilterQueries] = useState({
    activatedByFullName: "",
    code: "",
    completedAt: "",
    createdAt: "",
    createdByFullName: "",
    filledByFullName: "",
    testType: "",
    tenantName: "",
    text: "",
    testType: "any",
    result: "any",
  });
  const [resultFilterOpen, setResultFilterOpen] = useState(false);
  const [testTypeFilterOpen, setTestTypeFilterOpen] = useState(false);

  const pages = [50, 100, 200, 300, 500];
  const [pageNr, setPageNr] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [orderDirection, setOrderDirection] = useState("desc");
  const [orderBy, setOrderBy] = useState("completedAt");
  const [preparingCSV, setPreparingCSV] = useState(false);
  const [csvModalOpen, setCsvModalOpen] = useState(false);
  const [csvPeriodStart, setCsvPeriodStart] = useState("");
  const [csvPeriodEnd, setCsvPeriodEnd] = useState("");
  const [csvPeriodType, setCsvPeriodType] = useState("all");

  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down(394));

  useEffect(() => {
    if (csvPeriodStart && csvPeriodEnd && csvPeriodEnd < csvPeriodStart) {
      setCsvPeriodEnd(csvPeriodStart);
    }
  }, [csvPeriodStart]);

  useEffect(() => {
    if (csvPeriodStart && csvPeriodEnd && csvPeriodEnd < csvPeriodStart) {
      setCsvPeriodStart(csvPeriodEnd);
    }
  }, [csvPeriodEnd]);

  const changeSortDirection = (columnKey) => {
    if (activeSortCol === columnKey) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setActiveSortCol(columnKey);
      setSortDirection("asc");
    }
  };

  const fetchOrderData = async (orderId) => {
    setFetchingData(true);

    const data = await dispatch(
      fetchOrderDetailsByOrderId({
        orderId,
        isExternal,
        page: pageNr,
        pageSize: rowsPerPage,
        sort: `${orderDirection === "asc" ? orderBy : "-" + orderBy}`,
      })
    );
    setOrderWithTestResults(data);

    setFilledTests(
      data?.content.filter(({ orderItemType }) => orderItemType === "FORM_DATA")
    );
    setOrder(data?.element);

    setOrderItemsWaitingToAssignCode(
      data?.content.filter(
        ({ orderItemType, testType }) =>
          orderItemType === "WAITING_TO_ASSIGN_CODE" && testType !== "DELIVERY"
      )
    );
    setOrderItemsToActivate(
      data?.content.filter(
        ({ orderItemType, testType }) =>
          orderItemType === "TO_ACTIVATE" && testType !== "DELIVERY"
      )
    );
    setOrderItemsAlreadyActivated(
      data?.content.filter(
        ({ orderItemType, testType }) =>
          orderItemType === "ACTIVATED" && testType !== "DELIVERY"
      )
    );

    setFetchingData(false);
  };

  useEffect(() => {
    if (orderId) {
      fetchOrderData(orderId);
    }
  }, [orderId, pageNr, rowsPerPage, orderBy, orderDirection]);

  const handleChangePage = (event, newPage) => {
    setPageNr(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPageNr(0);
  };

  const handleSortRequest = (cellId) => {
    const isAsc = orderBy === cellId && orderDirection === "asc";
    setOrderDirection(isAsc ? "desc" : "asc");
    setOrderBy(cellId);
  };

  const moreThan4Hours = (item) => {
    const startedDate = item.createdAt
      ? item.createdAt[item.createdAt.length - 1] !== "Z"
        ? format(new Date(item.createdAt.concat("Z")), "yyyy-MM-dd HH:mm")
        : format(new Date(item.createdAt), "yyyy-MM-dd HH:mm")
      : "";
    const completedDate = item.completedAt
      ? item.completedAt[item.completedAt.length - 1] !== "Z"
        ? format(new Date(item.completedAt.concat("Z")), "yyyy-MM-dd HH:mm")
        : format(new Date(item.completedAt), "yyyy-MM-dd HH:mm")
      : "";

    let milliseconds = Math.abs(
      new Date(completedDate) - new Date(startedDate)
    );
    return milliseconds / 36e5 > 4;
  };

  // useEffect(() => {
  //   dispatch(fetchFilledTests());
  // }, [fetchFilledTests]);

  const appliedQueries = useMemo(() =>
    Object.fromEntries(
      Object.entries(filterQueries).map(([k, v]) => [
        k,
        v.replace(/\s+/g, " ").trim().toLowerCase().split(" "),
      ])
    )
  );

  const filteredTests = useMemo(
    () =>
      filledTests?.filter(
        fullFilter(appliedQueries, order, TEST_TYPE_TO_NAME_MAP)
      ),
    [filledTests, appliedQueries, order]
  );

  const filteredOrderItemsToActivate = useMemo(
    () =>
      orderItemsToActivate?.filter(
        partialFilter(appliedQueries, order, TEST_TYPE_TO_NAME_MAP)
      ),
    [orderItemsToActivate, appliedQueries, order]
  );

  const filteredOrderItemsAlreadyActivated = useMemo(
    () =>
      orderItemsAlreadyActivated?.filter(
        partialFilter2(appliedQueries, order, TEST_TYPE_TO_NAME_MAP)
      ),
    [orderItemsAlreadyActivated, appliedQueries, order]
  );

  const filteredOrderItemsWaitingToAssignCode = useMemo(
    () =>
      orderItemsWaitingToAssignCode?.filter(
        partialFilter3(
          appliedQueries,
          order,
          TEST_TYPE_TO_NAME_MAP,
          NO_NUMBER_ASSIGNED_DISPLAY
        )
      ),
    [orderItemsWaitingToAssignCode, appliedQueries, order]
  );

  const filteredOrderBundles = useMemo(
    () => [
      filteredTests && [
        ...filteredTests.map((ft) => ({ type: "filledTest", order: ft })),
      ],
      filteredOrderItemsToActivate && [
        ...filteredOrderItemsToActivate.map((ft) => ({
          type: "orderItemToActivate",
          order: ft,
        })),
      ],
      filteredOrderItemsAlreadyActivated && [
        ...filteredOrderItemsAlreadyActivated.map((ft) => ({
          type: "orderItemAlreadyActivated",
          order: ft,
        })),
      ],
      filteredOrderItemsWaitingToAssignCode && [
        ...filteredOrderItemsWaitingToAssignCode.map((ft) => ({
          type: "orderItemWaitingToAssignCode",
          order: ft,
        })),
      ],
    ],
    [
      filledTests,
      filteredOrderItemsToActivate,
      filteredOrderItemsAlreadyActivated,
      filteredOrderItemsWaitingToAssignCode,
    ]
  );

  const sortedFilteredOrderBundles = useMemo(() => {
    const cmp =
      sortDirection === "asc" ? (i1, i2) => i1 > i2 : (i1, i2) => i1 < i2;
    return !activeSortCol
      ? filteredOrderBundles
      : filteredOrderBundles.sort((ft1, ft2) => {
          if (activeSortCol === "code") {
            return cmp(ft1.order?.code, ft2.order?.code);
          } else if (activeSortCol === "testType") {
            return cmp(ft1.order?.testType, ft2.order?.testType);
          } else if (activeSortCol === "tenantName") {
            return 0;
          } else if (activeSortCol === "activatedBy") {
            return cmp(
              ft1.order?.activatedByFullName,
              ft2.order?.activatedByFullName
            );
          } else if (activeSortCol === "personDetails") {
            return cmp(
              `${ft1.order.personDetails?.firstName} ${ft1.order.personDetails?.surname}`,
              `${ft2.order.personDetails?.firstName} ${ft2.order.personDetails?.surname}`
            );
          } else if (activeSortCol === "createdAt") {
            return cmp(ft1.order?.createdAt, ft2.order?.createdAt);
          } else if (activeSortCol === "completedAt") {
            return cmp(ft1.order?.completedAt, ft2.order?.completedAt);
          } else if (activeSortCol === "result") {
            return cmp(
              ft1.order?.text ? "prawidłowy" : "nieprawidłowy",
              ft2.order?.text ? "prawidłowy" : "nieprawidłowy"
            );
          } else {
            console.log("error: sorting by invalid key");
            return 0;
          }
        });
  }, [filteredOrderBundles, activeSortCol, sortDirection]);

  const headData = [
    {
      title: "Zestaw",
      key: "code",
      sortable: true,
      filter: () => (
        <TextField
          className={classes.filter}
          type="text"
          onChange={({ target: { value } }) => {
            setFilterQueries((prevQueries) => ({
              ...prevQueries,
              code: value,
            }));
          }}
        />
      ),
    },
    {
      title: "Typ testu",
      key: "testType",
      sortable: true,
      filter: () => (
        <FormControl className={classes.formControl} fullWidth>
          <Select
            className={classes.filter}
            labelId="test-type-filter-label"
            id="test-type-filter"
            open={testTypeFilterOpen}
            label={"Filtruj po typie"}
            onClose={() => setTestTypeFilterOpen(false)}
            onOpen={() => setTestTypeFilterOpen(true)}
            onChange={({ target: { value } }) => {
              setFilterQueries((prevQueries) => ({
                ...prevQueries,
                testType: value,
              }));
            }}
            value={filterQueries.testType}
          >
            <MenuItem value="any">Dowolny</MenuItem>
            <MenuItem value="test węchu">Test węchu</MenuItem>
            <MenuItem value="test smaku">Test smaku</MenuItem>
            <MenuItem value="test smaku i węchu">Test smaku i węchu</MenuItem>
          </Select>
        </FormControl>
      ),
    },
    {
      title: "Właściciel",
      key: "tenantName",
      sortable: false,
      filter: () => (
        <TextField
          className={classes.filter}
          type="text"
          onChange={({ target: { value } }) => {
            setFilterQueries((prevQueries) => ({
              ...prevQueries,
              tenantName: value,
            }));
          }}
        />
      ),
    },
    {
      title: "Aktywowany przez",
      key: "activatedBy",
      sortable: true,
      filter: () => (
        <TextField
          className={classes.filter}
          type="text"
          onChange={({ target: { value } }) => {
            setFilterQueries((prevQueries) => ({
              ...prevQueries,
              activatedByFullName: value,
            }));
          }}
        />
      ),
    },
    {
      title: "Wypełniony przez",
      key: "filledByFullName",
      sortable: true,
      filter: () => (
        <TextField
          className={classes.filter}
          type="text"
          onChange={({ target: { value } }) => {
            setFilterQueries((prevQueries) => ({
              ...prevQueries,
              filledByFullName: value,
            }));
          }}
        />
      ),
    },
    {
      title: "Data rozpoczęcia wypełniania testu",
      key: "createdAt",
      sortable: true,
      filter: () => (
        <MuiPickersUtilsProvider locale={plLocale} utils={DateFnsUtils}>
          <KeyboardDatePicker
            className={classes.filter}
            size="small"
            margin="none"
            id={`created-at-filter`}
            format="yyyy-MM-dd"
            value={filterQueries.createdAt || null}
            onChange={(value) => {
              setFilterQueries((prevQueries) => ({
                ...prevQueries,
                createdAt: value.toISOString().slice(0, 10),
              }));
            }}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            cancelLabel={"Anuluj"}
            okLabel={"Zatwierdź"}
            fullWidth
            InputProps={{
              readOnly: true,
              endAdornment: (
                <IconButton
                  style={{ height: "15px", paddingLeft: "0px" }}
                  onClick={() => {
                    setFilterQueries((prevQueries) => ({
                      ...prevQueries,
                      createdAt: "",
                    }));
                  }}
                >
                  <ClearIcon size="small" />
                </IconButton>
              ),
            }}
            InputAdornmentProps={{
              position: "start",
            }}
          />
        </MuiPickersUtilsProvider>
      ),
    },
    {
      title: "Data zakończenia wypełniania testu",
      key: "completedAt",
      sortable: true,
      filter: () => (
        <MuiPickersUtilsProvider locale={plLocale} utils={DateFnsUtils}>
          <KeyboardDatePicker
            className={classes.filter}
            size="small"
            margin="none"
            id={`created-at-filter`}
            format="yyyy-MM-dd"
            value={filterQueries.completedAt || null}
            onChange={(value) => {
              setFilterQueries((prevQueries) => ({
                ...prevQueries,
                completedAt: value.toISOString().slice(0, 10),
              }));
            }}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            cancelLabel={"Anuluj"}
            okLabel={"Zatwierdź"}
            fullWidth
            InputProps={{
              readOnly: true,
              endAdornment: (
                <IconButton
                  style={{ height: "15px", paddingLeft: "0px" }}
                  onClick={() => {
                    setFilterQueries((prevQueries) => ({
                      ...prevQueries,
                      completedAt: "",
                    }));
                  }}
                >
                  <ClearIcon size="small" />
                </IconButton>
              ),
            }}
            InputAdornmentProps={{
              position: "start",
            }}
          />
        </MuiPickersUtilsProvider>
      ),
    },
    {
      title: "Wynik",
      key: "result",
      sortable: false,
      filter: () => (
        <FormControl className={classes.formControl} fullWidth>
          <Select
            className={classes.filter}
            labelId="result-filter-label"
            id="result-filter"
            open={resultFilterOpen}
            label={"Filtruj po wyniku"}
            onClose={() => setResultFilterOpen(false)}
            onOpen={() => setResultFilterOpen(true)}
            onChange={({ target: { value } }) => {
              setFilterQueries((prevQueries) => ({
                ...prevQueries,
                result: value,
              }));
            }}
            value={filterQueries.result}
          >
            <MenuItem value="any">Dowolny</MenuItem>
            <MenuItem value="prawidłowy">Prawidłowy</MenuItem>
            <MenuItem value="nieprawidłowy">Nieprawidłowy</MenuItem>
          </Select>
        </FormControl>
      ),
    },
    {
      title: "Pobierz wyniki",
      key: "resultDl",
      sortable: false,
      filter: () => null,
    },
    {
      title: "Podgląd",
      key: "resultDlAll",
      sortable: false,
      filter: () => null,
    },
  ];

  const page = () => (
    <Grid container>
      <AppBarTitle
        value={`Podgląd zamówienia ${
          orderWithTestResults
            ? orderWithTestResults.element?.orderNumber
            : "..."
        }`}
      />

      {fetchingData ? (
        <Grid item xs={12} style={{ padding: "16px" }}>
          <Box textAlign="center">
            <CircularProgress />
          </Box>
        </Grid>
      ) : (
        <Grid
          item
          container
          xs={12}
          style={{
            padding: smallScreen ? "50px 12px 12px 12px" : "12px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <h1
            aria-hidden="false"
            aria-label="Szczegóły zamówienia"
            style={{ display: "none" }}
          >
            Szczegóły zamówienia
          </h1>
          <Modal
            open={csvModalOpen}
            onClose={() => setCsvModalOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 400,
                backgroundColor: theme.palette.background.default,
                boxShadow: 24,
                padding: 6,
              }}
            >
              <Box>
                <Typography>Wyniki do pobrania w zakresie:</Typography>
                <FormControl>
                  <RadioGroup
                    value={csvPeriodType}
                    onChange={({ target: { value } }) => {
                      setCsvPeriodType(value);
                    }}
                  >
                    <FormControlLabel
                      value="all"
                      control={<Radio />}
                      label="Za cały okres"
                    ></FormControlLabel>
                    <FormControlLabel
                      value="period"
                      control={<Radio />}
                      label="W przedziale:"
                    ></FormControlLabel>
                  </RadioGroup>
                  <Box pt={4} pb={2}>
                    Od:
                    <MuiPickersUtilsProvider
                      locale={plLocale}
                      utils={DateFnsUtils}
                    >
                      <KeyboardDatePicker
                        disabled={csvPeriodType !== "period"}
                        style={{
                          backgroundColor: theme.palette.background.filter,
                          width: "40%",
                        }}
                        size="small"
                        margin="none"
                        id={`created-at-filter`}
                        format="yyyy-MM-dd"
                        value={csvPeriodStart || null}
                        onChange={(value) => {
                          setCsvPeriodStart(value.toISOString().slice(0, 10));
                        }}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        cancelLabel={"Anuluj"}
                        okLabel={"Zatwierdź"}
                        fullWidth
                        InputProps={{
                          readOnly: true,
                          endAdornment: (
                            <IconButton
                              disabled={csvPeriodType !== "period"}
                              style={{ height: "15px", paddingLeft: "0px" }}
                              onClick={() => {
                                setCsvPeriodStart("");
                              }}
                            >
                              <ClearIcon size="small" />
                            </IconButton>
                          ),
                        }}
                        InputAdornmentProps={{
                          position: "start",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                    Do:
                    <MuiPickersUtilsProvider
                      locale={plLocale}
                      utils={DateFnsUtils}
                    >
                      <KeyboardDatePicker
                        disabled={csvPeriodType !== "period"}
                        style={{
                          backgroundColor: theme.palette.background.filter,
                          width: "40%",
                        }}
                        size="small"
                        margin="none"
                        id={`created-at-filter`}
                        format="yyyy-MM-dd"
                        value={csvPeriodEnd || null}
                        onChange={(value) => {
                          setCsvPeriodEnd(value.toISOString().slice(0, 10));
                        }}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        cancelLabel={"Anuluj"}
                        okLabel={"Zatwierdź"}
                        fullWidth
                        InputProps={{
                          readOnly: true,
                          endAdornment: (
                            <IconButton
                              disabled={csvPeriodType !== "period"}
                              style={{ height: "15px", paddingLeft: "0px" }}
                              onClick={() => {
                                setCsvPeriodEnd("");
                              }}
                            >
                              <ClearIcon size="small" />
                            </IconButton>
                          ),
                        }}
                        InputAdornmentProps={{
                          position: "start",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Box>
                </FormControl>
              </Box>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <Button
                  variant={"contained"}
                  style={{
                    // backgroundColor: "#cccccc",
                    // color: "gray",
                    margin: 10,
                  }}
                  onClick={() => {
                    setCsvPeriodStart("");
                    setCsvPeriodEnd("");
                    setCsvPeriodType("all");
                    setCsvModalOpen(false);
                  }}
                >
                  Anuluj
                </Button>
                <Button
                  variant={"contained"}
                  color="primary"
                  style={{
                    // backgroundColor: !preparingCSV ? "blue" : "#cccccc",
                    // color: !preparingCSV ? "white" : "#666666",
                    margin: 10,
                  }}
                  disabled={preparingCSV}
                  onClick={() => {
                    const periodS =
                      csvPeriodType === "period" ? csvPeriodStart : "";
                    const periodE =
                      csvPeriodType === "period" ? csvPeriodEnd : "";
                    setCsvPeriodStart("");
                    setCsvPeriodEnd("");
                    setCsvPeriodType("all");
                    setPreparingCSV(true);
                    setCsvModalOpen(false);
                    getAxiosInstance()
                      .get(`/api/orders/${orderId}/csv`, {
                        params: {
                          isExternal,
                          csvPeriodStart: periodS,
                          csvPeriodEnd: periodE,
                        },
                      })
                      .then((data) => {
                        const fileName = data.headers[
                          "content-disposition"
                        ].slice(22, -1);
                        const element = document.createElement("a");
                        const file = new Blob([data.data], {
                          type: "text/csv",
                        });
                        element.href = URL.createObjectURL(file);
                        element.download = fileName;
                        document.body.appendChild(element);
                        element.click();
                        setPreparingCSV(false);
                      })
                      .catch(() => {
                        // dispatch(
                        //   alertAdd({
                        //     text: "Nie udało się pobrać danych",
                        //     isError: true,
                        //   })
                        // );
                        console.log("Nie udało się pobrać danych");
                        setPreparingCSV(false);
                      });
                  }}
                >
                  {!preparingCSV ? (
                    "Pobierz"
                  ) : (
                    <CircularProgress size={24} style={{ color: "white" }} />
                  )}
                </Button>
              </Box>
            </Box>
          </Modal>
          <Grid item xs={12} md={10}>
            <Button
              variant={"contained"}
              color="primary"
              style={{
                // backgroundColor: !preparingCSV ? "#102655" : "#cccccc",
                // color: !preparingCSV ? "white" : "#666666",
                margin: 10,
              }}
              onClick={() => setCsvModalOpen(true)}
              disabled={preparingCSV}
            >
              {!preparingCSV ? (
                "Pobierz wyniki w formacie CSV"
              ) : (
                <CircularProgress size={24} style={{ color: "white" }} />
              )}
            </Button>
          </Grid>
          <Grid item xs={12} md={10} style={{ marginTop: "24px" }}>
            <Typography
              variant="h5"
              style={{ color: theme.palette.text.alternative2 }}
            >
              Wyniki
            </Typography>
            <TableContainer className={s.tableContainer}>
              {/* <TableContainer style={{ maxHeight: "clamp(10vh,67vh,90vh)" }}> */}
              <Table>
                <TableHead>
                  <TableRow>
                    <MuiFirstHeaderTableCell>Lp.</MuiFirstHeaderTableCell>
                    {headData.map((header, index) => (
                      <PaddingTableCell
                        key={index}
                        // style={{ color: "#122140" }}
                      >
                        {header.sortable ? (
                          <div style={{ display: "flex" }}>
                            {header.title}
                            <TableSortLabel
                              classes={{
                                // Override with the active class if this is the selected column or inactive otherwise
                                icon:
                                  orderBy === header.key
                                    ? classes.activeSortIcon
                                    : classes.inactiveSortIcon,
                              }}
                              // active={orderBy === header.key}
                              direction={
                                orderBy === header.key ? orderDirection : "asc"
                              }
                              onClick={() => {
                                handleSortRequest(header.key);
                              }}
                            />
                          </div>
                        ) : (
                          header.title
                        )}
                      </PaddingTableCell>
                    ))}
                  </TableRow>
                  <TableRow>
                    <MuiFirstHeaderTableCell />
                    {headData.map((header, index) => (
                      <PaddingTableCell align="right" key={index}>
                        {header.filter()}
                      </PaddingTableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {[
                    ...sortedFilteredOrderBundles[0],
                    ...sortedFilteredOrderBundles[1],
                    ...sortedFilteredOrderBundles[2],
                    ...sortedFilteredOrderBundles[3],
                  ]?.map((itemBundle, id) => {
                    const item = itemBundle?.order;
                    if (itemBundle?.type === "filledTest") {
                      const {
                        personDetails = { firstName: "", surname: "" },
                      } = item;
                      return (
                        <TableRow key={id}>
                          <MuiFirstHeaderTableCell>
                            {`${id + 1 + pageNr * rowsPerPage}.`}
                          </MuiFirstHeaderTableCell>
                          <PaddingTableCell align="right">
                            {item.code}
                          </PaddingTableCell>
                          <PaddingTableCell align="right">
                            {TEST_TYPE_TO_NAME_MAP[item.testType]}
                          </PaddingTableCell>
                          <PaddingTableCell align="right">
                            {order.tenantName}
                          </PaddingTableCell>
                          <PaddingTableCell align="right">
                            {item.activatedByFullName || item.activatedBy || (
                              <span className={classes.removedAccount}>
                                konto usunięte
                              </span>
                            )}
                          </PaddingTableCell>
                          <PaddingTableCell align="right">
                            {personDetails.firstName &&
                            personDetails.surname ? (
                              `${personDetails.firstName} ${personDetails.surname}`
                            ) : (
                              <span className={classes.removedAccount}>
                                konto usunięte
                              </span>
                            )}
                          </PaddingTableCell>
                          <PaddingTableCell align="right">
                            {item.createdAt
                              ? item.createdAt[item.createdAt.length - 1] !==
                                "Z"
                                ? format(
                                    new Date(item.createdAt.concat("Z")),
                                    "yyyy-MM-dd HH:mm"
                                  )
                                : format(
                                    new Date(item.createdAt),
                                    "yyyy-MM-dd HH:mm"
                                  )
                              : ""}
                          </PaddingTableCell>
                          <PaddingTableCell align="right">
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {item.completedAt
                                ? item.completedAt[
                                    item.completedAt.length - 1
                                  ] !== "Z"
                                  ? format(
                                      new Date(item.completedAt.concat("Z")),
                                      "yyyy-MM-dd HH:mm"
                                    )
                                  : format(
                                      new Date(item.completedAt),
                                      "yyyy-MM-dd HH:mm"
                                    )
                                : ""}
                              {moreThan4Hours(item) && (
                                <NegativeResultTooltip title="UWAGA! Czas wypełniania testu przekroczył 4 godziny">
                                  <PriorityHighIcon
                                    size="small"
                                    style={{
                                      cursor: "pointer",
                                      // color: "red",
                                    }}
                                  />
                                </NegativeResultTooltip>
                              )}
                            </div>
                          </PaddingTableCell>

                          {item.completedAt ? (
                            <PaddingTableCell align="right">
                              {!item.isShared && <span>Brak zgody</span>}
                              {item.isShared && item.text === "ok" && (
                                <span className={classes.positiveResult}>
                                  Prawidłowy
                                </span>
                              )}
                              {item.isShared && item.text !== "ok" && (
                                <span className={classes.negativeResult}>
                                  Nieprawidłowy
                                  {((item.testType === "TASTE_TEST" &&
                                    (item.totalWeight / 4) * 100 < 26) ||
                                    (item.testType === "SMELL_TEST" &&
                                      (item.totalWeight / 6) * 100 < 26)) && (
                                    <NegativeResultTooltip title="UWAGA! Liczba poprawnych odpowiedzi jest mniejsza, bądź równa 25%">
                                      <PriorityHighIcon
                                        size="small"
                                        style={{ cursor: "pointer" }}
                                      />
                                    </NegativeResultTooltip>
                                  )}
                                </span>
                              )}
                            </PaddingTableCell>
                          ) : (
                            <PaddingTableCell align="right">-</PaddingTableCell>
                          )}
                          <PaddingTableCell align="right">
                            {item.completedAt ? (
                              <>
                                <a
                                  title="Wynik"
                                  href={`/api/form_data/${item.id}/pdf_report`}
                                >
                                  {/* <PictureAsPdfIcon /> */}
                                  <Tooltip title="Pobierz wynik ogólny">
                                    {/* <PDFIcon /> */}
                                    <img
                                      alt="Pobierz wynik ogólny"
                                      src={imgPDF}
                                      style={{ height: "28px" }}
                                    ></img>
                                  </Tooltip>
                                </a>

                                <a
                                  title="Wynik szczegółowy"
                                  href={`/api/form_data/${item.id}/pdf_report?detailed=true`}
                                >
                                  <Tooltip title="Pobierz wynik szczegółowy">
                                    <img
                                      alt="Pobierz wynik szczegółowy"
                                      src={imgPDF}
                                      style={{ height: "40px" }}
                                    ></img>
                                  </Tooltip>
                                </a>
                              </>
                            ) : (
                              "-"
                            )}
                          </PaddingTableCell>
                          <PaddingTableCell style={{ textAlign: "center" }}>
                            <NavLink
                              to={{
                                pathname: `/tests/result/${item.id}`,
                              }}
                              style={{
                                pointerEvents: item.isShared ? "" : "none",
                              }}
                            >
                              {/* Pokaż wynik szczegółowy */}
                              <img
                                alt="Podgląd wyniku"
                                src={magnifierImage}
                                style={{ height: "40px", width: "40px" }}
                              ></img>
                            </NavLink>
                          </PaddingTableCell>
                        </TableRow>
                      );
                    } else if (itemBundle?.type === "orderItemToActivate") {
                      return (
                        <TableRow key={id}>
                          <MuiFirstHeaderTableCell>
                            {`${id + 1 + pageNr * rowsPerPage}.`}
                          </MuiFirstHeaderTableCell>
                          <PaddingTableCell align="right">
                            {item.code}****
                          </PaddingTableCell>
                          <PaddingTableCell align="right">
                            {TEST_TYPE_TO_NAME_MAP[item.testType]}
                          </PaddingTableCell>
                          <PaddingTableCell align="right">
                            {order.tenantName}
                          </PaddingTableCell>
                          <PaddingTableCell align="right"></PaddingTableCell>
                          <PaddingTableCell align="right"></PaddingTableCell>
                          <PaddingTableCell align="right"></PaddingTableCell>
                          <PaddingTableCell align="right"></PaddingTableCell>
                          <PaddingTableCell align="right"></PaddingTableCell>
                          <PaddingTableCell align="right"></PaddingTableCell>
                          <PaddingTableCell align="right"></PaddingTableCell>
                        </TableRow>
                      );
                    } else if (
                      itemBundle?.type === "orderItemAlreadyActivated"
                    ) {
                      return (
                        <TableRow key={id}>
                          {/* <MuiFirstBodyTableCell>
                            {`${id + 1 + pageNr * rowsPerPage}.`}
                          </MuiFirstBodyTableCell> */}
                          <MuiFirstHeaderTableCell>
                            {`${id + 1 + pageNr * rowsPerPage}.`}
                          </MuiFirstHeaderTableCell>
                          <PaddingTableCell align="right">
                            {item.code}
                          </PaddingTableCell>

                          <PaddingTableCell align="right">
                            {TEST_TYPE_TO_NAME_MAP[item.testType]}
                          </PaddingTableCell>
                          <PaddingTableCell align="right">
                            {order.tenantName}
                          </PaddingTableCell>
                          <PaddingTableCell align="right">
                            {item.activatedByFullName || item.activatedBy || (
                              <span className={classes.removedAccount}>
                                konto usunięte
                              </span>
                            )}
                          </PaddingTableCell>
                          <PaddingTableCell align="right"></PaddingTableCell>
                          <PaddingTableCell align="right"></PaddingTableCell>
                          <PaddingTableCell align="right"></PaddingTableCell>
                          <PaddingTableCell align="right"></PaddingTableCell>
                          <PaddingTableCell align="right"></PaddingTableCell>
                          <PaddingTableCell align="right"></PaddingTableCell>
                        </TableRow>
                      );
                    } else if (
                      itemBundle
                        ? itemBundle?.type === "orderItemWaitingToAssignCode"
                        : false
                    ) {
                      return (
                        <TableRow key={id}>
                          <MuiFirstHeaderTableCell>
                            {` ${id + 1}.`}
                          </MuiFirstHeaderTableCell>
                          <PaddingTableCell
                            align="right"
                            className={classes.waitingForCode}
                          >
                            {NO_NUMBER_ASSIGNED_DISPLAY}
                          </PaddingTableCell>
                          <PaddingTableCell align="right">
                            {TEST_TYPE_TO_NAME_MAP[item.testType]}
                          </PaddingTableCell>
                          <PaddingTableCell align="right">
                            {order.tenantName}
                          </PaddingTableCell>
                          <PaddingTableCell align="right"></PaddingTableCell>
                          <PaddingTableCell align="right"></PaddingTableCell>
                          <PaddingTableCell align="right"></PaddingTableCell>
                          <PaddingTableCell align="right"></PaddingTableCell>
                          <PaddingTableCell align="right"></PaddingTableCell>
                          <PaddingTableCell align="right"></PaddingTableCell>
                          <PaddingTableCell align="right"></PaddingTableCell>
                        </TableRow>
                      );
                    } else {
                      return <TableRow>niewłaściwy typ pola</TableRow>;
                    }
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      )}
    </Grid>
  );

  const redirect = () => <Redirect to="/tests/active" />;

  return <Can permission="filled-kits:page" ok={page} not={redirect} />;
};

export default FilledKits;
