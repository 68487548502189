import React from "react";
import { TableCell, TableRow } from "@material-ui/core";

const EmployeeRow = ({
  loggedAsOwner,
  employeeData: { email, name, roles },
}) => {
  return !loggedAsOwner && roles.includes("SystemOwner") ? null : (
    <TableRow>
      <TableCell>{name}</TableCell>
      <TableCell>{email}</TableCell>
    </TableRow>
  );
};

export default EmployeeRow;
