import { getAxiosInstance } from "../common";
import {
  DONE,
  FAILED,
  FETCH_CART_ITEMS,
  REMOVE_CART_ITEM_DONE,
  REMOVE_CART_ITEM_FAILED,
  REMOVE_CART_ITEM_START,
  START,
  FETCH_PRICE_LIST,
  BUY_CART_ITEM,
  PURCHASE_ITEM_PAYMENT,
  PURCHASE_ITEMS_PAYMENT,
  PAYMENT_STATUS,
  PAYMENT_VERIFY,
  PAYMENT_CANCEL,
  WATCH_MY_ORDER,
} from "../../constants";

export const fetchCartItems = (payload) => async (dispatch) => {
  dispatch(fetchCartItemsStart());
  try {
    const reponse = await getAxiosInstance().get(`/api/cart`);

    dispatch(fetchCartItemsDone(reponse.data));
    return true;
  } catch (e) {
    dispatch(fetchCartItemsFailed(e));
    return false;
  }
};

export const fetchCartItemsStart = (payload) => ({
  type: FETCH_CART_ITEMS + START,
  payload,
});
export const fetchCartItemsDone = (payload) => ({
  type: FETCH_CART_ITEMS + DONE,
  payload,
});
export const fetchCartItemsFailed = (payload) => ({
  type: FETCH_CART_ITEMS + FAILED,
  payload,
});

export const buyCartItem = (payload) => async (dispatch) => {
  dispatch({ type: BUY_CART_ITEM + START, payload });

  try {
    const response = await getAxiosInstance().put(`/api/cart/${payload}/buy`);

    dispatch({ type: BUY_CART_ITEM + DONE, payload });
    return response.data;
  } catch (e) {
    dispatch({ type: BUY_CART_ITEM + FAILED, e });
    return false;
  }
};

export const removeCartItem = (payload) => async (dispatch) => {
  dispatch(removeCartItemStart());
  try {
    await getAxiosInstance().delete(`/api/cart/${payload}`);

    dispatch(removeCartItemDone(payload));
    return true;
  } catch (e) {
    dispatch(removeCartItemFailed(e));
    return false;
  }
};

export const removeCartItemStart = (payload) => ({
  type: REMOVE_CART_ITEM_START,
  payload,
});
export const removeCartItemDone = (payload) => ({
  type: REMOVE_CART_ITEM_DONE,
  payload,
});
export const removeCartItemFailed = (payload) => ({
  type: REMOVE_CART_ITEM_FAILED,
  payload,
});

export const fetchPriceList = (payload) => async (dispatch) => {
  dispatch({ type: FETCH_PRICE_LIST + START });
  try {
    const result = await getAxiosInstance().get(`/payment/v1/price?country=PL`);

    dispatch({ type: FETCH_PRICE_LIST + DONE, payload: result.data });
  } catch (e) {
    dispatch({ type: FETCH_PRICE_LIST + FAILED });
  }
};

export const purchaseItemPayment = ({
  product,
  quantity,
  myOrderId,
  billingInfo,
}) => async (dispatch) => {
  dispatch({ type: PURCHASE_ITEM_PAYMENT + START });

  try {
    const token = await getAxiosInstance().post(
      `/payment/v1/p24/start?product=${product}&quantity=${quantity}&myOrderId=${myOrderId}`,
      {
        ...(billingInfo ? { billingInfo } : {}),
      }
    );

    dispatch({ type: PURCHASE_ITEM_PAYMENT + DONE });
    return token;
  } catch (e) {
    dispatch({ type: PURCHASE_ITEM_PAYMENT + FAILED, e });
    return false;
  }
};

export const purchaseItemsPayment = (myOrderId, items, billingInfo) => async (
  dispatch
) => {
  dispatch({ type: PURCHASE_ITEMS_PAYMENT + START });

  try {
    const token = await getAxiosInstance().post(
      `/payment/v1/p24/basket/start?myOrderId=${myOrderId}`,
      {
        items,
        ...(billingInfo
          ? {
              billingInfo: {
                country: "PL",
                billingDataPayload: {
                  company: billingInfo.name,
                  taxId: billingInfo.taxId,
                  street: billingInfo.street,
                  zipCode: billingInfo.zipCode,
                  city: billingInfo.city,
                  post: billingInfo.post,
                  ...(billingInfo.emailTo
                    ? { emailTo: billingInfo.emailTo }
                    : {}),
                  // emailTo:
                },
              },
            }
          : {}),
      }
    );

    dispatch({ type: PURCHASE_ITEMS_PAYMENT + DONE });
    return token;
  } catch (e) {
    dispatch({ type: PURCHASE_ITEMS_PAYMENT + FAILED, e });
    return false;
  }
};

export const paymentStatus = () => async (dispatch) => {
  dispatch({ type: PAYMENT_STATUS + START });

  try {
    const response = await getAxiosInstance().get(`/payment/v1/p24/status`);
    // const response = await getAxiosInstance().put(`/payment/v1/p24/rollback`);

    dispatch({ type: PAYMENT_STATUS + DONE, payload: response.data });

    return response.data;
  } catch (e) {
    dispatch({ type: PAYMENT_STATUS + FAILED, e });
    return false;
  }
};

export const paymentVerify = () => async (dispatch) => {
  dispatch({ type: PAYMENT_VERIFY + START });

  try {
    const response = await getAxiosInstance().put(`/payment/v1/p24/verify`);

    dispatch({
      type: PAYMENT_VERIFY + DONE,
      payload: response.data,
      httpCode: response.status,
    });

    return response;
  } catch (e) {
    dispatch({ type: PAYMENT_VERIFY + FAILED, e });
    return false;
  }
};

export const paymentCancel = () => async (dispatch) => {
  dispatch({ type: PAYMENT_CANCEL + START });
  try {
    const response = await getAxiosInstance().put(`/payment/v1/p24/rollback`);

    dispatch({ type: PAYMENT_CANCEL + DONE, payload: response.data });

    return response.data;
  } catch (e) {
    dispatch({ type: PAYMENT_CANCEL + FAILED, e });
    return false;
  }
};

export const watchMyOrder = () => async (dispatch) => {
  dispatch({ type: WATCH_MY_ORDER + START });

  try {
    await getAxiosInstance().post(`/api/orders/transaction/watch`);

    dispatch({ type: WATCH_MY_ORDER + DONE });

    return true;
  } catch (e) {
    dispatch({ type: WATCH_MY_ORDER + FAILED, e });
    return false;
  }
};
