const FilterParts = {
  code: (test, appliedQueries) =>
    appliedQueries.code.every((queryPart) =>
      test.code?.toLowerCase().includes(queryPart)
    ),
  codeSupplied: (supplied, appliedQueries) =>
    appliedQueries.code.every((queryPart) =>
      supplied.toLowerCase().includes(queryPart)
    ),

  testType: (test, appliedQueries, typeToName) =>
    appliedQueries.testType[0] === "any" ||
    (test.testType &&
      typeToName[test.testType].toLowerCase() ===
        appliedQueries.testType.join(" ")),

  tenantName: (order, appliedQueries) =>
    appliedQueries.tenantName.every((queryPart) =>
      order.tenantName?.toLowerCase().includes(queryPart)
    ),

  activatedByFullName: (test, appliedQueries) =>
    appliedQueries.activatedByFullName.every((queryPart) =>
      test.activatedByFullName?.toLowerCase().includes(queryPart)
    ),
  activatedByFullNameEmpty: (appliedQueries) =>
    appliedQueries.activatedByFullName.every((queryPart) => queryPart === ""),

  completedAt: (test, appliedQueries) =>
    appliedQueries.completedAt.every((queryPart) =>
      test.completedAt?.toLowerCase().includes(queryPart)
    ),
  completedAtEmpty: (appliedQueries) =>
    appliedQueries.completedAt.every((queryPart) => queryPart === ""),

  createdAt: (test, appliedQueries) =>
    appliedQueries.createdAt.every((queryPart) =>
      test.createdAt?.toLowerCase().includes(queryPart)
    ),
  createdAtEmpty: (appliedQueries) =>
    appliedQueries.createdAt.every((queryPart) => queryPart === ""),

  createdByFullName: (test, appliedQueries) =>
    appliedQueries.createdByFullName.every((queryPart) =>
      test.createdByFullName?.toLowerCase().includes(queryPart)
    ),
  createdByFullNameEmpty: (appliedQueries) =>
    appliedQueries.createdByFullName.every((queryPart) => queryPart === ""),

  filledByFullName: (test, appliedQueries) =>
    appliedQueries.filledByFullName.every((queryPart) =>
      test.filledByFullName?.toLowerCase().includes(queryPart)
    ),
  filledByFullNameEmpty: (appliedQueries) =>
    appliedQueries.filledByFullName.every((queryPart) => queryPart === ""),

  result: (test, appliedQueries) =>
    appliedQueries.result.every(
      (queryPart) =>
        queryPart === "any" ||
        (test.text ? "prawidłowy" : "nieprawidłowy") === queryPart
    ),
  resultEmpty: (appliedQueries) =>
    appliedQueries.result.every((queryPart) => queryPart === "any"),
};

const fullFilter = (appliedQueries, order, typeToName) => (test) =>
  FilterParts.activatedByFullName(test, appliedQueries) &&
  FilterParts.code(test, appliedQueries) &&
  FilterParts.completedAt(test, appliedQueries) &&
  FilterParts.createdAt(test, appliedQueries) &&
  FilterParts.createdByFullName(test, appliedQueries) &&
  FilterParts.filledByFullName(test, appliedQueries) &&
  FilterParts.result(test, appliedQueries) &&
  FilterParts.tenantName(order, appliedQueries) &&
  FilterParts.testType(test, appliedQueries, typeToName);

const partialFilter = (appliedQueries, order, typeToName) => (test) =>
  FilterParts.code(test, appliedQueries) &&
  FilterParts.testType(test, appliedQueries, typeToName) &&
  FilterParts.tenantName(order, appliedQueries) &&
  FilterParts.activatedByFullNameEmpty(appliedQueries) &&
  FilterParts.completedAtEmpty(appliedQueries) &&
  FilterParts.createdAtEmpty(appliedQueries) &&
  FilterParts.createdByFullNameEmpty(appliedQueries) &&
  FilterParts.filledByFullNameEmpty(appliedQueries) &&
  FilterParts.resultEmpty(appliedQueries);

const partialFilter2 = (appliedQueries, order, typeToName) => (test) =>
  FilterParts.code(test, appliedQueries) &&
  FilterParts.testType(test, appliedQueries, typeToName) &&
  FilterParts.tenantName(order, appliedQueries) &&
  FilterParts.activatedByFullName(test, appliedQueries) &&
  FilterParts.completedAtEmpty(appliedQueries) &&
  FilterParts.createdAtEmpty(appliedQueries) &&
  FilterParts.createdByFullNameEmpty(appliedQueries) &&
  FilterParts.filledByFullNameEmpty(appliedQueries) &&
  FilterParts.resultEmpty(appliedQueries);

const partialFilter3 = (appliedQueries, order, typeToName, supplied) => (
  test
) =>
  FilterParts.codeSupplied(supplied, appliedQueries) &&
  FilterParts.testType(test, appliedQueries, typeToName) &&
  FilterParts.tenantName(order, appliedQueries) &&
  FilterParts.activatedByFullNameEmpty(appliedQueries) &&
  FilterParts.completedAtEmpty(appliedQueries) &&
  FilterParts.createdAtEmpty(appliedQueries) &&
  FilterParts.createdByFullNameEmpty(appliedQueries) &&
  FilterParts.filledByFullNameEmpty(appliedQueries) &&
  FilterParts.resultEmpty(appliedQueries);

export { fullFilter, partialFilter, partialFilter2, partialFilter3 };
