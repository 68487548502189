import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { fetchForms, fetchOrganization, fetchOrgUsers } from "../redux/actions";
import Can from "../components/can";
import { NavLink, Redirect, useHistory } from "react-router-dom";
import { checkPermission } from "../rbac";
import Alert from "@material-ui/lab/Alert";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import PersonIcon from "@material-ui/icons/Person";
import PageTitle from "../components/page-title";
import AppBarTitle from "../components/app-bar-title";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Link from "@material-ui/core/Link";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";
import FormatListBulletedOutlinedIcon from "@material-ui/icons/FormatListBulletedOutlined";
import FormatListNumberedOutlinedIcon from "@material-ui/icons/FormatListNumberedOutlined";
import image1 from "../assets/shopping-cart.svg";
import image2 from "../assets/main2.svg";
import image3 from "../assets/main3.svg";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const MuiNavLink = withStyles((theme) => ({
  root: {
    color: theme.palette.anchor.primary,
    underline: "always",
    "&:hover": {
      color: theme.palette.text.primary,
    },
  },
}))(Link);

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 250,
    minHeight: 250,
    borderRadius: "1rem",
    border: `0.5px solid ${theme.palette.hr.primary}`,
    boxShadow: "none",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    flex: "1",
  },
  media: {
    height: 140,
  },
  gridCard: {
    display: "flex",
    flexDirection: "row",
    marginTop: "24px",
    "@media (max-width: 1000px)": {
      flexDirection: "column",
    },
    flexWrap: "nowrap",
  },
  label: {
    letterSpacing: "0.3px",
    fontWeight: "300",
    fontSize: theme.typography.headerLarge,
    color: theme.palette.text.email,
    "@media (max-width: 900px)": {
      fontSize: "1.25rem",
      letterSpacing: "0.27px",
    },
  },
  labelEmail: {
    letterSpacing: "0.3px",
    fontWeight: "400",
    fontSize: theme.typography.headerLarge,
    color: theme.palette.text.email,
    "@media (max-width: 900px)": {
      fontSize: "0.9rem",
    },
  },
  redirectCardTitle: {
    color: theme.palette.text.alternative2,
    fontSize: theme.typography.redirectCardTitle,
  },
  redirectCardText: {
    color: theme.palette.text.alternative3,
  },
}));

const cartOrganizationItems = [
  {
    title: "Zamów testy",
    icon: image1,
    url: "/test-kit",
    text:
      "Po dokonaniu zamówienia otrzymujesz pod wskazany adres koperty z testami. Udostępniasz testy swoim pacjentom lub podopiecznym.",
  },
  {
    title: "Sprawdź wyniki",
    icon: image2,
    url: "/orders",
    text:
      "Po wykonaniu testów przez pacjenta lub podopiecznego, uzyskujesz wgląd do wyników badań stanu zmysłów smaku lub/i węchu.",
  },
];

const cartPersonalItems = [
  {
    title: "Zamów testy",
    icon: image1,
    iconRender: () => (
      <ShoppingCartOutlinedIcon fontSize="large" style={{ color: "#00346A" }} />
    ),
    url: "/test-kit",
    text:
      "Po dokonaniu zamówienia otrzymujesz pod wskazany adres kopertę z testami oraz kodami aktywacyjnymi. Testy możesz wykonać osobiście lub przekazać je do wykonania swojemu dziecku lub podopiecznemu",
  },
  {
    title: "Wykonaj test",
    icon: image3,
    url: "/tests/set/add",
    text:
      "Aktywujesz testy i przystępujesz do badania. Wykonanie testów jest intuicyjne, system prowadzi Cię krok po kroku. Wyniki uzyskujesz natychmiast.",
  },
  {
    title: "Sprawdź wyniki",
    icon: image2,
    url: "/tests",
    text:
      "Po wykonaniu testów uzyskujesz natychmiastowe wyniki, które wskazują na stan zmysłów smaku lub/i węchu.",
  },
];

const Main = ({ fetchOrgUsers, match, globalTheme }) => {
  const my = useSelector((state) => state.my);
  const roles = useSelector((state) => state.roles);
  // const globalTheme = useSelector((s) => s.globalTheme || "light");
  const isPersonalAccount = useSelector(
    (s) => s.organization?.accountType === "PERSONAL"
  );
  const [actions, setActions] = useState([]);
  const dispatch = useDispatch();

  const leftMenu = useMediaQuery((theme) => theme.breakpoints.down(1563));
  const classes = useStyles();

  useEffect(() => {
    if (checkPermission("admin:fetchOrgUsers", roles)) {
      fetchOrgUsers();
    }
  }, [roles]);

  useEffect(() => {
    if (checkPermission("admin:fetchOrgForms", roles)) {
      dispatch(fetchForms());
    }
  }, [roles]);

  useEffect(() => {
    if (checkPermission("admin:fetchOrgReports", roles)) {
    }
  }, [roles]);

  useEffect(() => {
    let res = [];

    if (my) {
      if (!my.firstName) {
        res.push(
          <Box mt={1} key={"fillProfileAlert"}>
            {globalTheme === "high-contrast" ? (
              <Alert
                icon={<PersonIcon />}
                severity="warning"
                style={{
                  backgroundColor: "#000000",
                  border: "1px solid #ffff00",
                  color: "#ffff00",
                }}
              >
                Prosimy o wypełnienie profilu.{" "}
                <MuiNavLink href="/profile">PRZEJDŹ DO PROFILU</MuiNavLink>
              </Alert>
            ) : (
              <Alert icon={<PersonIcon />} severity="warning">
                Prosimy o wypełnienie profilu.{" "}
                <MuiNavLink href="/profile">PRZEJDŹ DO PROFILU</MuiNavLink>
              </Alert>
            )}
          </Box>
        );
      }

      if (!my.activated) {
        res.push(
          <Box mt={1} key={"activateAccountAlert"}>
            {globalTheme === "high-contrast" ? (
              <Alert
                icon={<PersonIcon />}
                severity="warning"
                style={{
                  backgroundColor: "#000000",
                  border: "1px solid #ffff00",
                  color: "#ffff00",
                }}
              >
                Konto nie zostało aktywowane - prosimy kliknąć w link
                aktywacyjny wysłany w mailu powitalnym.
              </Alert>
            ) : (
              <Alert icon={<PersonIcon />} severity="warning">
                Konto nie zostało aktywowane - prosimy kliknąć w link
                aktywacyjny wysłany w mailu powitalnym.
              </Alert>
            )}
          </Box>
        );
      }
    }

    if (my?.anonimizationRequestedAt && leftMenu) {
      res.push(
        <Box mt={1} key={"removeRequestAlert"}>
          {globalTheme === "high-contrast" ? (
            <Alert
              severity="error"
              style={{
                backgroundColor: "#000000",
                border: "1px solid #ffff00",
                color: "#ffff00",
              }}
            >
              Twoje konto zostanie wkrótce usunięte!
            </Alert>
          ) : (
            <Alert severity="error">
              Twoje konto zostanie wkrótce usunięte!
            </Alert>
          )}
        </Box>
      );
    }
    setActions(res);
  }, [my, leftMenu, globalTheme]);

  // useEffect(() => {
  //   if (
  //     !actions.find((a) => a.key === "removeRequestAlert") &&
  //     my?.anonimizationRequestedAt &&
  //     actions &&
  //     leftMenu
  //   ) {
  //     setActions([
  //       ...actions,
  //       <Box mt={1} key={"removeRequestAlert"}>
  //         {globalTheme === "high-contrast" ? (
  //           <Alert
  //             severity="error"
  //             style={{
  //               backgroundColor: "#000000",
  //               border: "1px solid #ffff00",
  //               color: "#ffff00",
  //             }}
  //           >
  //             Twoje konto zostanie wkrótce usunięte!
  //           </Alert>
  //         ) : (
  //           <Alert severity="error">
  //             Twoje konto zostanie wkrótce usunięte!
  //           </Alert>
  //         )}
  //       </Box>,
  //     ]);
  //   }

  //   if (!leftMenu && actions.find((a) => a.key === "removeRequestAlert")) {
  //     setActions(actions.filter((a) => a.key !== "removeRequestAlert"));
  //   }
  // }, [leftMenu, globalTheme]);

  const loggedUserMainPage = () => (
    <Box>
      <AppBarTitle value="Start" />
      <h1
        aria-hidden="false"
        aria-label="Strona startowa"
        style={{ display: "none" }}
      >
        Strona startowa
      </h1>
      {/* <PageTitle title="Dzisiejsze akcje" /> */}
      {actions.length > 0 ? actions : <span></span>}
      <Grid
        container
        style={{
          margin: "50px auto",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Grid item xs={10} xl={8}>
          <Typography
            variant="h4"
            gutterBottom
            style={{
              // color: "#303030",
              marginTop: actions.length === 0 && "24px",
            }}
            className={classes.label}
          >
            Witaj, jesteś zalogowany jako
          </Typography>
        </Grid>
        <Grid item xs={10} xl={8}>
          {" "}
          <Typography
            variant="h3"
            gutterBottom
            // style={{ color: "#282829" }}
            className={classes.labelEmail}
          >
            {`${
              my.firstName && my.surname
                ? `${my.firstName.toUpperCase()} ${my.surname.toUpperCase()}`
                : `${my.user.email}`
            }`}
          </Typography>
        </Grid>
        <Grid
          item
          xs={10}
          xl={8}
          container
          justify="space-around"
          spacing={3}
          className={classes.gridCard}
        >
          {isPersonalAccount
            ? cartPersonalItems.map((item) => (
                <Grid item xs={4} style={{ display: "flex" }}>
                  <RedirectCard
                    title={item.title}
                    text={item.text}
                    url={item.url}
                    icon={item.icon}
                  />
                </Grid>
              ))
            : cartOrganizationItems.map((item) => (
                <Grid item xs={6}>
                  <RedirectCard
                    title={item.title}
                    text={item.text}
                    url={item.url}
                    icon={item.icon}
                  />
                </Grid>
              ))}
        </Grid>
      </Grid>
    </Box>
  );

  return (
    <>
      <Can
        permission="main:view"
        ok={loggedUserMainPage}
        not={() => <Redirect to="/start" />}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  orgUsers: state.orgUsers,
  orgReports: state.orgReports,
  organization: state.organization,
  globalTheme: state.globalTheme,
});

const mapDispatchToProps = (dispatch) => ({
  fetchOrgUsers: () => dispatch(fetchOrgUsers()),
  fetchForms: () => dispatch(fetchForms()),
  fetchOrganization: () => dispatch(fetchOrganization()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Main);

const RedirectCard = (props) => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <Card className={classes.root}>
      <CardContent style={{ padding: "24px 12px 12px 24px" }}>
        <Grid container direction="row" alignItems="center" spacing={2}>
          <Grid>
            <img alt="" src={props.icon}></img>
          </Grid>
          <Grid item>
            <Typography
              gutterBottom
              variant="h5"
              className={classes.redirectCardTitle}
            >
              {props.title}
            </Typography>
          </Grid>
        </Grid>
        <Typography
          variant="body2"
          component="p"
          className={classes.redirectCardText}
          style={{ margin: "24px auto 0px auto" }}
        >
          {props.text}
        </Typography>
      </CardContent>
      <CardActions style={{ justifyContent: "right" }}>
        <Button
          size="small"
          color="primary"
          variant="contained"
          style={{ width: "165px", height: "36px", borderRadius: "0.6rem" }}
          onClick={() => history.push(props.url)}
        >
          DALEJ
        </Button>
      </CardActions>
    </Card>
  );
};
