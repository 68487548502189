import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useHistory } from "react-router-dom";
import Can from "../../components/can";
import { Redirect } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles, withStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 250,
    borderRadius: "1rem",
    border: "0.5px solid gainsboro",
    boxShadow: "none",
  },
  colorText: {
    color: theme.palette.text.alternative2,
  },
  textHeader: {
    fontSize: theme.typography.headerLarge,
    "@media (max-width: 600px)": {
      fontSize: theme.typography.header,
    },
  },
  text: {
    fontSize: theme.typography.text,
  },
}));

const MessageSentLoggedUser = () => {
  const history = useHistory();

  const classes = useStyles();
  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  useEffect(() => {
    window.scrollTo(0, 0);
    const timer = setTimeout(() => history.push("/"), 5000);

    return () => clearTimeout(timer);
  }, []);
  const page = () => (
    <Box style={{ height: "100vh" }}>
      <Grid
        item
        container
        spacing={2}
        xs={12}
        sm={8}
        xl={8}
        style={{
          padding: "18px",
          margin: "24px auto",
          display: "flex",
        }}
        direction="column"
      >
        <h1
          aria-hidden="false"
          aria-label="Wiadomość wysłana"
          style={{ display: "none" }}
        >
          Wiadomość wysłana
        </h1>
        <Grid item xs={12} sm={10} md={8}>
          <Typography
            variant="h3"
            gutterBottom
            className={`${classes.colorText} ${classes.textHeader}`}
          >
            Dziękujemy za skorzystanie z formularza kontaktowego
          </Typography>
          <Typography
            variant="h3"
            gutterBottom
            className={`${classes.colorText} ${classes.text}`}
            style={{
              // fontSize: "18px",
              letterSpacing: 0.25,
            }}
          >
            Odpowiemy w ciągu 24 godzin
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );

  const redirect = () => <Redirect to="/login" />;

  return <Can permission="contact-logged-user:page" ok={page} not={redirect} />;
};

export default MessageSentLoggedUser;
