import React, { useState } from 'react'
import Datepicker, { registerLocale } from 'react-datepicker'
import pl from 'date-fns/locale/pl'
import * as classnames from 'classnames'
import format from 'date-fns/format'

registerLocale('pl', pl)

const DateFilter = ({id, onChange}) => {
    const [value, setValue] = useState('')
    const [since, setSince] = useState('')
    const [until, setUntil] = useState('')

    const updateValue = (val) => {
        setValue(val)
        let u;
        let s;
        !val ? val="" : val=format(val,"yyyy/MM/dd");
        !until ? u="" : u=format(until,"yyyy/MM/dd");
        !since ? s="" : s=format(since,"yyyy/MM/dd");
        if(onChange){
            onChange({id,value:{
                value: val,
                until: u,
                since: s}
            })
        }
    }
    const updateSince = (val) => {
        setSince(val)
        let v;
        let u;
        !val ? val="" : val=format(val,"yyyy/MM/dd");
        !until ? u="" : u=format(until,"yyyy/MM/dd");
        !value ? v="" : v=format(value,"yyyy/MM/dd");
        if(onChange){
            onChange({id, value: {
                value: v,
                until: u,
                since: val}
            })
        }
    }

    const updateUntil = (val) => {
        setUntil(val)
        let v;
        let s;
        !val ? val="" : val=format(val,"yyyy/MM/dd");
        !value ? v="" : v=format(value,"yyyy/MM/dd");
        !since ? s="" : s=format(since,"yyyy/MM/dd");
        if(onChange){
            onChange({id, value: {
                value: v,
                until: val,
                since: s}
            })
        }
    }

    return (
        <div>
          <Datepicker
            dateFormat="yyyy/MM/dd"
            locale={pl}
            className={classnames('input')}
            selected={value}
            value={value}
            onChange={value => updateValue(value)}
            placeholderText="Wyszukaj dzień"
            isClearable
          />
          <Datepicker
            dateFormat="yyyy/MM/dd"
            locale={pl}
            className={classnames('input')}
            selected={since}
            value={since}
            onChange={value => updateSince(value)}
            placeholderText="Wyszukaj od dnia"
            isClearable
          />
          <Datepicker
            dateFormat="yyyy/MM/dd"
            locale={pl}
            className={classnames('input')}
            selected={until}
            value={until}
            onChange={value => updateUntil(value)}
            placeholderText="Wyszukaj do dnia"
            isClearable
          />
        </div>
    )
}

export default DateFilter
