import React, { forwardRef, useEffect, useState } from "react";
import { validate } from "email-validator";
import * as classnames from "classnames";

const AddNewPerson = forwardRef((props, ref) => {
  const {
    showLabel,
    sending,
    error,
    done,
    moveToNew,
    setData,
    sent,
    showNewLabel,
    tmpIndex,
    keepEmpty = false,
  } = props;

  const [email, setEmail] = useState("");
  const [valid, setValid] = useState(true);

  useEffect(() => {
    setValid(validate(email));

    setData &&
      setData({
        mail: email.trim(),
      });
  }, [email]);

  useEffect(() => {
    if (email && keepEmpty) {
      setEmail("");
    }
  }, [email, valid, keepEmpty]);

  return (
    <div>
      {showNewLabel && tmpIndex !== 1 ? (
        <label>
          Podaj adres email kolejnej osoby którą chcesz zaprosić do organizacji
        </label>
      ) : (
        showLabel && (
          <label>Podaj adres email osoby którą chcesz zaprosić</label>
        )
      )}

      <div className="field">
        <div
          className={classnames("control", {
            "is-loading": sending,
            "has-icons-right": error || sending || done,
          })}
        >
          {!sent && !error && (
            <input
              ref={ref}
              className={classnames("input", {
                "is-success": email.length > 0 && valid,
                "is-danger": email.length > 0 && !valid,
              })}
              value={email}
              onChange={({ target: { value } }) => setEmail(value)}
            />
          )}

          {error && (
            <div className="is-small has-text-danger">
              Nie udało się zaprosić {email}: {error}
            </div>
          )}

          {sent && (
            <div className="is-small has-text-success">Zaproszono {email}</div>
          )}
        </div>
      </div>
    </div>
  );
});

export default AddNewPerson;
