export const ACTION_1 = "ACTION_1";

export const START = "_START";
export const DONE = "_DONE";
export const FAILED = "_FAILED";

export const REGISTER_ORGANIZATION_START = "REGISTER_ORGANIZATION_START";
export const REGISTER_ORGANIZATION_DONE = "REGISTER_ORGANIZATION_DONE";
export const REGISTER_ORGANIZATION_FAILED = "REGISTER_ORGANIZATION_FAILED";

export const JOIN_ORGANIZATION_START = "JOIN_ORGANIZATION_START";
export const JOIN_ORGANIZATION_DONE = "JOIN_ORGANIZATION_DONE";
export const JOIN_ORGANIZATION_FAILED = "JOIN_ORGANIZATION_FAILED";

export const FETCH_ORGANIZATION_START = "FETCH_ORGANIZATION_START";
export const FETCH_ORGANIZATION_DONE = "FETCH_ORGANIZATION_DONE";
export const FETCH_ORGANIZATION_FAILED = "FETCH_ORGANIZATION_FAILED";

export const FETCH_CONFIG_GLOBAL_START = "FETCH_CONFIG_GLOBAL_START";
export const FETCH_CONFIG_GLOBAL_DONE = "FETCH_CONFIG_GLOBAL_DONE";
export const FETCH_CONFIG_GLOBAL_FAILED = "FETCH_CONFIG_GLOBAL_FAILED";

export const FETCH_CONFIG = "FETCH_CONFIG";

export const LOGIN_START = "LOGIN_START";
export const LOGIN_DONE = "LOGIN_DONE";
export const LOGIN_FAILED = "LOGIN_FAILED";

export const LOGOUT_START = "LOGOUT_START";
export const LOGOUT_DONE = "LOGOUT_DONE";
export const LOGOUT_FAILED = "LOGOUT_FAILED";

export const SEND_CONTACT_START = "SEND_CONTACT_START";
export const SEND_CONTACT_DONE = "SEND_CONTACT_DONE";
export const SEND_CONTACT_FAILED = "SEND_CONTACT_FAILED";

export const CHECK_AUTH_START = "CHECK_AUTH_START";
export const CHECK_AUTH_DONE = "CHECK_AUTH_DONE";
export const CHECK_AUTH_FAILED = "CHECK_AUTH_FAILED";

export const FETCH_ORG_USERS_START = "FETCH_ORG_USERS_START";
export const FETCH_ORG_USERS_DONE = "FETCH_ORG_USERS_DONE";
export const FETCH_ORG_USERS_FAILED = "FETCH_ORG_USERS_FAILED";

export const FETCH_ORG_NAMES_START = "FETCH_ORG_NAMES_START";
export const FETCH_ORG_NAMES_DONE = "FETCH_ORG_NAMES_DONE";
export const FETCH_ORG_NAMES_FAILED = "FETCH_ORG_NAMES_FAILED";

export const INVITE_MULTIPLE_PEOPLE_START = "INVITE_MULTIPLE_PEOPLE_START";
export const INVITE_MULTIPLE_PEOPLE_DONE = "INVITE_MULTIPLE_PEOPLE_DONE";
export const INVITE_MULTIPLE_PEOPLE_FAILED = "INVITE_MULTIPLE_PEOPLE_FAILED";

export const SAVE_FORM_START = "SAVE_FORM_START";
export const SAVE_FORM_DONE = "SAVE_FORM_DONE";
export const SAVE_FORM_FAILED = "SAVE_FORM_FAILED";

export const UPDATE_FORM_START = "UPDATE_FORM_START";
export const UPDATE_FORM_DONE = "UPDATE_FORM_DONE";
export const UPDATE_FORM_FAILED = "UPDATE_FORM_FAILED";

export const SAVE_FORM_DATA_START = "SAVE_FORM_DATA_START";
export const SAVE_FORM_DATA_DONE = "SAVE_FORM_DATA_DONE";
export const SAVE_FORM_DATA_FAILED = "SAVE_FORM_DATA_FAILED";

export const UPDATE_FORM_DATA_START = "UPDATE_FORM_DATA_START";
export const UPDATE_FORM_DATA_DONE = "UPDATE_FORM_DATA_DONE";
export const UPDATE_FORM_DATA_FAILED = "UPDATE_FORM_DATA_FAILED";

export const FETCH_FORMS_START = "FETCH_FORMS_START";
export const FETCH_FORMS_DONE = "FETCH_FORMS_DONE";
export const FETCH_FORMS_FAILED = "FETCH_FORMS_FAILED";

export const FETCH_SCHEDULED_FORMS = "FETCH_SCHEDULED_FORMS";

export const REMOVE_FORM_START = "REMOVE_FORM_START";
export const REMOVE_FORM_DONE = "REMOVE_FORM_DONE";
export const REMOVE_FORM_FAILED = "REMOVE_FORM_FAILED";

export const FETCH_FORM_START = "FETCH_FORM_START";
export const FETCH_FORM_DONE = "FETCH_FORM_DONE";
export const FETCH_FORM_FAILED = "FETCH_FORM_FAILED";

export const FETCH_FORM_DATA_START = "FETCH_FORM_DATA_START";
export const FETCH_FORM_DATA_DONE = "FETCH_FORM_DATA_DONE";
export const FETCH_FORM_DATA_FAILED = "FETCH_FORM_DATA_FAILED";

export const CREATE_FILLED_FORM_DATA_START = "CREATE_FILLED_FORM_DATA_START";
export const CREATE_FILLED_FORM_DATA_DONE = "CREATE_FILLED_FORM_DATA_DONE";
export const CREATE_FILLED_FORM_DATA_FAILED = "CREATE_FILLED_FORM_DATA_FAILED";

export const SAVE_FILLED_FORM_DATA_START = "SAVE_FILLED_FORM_DATA_START";
export const SAVE_FILLED_FORM_DATA_DONE = "SAVE_FILLED_FORM_DATA_DONE";
export const SAVE_FILLED_FORM_DATA_FAILED = "SAVE_FILLED_FORM_DATA_FAILED";

export const FETCH_FILLED_FORMS_START = "FETCH_FILLED_FORMS_START";
export const FETCH_FILLED_FORMS_DONE = "FETCH_FILLED_FORMS_DONE";
export const FETCH_FILLED_FORMS_FAILED = "FETCH_FILLED_FORMS_FAILED";

export const FETCH_FILLED_FORMS_EXTENDED = "FETCH_FILLED_FORMS_EXTENDED";

export const RECOVER_PASSWORD_START = "RECOVER_PASSWORD_START";
export const RECOVER_PASSWORD_DONE = "RECOVER_PASSWORD_DONE";
export const RECOVER_PASSWORD_FAILED = "RECOVER_PASSWORD_FAILED";

export const RESET_BY_MAIL_PASSWORD_START = "RESET_BY_MAIL_PASSWORD_START";
export const RESET_BY_MAIL_PASSWORD_DONE = "RESET_BY_MAIL_PASSWORD_DONE";
export const RESET_BY_MAIL_PASSWORD_FAILED = "RESET_BY_MAIL_PASSWORD_FAILED";

export const ALERT_SHOW = "ALERT_SHOW";
export const ALERT_HIDE = "ALERT_HIDE";
export const ALERT_TIMEOUT = "ALERT_TIMEOUT";

export const SEND_COMMENT_START = "SEND_COMMENT_START";
export const SEND_COMMENT_DONE = "SEND_COMMENT_DONE";
export const SEND_COMMENT_FAILED = "SEND_COMMENT_FAILED";

export const FETCH_ACTIVE_FORMS = "FETCH_ACTIVE_FORMS";
export const ACTIVATE_FORM = "ACTIVATE_FORM";

export const REMOVE_USER = "REMOVE_USER";
export const ANONIMIZE_USER = "ANONIMIZE_USER";
export const ACTIVATE_USER = "ACTIVATE_USER";
export const UPDATE_ENTITLED_USERS = "UPDATE_ENTITLED_USERS";
export const DUPLICATE_FORM = "DUPLICATE_FORM";
export const DEACTIVATE_FORM = "DEACTIVATE_FORM";
export const FETCH_FORM_EDITIONS = "FETCH_FORM_EDITIONS";
export const FETCH_OLD_FORM_EDITIONS = "FETCH_OLD_FORM_EDITIONS";

export const REMOVE_CART_ITEM_START = "REMOVE_CART_ITEM_START";
export const REMOVE_CART_ITEM_DONE = "REMOVE_CART_ITEM_DONE";
export const REMOVE_CART_ITEM_FAILED = "REMOVE_CART_ITEM_FAILED";

export const BUY_CART_ITEM = "BUY_CART_ITEM";
export const PURCHASE_ITEM_PAYMENT = "PURCHASE_ITEM_PAYMENT";
export const PURCHASE_ITEMS_PAYMENT = "PURCHASE_ITEM_PAYMENT";
export const PAYMENT_STATUS = "PAYMENT_STATUS";
export const PAYMENT_VERIFY = "PAYMENT_VERIFY";
export const PAYMENT_CANCEL = "PAYMENT_CANCEL";

export const WATCH_MY_ORDER = "WATCH_MY_ORDER";

export const REMOVE_TERM_START = "REMOVE_TERM_START";
export const REMOVE_TERM_DONE = "REMOVE_TERM_DONE";
export const REMOVE_TERM_FAILED = "REMOVE_TERM_FAILED";

export const FETCH_DATA_AS_CSV = "FETCH_DATA_AS_CSV";

export const ADD_ITEM_TO_CART = "ADD_ITEM_TO_CART";
export const EDIT_ITEM_CART = "EDIT_ITEM_CART";
export const REMOVE_ITEM_FROM_CART = "REMOVE_ITEM_FROM_CART";
export const FETCH_CART_ITEMS = "FETCH_CART_ITEMS";
export const FETCH_PRICE_LIST = "FETCH_PRICE_LIST";

export const X_AUTH_TOKEN_KEY = "x-auth-token";

export const FETCH_ORDERS_IN_PROGRESS = "FETCH_ORDERS_IN_PROGRESS";
export const FETCH_HAS_ANY_ORDERS = "FETCH_HAS_ANY_ORDERS";
export const UPDATE_ORDER_IN_PROGRESS = "UPDATE_ORDER_IN_PROGRESS";
export const ACCEPT_ORDER_ITEM_CODE = "ACCEPT_ORDER_ITEM_CODE";
export const SET_TEST_CODE = "SET_TEST_CODE";
export const FETCH_ALL_MY_TESTS = "FETCH_ALL_MY_TESTS";
export const FETCH_FILLED_TESTS = "FETCH_FILLED_TESTS";
export const FETCH_ACTIVATED_KITS = "FETCH_ACTIVATED_KITS";
export const UPDATE_REQUESTED_SHARING_BY_OWNER =
  "UPDATE_REQUESTED_SHARING_BY_OWNER";
export const TOGGLE_REQUESTED_SHARING_BY_OWNER =
  "TOGGLE_REQUESTED_SHARING_BY_OWNER";
export const UPDATE_FORM_DATA_IS_SHARED = "UPDATE_FORM_DATA_IS_SHARED";

export const SET_APP_BAR_TITLE = "SET_APP_BAR_TITLE";
export const FETCH_ORGANIZATION_TERMS = "FETCH_ORGANIZATION_TERMS";
export const FETCH_ALL_TERMS = "FETCH_ALL_TERMS";
export const FETCH_ORDER_DETAILS_BY_ORDER_ID =
  "FETCH_ORDER_DETAILS_BY_ORDER_ID";
export const FETCH_ALL_PROTEGES = "FETCH_ALL_PROTEGES";
export const FETCH_PROTEGES = "FETCH_PROTEGES";
export const ADD_PROTEGE = "ADD_PROTEGE";
export const EDIT_PROTEGE = "EDIT_PROTEGE";
export const REMOVE_PROTEGE = "REMOVE_PROTEGE";
export const FETCH_ORDER_SUMMARY_BY_ORDER_ID =
  "FETCH_ORDER_SUMMARY_BY_ORDER_ID";
export const TOGGLE_GLOBAL_THEME = "TOGGLE_GLOBAL_THEME";
export const READ_GLOBAL_THEME = "READ_GLOBAL_THEME";
export const READ_FONT_SIZE = "READ_FONT_SIZE";
export const SET_FONT_SIZE = "SET_FONT_SIZE";
export const UPDATE_ORDER_STATUS_TO_PAID = "UPDATE_ORDER_STATUS_TO_PAID";
export const UPDATE_ORDER_STATUS = "UPDATE_ORDER_STATUS";
