import React from 'react'
import TextControl from './components/form-controls/text-control'
import PeselControl from './components/form-controls/pesel-control'
import DateControl from './components/form-controls/date-control'
import QRCodeControl from './components/form-controls/qrcode-control'
import LocationControl from './components/form-controls/location-control'
import BoolControl from './components/form-controls/bool-control'
import NumberControl from './components/form-controls/number-control'
import ImageControl from './components/form-controls/image-control'
import SignControl from './components/form-controls/sign-control'
import TimeControl from './components/form-controls/time-control'
import DateTimeControl from './components/form-controls/datetime-control'
import GroupControl from './components/form-controls/group-control'
import RepeatingGroupControl from './components/form-controls/repeating-group-control'
import DictionaryControl from './components/form-controls/dictionary-control'
import LabelControl from './components/form-controls/label-control'

export const ItemTypes = {
  COMPONENT: 'component',
}

export const ItemSubtypes = {
  TEXT: 'TEXT',
  NUMBER: 'NUMBER',
  IMAGE: 'IMAGE',
  SIGN: 'SIGN',
  LOCATION: 'LOCATION',
  DICTIONARY: 'DICTIONARY',
  TIME: 'TIME',
  DATE: 'DATE',
  DATETIME: 'DATETIME',
  GROUP: 'GROUP',
  REPEATING_GROUP: 'REPEATING_GROUP',
  BOOL: 'BOOL',
  PESEL: 'PESEL',
  QRCODE: 'QRCODE',
  FORM: 'FORM',
  ROW: 'ROW',
  LABEL: 'LABEL'
}

export const createControl = (props) => {
  const { subtype } = props

  switch (subtype) {
    case ItemSubtypes.TEXT:
      return <TextControl {...props}/>
    case ItemSubtypes.NUMBER:
      return <NumberControl {...props}/>
    case ItemSubtypes.DATE:
      return <DateControl {...props}/>
    case ItemSubtypes.TIME:
      return <TimeControl {...props}/>
    case ItemSubtypes.DATETIME:
      return <DateTimeControl {...props}/>
    case ItemSubtypes.PESEL:
      return <PeselControl {...props}/>
    case ItemSubtypes.QRCODE:
      return <QRCodeControl {...props}/>
    case ItemSubtypes.LOCATION:
      return <LocationControl {...props}/>
    case ItemSubtypes.BOOL:
      return <BoolControl {...props}/>
    case ItemSubtypes.IMAGE:
      return <ImageControl {...props}/>
    case ItemSubtypes.SIGN:
      return <SignControl {...props}/>
    case ItemSubtypes.GROUP:
      return <GroupControl {...props}/>
    case ItemSubtypes.REPEATING_GROUP:
      return <RepeatingGroupControl {...props}/>
    case ItemSubtypes.DICTIONARY:
      return <DictionaryControl {...props}/>
    case ItemSubtypes.LABEL:
      return <LabelControl {...props}/>
    default:
      return ''
  }
}
