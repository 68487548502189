import React from "react";
import { ItemSubtypes } from "../../../DnDTypes";
import { ColorPicker } from "../../color-picker";
import s from "./index.module.css";

export const SupportedType = ItemSubtypes.DICTIONARY;

export const STRING_TYPE = "string";
export const NUMBER_TYPE = "number";

const IMAGES = [
  "/images/image-apple.png",
  "/images/image-banana.png",
  "/images/image-chocolate.png",
  "/images/image-cinamon.png",
  "/images/image-flower.png",
  "/images/image-garlic.png",
  "/images/image-gasoline.png",
  "/images/image-glue.png",
  "/images/image-leather.png",
  "/images/image-lemon.png",
  "/images/image-pineapple.png",
  "/images/image-salt.png",
  "/images/image-grapefruit.png",
  "/images/image-smoke.png",
  "/images/image-strawberry.png",
  "/images/image-thinner.png",
];

/**
 * values format:
 * {
 *   type: "string|number"
 *   dictionaryValues: ["opt1", "opt2"...] or [1,2,3...]
 * }
 * @param values
 * @param setValues
 * @returns {*}
 * @constructor
 */

export class DictionaryProperties extends React.Component {
  constructor() {
    super();

    this.addValue = this.addValue.bind(this);
    this.removeValue = this.removeValue.bind(this);
  }

  state = {
    type: undefined,
    weight: "",
    color: "",
    value: "",
    text: "",
    addFirstDictValueDisplay: true,
    addNewDictValueFlag: false,
    showColorPicker: false,
    editValueNumber: null,
    tmpDictValueString: "",
    tmpDictValueInt: null,
    tmpDictValueColor: "",
    tmpDictValueWeight: null,
    tmpDictValueImage: null,
    tmpDictValueText: "",
  };

  componentDidMount() {
    this.state.dictionaryValues.length !== 0 &&
      this.setState({ addFirstDictValueDisplay: false });
  }

  static getDerivedStateFromProps(props, state) {
    const dictionaryValues =
      (props && (props.values.dictionary || {}).dictionaryValues) || [];
    let type;
    if (!state.type && dictionaryValues.length) {
      type =
        dictionaryValues.filter(({ stringValue }) => !!stringValue).length > 0
          ? STRING_TYPE
          : NUMBER_TYPE;
    }

    return {
      // values: props && props.values,
      ...(type ? { type } : {}),
      setValues: props && props.setValues,
      dictionaryValues: dictionaryValues,
    };
  }

  setType(type) {
    this.props.formEdited(true);
    this.setState({ type });
    this.props.setValues({
      ...this.props.values,
      dictionary: {
        ...this.props.values.dictionary,
        type: type,
      },
    });
  }

  addValue() {
    this.props.formEdited(true);
    this.setState({ addNewDictValueFlag: false });
    let weight;
    try {
      weight = parseInt(this.state.weight, 10);
    } catch {}

    let intValue;
    try {
      if (this.state.type === NUMBER_TYPE) {
        intValue = parseInt(this.state.value, 10);
      }
    } catch {}

    let stringValue;
    try {
      if (this.state.type === STRING_TYPE) {
        stringValue = this.state.value.trim();
      }
    } catch {}

    let text;
    try {
      if (this.state.text && this.state.text.trim()) {
        text = this.state.text.trim();
      }
    } catch {}

    const pack = {
      intValue,
      stringValue,
      ...(this.state.color.length > 0 ? { color: this.state.color } : {}),
      weight,
      text,
    };

    this.props.setValues({
      ...this.props.values,
      dictionary: {
        ...this.props.values.dictionary,
        type: this.state.type,
        dictionaryValues: [...(this.state.dictionaryValues || []), pack],
      },
    });
  }

  saveEditedDictValue = (index) => {
    this.props.formEdited(true);
    if (this.state.type === STRING_TYPE) {
      this.state.dictionaryValues[
        index
      ].stringValue = this.state.tmpDictValueString;
    } else if (this.state.type === NUMBER_TYPE) {
      this.state.dictionaryValues[index].intValue = this.state.tmpDictValueInt;
    }
    this.state.dictionaryValues[index].weight = this.state.tmpDictValueWeight;
    this.state.dictionaryValues[index].color = this.state.tmpDictValueColor;
    this.state.dictionaryValues[index].text = this.state.tmpDictValueText;
    this.state.dictionaryValues[index].image = this.state.tmpDictValueImage;

    this.setState({ editValueNumber: false });

    this.props.setValues({
      ...this.values,
      dictionary: {
        ...this.props.values.dictionary,
        type: this.state.type,
        dictionaryValues: this.state.dictionaryValues,
      },
    });
  };

  removeValue(v) {
    this.props.formEdited(true);
    this.state.dictionaryValues.length === 1 &&
      this.setState({ addFirstDictValueDisplay: true });
    this.setState({ addNewDictValueFlag: false });
    this.props.setValues({
      ...this.values,
      dictionary: {
        ...this.props.values.dictionary,
        type: this.state.type,
        dictionaryValues: this.state.dictionaryValues.filter(
          ({ intValue, stringValue }) => {
            return this.state.type === STRING_TYPE
              ? stringValue !== v
              : intValue !== v;
          }
        ),
      },
    });
  }

  setTmpDictValue(v, color, weight, text, image) {
    this.state.type === STRING_TYPE
      ? this.setState({ tmpDictValueString: v })
      : this.setState({ tmpDictValueInt: v });
    this.setState({
      tmpDictValueColor: color,
      tmpDictValueWeight: weight,
      tmpDictValueText: text,
      tmpDictValueImage: image,
    });
  }

  moveDictValue(old_index, new_index) {
    this.props.formEdited(true);
    let arr = [];
    arr = this.state.dictionaryValues;
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);

    this.props.setValues({
      ...this.values,
      dictionary: {
        ...this.props.values.dictionary,
        type: this.state.type,
        dictionaryValues: arr,
      },
    });
  }

  render() {
    return (
      <div>
        {!this.state.type ? (
          <>
            <div className="is-size-6">Zdefiniuj typ słownika:</div>
            <div>
              <button
                className="button"
                onClick={() => this.setType(STRING_TYPE)}
              >
                Tekst
              </button>
              <button
                className="button"
                onClick={() => this.setType(NUMBER_TYPE)}
              >
                Liczba
              </button>
            </div>
          </>
        ) : (
          <div>
            {this.state.dictionaryValues.length === 0 ||
            this.state.addNewDictValueFlag ? (
              this.state.addFirstDictValueDisplay ? (
                <div>
                  <span className={s.text}>
                    Brak wartości.
                    <a
                      className={s.clickText}
                      style={{ color: "#3273DC" }}
                      onClick={() =>
                        this.setState({ addFirstDictValueDisplay: false })
                      }
                    >
                      {" "}
                      Dodaj pierwszą wartość.
                    </a>
                  </span>
                </div>
              ) : (
                <div>
                  <span>Wartości:</span>
                  <div style={{ backgroundColor: "#F7F7F7" }}>
                    <label className={s.editValueLabel}>Nowa wartość</label>
                    <br></br>
                    <table>
                      <tr>
                        <td>
                          <label style={{ marginLeft: 10 }}>Wartość:</label>
                        </td>
                        <td>
                          <input
                            className="input is-small"
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                            placeholder="Dodaj wartość"
                            type={
                              this.state.type === STRING_TYPE
                                ? "text"
                                : "number"
                            }
                            value={this.state.value}
                            onChange={({ target: { value } }) =>
                              this.setState({ value })
                            }
                          />
                        </td>
                      </tr>
                      <br></br>

                      <tr>
                        <td>
                          <label style={{ marginLeft: 10 }}>Kolor:</label>
                        </td>
                        <td>
                          <div
                            className={s.editColorArea}
                            style={{
                              cursor: "pointer",
                              background: this.state.color
                                ? this.state.color
                                : "white",
                            }}
                            onClick={() => {
                              this.setState({
                                showColorPicker: !this.state.showColorPicker,
                              });
                            }}
                          ></div>
                        </td>
                      </tr>

                      <br></br>
                      <tr>
                        <td>
                          <label style={{ marginLeft: 10 }}>Waga:</label>
                        </td>
                        <td>
                          <input
                            className={"input is-small"}
                            type="number"
                            placeholder="Brak"
                            min="0"
                            value={this.state.weight}
                            onChange={({ target: { value: weight } }) =>
                              this.setState({ weight })
                            }
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <label style={{ marginLeft: 10 }}>Obrazek:</label>
                        </td>
                        <td>
                          {["brak", ...IMAGES].map((image) => {
                            if (image === "brak") {
                              return (
                                <div
                                  onClick={() =>
                                    this.setState({
                                      tmpDictValueImage: null,
                                    })
                                  }
                                >
                                  brak
                                </div>
                              );
                            }
                            return (
                              <img
                                key={image}
                                src={image}
                                className={[
                                  s.image,
                                  ...(this.state.tmpDictValueImage === image
                                    ? [s.selectedImage]
                                    : []),
                                ]}
                                onClick={() => {
                                  this.setState({
                                    tmpDictValueImage:
                                      image !== "brak" ? image : null,
                                  });
                                }}
                              />
                            );
                          })}
                        </td>
                      </tr>
                    </table>
                    {this.state.showColorPicker && (
                      <div>
                        <ColorPicker
                          required={false}
                          value={this.state.color}
                          onChange={(color) => {
                            this.setState({ color });
                            this.setState({
                              showColorPicker: false,
                            });
                          }}
                        />
                      </div>
                    )}
                    <br></br>
                    <label style={{ marginLeft: 10 }}>Komunikat:</label>
                    <textarea
                      className={"textarea is-small"}
                      placeholder="Brak"
                      type="textarea"
                      rows={3}
                      value={this.state.text}
                      onChange={({ target: { value: text } }) =>
                        this.setState({ text })
                      }
                    />

                    <button
                      className="button is-primary is-small"
                      style={{ float: "right", marginLeft: 5, marginTop: 10 }}
                      onClick={this.addValue}
                    >
                      Dodaj
                    </button>

                    <button
                      className="button is-small"
                      style={{ float: "right", marginTop: 10 }}
                      onClick={() => {
                        this.state.dictionaryValues.length === 0 &&
                          this.setState({ addFirstDictValueDisplay: true });
                        this.setState({ addNewDictValueFlag: false });
                      }}
                    >
                      Anuluj
                    </button>
                  </div>
                </div>
              )
            ) : (
              <div className="control">
                <br></br>
                <div>Wartości: ({this.state.dictionaryValues.length})</div>
                {this.state.dictionaryValues.map(
                  (
                    { stringValue, intValue, weight, color, text, image },
                    index
                  ) =>
                    this.state.editValueNumber === index ? (
                      <div>
                        <div style={{ backgroundColor: "#F7F7F7" }}>
                          <label className={s.editValueLabel}>
                            Edycja wartości
                          </label>
                          <br />
                          <table>
                            <tr>
                              <td>
                                <label style={{ marginLeft: 10 }}>
                                  Wartość:
                                </label>
                              </td>
                              <td>
                                <input
                                  className="input is-small"
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                  }}
                                  placeholder="Dodaj wartość"
                                  type={
                                    this.state.type === STRING_TYPE
                                      ? "text"
                                      : "number"
                                  }
                                  value={
                                    this.state.type === STRING_TYPE
                                      ? this.state.tmpDictValueString
                                      : this.state.tmpDictValueInt
                                  }
                                  onChange={({ target: { value } }) =>
                                    this.setState(
                                      this.state.type === STRING_TYPE
                                        ? { tmpDictValueString: value }
                                        : { tmpDictValueInt: value }
                                    )
                                  }
                                />
                              </td>
                            </tr>
                            <br></br>
                            <tr>
                              <td>
                                <label style={{ marginLeft: 10 }}>Kolor:</label>
                              </td>
                              <td>
                                <div
                                  className={s.editColorArea}
                                  style={{
                                    cursor: "pointer",
                                    backgroundColor: this.state
                                      .tmpDictValueColor
                                      ? this.state.tmpDictValueColor
                                      : "white",
                                  }}
                                  onClick={() => {
                                    this.setState({
                                      showColorPicker: !this.state
                                        .showColorPicker,
                                    });
                                  }}
                                ></div>
                              </td>
                            </tr>
                            <br></br>
                            <tr>
                              <td>
                                <label style={{ marginLeft: 10 }}>Waga:</label>
                              </td>
                              <td>
                                <input
                                  className={"input is-small"}
                                  type="number"
                                  placeholder="Brak"
                                  min="0"
                                  value={this.state.tmpDictValueWeight}
                                  onChange={({ target: { value: weight } }) =>
                                    this.setState({
                                      tmpDictValueWeight: weight,
                                    })
                                  }
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label style={{ marginLeft: 10 }}>
                                  Obrazek:
                                </label>
                              </td>
                              <td>
                                {["brak", ...IMAGES].map((image) => {
                                  if (image === "brak") {
                                    return (
                                      <div
                                        onClick={() =>
                                          this.setState({
                                            tmpDictValueImage: null,
                                          })
                                        }
                                      >
                                        brak
                                      </div>
                                    );
                                  }
                                  return (
                                    <img
                                      key={image}
                                      src={image}
                                      className={[
                                        s.image,
                                        ...(this.state.tmpDictValueImage ===
                                        image
                                          ? [s.selectedImage]
                                          : []),
                                      ]}
                                      onClick={() => {
                                        this.setState({
                                          tmpDictValueImage:
                                            image !== "brak" ? image : null,
                                        });
                                      }}
                                    />
                                  );
                                })}
                              </td>
                            </tr>
                          </table>

                          {this.state.showColorPicker && (
                            <div>
                              <ColorPicker
                                required={false}
                                value={this.state.tmpDictValueColor}
                                onChange={(color) => {
                                  this.setState({
                                    tmpDictValueColor: color,
                                    showColorPicker: false,
                                  });
                                }}
                              />
                            </div>
                          )}
                          <br />
                          <label style={{ marginLeft: 10 }}>Komunikat:</label>
                          <textarea
                            className={"textarea is-small"}
                            placeholder="Brak"
                            type="textarea"
                            rows={3}
                            value={this.state.tmpDictValueText}
                            onChange={({ target: { value: text } }) =>
                              this.setState({ tmpDictValueText: text })
                            }
                          />
                          <button
                            className="button is-primary is-small"
                            style={{
                              float: "right",
                              marginLeft: 5,
                              marginTop: 10,
                            }}
                            onClick={() => {
                              this.saveEditedDictValue(index);
                            }}
                          >
                            Zapisz
                          </button>

                          <button
                            className="button is-small"
                            style={{ float: "right", marginTop: 10 }}
                            onClick={() => {
                              this.setState({ editValueNumber: false });
                            }}
                          >
                            Anuluj
                          </button>
                          <br></br>
                          <br></br>
                        </div>
                        <hr style={{ marginTop: 5 }} />
                      </div>
                    ) : (
                      <div>
                        <li
                          key={index}
                          style={{ color: "#000000", fontWeight: "bold" }}
                        >
                          {this.state.type === STRING_TYPE
                            ? stringValue
                            : intValue}
                          <div
                            style={{
                              color: "#4A4A4A",
                              marginLeft: 30,
                              fontWeight: "normal",
                            }}
                          >
                            {text}
                          </div>
                        </li>
                        <div
                          style={{
                            fontSize: 15,
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <label>Kolor: </label>
                          <label
                            className={color && s.colorAreaSmall}
                            style={{ background: color && color }}
                          >
                            {!color && (
                              <label style={{ marginLeft: 5 }}>Brak</label>
                            )}
                          </label>
                        </div>
                        <div
                          style={{
                            fontSize: 15,
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          Waga: {weight ? weight : "Brak"}
                        </div>
                        <div
                          style={{
                            fontSize: 15,
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          Obrazek:{" "}
                          {image ? (
                            <img className={s.image} src={image} />
                          ) : (
                            "Brak"
                          )}
                        </div>
                        <div
                          style={{
                            flex: 1,
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          {index === 0 &&
                            this.state.dictionaryValues.length !== 1 && (
                              <a
                                className={s.buttonDictValue}
                                onClick={() =>
                                  this.moveDictValue(index, index + 1)
                                }
                              >
                                {" "}
                                Przesuń niżej
                              </a>
                            )}

                          {this.state.dictionaryValues.length === index + 1 &&
                            this.state.dictionaryValues.length !== 1 && (
                              <a
                                className={s.buttonDictValue}
                                onClick={() =>
                                  this.moveDictValue(index, index - 1)
                                }
                              >
                                {" "}
                                Przesuń wyżej
                              </a>
                            )}

                          {index !== 0 &&
                            this.state.dictionaryValues.length !== index + 1 &&
                            this.state.dictionaryValues.length !== 1 && (
                              <div
                                style={{
                                  flex: 1,
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <a
                                  className={s.buttonDictValue}
                                  onClick={() =>
                                    this.moveDictValue(index, index - 1)
                                  }
                                >
                                  {" "}
                                  Przesuń wyżej
                                </a>
                                <a
                                  className={s.buttonDictValue}
                                  onClick={() =>
                                    this.moveDictValue(index, index + 1)
                                  }
                                >
                                  {" "}
                                  Przesuń nizej
                                </a>
                              </div>
                            )}

                          <a
                            className={s.buttonDictValue}
                            onClick={() => {
                              this.setState({ editValueNumber: index });
                              this.setTmpDictValue(
                                this.state.type === STRING_TYPE
                                  ? stringValue
                                  : intValue,
                                color,
                                weight,
                                text,
                                image
                              );
                            }}
                          >
                            {" "}
                            Zmień
                          </a>
                          <a
                            className={s.buttonDictValue}
                            onClick={() => {
                              this.removeValue(
                                this.state.type === STRING_TYPE
                                  ? stringValue
                                  : intValue
                              );
                            }}
                          >
                            {" "}
                            Usuń
                          </a>
                        </div>
                        <hr style={{ marginTop: 3, marginDown: 3 }} />
                      </div>
                    )
                )}
                <a
                  style={{
                    color: "#3273DC",
                    fontSize: 10,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                  onClick={() =>
                    this.setState({
                      addNewDictValueFlag: true,
                      weight: "",
                      color: "",
                      value: "",
                      text: "",
                    })
                  }
                >
                  {" "}
                  Dodaj nową wartość
                </a>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}
