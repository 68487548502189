import React, { useEffect, useState } from "react";
import {
  Link,
  NavLink,
  Redirect,
  useHistory,
  useLocation,
} from "react-router-dom";
import Can from "../../components/can";
import { Box, Button, FormControlLabel, Tooltip } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TablePagination from "@material-ui/core/TablePagination";
import TableContainer from "@material-ui/core/TableContainer";
import TableBody from "@material-ui/core/TableBody";
import format from "date-fns/format";
import { alertAdd, updateFetchedOrderStatus } from "../../redux/actions";
import {
  paymentCancel,
  paymentStatus,
  purchaseItemsPayment,
} from "../../redux/shopping-cart/actions";
import TableCell from "@material-ui/core/TableCell";
import withStyles from "@material-ui/core/styles/withStyles";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useTheme from "@material-ui/core/styles/useTheme";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  fetchHasAnyOrders,
  fetchOrdersInProgress,
} from "../../redux/orders/actions";
import Loader from "../../components/loader";
import AppBarTitle from "../../components/app-bar-title";
import Switch from "@material-ui/core/Switch";
import FormGroup from "@material-ui/core/FormGroup";
import FormControl from "@material-ui/core/FormControl";
import Container from "@material-ui/core/Container";
import Alert from "@material-ui/lab/Alert";
import OrderStatusWebsocket from "../order-status-websocket";

const MuiNavLink = withStyles((theme) => ({
  root: {
    color: theme.palette.anchor.primary,
    underline: "always",
    "&:hover": {
      color: theme.palette.text.primary2,
    },
  },
}))(Link);

const MuiTableCell = withStyles({
  root: {
    borderBottom: "none",
  },
})(TableCell);

const MuiHeaderTableCell = withStyles((theme) => ({
  root: {
    borderBottom: "none",
    padding: "16px 16px 0px 16px",
    fontSize: theme.typography.leftMenuLabel,
    color: theme.palette.text.alternative3,
    letterSpacing: "0.24px",
  },
}))(TableCell);

const MuiBodyTableCell = withStyles((theme) => ({
  root: {
    borderBottom: "none",
    padding: "8px 16px 0px 16px",
    color: theme.palette.text.alternative2,
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
  margin: {
    margin: theme.spacing(1),
  },
  container: {
    maxWidth: "3000px",
    minWidth: "300px",
    padding: "0px",
  },
  headerGrid: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "12px 8px",
    boxShadow: `0px 1px 0px ${theme.palette.text.primary2}20`,
    position: "sticky",
    top: "6rem",
    [theme.breakpoints.down("sm")]: {
      top: "6rem",
    },
    background: theme.palette.background.default,
    zIndex: "100",
  },
  testKitButton: {
    // backgroundColor: "#0000FF",
    borderRadius: "4px",
  },
  select: {
    "&:after": {
      borderBottomColor: theme.palette.text.item,
    },
    "& .MuiSvgIcon-root": {
      color: theme.palette.text.item,
    },
  },
  orderTable: {
    padding: theme.spacing(2),
  },
  mainGrid: {
    height: "86vh",
    padding: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
  },
  contentGrid: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
  },
  itemGrid: {
    display: "flex",
    justifyContent: "center",
  },
  label: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: theme.typography.text,
    lineHeight: "20px",
    color: theme.palette.text.primary2,
  },
  gridTable: {
    margin: "12px auto",
  },
  bodyCell: {
    letterSpacing: "0.33px",
    font: "normal normal normal 22px/27px Open Sans",
  },
  cmallBodyCell: {
    fontSize: "15px",
  },
  bodyCellSmall: {
    fontSize: "18px",
  },
  navlinkHover: {
    color: theme.palette.text.alternative3,
    textDecoration: "underline",
    "&:hover": {
      color: theme.palette.text.alternative,
    },
  },
}));

const STATUS_TO_NAME_MAP = {
  created: "Nieopłacone",
  paid: "W trakcie realizacji",
  completed: "Wysłane",
  cancelled: "Anulowane",
  ready: "Skompletowane",
};

const STATUS_TO_COLOR_MAP = {
  created: "#E70000",
  paid: "#ff7a06",
  completed: "#1C8A02",
  cancelled: "#E70000",
  ready: "#ff7a06",
};

const OrderInProgress = ({
  ordersInProgress,
  hasAnyOrders,
  ordersInProgressTotalElements,
}) => {
  const classes = useStyles();
  const location = useLocation();
  const accountType = useSelector((s) => s.organization?.accountType);
  const myMail = useSelector((s) => s.my?.mail);
  const globalTheme = useSelector((s) => s.globalTheme || "light");

  const dispatch = useDispatch();
  const theme = useTheme();

  const pages = [50, 100, 200, 300, 500];
  const [pageNr, setPageNr] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("created");
  const [hintDisplay, setHintDisplay] = useState(location.state?.hintDisplay);

  const fetchingOrdersInProgress = useSelector(
    (s) => s.fetchingOrdersInProgress
  );
  const fetchedOrdersInProgress = useSelector((s) => s.fetchedOrdersInProgress);

  const [selectedScope, setSelectedScope] = useState("my");
  const [fetchingOrders, setFetchingOrders] = useState(false);
  const [ongoingPaymentId, setOngoingPaymentOrderId] = useState();
  const [ongoingPaymentTooOld, setOngoingPaymentTooOld] = useState(false);

  const config = useSelector((s) => s.config);

  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  const history = useHistory();

  useEffect(() => {
    dispatch(fetchHasAnyOrders());
  }, []);

  useEffect(() => {
    if (fetchedOrdersInProgress) {
      setTimeout(() => {
        setHintDisplay(false);
      }, [10000]);
    }
  }, [fetchedOrdersInProgress]);

  useEffect(() => {
    async function fetchOrders() {
      setFetchingOrders(true);
      if (hasAnyOrders) {
        dispatch(
          fetchOrdersInProgress({
            scope: selectedScope,
            page: pageNr,
            pageSize: rowsPerPage,
            ...(orderBy
              ? { sort: `${order === "asc" ? orderBy : "-" + orderBy}` }
              : {}),
          })
        );
      }
      setFetchingOrders(false);
      setHintDisplay(location.state?.hintDisplay);
    }
    fetchOrders();
  }, [selectedScope, pageNr, rowsPerPage, orderBy, order, hasAnyOrders]);

  const [ordersInProgressDataset, setOrdersInProgressDataset] = useState([]);

  useEffect(() => {
    setOrdersInProgressDataset(
      ordersInProgress?.map((o) => {
        return {
          ...o,
          tasteTestsAmount:
            (
              (o.items || []).find(({ product }) => product === "TASTE_TEST") ||
              {}
            ).quantity || "-",
          smellTestsAmount:
            (
              (o.items || []).find(({ product }) => product === "SMELL_TEST") ||
              {}
            ).quantity || "-",
          tasteAndSmellTestsAmount:
            (
              (o.items || []).find(
                ({ product }) => product === "SMELLTASTE_TEST"
              ) || {}
            ).quantity || "-",
        };
      })
    );
  }, [ordersInProgress]);

  useEffect(() => {
    const fetchStatus = async () => {
      const response = await dispatch(paymentStatus());
      if (response !== false) {
        setOngoingPaymentOrderId(response.session.myOrderId);

        const dateStart = new Date(response.session.beginTs + "Z");
        const dateNow = new Date();

        const transactionAge = (dateNow - dateStart) / 60000;
        const oldestTrackedTransactionAge = config.oldestTrackedTransactionAge;

        setOngoingPaymentTooOld(transactionAge > oldestTrackedTransactionAge);
      }
    };
    if (ordersInProgress && config) {
      fetchStatus();
    }
  }, [ordersInProgress, config]);

  const wait500ms = async () => {
    await delay(500);
  };

  const toggleChecked = (event) => {
    if (event.target.checked) {
      setSelectedScope("my");
    } else {
      setSelectedScope("all");
    }
  };

  const handleChangePage = (event, newPage) => {
    setPageNr(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPageNr(0);
  };

  const handleSortRequest = (cellId) => {
    const isAsc = orderBy === cellId && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(cellId);
  };

  const extractEmails = (text) => {
    return (
      text.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi) &&
      text.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi)[0]
    );
  };

  const handleUnpaidOrder = async ({ orderId, items, billingInfo }) => {
    // setLoading(true);

    dispatch(alertAdd({ text: "Za chwilę nastąpi przekierowanie" }));
    const myOrderId = orderId;
    let response = null;
    if (items) {
      response = await dispatch(
        purchaseItemsPayment(
          myOrderId,
          items.map((item) => ({
            quantity: item.quantity,
            product: item.product,
          })),
          billingInfo
            ? {
                ...billingInfo,
                emailTo: myMail,
              }
            : undefined
        )
      );
    }
    // }
    await wait500ms();
    if (!response) {
      await dispatch(paymentCancel());
      response = await dispatch(
        purchaseItemsPayment(
          myOrderId,
          items.map((item) => ({
            quantity: item.quantity,
            product: item.product,
          })),
          billingInfo
            ? {
                ...billingInfo,
                emailTo: myMail,
              }
            : undefined
        )
      );
    }
    await wait500ms();
    if (response?.data?.token) {
      if (process.env.NODE_ENV === "production") {
        window.open(
          `${process.env.REACT_APP_P24_PRODUCTION_ADDRESS}/trnRequest/${response.data.token}`,
          `_self`
        );
      } else {
        window.open(
          `${process.env.REACT_APP_P24_STAGING_ADDRESS}/trnRequest/${response.data.token}`,
          `_self`
        );
      }
      // setLoading(false);
    }
  };

  const updateOrderStatus = (orderId, status) => {
    dispatch(updateFetchedOrderStatus(orderId, status));
  };

  const Row = ({ order }) => {
    return (
      <>
        <TableContainer
          style={{
            marginBottom: "50px",
            overflowX: "auto",
            overflowY: "hidden",
          }}
        >
          <Table>
            <colgroup>
              <col style={{ width: "26%" }} />
              <col style={{ width: "26%" }} />
              <col style={{ width: "12%" }} />
              <col style={{ width: "12%" }} />
              <col style={{ width: "12%" }} />
              <col style={{ width: "12%" }} />
            </colgroup>
            <TableHead>
              <TableRow>
                <MuiHeaderTableCell>Numer zamówienia</MuiHeaderTableCell>
                <MuiHeaderTableCell align="right">
                  Właściciel
                </MuiHeaderTableCell>
                <MuiHeaderTableCell align="right"></MuiHeaderTableCell>
                <MuiHeaderTableCell align="right"></MuiHeaderTableCell>
                <MuiHeaderTableCell align="right"></MuiHeaderTableCell>
                <MuiHeaderTableCell align="right"></MuiHeaderTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <MuiBodyTableCell
                  component="th"
                  scope="row"
                  style={{
                    color: theme.palette.text.alternative2,
                    letterSpacing: "0.51px",
                    fontSize: theme.typography.mainTitle,
                    fontWeight: "500",
                  }}
                >
                  {order.orderNumber}

                  {order.invoiceId ? (
                    <div
                      style={{
                        fontSize: theme.typography.footerMobile,
                      }}
                    >
                      <a
                        style={{
                          color: theme.palette.anchor.primary,
                        }}
                        href={`/invoice/v1/if/download/pdf?invoiceId=${order.invoiceId}&locale=pl`}
                      >
                        FAKTURA
                      </a>
                    </div>
                  ) : null}
                </MuiBodyTableCell>
                <MuiBodyTableCell
                  align="right"
                  style={{
                    letterSpacing: "0.33px",
                    font: "normal normal medium 22px/27px Open Sans",
                    fontSize: "22px",
                    fontWeight: "500",
                  }}
                >
                  {order.tenantName.replace(
                    /([^.@\s]+)(\.[^.@\s]+)*@([^.@\s]+\.)+([^.@\s]+)/,
                    ""
                  )}
                </MuiBodyTableCell>
                <MuiBodyTableCell align="right"></MuiBodyTableCell>
                <MuiBodyTableCell align="right"></MuiBodyTableCell>
                <MuiBodyTableCell align="right"></MuiBodyTableCell>
                <MuiBodyTableCell align="right"></MuiBodyTableCell>
              </TableRow>{" "}
              <TableRow>
                <MuiTableCell colSpan={6} style={{ padding: "0px 0px" }}>
                  <Table size="small">
                    <colgroup>
                      <col style={{ width: "26%" }} />
                      <col style={{ width: "26%" }} />
                      <col style={{ width: "12%" }} />
                      <col style={{ width: "12%" }} />
                      <col style={{ width: "12%" }} />
                      <col style={{ width: "12%" }} />
                    </colgroup>
                    <TableHead>
                      <TableRow>
                        <MuiHeaderTableCell
                          style={{ padding: "8px 16px 0px 16px" }}
                        >
                          Status
                        </MuiHeaderTableCell>
                        <MuiHeaderTableCell
                          style={{ padding: "8px 16px 0px 16px" }}
                        >
                          Data zamówienia
                        </MuiHeaderTableCell>
                        <MuiHeaderTableCell
                          style={{ padding: "8px 16px 0px 16px" }}
                        >
                          Test smaku
                        </MuiHeaderTableCell>
                        <MuiHeaderTableCell
                          style={{ padding: "8px 16px 0px 16px" }}
                        >
                          Test węchu
                        </MuiHeaderTableCell>
                        <MuiHeaderTableCell
                          style={{ padding: "8px 16px 0px 16px" }}
                        >
                          Zestaw testów smaku i węchu
                        </MuiHeaderTableCell>
                        <MuiHeaderTableCell
                          style={{ padding: "8px 16px 0px 16px" }}
                        >
                          {" "}
                          {!(
                            order.status === "created" ||
                            order.status === "cancelled"
                          ) ? (
                            order.notMine ? (
                              <Link
                                className={classes.navlinkHover}
                                to={`/order-details/${order.orderId}/external`}
                              >
                                WYNIKI
                              </Link>
                            ) : (
                              <Link
                                className={classes.navlinkHover}
                                to={`/order-details/${order.orderId}`}
                              >
                                WYNIKI
                              </Link>
                            )
                          ) : (
                            <span></span>
                          )}
                        </MuiHeaderTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <MuiBodyTableCell className={classes.bodyCell}>
                          {ongoingPaymentId !== order.orderId && (
                            <span
                              style={{
                                color: `${
                                  globalTheme === "light"
                                    ? STATUS_TO_COLOR_MAP[order.status]
                                    : "#ffff00"
                                }`,
                              }}
                            >
                              {STATUS_TO_NAME_MAP[order.status]}
                            </span>
                          )}

                          {ongoingPaymentId === order.orderId && (
                            <>
                              <span
                                style={{
                                  color:
                                    globalTheme === "light"
                                      ? STATUS_TO_COLOR_MAP[`created`]
                                      : "#ffff00",
                                }}
                              >
                                Płatność w realizacji
                              </span>

                              <div className={classes.cmallBodyCell}>
                                {ongoingPaymentTooOld && (
                                  <Tooltip
                                    title="Płatność jest prawdopodobnie przestarzała, proszę rozważyć anulowanie transakcji"
                                    placement="top"
                                  >
                                    <div className={classes.cmallBodyCell}>
                                      Płatność jest prawdopodobnie przestarzała
                                    </div>
                                  </Tooltip>
                                )}

                                <Tooltip
                                  title="Możesz anulować rozpoczętą wcześniej transakcję"
                                  placement="top"
                                >
                                  <a
                                    className={classes.cmallBodyCell}
                                    style={{
                                      color: theme.palette.text.alternative,
                                    }}
                                    href="#"
                                    onClick={async (e) => {
                                      e.preventDefault();
                                      await dispatch(paymentCancel());
                                      updateOrderStatus(
                                        order.orderId,
                                        "created"
                                      );
                                      setOngoingPaymentOrderId(undefined);
                                    }}
                                  >
                                    Anuluj płatność
                                  </a>
                                </Tooltip>
                              </div>
                            </>
                          )}

                          {order.status === "created" &&
                            ongoingPaymentId !== order.orderId && (
                              <>
                                {" "}
                                <Button
                                  style={{
                                    fontSize:
                                      theme.typography.redirectCardTitle,
                                    padding: "0px",
                                  }}
                                  onClick={() => handleUnpaidOrder(order)}
                                >
                                  <a
                                    style={{
                                      textDecoration: "underline",
                                      color: theme.palette.anchor.primary,
                                    }}
                                  >
                                    OPŁAĆ
                                    {/* <Box sx={{ display: "flex" }}>
                                      <CircularProgress />
                                    </Box> */}
                                    <Loader />
                                  </a>
                                </Button>
                              </>
                            )}
                        </MuiBodyTableCell>
                        <MuiBodyTableCell className={classes.bodyCell}>
                          {" "}
                          {format(new Date(order.created), "yyyy-MM-dd HH:mm")}
                        </MuiBodyTableCell>
                        <MuiBodyTableCell className={classes.bodyCell}>
                          {order.tasteTestsAmount !== "-" ? (
                            `${order.tasteTestsAmount} szt.`
                          ) : (
                            <Tooltip
                              title={
                                myMail === extractEmails(order.tenantName) ||
                                accountType == "ORGANIZATION"
                                  ? ""
                                  : "Brak uprawnień do wyświetlania ilości zamówionych zestawów"
                              }
                            >
                              {myMail === extractEmails(order.tenantName) ||
                              accountType == "ORGANIZATION" ? (
                                <span>0 szt.</span>
                              ) : (
                                <span>-</span>
                              )}
                            </Tooltip>
                          )}
                        </MuiBodyTableCell>
                        <MuiBodyTableCell className={classes.bodyCell}>
                          {order.smellTestsAmount !== "-" ? (
                            `${order.smellTestsAmount} szt.`
                          ) : (
                            <Tooltip
                              title={
                                myMail === extractEmails(order.tenantName) ||
                                accountType == "ORGANIZATION"
                                  ? ""
                                  : "Brak uprawnień do wyświetlania ilości zamówionych zestawów"
                              }
                            >
                              {myMail === extractEmails(order.tenantName) ||
                              accountType == "ORGANIZATION" ? (
                                <span>0 szt.</span>
                              ) : (
                                <span>-</span>
                              )}
                            </Tooltip>
                          )}
                        </MuiBodyTableCell>
                        <MuiBodyTableCell className={classes.bodyCell}>
                          {order.tasteAndSmellTestsAmount !== "-" ? (
                            `${order.tasteAndSmellTestsAmount} szt.`
                          ) : (
                            <Tooltip
                              title={
                                myMail === extractEmails(order.tenantName) ||
                                accountType == "ORGANIZATION"
                                  ? ""
                                  : "Brak uprawnień do wyświetlania ilości zamówionych zestawów"
                              }
                            >
                              {myMail === extractEmails(order.tenantName) ||
                              accountType == "ORGANIZATION" ? (
                                <span>0 szt.</span>
                              ) : (
                                <span>-</span>
                              )}
                            </Tooltip>
                          )}
                        </MuiBodyTableCell>
                        <MuiBodyTableCell
                          className={classes.bodyCell}
                          style={{ fontSize: "18px" }}
                        ></MuiBodyTableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </MuiTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <hr style={{ backgroundColor: theme.palette.hr.primary }} />
      </>
    );
  };

  const page = () => (
    <Box>
      <Can
        permission="test-kit:page"
        ok={() => (
          <>
            <OrderStatusWebsocket
              start={true}
              onPaid={({ orderId }) => {
                updateOrderStatus(orderId, "paid");
                setOngoingPaymentOrderId(undefined);
              }}
            />
            {fetchingOrdersInProgress || fetchingOrders ? (
              <Box p={3}>
                <Loader
                  loading={fetchingOrdersInProgress || fetchingOrders}
                  text="Pobieranie zamówień w realizacji"
                />
              </Box>
            ) : (
              <Container className={classes.container}>
                <AppBarTitle value="Zamówienia" />
                <h1
                  aria-hidden="false"
                  aria-label="Zamówienia"
                  style={{ display: "none" }}
                >
                  Zamówienia
                </h1>
                {hasAnyOrders ? (
                  <Grid container>
                    {/* <AppBarTitle value="Zamówienia" /> */}
                    <Grid
                      item
                      container
                      xs={12}
                      direction={"row"}
                      className={classes.headerGrid}
                      elevation={15}
                    >
                      {accountType === "PERSONAL" && (
                        <Grid item>
                          <Box mr={2}>
                            <FormControl component="fieldset">
                              <FormGroup aria-label="position" row>
                                <FormControlLabel
                                  value="end"
                                  control={
                                    <Switch
                                      checked={selectedScope === "my" || false}
                                      color="primary"
                                      onChange={toggleChecked}
                                    />
                                  }
                                  label={
                                    <span
                                      style={{
                                        color: `${theme.palette.text.primary2}`,
                                        opacity: "0.87",
                                      }}
                                    >
                                      Pokaż tylko moje zamówienia
                                    </span>
                                  }
                                  labelPlacement="end"
                                />
                              </FormGroup>
                            </FormControl>
                          </Box>
                        </Grid>
                      )}{" "}
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          // className={classes.testKitButton}
                          onClick={() => history.push("/test-kit")}
                        >
                          Dodaj zamówienie
                        </Button>
                      </Grid>
                    </Grid>
                    <Grid item xs={10} xl={8} className={classes.gridTable}>
                      {hintDisplay && (
                        <Alert
                          severity="warning"
                          style={{
                            backgroundColor:
                              globalTheme === "high-contrast" && "#000000",
                            border:
                              globalTheme === "high-contrast" &&
                              "1px solid #ffff00",
                            color: globalTheme === "high-contrast" && "#ffff00",
                          }}
                        >
                          Jeśli status zamówienia nie jest zgodny z oczekiwanym
                          - proszę odświeżyć stronę
                        </Alert>
                      )}

                      {ordersInProgressDataset.map((order, id) => (
                        <Row key={id} order={order} />
                      ))}
                    </Grid>
                    <Grid item xs={12}>
                      <TablePagination
                        component="div"
                        page={pageNr}
                        rowsPerPageOptions={pages}
                        rowsPerPage={rowsPerPage}
                        count={ordersInProgressTotalElements}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        labelRowsPerPage={"Wierszy na stronę:"}
                        labelDisplayedRows={({ from, to, count }) =>
                          `${from}-${to} z ${count}`
                        }
                        className={classes.select}
                      />
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container className={classes.mainGrid}>
                    <AppBarTitle value="Zamówienia" />
                    <Grid
                      item
                      container
                      xs={12}
                      spacing={3}
                      className={classes.contentGrid}
                    >
                      <Grid item xs={12} className={classes.itemGrid}>
                        <p className={classes.label}>
                          Nie masz jeszcze żadnego zamówienia.
                        </p>
                      </Grid>
                      <Grid item xs={12} className={classes.itemGrid}>
                        {" "}
                        <Button
                          // className={classes.testKitButton}
                          variant="contained"
                          color="primary"
                          onClick={() => history.push("/test-kit")}
                        >
                          {/* <NavLink
                          to="/test-kit"
                          style={{ color: theme.palette.background.default }}
                        >
                          DODAJ ZAMÓWIENIE
                        </NavLink> */}
                          DODAJ ZAMÓWIENIE
                        </Button>
                      </Grid>{" "}
                    </Grid>
                  </Grid>
                )}
              </Container>
            )}
          </>
        )}
      />
    </Box>
  );

  const redirect = () => <Redirect to="/login" />;

  return <Can permission="order-in-progress:page" ok={page} not={redirect} />;
};

const mapStateToProps = (state) => ({
  ordersInProgress: state.ordersInProgress,
  hasAnyOrders: state.hasAnyOrders,
  ordersInProgressTotalElements: state.ordersInProgressTotalElements,
});
const mapDispatchToProps = (dispatch) => ({
  purchaseItemsPayment: (myOrderId, items, billingInfo) =>
    dispatch(purchaseItemsPayment(myOrderId, items, billingInfo)),
  paymentCancel: () => dispatch(paymentCancel()),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderInProgress);
