import React from "react";
import classnames from "classnames";
import s from "../../pages/register-organization/index.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useTheme from "@material-ui/core/styles/useTheme";

const Loader = ({ loading, text, size = "3x" }) => {
  const theme = useTheme();
  if (loading) {
    return (
      <div className="columns is-centered">
        <div className={classnames("column is-half", s.centered)}>
          <span style={{ color: theme.palette.text.primary2 }}>{text}</span>
          <br />
          <br />
          <br />
          <FontAwesomeIcon
            icon="sync-alt"
            style={{ color: theme.palette.text.primary2 }}
            spin={true}
            size={size}
          />
        </div>
      </div>
    );
  }
  return null;
};

export default Loader;
