import {
  ACTION_1,
  ACTIVATE_FORM,
  ACTIVATE_USER,
  ADD_ITEM_TO_CART,
  ADD_PROTEGE,
  ALERT_HIDE,
  ALERT_SHOW,
  ALERT_TIMEOUT,
  ANONIMIZE_USER,
  CHECK_AUTH_DONE,
  CHECK_AUTH_FAILED,
  CHECK_AUTH_START,
  CREATE_FILLED_FORM_DATA_DONE,
  CREATE_FILLED_FORM_DATA_FAILED,
  CREATE_FILLED_FORM_DATA_START,
  DEACTIVATE_FORM,
  DONE,
  DUPLICATE_FORM,
  EDIT_ITEM_CART,
  EDIT_PROTEGE,
  FAILED,
  FETCH_ACTIVATED_KITS,
  FETCH_ACTIVE_FORMS,
  FETCH_ALL_MY_TESTS,
  FETCH_ALL_PROTEGES,
  FETCH_ALL_TERMS,
  FETCH_CONFIG,
  FETCH_CONFIG_GLOBAL_DONE,
  FETCH_CONFIG_GLOBAL_FAILED,
  FETCH_CONFIG_GLOBAL_START,
  FETCH_DATA_AS_CSV,
  FETCH_FILLED_FORMS_DONE,
  FETCH_FILLED_FORMS_EXTENDED,
  FETCH_FILLED_FORMS_FAILED,
  FETCH_FILLED_FORMS_START,
  FETCH_FILLED_TESTS,
  FETCH_FORM_DATA_DONE,
  FETCH_FORM_DATA_FAILED,
  FETCH_FORM_DATA_START,
  FETCH_FORM_DONE,
  FETCH_FORM_EDITIONS,
  FETCH_FORM_FAILED,
  FETCH_FORM_START,
  FETCH_FORMS_DONE,
  FETCH_FORMS_FAILED,
  FETCH_FORMS_START,
  FETCH_OLD_FORM_EDITIONS,
  FETCH_ORDER_DETAILS_BY_ORDER_ID,
  FETCH_ORDER_SUMMARY_BY_ORDER_ID,
  FETCH_ORG_NAMES_DONE,
  FETCH_ORG_NAMES_FAILED,
  FETCH_ORG_NAMES_START,
  FETCH_ORG_USERS_DONE,
  FETCH_ORG_USERS_FAILED,
  FETCH_ORG_USERS_START,
  FETCH_ORGANIZATION_DONE,
  FETCH_ORGANIZATION_FAILED,
  FETCH_ORGANIZATION_START,
  FETCH_PROTEGES,
  FETCH_SCHEDULED_FORMS,
  INVITE_MULTIPLE_PEOPLE_DONE,
  INVITE_MULTIPLE_PEOPLE_FAILED,
  INVITE_MULTIPLE_PEOPLE_START,
  JOIN_ORGANIZATION_DONE,
  JOIN_ORGANIZATION_FAILED,
  JOIN_ORGANIZATION_START,
  LOGIN_DONE,
  LOGIN_FAILED,
  LOGIN_START,
  LOGOUT_DONE,
  LOGOUT_FAILED,
  LOGOUT_START,
  READ_FONT_SIZE,
  READ_GLOBAL_THEME,
  RECOVER_PASSWORD_DONE,
  RECOVER_PASSWORD_FAILED,
  RECOVER_PASSWORD_START,
  REGISTER_ORGANIZATION_DONE,
  REGISTER_ORGANIZATION_FAILED,
  REGISTER_ORGANIZATION_START,
  REMOVE_FORM_DONE,
  REMOVE_FORM_FAILED,
  REMOVE_FORM_START,
  REMOVE_ITEM_FROM_CART,
  REMOVE_PROTEGE,
  REMOVE_USER,
  RESET_BY_MAIL_PASSWORD_DONE,
  RESET_BY_MAIL_PASSWORD_FAILED,
  RESET_BY_MAIL_PASSWORD_START,
  SAVE_FILLED_FORM_DATA_DONE,
  SAVE_FILLED_FORM_DATA_FAILED,
  SAVE_FILLED_FORM_DATA_START,
  SAVE_FORM_DATA_DONE,
  SAVE_FORM_DATA_FAILED,
  SAVE_FORM_DATA_START,
  SAVE_FORM_DONE,
  SAVE_FORM_FAILED,
  SAVE_FORM_START,
  SEND_COMMENT_DONE,
  SEND_COMMENT_FAILED,
  SEND_COMMENT_START,
  SEND_CONTACT_DONE,
  SEND_CONTACT_FAILED,
  SEND_CONTACT_START,
  SET_APP_BAR_TITLE,
  SET_FONT_SIZE,
  START,
  TOGGLE_GLOBAL_THEME,
  TOGGLE_REQUESTED_SHARING_BY_OWNER,
  UPDATE_ENTITLED_USERS,
  UPDATE_FORM_DATA_DONE,
  UPDATE_FORM_DATA_FAILED,
  UPDATE_FORM_DATA_IS_SHARED,
  UPDATE_FORM_DATA_START,
  UPDATE_FORM_DONE,
  UPDATE_FORM_FAILED,
  UPDATE_FORM_START,
  UPDATE_ORDER_STATUS,
  UPDATE_REQUESTED_SHARING_BY_OWNER,
} from "../../constants";
import { getAxiosInstance, refreshAxios } from "../common";

refreshAxios();

export const action1 = (payload) => ({ type: ACTION_1, payload });

/**
 *
 * @param payload {name, mail, password}
 * @returns {Function}
 */
export const register_organization = (payload) => async (dispatch) => {
  dispatch(register_organization_start());
  try {
    refreshAxios();
    const result = await getAxiosInstance().post(
      "/api/organizations/register",
      {
        firstName: payload.firstName,
        surname: payload.surname,
        orgName: payload.orgName,
        mail: payload.mail,
        password: payload.password,
        accountType: payload.accountType,
      }
    );

    dispatch(register_organization_done(result.data));
    return result.data;
  } catch (e) {
    dispatch(register_organization_failed(e));
    throw e;
  }
};

export const join_organization = (payload) => async (dispatch) => {
  dispatch(join_organization_start());
  try {
    refreshAxios();

    const result = await getAxiosInstance().post("/api/organizations/join", {
      orgName: payload.selectedOrg,
      mail: payload.mail,
      password: payload.password,
      orgCode: payload.orgCode,
    });

    dispatch(join_organization_done(result.data));
  } catch (e) {
    dispatch(join_organization_failed(e));
  }
};

export const register_organization_start = (payload) => ({
  type: REGISTER_ORGANIZATION_START,
  payload,
});
export const register_organization_done = (payload) => ({
  type: REGISTER_ORGANIZATION_DONE,
  payload,
});
export const register_organization_failed = (payload) => ({
  type: REGISTER_ORGANIZATION_FAILED,
  payload,
});

export const join_organization_start = (payload) => ({
  type: JOIN_ORGANIZATION_START,
  payload,
});
export const join_organization_done = (payload) => ({
  type: JOIN_ORGANIZATION_DONE,
  payload,
});
export const join_organization_failed = (payload) => ({
  type: JOIN_ORGANIZATION_FAILED,
  payload,
});

/**
 *
 * @param payload {username, password}
 * @returns {Function}
 */
export const login = (payload) => async (dispatch) => {
  dispatch(login_start());
  try {
    refreshAxios();
    const result = await getAxiosInstance().post("/api/auth/login", {
      username: payload.username,
      password: payload.password,
    });

    refreshAxios(result.data.token);

    const my = await getAxiosInstance().get("/api/persons/my");

    dispatch(
      login_done({
        data: result.data,
        my: my.data,
      })
    );

    return true;
  } catch (e) {
    dispatch(login_failed(e));
    return false;
  }
};

export const login_start = (payload) => ({ type: LOGIN_START, payload });
export const login_done = (payload) => ({ type: LOGIN_DONE, payload });
export const login_failed = (payload) => ({
  type: LOGIN_FAILED,
  errorMessage: payload.response.data.message,
  payload,
});

export const sendContact = ({ name, mail, messageText }) => async (
  dispatch
) => {
  dispatch(sendContact_start());
  try {
    refreshAxios();
    const result = await getAxiosInstance().post("/api/contact", {
      name: name,
      mail: mail,
      messageText: messageText,
    });

    // dispatch(
    //   sendContact_done({
    //     data: result.data,
    //     my: my.data,
    //   })
    // );

    return result;
  } catch (e) {
    dispatch(sendContact_failed(e));
    return false;
  }
};

export const sendContact_start = (payload) => ({
  type: SEND_CONTACT_START,
  payload,
});
export const sendContact_done = (payload) => ({
  type: SEND_CONTACT_DONE,
  payload,
});
export const sendContact_failed = (payload) => ({
  type: SEND_CONTACT_FAILED,
  errorMessage: payload.response.data.message,
  payload,
});

export const checkAuth = () => async (dispatch) => {
  dispatch(checkAuth_start());
  try {
    await refreshAxios();

    const result = await getAxiosInstance().get("/api/auth/refresh");

    const my = await getAxiosInstance().get("/api/persons/my");

    dispatch(
      checkAuth_done({
        token: result.data.token,
        my: my.data,
      })
    );
    return true;
  } catch (e) {
    dispatch(checkAuth_failed(e));
    return false;
  }
};

export const checkAuth_start = (payload) => ({
  type: CHECK_AUTH_START,
  payload,
});
export const checkAuth_done = (payload) => ({ type: CHECK_AUTH_DONE, payload });
export const checkAuth_failed = (payload) => ({
  type: CHECK_AUTH_FAILED,
  payload,
});

export const fetchOrgUsers = () => async (dispatch) => {
  dispatch(fetchOrgUsers_start());
  try {
    const result = await getAxiosInstance().get("/api/persons");

    dispatch(fetchOrgUsers_done({ users: result.data }));
    return true;
  } catch (e) {
    dispatch(fetchOrgUsers_failed(e));
    return false;
  }
};

export const fetchOrgUsers_start = (payload) => ({
  type: FETCH_ORG_USERS_START,
  payload,
});

export const fetchOrgUsers_done = (payload) => ({
  type: FETCH_ORG_USERS_DONE,
  payload,
});

export const fetchOrgUsers_failed = (payload) => ({
  type: FETCH_ORG_USERS_FAILED,
  payload,
});

export const fetchOrgNames = () => async (dispatch) => {
  dispatch(fetchOrgNames_start());
  try {
    const result = await getAxiosInstance().get("/api/organizations/register");
    dispatch(fetchOrgNames_done({ orgNames: result.data }));
    return result.data;
  } catch (e) {
    dispatch(fetchOrgNames_failed(e));
    return false;
  }
};

export const fetchOrgNames_start = (payload) => ({
  type: FETCH_ORG_NAMES_START,
  payload,
});
export const fetchOrgNames_done = (payload) => ({
  type: FETCH_ORG_NAMES_DONE,
  payload,
});
export const fetchOrgNames_failed = (payload) => ({
  type: FETCH_ORG_NAMES_FAILED,
  payload,
});

export const inviteMultiplePeople = (payload) => async (dispatch) => {
  dispatch(inviteMultiplePeople_start());

  try {
    const res = await getAxiosInstance().post(
      `/api/organizations/${payload.orgId}/add_users`,
      payload.mails.map((mail) => ({ mail }))
    );

    dispatch(inviteMultiplePeople_done({ users: res }));
    return res.data;
  } catch (e) {
    dispatch(inviteMultiplePeople_failed(e));
    return {
      error: e,
    };
  }
};

export const inviteMultiplePeople_start = (payload) => ({
  type: INVITE_MULTIPLE_PEOPLE_START,
  payload,
});

export const inviteMultiplePeople_done = (payload) => ({
  type: INVITE_MULTIPLE_PEOPLE_DONE,
  payload,
});

export const inviteMultiplePeople_failed = (payload) => ({
  type: INVITE_MULTIPLE_PEOPLE_FAILED,
  payload,
});

//////////// logout

export const logout = () => async (dispatch) => {
  dispatch(logoutStart());

  try {
    const result = await getAxiosInstance().post("/api/auth/logout");
    dispatch(logoutDone());
    return true;
  } catch (e) {
    dispatch(logoutFailed());
  }

  return false;
};

export const logoutStart = (payload) => ({ type: LOGOUT_START, payload });

export const logoutDone = (payload) => ({ type: LOGOUT_DONE, payload });

export const logoutFailed = (payload) => ({ type: LOGOUT_FAILED, payload });

//// save  form

export const saveForm = (payload) => async (dispatch) => {
  dispatch(saveFormStart());
  try {
    const result = await getAxiosInstance().post(`/api/forms`, payload);
    dispatch(saveFormDone(result.data));
    return result.data;
  } catch (e) {
    dispatch(saveFormFailed(e));
    return null;
  }
};

export const saveFormStart = (payload) => ({ type: SAVE_FORM_START, payload });

export const saveFormDone = (payload) => ({ type: SAVE_FORM_DONE, payload });

export const saveFormFailed = (payload) => ({
  type: SAVE_FORM_FAILED,
  payload,
});

//// update  form

export const updateForm = (id, payload) => async (dispatch) => {
  dispatch(updateFormStart());
  try {
    const result = await getAxiosInstance().put(`/api/forms/${id}`, payload);
    dispatch(updateFormDone(result.data));
    return result.data;
  } catch (e) {
    dispatch(updateFormFailed(e));
    return null;
  }
};
const updateFormStart = (payload) => ({ type: UPDATE_FORM_START, payload });
const updateFormDone = (payload) => ({ type: UPDATE_FORM_DONE, payload });
const updateFormFailed = (payload) => ({ type: UPDATE_FORM_FAILED, payload });

export const duplicateForm = (id) => async (dispatch) => {
  dispatch({ type: DUPLICATE_FORM + START, id });
  try {
    const result = await getAxiosInstance().put(`/api/forms/${id}/duplicate`);
    dispatch({ type: DUPLICATE_FORM + DONE, payload: result.data });
    return result.data;
  } catch (e) {
    dispatch({ type: DUPLICATE_FORM + FAILED, id });
    return null;
  }
};

export const deactivateForm = (id) => async (dispatch) => {
  dispatch({ type: DEACTIVATE_FORM + START, id });
  try {
    const result = await getAxiosInstance().put(`/api/forms/${id}/deactivate`);
    dispatch({ type: DEACTIVATE_FORM + DONE, payload: result.data });
    return result.data;
  } catch (e) {
    dispatch({ type: DEACTIVATE_FORM + FAILED, id });
    return null;
  }
};

//// save  form data
export const saveFormData = (payload, formId) => async (dispatch) => {
  dispatch(saveFormDataStart());
  try {
    const result = await getAxiosInstance().post("/api/form_template", payload);
    dispatch(saveFormDataDone(result.data));
    return result.data;
  } catch (e) {
    dispatch(saveFormDataFailed(e));
  }
};
export const saveFormDataStart = (payload) => ({
  type: SAVE_FORM_DATA_START,
  payload,
});
export const saveFormDataDone = (payload) => ({
  type: SAVE_FORM_DATA_DONE,
  payload,
});
export const saveFormDataFailed = (payload) => ({
  type: SAVE_FORM_DATA_FAILED,
  payload,
});

//// update  form data
export const updateFormData = (payload, formId) => async (dispatch) => {
  dispatch(updateFormDataStart());
  try {
    const result = await getAxiosInstance().put(
      `/api/form_template/${payload.id}`,
      payload
    );
    dispatch(updateFormDataDone(result.data));
    return result.data;
  } catch (e) {
    console.log(e);
    dispatch(updateFormDataFailed(e));
  }
};
export const updateFormDataStart = (payload) => ({
  type: UPDATE_FORM_DATA_START,
  payload,
});
export const updateFormDataDone = (payload) => ({
  type: UPDATE_FORM_DATA_DONE,
  payload,
});
export const updateFormDataFailed = (payload) => ({
  type: UPDATE_FORM_DATA_FAILED,
  payload,
});

//// fetch org

export const fetchOrganization = (payload, orgId) => async (dispatch) => {
  dispatch(fetchOrganizationStart());
  try {
    const result = await getAxiosInstance().get(
      "/api/persons/my/organizations"
    );
    dispatch(fetchOrganizationDone(result.data));
    return result.data;
  } catch (e) {
    dispatch(fetchOrganizationFailed(e));
  }
};

export const fetchOrganizationStart = (payload) => ({
  type: FETCH_ORGANIZATION_START,
  payload,
});

export const fetchOrganizationDone = (payload) => ({
  type: FETCH_ORGANIZATION_DONE,
  payload,
});

export const fetchOrganizationFailed = (payload) => ({
  type: FETCH_ORGANIZATION_FAILED,
  payload,
});

/////////////

export const fetchConfig = () => async (dispatch) => {
  dispatch({ type: FETCH_CONFIG + START });
  try {
    const result = await getAxiosInstance().get("/api/config");
    dispatch({ type: FETCH_CONFIG + DONE, payload: result.data });
    return result.data;
  } catch (e) {
    dispatch({ type: FETCH_CONFIG + FAILED });
  }
};

export const fetchConfigGlobal = () => async (dispatch) => {
  dispatch(fetchConfigGlobalStart());
  try {
    const result = await getAxiosInstance().get("/api/config_global");
    dispatch(fetchConfigGlobalDone(result.data));
    return result.data;
  } catch (e) {
    dispatch(fetchConfigGlobalFailed(e));
  }
};

export const fetchConfigGlobalStart = (payload) => ({
  type: FETCH_CONFIG_GLOBAL_START,
  payload,
});

export const fetchConfigGlobalDone = (payload) => ({
  type: FETCH_CONFIG_GLOBAL_DONE,
  payload,
});

export const fetchConfigGlobalFailed = (payload) => ({
  type: FETCH_CONFIG_GLOBAL_FAILED,
  payload,
});

export const fetchFormsStart = (payload) => ({
  type: FETCH_FORMS_START,
  payload,
});
export const fetchFormsDone = (payload) => ({
  type: FETCH_FORMS_DONE,
  payload,
});
export const fetchFormsFailed = (payload) => ({
  type: FETCH_FORMS_FAILED,
  payload,
});

export const fetchForms = (payload) => async (dispatch) => {
  dispatch(fetchFormsStart());
  try {
    const forms = (await getAxiosInstance().get("/api/forms")).data;

    dispatch(fetchFormsDone(forms));
    return true;
  } catch (e) {
    dispatch(fetchFormsFailed(e));
    return false;
  }
};

export const fetchScheduledForms = (payload) => async (dispatch) => {
  dispatch({ type: FETCH_SCHEDULED_FORMS + START });
  try {
    const forms = (await getAxiosInstance().get("/api/forms/scheduled")).data;

    dispatch({ type: FETCH_SCHEDULED_FORMS + DONE, payload: forms });
    return true;
  } catch (e) {
    dispatch({ type: FETCH_SCHEDULED_FORMS + FAILED, payload: e });
    return false;
  }
};

export const fetchActiveForms = () => async (dispatch) => {
  dispatch({ type: FETCH_ACTIVE_FORMS + START });
  try {
    const forms = (await getAxiosInstance().get("/api/forms/active")).data;

    dispatch({ type: FETCH_ACTIVE_FORMS + DONE, payload: forms });
  } catch (e) {
    dispatch({ type: FETCH_ACTIVE_FORMS + FAILED });
  }
};

/////////////

export const removeFormStart = (payload) => ({
  type: REMOVE_FORM_START,
  payload,
});
export const removeFormDone = (payload) => ({
  type: REMOVE_FORM_DONE,
  payload,
});
export const removeFormFailed = (payload) => ({
  type: REMOVE_FORM_FAILED,
  payload,
});

export const removeForm = (payload) => async (dispatch) => {
  dispatch(removeFormStart());
  try {
    await getAxiosInstance().delete(`/api/forms/${payload}`);

    dispatch(removeFormDone(payload));
    return true;
  } catch (e) {
    dispatch(removeFormFailed(e));
    return false;
  }
};

/////////////

export const fetchFormDataStart = (payload) => ({
  type: FETCH_FORM_DATA_START,
  payload,
});
export const fetchFormDataDone = (payload) => ({
  type: FETCH_FORM_DATA_DONE,
  payload,
});
export const fetchFormDataFailed = (payload) => ({
  type: FETCH_FORM_DATA_FAILED,
  payload,
});

export const fetchFormData = (formDataId) => async (dispatch) => {
  dispatch(fetchFormDataStart());
  try {
    const data = (
      await getAxiosInstance().get(`/api/form_template/${formDataId}`)
    ).data;

    dispatch(fetchFormDataDone(data));
    return true;
  } catch (e) {
    dispatch(fetchFormDataFailed(e));
    return false;
  }
};

/////////////

export const fetchFormStart = (payload) => ({
  type: FETCH_FORM_START,
  payload,
});
export const fetchFormDone = (payload) => ({ type: FETCH_FORM_DONE, payload });
export const fetchFormFailed = (payload) => ({
  type: FETCH_FORM_FAILED,
  payload,
});

export const fetchForm = (id) => async (dispatch) => {
  dispatch(fetchFormStart());
  try {
    const data = (await getAxiosInstance().get(`/api/forms/${id}`)).data;

    dispatch(fetchFormDone(data));
    return true;
  } catch (e) {
    dispatch(fetchFormFailed(e));
    return false;
  }
};

/////////////

export const createFilledFormDataStart = (payload) => ({
  type: CREATE_FILLED_FORM_DATA_START,
  payload,
});
export const createFilledFormDataDone = (payload) => ({
  type: CREATE_FILLED_FORM_DATA_DONE,
  payload,
});
export const createFilledFormDataFailed = (payload) => ({
  type: CREATE_FILLED_FORM_DATA_FAILED,
  payload,
});

export const createFilledFormData = ({
  formId,
  scheduledTestId,
  values,
}) => async (dispatch) => {
  dispatch(createFilledFormDataStart());
  try {
    const payload = {
      form: {
        id: formId,
      },
    };
    const formMetadata = (
      await getAxiosInstance().post(`/api/form_metadata/`, payload)
    ).data;
    dispatch(createFilledFormDataDone(formMetadata));

    const formData = await dispatch(
      saveFilledFormData({
        formId,
        scheduledTestId,
        formMetadataId: formMetadata.id,
        values,
      })
    );

    return formData;
  } catch (e) {
    dispatch(createFilledFormDataFailed(e));
    return false;
  }
};

/////////////

export const saveFilledFormDataStart = (payload) => ({
  type: SAVE_FILLED_FORM_DATA_START,
  payload,
});
export const saveFilledFormDataDone = (payload) => ({
  type: SAVE_FILLED_FORM_DATA_DONE,
  payload,
});
export const saveFilledFormDataFailed = (payload) => ({
  type: SAVE_FILLED_FORM_DATA_FAILED,
  payload,
});

export const saveFilledFormData = ({
  formId,
  formMetadataId,
  scheduledTestId,
  values,
}) => async (dispatch) => {
  dispatch(saveFilledFormDataStart());
  try {
    const payload = {
      formId,
      scheduledTestId,
      data: values,
      formMetadataId,
    };

    const result = (await getAxiosInstance().post(`/api/form_data/`, payload))
      .data;
    dispatch(saveFilledFormDataDone(result));

    return result;
  } catch (e) {
    dispatch(saveFilledFormDataFailed(e));
    return false;
  }
};

/////////////

export const fetchFilledFormsStart = (payload) => ({
  type: FETCH_FILLED_FORMS_START,
  payload,
});
export const fetchFilledFormsDone = (payload) => ({
  type: FETCH_FILLED_FORMS_DONE,
  payload,
});
export const fetchFilledFormsFailed = (payload) => ({
  type: FETCH_FILLED_FORMS_FAILED,
  payload,
});

export const fetchFilledForms = (formId) => async (dispatch) => {
  dispatch(fetchFilledFormsStart());
  try {
    const result = (
      await getAxiosInstance().get(`/api/form_data/by_form_id/${formId}`)
    ).data;
    dispatch(
      fetchFilledFormsDone({
        formId,
        data: result,
      })
    );

    return result;
  } catch (e) {
    dispatch(fetchFilledFormsFailed(e));
    return false;
  }
};

export const fetchFilledFormsExtended = (formId, fieldId, sortOrder) => async (
  dispatch
) => {
  dispatch({ type: FETCH_FILLED_FORMS_EXTENDED + START });
  try {
    const result = (
      await getAxiosInstance().get(
        `/api/form_data/by_form_id_extended/${formId}/?sortBy=${fieldId}&sortOrder=${sortOrder}`
      )
    ).data;
    dispatch({
      type: FETCH_FILLED_FORMS_EXTENDED + DONE,
      payload: {
        formId,
        data: result,
      },
    });

    return result;
  } catch (e) {
    dispatch({ type: FETCH_FILLED_FORMS_EXTENDED + FAILED, payload: e });
    return false;
  }
};

const recoverPasswordStart = (payload) => ({
  type: RECOVER_PASSWORD_START,
  payload,
});
const recoverPasswordDone = (payload) => ({
  type: RECOVER_PASSWORD_DONE,
  payload,
});
const recoverPasswordFailed = (payload) => ({
  type: RECOVER_PASSWORD_FAILED,
  payload,
});
export const recoverPassword = (email) => async (dispatch) => {
  dispatch(recoverPasswordStart());
  try {
    const result = await getAxiosInstance().post(
      `/employees/v1/password/reset/ask`,
      {
        email,
        lang: "pl",
      }
    );

    const data = result.data;
    dispatch(recoverPasswordDone(data));

    return result;
  } catch (e) {
    dispatch(recoverPasswordFailed(e));
    return false;
  }
};

const resetByMailPasswordStart = (payload) => ({
  type: RESET_BY_MAIL_PASSWORD_START,
  payload,
});
const resetByMailPasswordDone = (payload) => ({
  type: RESET_BY_MAIL_PASSWORD_DONE,
  payload,
});
const resetByMailPasswordFailed = (payload) => ({
  type: RESET_BY_MAIL_PASSWORD_FAILED,
  payload,
});

export const resetByMailPassword = ({ email, resetId, newPassword }) => async (
  dispatch
) => {
  dispatch(resetByMailPasswordStart());
  try {
    const response = await getAxiosInstance().post(
      `/employees/v1/password/reset`,
      {
        email,
        resetId,
        newPassword,
      }
    );
    const data = response.data;
    dispatch(resetByMailPasswordDone(data));

    return response;
  } catch (e) {
    dispatch(resetByMailPasswordFailed(e));
    return e.response;
  }
};

export const alertAdd = (payload) => ({ type: ALERT_SHOW, payload });
export const alertHide = (payload) => ({ type: ALERT_HIDE, payload });
export const alertTimeout = (payload) => ({ type: ALERT_TIMEOUT, payload });

const sendCommentStart = () => ({ type: SEND_COMMENT_START });
const sendCommentDone = () => ({ type: SEND_COMMENT_DONE });
const sendCommentFailed = () => ({ type: SEND_COMMENT_FAILED });
export const sendComment = ({ url, text }) => async (dispatch) => {
  dispatch(sendCommentStart());
  try {
    const result = await getAxiosInstance().post(`/api/site-comment`, {
      url,
      text,
    });
    dispatch(sendCommentDone());

    return result;
  } catch (e) {
    dispatch(sendCommentFailed(e));
    return false;
  }
};

export const activateForm = ({ id }) => async (dispatch) => {
  dispatch({ type: ACTIVATE_FORM + START });
  try {
    const result = await getAxiosInstance().put(`/api/forms/${id}/activate`);
    dispatch({ type: ACTIVATE_FORM + DONE, payload: result.data });

    return result;
  } catch (e) {
    dispatch({ type: ACTIVATE_FORM + FAILED, payload: e });
    return false;
  }
};

export const removeUserStart = (payload) => ({ type: REMOVE_USER, payload });
export const removeUserDone = (payload) => ({
  type: REMOVE_USER + DONE,
  payload,
});
export const removeUserFailed = (payload) => ({
  type: REMOVE_USER + FAILED,
  payload,
});

export const removeUser = (userId) => async (dispatch) => {
  dispatch(removeUserStart());
  try {
    const result = await getAxiosInstance().delete(`/api/v1/users/${userId}`);
    dispatch(removeUserDone(result));
    dispatch(fetchOrgUsers());

    return result;
  } catch (e) {
    dispatch(removeUserFailed(e));
    return false;
  }
};
export const anonimizeUserStart = (payload) => ({
  type: ANONIMIZE_USER + START,
  payload,
});
export const anonimizeUserDone = (payload) => ({
  type: ANONIMIZE_USER + DONE,
  payload,
});
export const anonimizeUserFailed = (payload) => ({
  type: ANONIMIZE_USER + FAILED,
  payload,
});

export const anonimizeUser = (userId) => async (dispatch) => {
  dispatch(anonimizeUserStart());
  try {
    const result = await getAxiosInstance().post(
      `/api/persons/${userId}/anonimize`
    );
    dispatch(anonimizeUserDone(result));
    dispatch(fetchOrgUsers());

    return result;
  } catch (e) {
    dispatch(anonimizeUserFailed(e));
    return false;
  }
};

export const activateUserStart = (payload) => ({
  type: ACTIVATE_USER,
  payload,
});
export const activateUserDone = (payload) => ({
  type: ACTIVATE_USER + DONE,
  payload,
});
export const activateUserFailed = (payload) => ({
  type: ACTIVATE_USER + FAILED,
  payload,
});

export const activateUser = (userId) => async (dispatch) => {
  dispatch(activateUserStart());
  try {
    const result = await getAxiosInstance().put(
      `/api/v1/users/${userId}/activate`
    );
    dispatch(activateUserDone(result));
    dispatch(fetchOrgUsers());

    return result;
  } catch (e) {
    dispatch(activateUserFailed(e));
    return false;
  }
};

export const updateEntitledUsersStart = (payload) => ({
  type: UPDATE_ENTITLED_USERS + START,
  payload,
});
export const updateEntitledUsersDone = (payload) => ({
  type: UPDATE_ENTITLED_USERS + DONE,
  payload,
});
export const updateEntitledUsersFailed = (payload) => ({
  type: UPDATE_ENTITLED_USERS + FAILED,
  payload,
});

export const updateEntitledUsers = (formId, emails) => async (dispatch) => {
  dispatch(updateEntitledUsersStart());
  try {
    const result = await getAxiosInstance().put(
      `/api/forms/${formId}/entitled_users`,
      {
        users: emails.map((email) => ({
          username: email,
        })),
      }
    );
    dispatch(updateEntitledUsersDone(result.data));

    return result.data;
  } catch (e) {
    dispatch(updateEntitledUsersFailed(e));
    return false;
  }
};

export const fetchFormEditions = (formId) => async (dispatch) => {
  dispatch({ type: FETCH_FORM_EDITIONS + START });
  try {
    const result = await getAxiosInstance().get(
      `/api/form_data/editions_by_form_id/${formId}`
    );
    dispatch({ type: FETCH_FORM_EDITIONS + DONE, payload: result.data });
    return result.data;
  } catch (e) {
    dispatch({ type: FETCH_FORM_EDITIONS + FAILED, payload: e });
  }
};

export const fetchOldFormEditions = (formId) => async (dispatch) => {
  dispatch({ type: FETCH_OLD_FORM_EDITIONS + START });
  try {
    const result = await getAxiosInstance().get(
      `/api/forms/${formId}/old_versions`
    );
    dispatch({ type: FETCH_OLD_FORM_EDITIONS + DONE, payload: result.data });
    return result.data;
  } catch (e) {
    dispatch({ type: FETCH_OLD_FORM_EDITIONS + FAILED, payload: e });
  }
};

export const fetchDataAsCSV = (formId) => async (dispatch) => {
  dispatch({ type: FETCH_DATA_AS_CSV + START });
  try {
    const result = await getAxiosInstance().get(`/api/form_data/csv/${formId}`);
    dispatch({ type: FETCH_DATA_AS_CSV + DONE, payload: result.data });
    return result.data;
  } catch (e) {
    dispatch({ type: FETCH_DATA_AS_CSV + FAILED, payload: e });
  }
};

export const addItemToCart = (payload) => async (dispatch) => {
  dispatch({ type: ADD_ITEM_TO_CART + START });
  try {
    const result = await getAxiosInstance().post(`/api/cart`, payload);
    dispatch({ type: ADD_ITEM_TO_CART + DONE, payload: result.data });
    return result.data;
  } catch (e) {
    dispatch({ type: ADD_ITEM_TO_CART + FAILED, payload: e });
  }
};

export const editItemCart = (id, payload) => async (dispatch) => {
  dispatch({ type: EDIT_ITEM_CART + START });
  try {
    const result = await getAxiosInstance().put(`/api/cart/${id}`, payload);
    dispatch({ type: EDIT_ITEM_CART + DONE, payload: result.data });
    return result.data;
  } catch (e) {
    dispatch({ type: EDIT_ITEM_CART + FAILED, payload: e });
  }
};

export const removeFromCart = (id) => async (dispatch) => {
  dispatch({ type: REMOVE_ITEM_FROM_CART + START });
  try {
    const result = await getAxiosInstance().delete(`/api/cart/${id}`);
    dispatch({ type: REMOVE_ITEM_FROM_CART + DONE, payload: id });
    return result.data;
  } catch (e) {
    dispatch({ type: REMOVE_ITEM_FROM_CART + FAILED, payload: e });
  }
};

export const fetchAllMyTests = () => async (dispatch) => {
  dispatch({ type: FETCH_ALL_MY_TESTS + START });
  try {
    const result = await getAxiosInstance().get(`/api/form_data/all/my`, {
      params: {
        sortBy: "createdBy",
        sortOrder: "asc",
      },
    });
    dispatch({ type: FETCH_ALL_MY_TESTS + DONE, payload: result.data });
    return result.data;
  } catch (e) {
    dispatch({ type: FETCH_ALL_MY_TESTS + FAILED, payload: e });
  }
};

export const fetchFilledTests = (params) => async (dispatch) => {
  dispatch({ type: FETCH_FILLED_TESTS + START });
  try {
    const result = await getAxiosInstance().post(
      "/api/form_data/all/my",
      params
    );
    dispatch({ type: FETCH_FILLED_TESTS + DONE, payload: result.data });

    return result.data;
  } catch (payload) {
    dispatch({ type: FETCH_FILLED_TESTS + FAILED, payload });
    return false;
  }
};

export const fetchActivatedKits = () => async (dispatch) => {
  dispatch({ type: FETCH_ACTIVATED_KITS + START });
  try {
    const result = await getAxiosInstance().get(
      `/api/forms/scheduled/activated`
    );
    dispatch({ type: FETCH_ACTIVATED_KITS + DONE, payload: result.data });
    return result.data;
  } catch (e) {
    dispatch({ type: FETCH_ACTIVATED_KITS + FAILED, payload: e });
  }
};

export const toggleRequestedSharingByOwner = (scheduledTestId) => async (
  dispatch
) => {
  dispatch({ type: TOGGLE_REQUESTED_SHARING_BY_OWNER + START });
  try {
    const result = await getAxiosInstance().patch(
      `/api/forms/scheduled/${scheduledTestId}/toggle-requested-sharing-by-owner`
    );
    dispatch({
      type: TOGGLE_REQUESTED_SHARING_BY_OWNER + DONE,
      payload: result.data,
    });
    return result.data;
  } catch (e) {
    dispatch({ type: TOGGLE_REQUESTED_SHARING_BY_OWNER + FAILED, payload: e });
  }
};

export const updateRequestedSharingByOwner = (
  scheduledFormId,
  enable
) => async (dispatch) => {
  dispatch({ type: UPDATE_REQUESTED_SHARING_BY_OWNER + START });
  try {
    const result = await getAxiosInstance().patch(
      `/api/forms/scheduled/${scheduledFormId}/${
        enable ? "enable" : "disable"
      }-requested-sharing-by-owner`
    );
    dispatch({
      type: UPDATE_REQUESTED_SHARING_BY_OWNER + DONE,
      payload: result.data,
    });
    return result.data;
  } catch (e) {
    dispatch({ type: UPDATE_REQUESTED_SHARING_BY_OWNER + FAILED, payload: e });
  }
};

export const updateSharingFormData = (formDataId, enable) => async (
  dispatch
) => {
  dispatch({ type: UPDATE_FORM_DATA_IS_SHARED + START });
  try {
    const result = await getAxiosInstance().patch(
      `/api/form_data/${formDataId}/${enable ? "enable" : "disable"}-sharing`
    );
    dispatch({
      type: UPDATE_FORM_DATA_IS_SHARED + DONE,
      payload: result.data,
    });
    return result.data;
  } catch (e) {
    dispatch({ type: UPDATE_FORM_DATA_IS_SHARED + FAILED, payload: e });
  }
};

export const setAppBarTitle = (title) => async (dispatch) => {
  dispatch({
    type: SET_APP_BAR_TITLE,
    payload: { title },
  });
};

export const fetchTerms = (orgId) => async (dispatch) => {
  dispatch({ type: FETCH_ALL_TERMS + START });
  try {
    const result = await getAxiosInstance().get(`/api/organizations/terms`);
    dispatch({
      type: FETCH_ALL_TERMS + DONE,
      payload: result.data,
      orgId,
    });
    return result.data;
  } catch (e) {
    dispatch({ type: FETCH_ALL_TERMS + FAILED, payload: e });
  }
};

/**
 * This will fetch the following order details:
 * - all kits that were part of this order
 * - all tests that were in the kit
 * - all results of the tests that were already conducted
 * @param data
 * @returns {function(*): Promise<*|undefined>}
 */
export const fetchOrderDetailsByOrderId = ({
  orderId,
  isExternal,
  page,
  pageSize,
  sort,
}) => async (dispatch) => {
  dispatch({ type: FETCH_ORDER_DETAILS_BY_ORDER_ID + START });
  try {
    const result = await getAxiosInstance().get(`/api/v2/orders/${orderId}`, {
      params: {
        ...(isExternal ? { isExternal } : {}),
        page,
        ...(pageSize ? { pageSize } : {}),
        ...(sort ? { sort } : {}),
      },
    });

    dispatch({
      type: FETCH_ORDER_DETAILS_BY_ORDER_ID + DONE,
      payload: result.data,
    });
    return result.data;
  } catch (e) {
    dispatch({ type: FETCH_ORDER_DETAILS_BY_ORDER_ID + FAILED, payload: e });
  }
};

export const fetchOrderSummaryOrderId = (orderId) => async (dispatch) => {
  dispatch({ type: FETCH_ORDER_SUMMARY_BY_ORDER_ID + START });
  try {
    const result = await getAxiosInstance().get(`/api/cart/${orderId}`);

    dispatch({
      type: FETCH_ORDER_SUMMARY_BY_ORDER_ID + DONE,
      payload: result.data,
    });
    return result.data;
  } catch (e) {
    dispatch({ type: FETCH_ORDER_SUMMARY_BY_ORDER_ID + FAILED, payload: e });
  }
};

export const fetchProteges = (payload) => async (dispatch) => {
  dispatch({ type: FETCH_PROTEGES + START });
  try {
    const result = await getAxiosInstance().get(`/api/v2/subaccount`, {
      params: payload,
    });
    dispatch({
      type: FETCH_PROTEGES + DONE,
      payload: result.data,
    });
    return result.data.content;
  } catch (e) {
    dispatch({ type: FETCH_PROTEGES + FAILED, payload: e });
  }
};

export const fetchAllProteges = (payload) => async (dispatch) => {
  dispatch({ type: FETCH_ALL_PROTEGES + START });
  try {
    const result = await getAxiosInstance().get(`/api/subaccount`);
    dispatch({
      type: FETCH_ALL_PROTEGES + DONE,
      payload: result.data,
    });
    return result.data;
  } catch (e) {
    dispatch({ type: FETCH_ALL_PROTEGES + FAILED, payload: e });
  }
};

export const addProtege = (payload) => async (dispatch) => {
  dispatch({ type: ADD_PROTEGE + START });

  try {
    const res = await getAxiosInstance().post(`/api/subaccount`, payload);
    dispatch({ type: ADD_PROTEGE + DONE, payload: res.data });
    return res.data;
  } catch (e) {
    dispatch({ type: ADD_PROTEGE + FAILED, payload: e });
    throw e;
  }
};

export const editProtege = (protegeId, payload) => async (dispatch) => {
  dispatch({ type: EDIT_PROTEGE + START });

  try {
    const res = await getAxiosInstance().put(
      `/api/subaccount/${protegeId}`,
      payload
    );
    dispatch({ type: EDIT_PROTEGE + DONE, payload: res.data });
    return res.data;
  } catch (e) {
    dispatch({ type: EDIT_PROTEGE + FAILED, payload: e });
    throw e;
  }
};

export const removeProtege = (protegeId) => async (dispatch) => {
  dispatch({ type: REMOVE_PROTEGE + START });

  try {
    const res = await getAxiosInstance().delete(`/api/subaccount/${protegeId}`);
    dispatch({ type: REMOVE_PROTEGE + DONE, payload: res.data });
    return res.data;
  } catch (e) {
    dispatch({ type: REMOVE_PROTEGE + FAILED, payload: e });
    throw e;
  }
};

export const toggleGlobalTheme = () => async (dispatch) => {
  dispatch({ type: TOGGLE_GLOBAL_THEME + DONE });
  return {
    type: TOGGLE_GLOBAL_THEME,
  };
};
export const readGlobalTheme = () => {
  return {
    type: READ_GLOBAL_THEME,
  };
};
export const readFontSize = () => {
  return {
    type: READ_FONT_SIZE,
  };
};

export const setFontSize = (fontSize) => async (dispatch) => {
  dispatch({ type: SET_FONT_SIZE + DONE, payload: fontSize });
  return {
    fontSize: SET_FONT_SIZE,
  };
};

export const updateFetchedOrderStatus = (orderId, status) => (dispatch) => {
  dispatch({ type: UPDATE_ORDER_STATUS, payload: { orderId, status } });
};
