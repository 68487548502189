import React, { useState } from "react";
import TimePicker from "rc-time-picker";
import * as classnames from "classnames";
import moment from "moment";

const TimeFilter = ({ id, onChange }) => {
  const [value, setValue] = useState();
  const [since, setSince] = useState();
  const [until, setUntil] = useState();

  const updateValue = (val) => {
    setValue(val);
    let u;
    let s;
    !val ? (val = "") : (val = moment(val).format("HH:mm"));
    !until ? (u = "") : (u = moment(until).format("HH:mm"));
    !since ? (s = "") : (s = moment(since).format("HH:mm"));
    if (onChange) {
      onChange({
        id,
        value: {
          value: val,
          until: u,
          since: s,
        },
      });
    }
  };
  const updateSince = (val) => {
    setSince(val);
    let v;
    let u;
    !val ? (val = "") : (val = moment(val).format("HH:mm"));
    !until ? (u = "") : (u = moment(until).format("HH:mm"));
    !value ? (v = "") : (v = moment(value).format("HH:mm"));
    if (onChange) {
      onChange({
        id,
        value: {
          value: v,
          until: u,
          since: val,
        },
      });
    }
  };

  const updateUntil = (val) => {
    setUntil(val);
    let v;
    let s;
    !val ? (val = "") : (val = moment(val).format("HH:mm"));
    !value ? (v = "") : (v = moment(value).format("HH:mm"));
    !since ? (s = "") : (s = moment(since).format("HH:mm"));
    if (onChange) {
      onChange({
        id,
        value: {
          value: v,
          until: val,
          since: s,
        },
      });
    }
  };

  return (
    <div>
      <TimePicker
        value={value}
        onChange={(value) => updateValue(value)}
        className={classnames("input")}
        format="HH:mm"
        showSecond={false}
        placeholder="Wyszukaj godzinę"
      />
      <TimePicker
        value={since}
        onChange={(value) => updateSince(value)}
        className={classnames("input")}
        format="HH:mm"
        showSecond={false}
        placeholder="Wyszukaj od godziny"
      />
      <TimePicker
        value={until}
        onChange={(value) => updateUntil(value)}
        className={classnames("input")}
        format="HH:mm"
        showSecond={false}
        placeholder="Wyszukaj do godziny"
      />
    </div>
  );
};

export default TimeFilter;
