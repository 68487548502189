import { Box } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import Can from "../../components/can";
import { fetchOrgUsers } from "../../redux/actions";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { anonimizeUser } from "../../redux/actions";

const AdminAllUsersPage = () => {
  const users = useSelector((s) => s.orgUsers);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchOrgUsers());
  }, []);
  const page = () => (
    <Box>
      <Box p={1}>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Imię</TableCell>
                <TableCell align="right">Nazwisko</TableCell>
                <TableCell align="right">Opcje</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user) => (
                <TableRow key={user.id}>
                  <TableCell component="th" scope="row">
                    {user.firstName || ""}
                  </TableCell>
                  <TableCell align="right">{user.surname || ""}</TableCell>
                  {user.anonimizationRequestedAt && (
                    <TableCell align="right">
                      <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={() => dispatch(anonimizeUser(user.id))}
                      >
                        Wymaż dane
                      </Button>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
  const redirect = () => <Redirect to="/login" />;

  return <Can permission="adminUsersList:view" ok={page} not={redirect} />;
};

export default AdminAllUsersPage;
