import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import Can from "../../components/can";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useTheme from "@material-ui/core/styles/useTheme";
import Loader from "../../components/loader";
import { Grid, Typography } from "@material-ui/core";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import {
  paymentStatus,
  paymentVerify,
  watchMyOrder,
} from "../../redux/shopping-cart/actions";
import OrderStatusWebsocket from "../order-status-websocket";

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    padding: "15px",
  },
  icon: {
    fill: "green",
    width: "100px",
    height: "100px",
  },
  gridCentered: {
    display: "flex",
    justifyContent: "center",
  },
}));

const PaymentAcceptedPage = ({ history }) => {
  const classes = useStyles();
  const theme = useTheme();

  const actualPaymentStatus = useSelector((s) => s.paymentStatus || "");
  const paymentVerifiedCode = useSelector((s) => s.paymentVerify || null);
  const [openSocketAndWaitUntilPaid, setOpenSocketAndWaitUntilPaid] = useState(
    false
  );

  const dispatch = useDispatch();

  useEffect(() => {
    async function getPaymentStatus() {
      const response = await dispatch(paymentStatus());
      // ETAP 0
      if (!response) {
        history.push("/payment-declined");
      }
    }

    async function watchMyOrderCall() {
      const response = await dispatch(watchMyOrder());

      history.push({
        pathname: "/orders",
      });
    }

    // getPaymentStatus();
    watchMyOrderCall();
  }, []);

  useEffect(() => {
    //   //ETAP 1
    // if (actualPaymentStatus === "started") {
    //   async function getPaymentStatus() {
    //     const response = await dispatch(paymentStatus());
    //     if (!response) {
    //       history.push("/payment-declined");
    //     }
    //   }
    //
    //   const interval = setInterval(() => {
    //     getPaymentStatus();
    //   }, 2000);
    //
    //   return () => clearInterval(interval);
    // }
    // //ETAP 2
    // if (actualPaymentStatus === "confirmed") {
    //   async function getPaymentVerify() {
    //     await dispatch(paymentVerify());
    //   }
    //   getPaymentVerify();
    // }
    //   if (
    //     paymentVerifiedCode &&
    //     paymentVerifiedCode !== 200 &&
    //     actualPaymentStatus === "committed"
    //   ) {
    //     async function getPaymentStatus() {
    //       await dispatch(paymentStatus());
    //     }
    //     setTimeout(() => getPaymentStatus(), 2000);
    //   }
    //   // ETAP 2 - negative response
    //   if (
    //     paymentVerifiedCode &&
    //     paymentVerifiedCode !== 200 &&
    //     actualPaymentStatus !== "committed"
    //   ) {
    //
    //     history.push("/payment-declined");
    //   }
  }, [actualPaymentStatus]);

  useEffect(() => {
    if (paymentVerifiedCode === 200) {
      setOpenSocketAndWaitUntilPaid(true);
      // setTimeout(
      //   () =>
      //     history.push({
      //       pathname: "/orders",
      //       state: {
      //         hintDisplay: "true",
      //       },
      //     }),
      //   6000
      // );
    }
    // ETAP 2 - negative response
    if (paymentVerifiedCode !== 200) {
      async function getPaymentStatus() {
        await dispatch(paymentStatus());
      }
      getPaymentStatus();
    }
  }, [paymentVerifiedCode]);

  const page = () =>
    paymentVerifiedCode !== 200 && actualPaymentStatus !== "confirmed" ? (
      <>
        <Loader loading={true} text="Pobieranie statusu płatności" />
        <h1
          aria-hidden="false"
          aria-label="Pobieranie statusu płatności"
          style={{ display: "none" }}
        >
          Pobieranie statusu płatności
        </h1>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <a style={{ color: theme.palette.anchor.primary }} href="/orders">
            Przejdź do widoku zamówień
          </a>
        </div>
      </>
    ) : (
      <div className={classes.root}>
        <OrderStatusWebsocket
          start={openSocketAndWaitUntilPaid}
          onPaid={() => {
            history.push({
              pathname: "/orders",
            });
          }}
        />
        <Grid container spacing={3} alignItems="center" justify="center">
          <h1
            aria-hidden="false"
            aria-label="Status płatności"
            style={{ display: "none" }}
          >
            Status płatności
          </h1>
          <Grid item xs={12} className={classes.gridCentered}>
            <Typography variant="h4" gutterBottom>
              Płatność przebiegła pomyślnie
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.gridCentered}>
            <CheckCircleOutlinedIcon className={classes.icon} />
          </Grid>
          <Grid item xs={12} className={classes.gridCentered}>
            <Typography variant="overline" display="block" gutterBottom>
              Za moment nastąpi przekierowanie na stronę z zamówieniami...
            </Typography>
          </Grid>
        </Grid>
      </div>
    );

  const redirect = () => <Redirect to="/login" />;

  return <Can permission="paymentStatus:page" ok={page} not={redirect} />;
};

export default PaymentAcceptedPage;
