import React from 'react'
import DateFilter from './date-filter'
import TextFilter from './text-filter'
import TimeFilter from './time-filter'
import DictionaryFilter from './dictionary-filter'

export const CreateFilter = (props) => {
    const { dataType } = props
    switch (dataType) {
        case 'DATE':
            return <DateFilter {...props}/>
        case 'TIME':
            return <TimeFilter {...props}/>
        case 'DICTIONARY':
            return <DictionaryFilter {...props}/>
        default:
            return <TextFilter {...props}/>
    }
}