import React, { useEffect, useState } from "react";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { getAxiosInstance } from "../../redux/common";
import EmployeeRow from "./employee-row";
import { AddNewEmployee } from "./add-new-employee";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    display: "block",
  },
}));

const Employees = ({ userState }) => {
  const [employees, setEmployees] = useState([]);
  const [showAdd, setShowAdd] = useState(false);

  const loggedAsOwner = userState[0].roles.includes("SystemOwner");

  const classes = useStyles();

  const loadEmployees = async () => {
    try {
      const response = await getAxiosInstance().get("/owner/v1/all");

      const employees = response.data;
      setEmployees(employees);
    } catch (e) {
      setEmployees([]);
    }
  };

  useEffect(() => {
    loadEmployees();
  }, []);

  return (
    <div>
      {showAdd && (
        <AddNewEmployee
          loggedAsOwner={loggedAsOwner}
          handleClose={() => {
            setShowAdd(false);
          }}
          refreshList={loadEmployees}
        />
      )}
      <Button onClick={() => setShowAdd(true)}>Dodaj</Button>

      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="employee list">
          <TableHead>
            <TableRow>
              <TableCell>Nazwa</TableCell>
              <TableCell>Email</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {employees.map((e, index) => (
              <EmployeeRow
                key={index}
                employeeData={e}
                loggedAsOwner={loggedAsOwner}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default Employees;
