import React, { useState } from "react";
import Can from "../../components/can";
import { Redirect } from "react-router-dom";
import s from "./index.module.css";
import * as classnames from "classnames";
import { alertAdd } from "../../redux/actions";
import { useDispatch } from "react-redux";
import { changeMyPassword } from "../../redux/person/actions";
import { Box } from "@material-ui/core";

const PasswordChangePage = ({ history }) => {
  const [state, setState] = useState({
    newPassword: "",
    oldPassword: "",
    confirmedNewPassword: "",
  });
  const [busy, setBusy] = useState(false);
  const [passwordScore, setPasswordScore] = useState(0);
  const [typingTimer, setTypingTimer] = useState(null);
  const dispatch = useDispatch();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
    testStrengthPassword(event);
  };

  const testStrengthPassword = (e) => {
    let pass = e.target.value;
    let tmpPasswordScore = 0;
    if (pass.length > 8) {
      tmpPasswordScore = tmpPasswordScore + 1;
    }
    if (/[a-z]/.test(pass)) {
      tmpPasswordScore = tmpPasswordScore + 1;
    }
    if (/[A-Z]/.test(pass)) {
      tmpPasswordScore = tmpPasswordScore + 1;
    }
    if (/[0-9]/.test(pass)) {
      tmpPasswordScore = tmpPasswordScore + 1;
    }
    if (/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(pass)) {
      tmpPasswordScore = tmpPasswordScore + 1;
    }
    clearTimeout(typingTimer);
    if (tmpPasswordScore === 0) {
      setPasswordScore(tmpPasswordScore);
    }

    setTypingTimer(
      setTimeout(() => {
        if (tmpPasswordScore) {
          setPasswordScore(tmpPasswordScore);
        }
      }, 1000)
    );
  };

  const changePassword = async () => {
    setBusy(true);
    const isPasswordChanged = await dispatch(
      changeMyPassword({
        oldPassword: state.oldPassword,
        newPassword: state.newPassword,
      })
    );
    if (isPasswordChanged) {
      dispatch(
        alertAdd({
          text: "Hasło zostało zmienione.",
        })
      );

      setState({
        newPassword: "",
        oldPassword: "",
        confirmedNewPassword: "",
      });
    } else {
      dispatch(
        alertAdd({
          text: "Wprowadzone aktualne hasło jest niepoprawne.",
        })
      );
    }
    setBusy(false);
  };

  const handleCancelClick = (event) => {
    history.push("/");
  };

  const handleSaveClick = (e) => {
    e.preventDefault();

    state.newPassword === state.confirmedNewPassword && state.newPassword !== ""
      ? changePassword()
      : dispatch(
          alertAdd({
            text:
              "Nie wprowadzono nowego hasła lub wprowadzone nowe hasło nie zgadza się z powtórzonym hasłem.",
          })
        );
  };

  const page = () => (
    <div className="container is-fluid">
      <p className="title">Zmiana hasła</p>

      <form
        id="change_password_form"
        onSubmit={handleSaveClick}
        noValidate
        autoComplete="off"
      ></form>

      <div className="columns">
        <div className={classnames("column is-half")}>
          <table className={`table is-fullwidth ${s.noborder}`}>
            <tbody>
              <tr>
                <td>Aktualne hasło</td>
                <td>
                  <input
                    type="password"
                    className="input"
                    name="oldPassword"
                    form="change_password_form"
                    value={state.oldPassword}
                    onChange={handleChange}
                  />
                </td>
              </tr>
              <tr>
                <td>Nowe hasło</td>
                <td>
                  <input
                    type="password"
                    className="input"
                    name="newPassword"
                    form="change_password_form"
                    value={state.newPassword}
                    onChange={handleChange}
                  />
                </td>
              </tr>
              <tr>
                <td>Powtórz nowe hasło</td>
                <td>
                  <input
                    type="password"
                    className="input"
                    name="confirmedNewPassword"
                    form="change_password_form"
                    value={state.confirmedNewPassword}
                    onChange={handleChange}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <Box mt={1} display="flex">
            <span className={s.formHint}>
              Aby spełnić zalożenie polityki Silnego Hasła prosi się o podanie
              co najmniej jednej wielkiej litery, małej litery, cyfry oraz znaku
              specjanlego. Hasło powinno być dłuższe niż 7 znaków.
            </span>
          </Box>
          {passwordScore > 0 && (
            <div>
              <label className={s.labelStrengthPassword}>
                Siła nowego hasła:
              </label>
              <Box mt={1} display="flex">
                <span
                  className={s.strengthPassword}
                  datascore={passwordScore}
                />
              </Box>
            </div>
          )}
        </div>
      </div>

      <div className="field is-grouped is-grouped-centered">
        <div className="control">
          <button className="button" onClick={handleCancelClick} name="clear">
            Anuluj
          </button>
        </div>
        <div className="control">
          <button
            form="change_password_form"
            type="submit"
            className={classnames("button is-primary", { "is-loading": busy })}
            name="save"
          >
            Zapisz
          </button>
        </div>
      </div>
    </div>
  );

  const redirect = () => <Redirect to="/login" />;

  return <Can permission="passwordChangePage:view" ok={page} not={redirect} />;
};

export default PasswordChangePage;
