import React, { useState, useEffect } from "react";
import { getAxiosInstance } from "../../redux/common";
import { TextField, Button, Container, Paper, Box } from "@material-ui/core";
import { Redirect } from "react-router-dom";
import Loader from "../../components/loader";

function LoginForm(props) {
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [isUserLoggedIn, setUserLoggedIn] = useState();
  const onUserChange = props.onUserChange
    ? props.onUserChange
    : () => {
        console.log("Set onUserChange prop!");
      };

  function handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    setFormData((formData) => ({
      ...formData,
      [name]: value,
    }));
  }

  async function checkLogin() {
    try {
      await getAxiosInstance().get("/owner/v1/auth");
      setUserLoggedIn(true);
    } catch (e) {
      setUserLoggedIn(false);
    }
  }

  async function handleSubmit(event) {
    event.preventDefault();

    const loginData = {
      email: formData.email,
      password: formData.password,
    };

    try {
      await getAxiosInstance().post("/owner/v1/login", loginData);
      onUserChange();
      setUserLoggedIn(true);
    } catch (e) {
      console.log(e.response.status);
      setUserLoggedIn(false);
    }
  }

  useEffect(() => {
    checkLogin();
  }, []);

  if (isUserLoggedIn === undefined) {
    return <Loader loading={true} text="Uwierzytelnianie" />;
  } else if (isUserLoggedIn) {
    return <Redirect to="/owner-panel" />;
  } else {
    return (
      <Container maxWidth="sm">
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
        >
          <Paper elevation={3}>
            <Box pl={4} pr={4} pt={2} pb={2}>
              <form onSubmit={handleSubmit}>
                <Box display="block" m={1}>
                  <TextField
                    name="email"
                    label="User name"
                    value={formData.email}
                    onChange={handleChange}
                  />
                </Box>
                <Box display="block" m={1}>
                  <TextField
                    name="password"
                    label="Password"
                    type="password"
                    value={formData.password}
                    onChange={handleChange}
                  />
                </Box>
                <Box m={2}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    type="submit"
                  >
                    Zaloguj się
                  </Button>
                </Box>
              </form>
            </Box>
          </Paper>
        </Box>
      </Container>
    );
  }
}

export default LoginForm;
