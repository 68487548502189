import React, {useState} from 'react'
import * as classnames from 'classnames'

const TextFilter = ({id, onChange}) => {
    const [value, setValue] = useState('')

    const updateValue = value => {
        setValue(value)

        if(onChange){
            onChange({
                id, value: value
            })
        }
    }
    return (
        <input name="value" className={classnames('input')} type="text" value={value} onChange={({target: {value}}) => updateValue(value)} />
    )
}

export default TextFilter