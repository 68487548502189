import { createMuiTheme } from "@material-ui/core/styles";
import React from "react";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

export const lightTheme = {
  palette: {
    primary: {
      main: "#102655",
    },
    secondary: {
      main: "#AC1426",
    },
    text: {
      fontFamily: "Open Sans",
      primary: "#5D5D5D",
      primary2: "#000000",
      alternative: "#FF7A06",
      alternative2: "#102655",
      alternative3: "#122140",
      email: "#282829",
      blueHeader: "#0021db",
      item: "#707070",
      qr: "#5D5D5D",
    },
    checkIcon: {
      primary: "#4CAF50",
    },
    background: {
      default: "#fff",
      tab: "rgba(250, 250, 250,  .8)",
      border: "rgba(250, 250, 250,  .8)",
      borderTest: "#979797",
      filter: "#f5f5f5",
      filterBorder: "#f5f5f5",
    },
    hr: {
      primary: "#DCDCDC",
    },
    anchor: {
      primary: "#3273dc",
    },
    paragraph: {
      primary: "rgb(18, 33, 64)",
    },
  },
  drawer: {
    backgroundColor: "#fff",
  },
  typography: {
    fontFamily: "Open Sans",
    color: "#5D5D5D",
    // fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    h6: {
      color: "#0021db",
    },
  },
  props: {
    MuiLink: {
      underline: "none",
    },
  },
  overrides: {
    MuiFilledInput: {
      root: {
        backgroundColor: "#ffffff",
        "&:hover": {
          backgroundColor: "#ffffff",
        },
        "&.Mui-focused": {
          backgroundColor: "#ffffff",
        },
      },
      input: {
        "&:-webkit-autofill": {
          WebkitBoxShadow: "0 0 0 100px #ffffff inset",
          color: "#000",
        },
      },
    },
    MuiInputBase: {
      input: {
        color: "#000",
      },
    },
    MuiTab: {
      root: {
        color: "black",
        backgroundColor: "#EEEDED",
        opacity: 0.6,
        borderTopRightRadius: "1rem",
        borderTopLeftRadius: "1rem",
        "&$selected": {
          backgroundColor: "rgba(250, 250, 250,  .8)",
          color: "black",
          borderTopRightRadius: "1rem",
          borderTopLeftRadius: "1rem",
          opacity: 1,
          color: "black",
        },
      },
    },
  },
};

export const highContrastTheme = {
  palette: {
    primary: {
      main: "#ffff00",
    },
    secondary: {
      main: "#ffff00",
    },
    error: {
      main: "#ffff00",
    },
    helper: {
      color: "#ffff00",
    },
    text: {
      fontFamily: "Open Sans",
      primary: "#ffff00",
      primary2: "#ffff00",
      error: "#ffff00",
      alternative: "#ffff00",
      alternative2: "#ffff00",
      alternative3: "#ffff00",
      blueHeader: "#0021db",
      email: "#ffff00",
      disabled: "#ffff00",
      item: "#ffff00",
    },
    checkIcon: {
      primary: "#ffff00",
    },
    background: {
      default: "#000",
      tab: "#000",
      border: "#ffff00",
      borderTest: "#ffff00",
      filter: "#000",
      filterBorder: "#ffff00",
    },
    hr: {
      primary: "#ffff00",
    },
    anchor: {
      primary: "#ffff00",
    },
    paragraph: {
      primary: "#ffff00",
    },
    action: {
      disabledBackground: "#000",
      border: "1px solid red",
      disabled: "#ffff00",
    },
  },
  drawer: {
    backgroundColor: "#000",
  },
  typography: {
    fontFamily: "Open Sans",
    color: "#ffff00",
    // fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    h6: {
      color: "#ffff00",
    },
  },
  overrides: {
    MuiSwitch: {
      switchBase: {
        // Controls default (unchecked) color for the thumb
        color: "#d1c811",
      },
    },
    MuiPaper: {
      root: {
        backgroundColor: "#000",
      },
    },
    MuiGrid: {
      container: {
        backgroundColor: "#000",
      },
    },
    MuiButton: {
      contained: {
        color: "#000",
        backgroundColor: "#ffff00",
        "&:hover": {
          backgroundColor: "#b5b516",
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            backgroundColor: "#ffff00",
          },
        },
      },
    },
    MuiButtonBase: {
      root: {
        "&.Mui-disabled": {
          // border: `1px solid #ffff00`,
        },
      },
    },
    MuiFormHelperText: {
      root: {
        color: "#ffff00",
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: "#000",
        "&:hover": {
          backgroundColor: "#000",
        },
        "&$focused": {
          backgroundColor: "#000",
        },
      },
      underline: {
        "&:before": {
          borderBottomColor: "#ffff00",
        },
        "&:hover:not(.Mui-focused):before": {
          borderBottomColor: "#ffff00",
        },
        "&:after": {
          // focused
          borderBottomColor: "#ffff00",
        },
      },
      input: {
        "&:-webkit-autofill": {
          WebkitBoxShadow: "0 0 0 100px #000 inset",
          WebkitTextFillColor: "#ffff00",
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        "& $notchedOutline": {
          borderColor: "#ffff00",
        },
        "&:hover $notchedOutline": {
          borderColor: "#ffff00",
        },
        "&$focused $notchedOutline": {
          borderColor: "#ffff00",
        },
      },
      input: {
        "&:-webkit-autofill": {
          WebkitBoxShadow: "0 0 0 100px #000 inset",
          WebkitTextFillColor: "#ffff00",
        },
        "&[type=number]": {
          "-moz-appearance": "textfield",
        },
        "&::-webkit-outer-spin-button": {
          "-webkit-appearance": "none",
          margin: 0,
        },
        "&::-webkit-inner-spin-button": {
          "-webkit-appearance": "none",
          margin: 0,
        },
      },
    },
    MuiInputLabel: {
      filled: {
        color: "#d1c811",
        ".MuiFormControl-root:hover &:not($focused)": {
          color: "#ffff00",
        },
        "&$focused": {
          color: "#ffff00",
        },
      },
      outlined: {
        color: "#ffff00",
        "&$disabled": {
          color: "#d1c811",
        },
        ".MuiFormControl-root:hover &:not($focused)": {
          color: "#ffff00",
        },
        "&$focused": {
          color: "#ffff00",
        },
      },
    },
    MuiTab: {
      root: {
        "& .MuiTab-wrapper": {
          color: "#ffff00",
        },
        color: "#ffff00",
        backgroundColor: "#000",
        borderTopRightRadius: "1rem",
        borderTopLeftRadius: "1rem",
        "&$selected": {
          border: "1px solid #ffff00",
          backgroundColor: "#000",
          color: "#ffff00",
          borderTopRightRadius: "1rem",
          borderTopLeftRadius: "1rem",
          opacity: 1,
        },
      },
    },
    MuiLink: {
      root: {
        "&$focusVisible": {
          backgroundColor: "#000",
        },
      },
    },
  },
  props: {
    MuiLink: {
      underline: "none",
    },
    MuiSvgIcon: {
      htmlColor: "#ffff00",
    },
  },
};

const FONT_SIZE = 14;
const MENU = 18;
const MAIN_TITLE = 35;
const TITLE = 30;
const TEXT = 18;
const TEXT2 = 20;
const HEADER = 24;
const HEADER_LARGE = 60;
const FOOTER = 14;
const FOOTER_MOBILE = 12;
const LEFT_MENU_LABEL = 16;
const HINT_TEXT = 10;
const REDIRECT_CARD_TITLE = 22;

export const fontSizeNormal = {
  typography: {
    fontSize: FONT_SIZE,
    menu: MENU,
    mainTitle: MAIN_TITLE,
    title: TITLE,
    text: TEXT,
    text2: TEXT2,
    header: HEADER,
    headerLarge: HEADER_LARGE,
    footer: FOOTER,
    footerMobile: FOOTER_MOBILE,
    leftMenuLabeL: LEFT_MENU_LABEL,
    hintText: HINT_TEXT,
    redirectCardTitle: REDIRECT_CARD_TITLE,
    underline: "16px",
  },
};

export const fontSizeMedium = {
  typography: {
    fontSize: FONT_SIZE * 1.14,
    menu: MENU * 1.14,
    mainTitle: MAIN_TITLE * 1.14,
    title: TITLE * 1.14,
    text: TEXT * 1.14,
    text2: TEXT2 * 1.14,
    header: HEADER * 1.14,
    headerLarge: HEADER_LARGE * 1.14,
    footer: FOOTER * 1.14,
    footerMobile: FOOTER_MOBILE * 1.14,
    leftMenuLabeL: LEFT_MENU_LABEL * 1.14,
    hintText: HINT_TEXT * 1.14,
    redirectCardTitle: REDIRECT_CARD_TITLE * 1.14,
    underline: "15px",
  },
};

export const fontSizeLarge = {
  typography: {
    fontSize: FONT_SIZE * 1.29,
    menu: MENU * 1.29,
    mainTitle: MAIN_TITLE * 1.29,
    title: TITLE * 1.29,
    text: TEXT * 1.29,
    text2: TEXT2 * 1.29,
    header: HEADER * 1.29,
    headerLarge: HEADER_LARGE * 1.29,
    footer: FOOTER * 1.29,
    footerMobile: FOOTER_MOBILE * 1.29,
    leftMenuLabeL: LEFT_MENU_LABEL * 1.29,
    hintText: HINT_TEXT * 1.29,
    redirectCardTitle: REDIRECT_CARD_TITLE * 1.29,
    underline: "13px",
  },
};
