import React, { useEffect, useState } from "react";
import Can from "../../components/can";
import { Redirect } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Box, Container } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import * as classnames from "classnames";
import {
  fetchOrganizationTerms,
  removeTerm,
  uploadOrganizationTerm,
} from "../../redux/organization/actions";
import { connect, useDispatch } from "react-redux";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import { makeStyles } from "@material-ui/core/styles";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { Paper } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Tooltip from "@material-ui/core/Tooltip";
import { ConfirmDialog } from "../../components/confirm-dialog";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const NO_ID_TO_REMOVE = -1;

const OrganizationTerms = ({
  organization,
  terms,
  fetchTerms,
  removeTerm,
  history,
}) => {
  const [termName, setTermName] = useState("");
  const [termPath, setTermPath] = useState("");
  const [termFile, setTermFile] = useState();

  const [idToRemove, setIdToRemove] = useState(NO_ID_TO_REMOVE);

  const [formRef, setFormRef] = useState();

  const dispatch = useDispatch();

  const [busy, setBusy] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    if (organization) {
      fetchTerms(organization.id);
    }
  }, [organization]);

  const handleUploadedFiles = (files) => {
    if (files.length > 0) {
      const file = files[0];

      let reader = new FileReader();

      reader.onloadend = () => {
        setTermFile(file);
        setTermPath(reader.result);
      };

      reader.readAsDataURL(file);
    }
  };

  const askToRemove = (formId) => {
    setIdToRemove(formId);
  };

  const remove = (id) => {
    removeTerm(id);
    // fetchTerms(organization.id);
  };

  const submit = async (e) => {
    e.preventDefault();

    if (termName && termFile) {
      setBusy(true);

      await dispatch(
        uploadOrganizationTerm({
          termFile,
          name: termName,
          active: true,
        })
      );
      formRef.reset();
      setTermName(null);
      setTermFile(null);
      fetchTerms(organization.id);
    }
  };

  const cancel = (e) => {
    e.preventDefault();

    history.push("/profile");
  };

  const page = () => (
    <Container style={{ padding: "12px" }}>
      <Grid container>
        <Grid item xs={12}>
          <Box>
            <Typography variant="h6" gutterBottom>
              Zarządzanie regulaminami
            </Typography>
          </Box>
        </Grid>

        <hr />

        <Grid item xs={12} sm={3}>
          <form
            ref={(el) => setFormRef(el)}
            onSubmit={submit}
            noValidate
            autoComplete="off"
          >
            <Typography variant="subtitle1" component="h2">
              Dodaj regulamin (plik formacie PDF)
            </Typography>

            <Box mt={2}>
              <TextField
                required
                label="Nazwa regulaminu"
                variant="outlined"
                type="text"
                value={termName}
                fullWidth
                onChange={(e) => setTermName(e.target.value)}
              />
            </Box>
            <Box mt={3}>
              <TextField
                required
                label="Wgraj regulamin"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                type="file"
                fullWidth
                onChange={({ target: { files } }) => {
                  handleUploadedFiles(files);
                }}
              />
            </Box>
            <Box mt={1} display="flex">
              <Button variant={"contained"} fullWidth onClick={cancel}>
                Anuluj
              </Button>
              <Button
                className={classnames({ "is-loading": busy })}
                type="submit"
                variant={"contained"}
                disabled={
                  !(termFile !== null && termName !== null && termName !== "")
                }
                fullWidth
                style={{
                  backgroundColor: !(
                    termFile !== null &&
                    termName !== null &&
                    termName !== ""
                  )
                    ? ""
                    : "blue",
                  color: !(
                    termFile !== null &&
                    termName !== null &&
                    termName !== ""
                  )
                    ? ""
                    : "white",
                }}
              >
                Dodaj regulamin
              </Button>
            </Box>
          </form>
        </Grid>
        <hr />
        <Grid item xs={12}>
          <Typography variant="subtitle1" component="h1">
            Lista aktualnych regulaminów
          </Typography>
          <br />
          {idToRemove !== NO_ID_TO_REMOVE && (
            <ConfirmDialog
              text={"Czy na pewno usunąć ten element? "}
              yesAction={() => {
                remove(idToRemove);
                setIdToRemove(NO_ID_TO_REMOVE);
              }}
              noAction={() => setIdToRemove(NO_ID_TO_REMOVE)}
            />
          )}
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell align="right">Lp.</TableCell>
                  <TableCell align="right">Nazwa</TableCell>
                  <TableCell align="right">Opcje</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {terms.map((term, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {index + 1}.
                    </TableCell>
                    <TableCell align="right">
                      {term.name}{" "}
                      <a
                        href={`/org-media/files${term.link}`}
                        target={"_blank"}
                      >
                        (podgląd)
                      </a>
                    </TableCell>
                    <TableCell align="right">
                      <Tooltip placement="top" title={"Usuń regulamin"}>
                        <span>
                          <IconButton
                            onClick={() => askToRemove(term.id)}
                            size="small"
                          >
                            <DeleteIcon />
                          </IconButton>
                        </span>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Container>
  );

  const redirect = () => <Redirect to="/login" />;

  return <Can permission="terms-page:view" ok={page} not={redirect} />;
};

const mapStateToProps = (state) => ({
  terms: state.terms,
  organization: state.organization,
});

const mapDispatchToProps = (dispatch) => ({
  fetchTerms: (id) => dispatch(fetchOrganizationTerms(id)),
  removeTerm: (termId) => dispatch(removeTerm(termId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationTerms);
