import {
  ACCEPT_ORDER_ITEM_CODE,
  DONE,
  FAILED,
  START,
  UPDATE_ORDER_IN_PROGRESS,
  SET_TEST_CODE,
} from "../../constants";
import { getAxiosInstance } from "../common";

export const FETCH_ORDERS_IN_PROGRESS = "FETCH_ORDERS_IN_PROGRESS";
export const FETCH_HAS_ANY_ORDERS = "FETCH_HAS_ANY_ORDERS";

export const fetchOrdersInProgress = ({
  scope,
  page,
  pageSize,
  sort,
}) => async (dispatch) => {
  dispatch({ type: FETCH_ORDERS_IN_PROGRESS + START });
  try {
    // const result = await getAxiosInstance().get("/api/orders", {
    const result = await getAxiosInstance().get("/api/v2/orders", {
      params: {
        scope,
        page,
        ...(pageSize ? { pageSize } : {}),
        ...(sort ? { sort } : {}),
      },
    });
    dispatch({
      type: FETCH_ORDERS_IN_PROGRESS + DONE,
      payload: result.data,
    });

    return result.data;
  } catch (payload) {
    dispatch({ type: FETCH_ORDERS_IN_PROGRESS + FAILED, payload });
    return false;
  }
};

export const fetchHasAnyOrders = () => async (dispatch) => {
  dispatch({ type: FETCH_HAS_ANY_ORDERS + START });
  try {
    const result = await getAxiosInstance().get(
      "/api/v2/orders/has-any-orders"
    );
    dispatch({
      type: FETCH_HAS_ANY_ORDERS + DONE,
      payload: result.data.hasAnyOrders,
    });

    return result.data;
  } catch (payload) {
    dispatch({ type: FETCH_HAS_ANY_ORDERS + FAILED, payload });
    return false;
  }
};

export const updateOrder = (payload, id) => async (dispatch) => {
  dispatch({ type: UPDATE_ORDER_IN_PROGRESS + START, id });
  try {
    const result = await getAxiosInstance().put(`/api/orders/${id}`, payload);
    dispatch({ type: UPDATE_ORDER_IN_PROGRESS + DONE, payload: result.data });
    return result.data;
  } catch (e) {
    dispatch({ type: UPDATE_ORDER_IN_PROGRESS + FAILED, id });
    return null;
  }
};

export const acceptOrderItemCode = (orderId, payload) => async (dispatch) => {
  dispatch({ type: ACCEPT_ORDER_ITEM_CODE + START });
  try {
    const result = await getAxiosInstance().put(
      `/api/orders/${orderId}/accept-code`,
      payload
    );
    dispatch({ type: ACCEPT_ORDER_ITEM_CODE + DONE, payload: result.data });
    return result.data;
  } catch (e) {
    dispatch({ type: ACCEPT_ORDER_ITEM_CODE + FAILED, orderId });
    return null;
  }
};

export const setTestCode = ({ scheduledFormId, code }) => async (dispatch) => {
  dispatch({ type: SET_TEST_CODE + START });
  try {
    const result = await getAxiosInstance().patch(
      `/api/forms/scheduled/${scheduledFormId}/${code}`
    );
    dispatch({ type: SET_TEST_CODE + DONE, payload: result.data });
    return result.data;
  } catch (e) {
    dispatch({ type: SET_TEST_CODE + FAILED, e });
    return null;
  }
};
