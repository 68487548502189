import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchDataAsCSV,
  fetchFilledFormsExtended,
  fetchForm,
  fetchFormData,
  fetchFormEditions,
} from "../../redux/actions";
import { getValueRenderer } from "../../value-renderers";
import Select from "@material-ui/core/Select";
import s from "./index.module.css";
import { Box } from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import { makeStyles } from "@material-ui/core/styles";
import Datepicker, { registerLocale } from "react-datepicker";
import pl from "date-fns/locale/pl";
import * as classnames from "classnames";
import Can from "../can";
import format from "date-fns/format";
import { CreateFilter } from "../form-filters";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import { NavLink } from "react-router-dom";

const useStyles = makeStyles({
  table: {
    width: "auto",
    tableLayout: "auto",
  },
});

registerLocale("pl", pl);

export const FilledFormData = ({ match: { params }, history }) => {
  const dispatch = useDispatch();

  const formId = parseInt(params.testId, 10);
  const filledFormsExtended = useSelector((s) => s.filledFormsExtended[formId]);
  const formData = useSelector((s) => s.formData);
  const [columns, setColumns] = useState([]);
  const [dataConfig, setDataConfig] = useState({});
  const [editions, setEditions] = useState([]);
  const [selectedEdition, setSelectedEdition] = useState();
  const [sinceDateFilter, setSinceDateFilter] = useState();
  const [untilDateFilter, setUntilDateFilter] = useState();
  const [selectedUserFilling, setSelectedUserFilling] = useState("wszyscy");
  const [filters, setFilters] = useState([]);
  const orgForms = useSelector((s) => s.orgForms);
  const [form, setForm] = useState();
  const [dictionaries, setDictionaries] = useState([]);

  const userLoggedIn = useSelector((s) => s.loggedIn);
  const [sortFieldId, setSortFieldId] = useState("createdAt");
  const [sortOrder, setSortOrder] = useState("desc");

  const fetchEditions = async (formId) => {
    const data = await dispatch(fetchFormEditions(formId));
    setEditions(data);
  };

  useEffect(() => {
    if (selectedEdition) {
      if (params.testId !== selectedEdition) {
        history.push(`/testdata/all/${selectedEdition}`);
      }
    }
  }, [selectedEdition]);

  useEffect(() => {
    if (!userLoggedIn) {
      history.push(`/login`);
    }
    const currentlySelected = editions.find((d) => d.id === formId);
    if (currentlySelected) {
      setSelectedEdition(currentlySelected.id);
    }
  }, [formData, editions]);

  useEffect(() => {
    if (!filledFormsExtended) {
      dispatch(fetchFilledFormsExtended(formId));
      fetchEditions(formId);
    }
  }, [formId]);

  useEffect(() => {
    if (sortFieldId) {
      dispatch(fetchFilledFormsExtended(formId, sortFieldId, sortOrder));
    }
  }, [formId, sortFieldId, sortOrder]);

  useEffect(() => {
    if (filledFormsExtended) {
      const allCells = filledFormsExtended.reduce((all, current) => {
        return [...all, ...current.items];
      }, []);

      let columns = allCells
        .filter(
          ({ id, label }, index, all) =>
            all.findIndex((x) => x.id === id) === index
        )
        .map(({ id, label, dataType }) => ({ id, label, dataType }));

      columns = columns.filter((column) => {
        if (column.label) {
          return column;
        }
      });

      const columnsExt = [
        ...columns,
        {
          id: "createdBy",
          label: "Utworzony przez",
        },
        {
          id: "createdAt",
          label: "Utworzony dnia",
        },
      ];
      setColumns(columnsExt);
      setFilters(
        columns.map(({ id, dataType }) => {
          if (dataType === "DATE" || dataType === "TIME") {
            return {
              id: id,
              value: { since: "", until: "", value: "" },
              dataType: dataType,
            };
          } else {
            return { id: id, value: "", dataType: dataType };
          }
        })
      );
    }
  }, [filledFormsExtended]);

  useEffect(() => {
    if (formId) {
      fetchForm(formId);
    }
  }, [orgForms, fetchForm]);

  useEffect(() => {
    const f = orgForms.find(({ id }) => id === formId);
    if (f) {
      setForm(f);
    }
  }, [orgForms]);

  useEffect(() => {
    if (form && form.layoutId) {
      dispatch(fetchFormData(form.layoutId));
    }
  }, [form, fetchFormData]);

  useEffect(() => {
    const loToDicValues = () => {
      let dic = [];
      formData.layoutElementObject.children.map((c) => {
        c.children.map((c) => {
          if (c.dataType === "DICTIONARY") {
            dic.push({
              id: c.id,
              dictionaryValues: c.configuration.dictionary.dictionaryValues,
            });
          }
        });
      });
      return dic;
    };

    if (formData) {
      setDictionaries(loToDicValues());
    }
  }, [formData]);

  const getCSV = async (e) => {
    e.preventDefault();

    const data = await dispatch(fetchDataAsCSV(formId));

    const element = document.createElement("a");
    const file = new Blob([data], { type: "text/csv" });
    element.href = URL.createObjectURL(file);
    element.download = "data.csv";
    document.body.appendChild(element);
    element.click();
  };
  const data = (filledFormsExtended || []).map(({ items, ...row }) => {
    const baseValues = {
      createdBy: {
        id: "createdBy",
        label: "createdBy",
        value: row.createdByFullName || row.createdBy,
      },
      createdAt: {
        id: "createdAt",
        label: "createdAt",
        value: format(new Date(row.createdAt), "yyyy-MM-dd HH:mm"),
      },
    };

    const values = {
      ...row,
      values: {
        ...baseValues,
        ...items.reduce((obj, current) => {
          return {
            ...obj,
            [current.id]: current,
          };
        }, {}),
      },
    };
    return values;
  });

  const fulfillDateFilter = (createdAt) => {
    let fillDate = new Date(createdAt);
    fillDate = format(fillDate, "yyyy/MM/dd");
    let until;
    let since;
    let result = true;
    if (sinceDateFilter !== undefined && untilDateFilter !== undefined) {
      since = format(sinceDateFilter, "yyyy/MM/dd");
      until = format(untilDateFilter, "yyyy/MM/dd");

      fillDate <= until && fillDate >= since
        ? (result = true)
        : (result = false);
    } else if (sinceDateFilter === undefined && untilDateFilter !== undefined) {
      until = format(untilDateFilter, "yyyy/MM/dd");
      fillDate <= until ? (result = true) : (result = false);
    } else if (sinceDateFilter !== undefined && untilDateFilter === undefined) {
      since = format(sinceDateFilter, "yyyy/MM/dd");
      fillDate >= since ? (result = true) : (result = false);
    }
    return result;
  };

  const fulfillUserFilter = (createdBy) => {
    let result;
    if (selectedUserFilling === "wszyscy") result = true;
    else {
      selectedUserFilling === createdBy ? (result = true) : (result = false);
    }
    return result;
  };

  const usersFilling = [
    ...new Set(
      data.map((data) => {
        return data.createdBy;
      })
    ),
  ];

  const handleFilterChange = (data) => {
    setFilters(
      filters.map((filter) => {
        if (filter.id === data.id) {
          filter = {
            ...filter,
            value: data.value,
          };
        }
        return filter;
      })
    );
  };

  const fulfillColumnFilters = (values) => {
    let result = true;
    filters.map((filter) => {
      if (filter.dataType === "DATE" || filter.dataType === "TIME") {
        if (
          filter.value.value !== "" &&
          !values[filter.id].value.includes(filter.value.value)
        ) {
          result = false;
        }
        if (
          filter.value.since !== "" &&
          filter.value.until !== "" &&
          (values[filter.id].value < filter.value.since ||
            values[filter.id].value > filter.value.until)
        ) {
          result = false;
        } else if (
          filter.value.since === "" &&
          filter.value.until !== "" &&
          values[filter.id].value > filter.value.until
        ) {
          result = false;
        } else if (
          filter.value.since !== "" &&
          filter.value.until === "" &&
          values[filter.id].value < filter.value.since
        ) {
          result = false;
        }
      } else if (filter.dataType === "DICTIONARY") {
        console.log(
          "filter.value: " + filter.value + "value: " + values[filter.id].value
        );
        if (
          filter.value.length !== 0 &&
          !filter.value.find((f) => f === values[filter.id].value)
        ) {
          result = false;
        }
      } else {
        if (
          filter.value !== "" &&
          !values[filter.id].value.includes(filter.value)
        ) {
          result = false;
        }
      }
    });
    return result;
  };

  const classes = useStyles();

  return (
    <Box>
      {editions.length > 1 && (
        <div>
          Wybrany formularz występował w kilku wersjach. Wybierz tę, której
          wyniki chcesz zobaczyć:{" "}
          <Select
            native
            value={selectedEdition ? selectedEdition : ""}
            onChange={({ target: { value } }) => setSelectedEdition(value)}
          >
            {editions.map((edition) => (
              <option key={edition.id} value={edition.id}>
                {edition.name} (v.{edition.edition})
              </option>
            ))}
          </Select>
        </div>
      )}

      <a href="#" onClick={getCSV}>
        Pobierz CSV
      </a>
      <br />

      <div>
        <table className="table">
          <tbody>
            <tr>
              <td>Data wypełnienia od:</td>
              <td>
                <Datepicker
                  dateFormat="yyyy-MM-dd"
                  locale={pl}
                  className={classnames("input")}
                  selected={sinceDateFilter}
                  value={sinceDateFilter}
                  onChange={(value) => setSinceDateFilter(value)}
                />
              </td>
              <td>do</td>
              <td>
                <Datepicker
                  dateFormat="yyyy-MM-dd"
                  locale={pl}
                  className={classnames("input")}
                  selected={untilDateFilter}
                  value={untilDateFilter}
                  onChange={(value) => setUntilDateFilter(value)}
                />
              </td>
              <Can
                permission="users-filled-tests:view"
                ok={() => (
                  <td>
                    Wypełniający:&nbsp;
                    <Select
                      //native
                      value={selectedUserFilling ? selectedUserFilling : ""}
                      onChange={({ target: { value } }) =>
                        setSelectedUserFilling(value)
                      }
                    >
                      <option key="0" value="wszyscy">
                        wszyscy
                      </option>
                      {usersFilling.map((createdBy) => (
                        <option key={createdBy} value={createdBy}>
                          {createdBy}
                        </option>
                      ))}
                    </Select>
                  </td>
                )}
              />
            </tr>
          </tbody>
        </table>
      </div>

      <TableContainer>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className={s.abc} />
              {columns.map(({ id, label, dataType }) => (
                <TableCell align="right" className={s.abc} key={id}>
                  {label}
                  <CreateFilter
                    id={id}
                    dataType={dataType}
                    onChange={handleFilterChange}
                    dictionaries={dictionaries}
                  />
                </TableCell>
              ))}
            </TableRow>
            <TableRow>
              <TableCell className={s.abc} />
              {columns.map(({ id, label }) => (
                <TableCell
                  className={s.abc}
                  key={id}
                  sortDirection={sortFieldId === id ? sortOrder : false}
                >
                  <TableSortLabel
                    active={sortFieldId === id}
                    direction={sortFieldId === id ? sortOrder : "asc"}
                    onClick={() => {
                      const isAsc = sortFieldId === id && sortOrder === "asc";
                      setSortOrder(isAsc ? "desc" : "asc");
                      setSortFieldId(id);
                    }}
                  >
                    {label}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.map(
                ({ values, id, color, text, createdBy, createdAt }) =>
                  fulfillDateFilter(createdAt) &&
                  fulfillUserFilter(createdBy) &&
                  fulfillColumnFilters(values) && (
                    <TableRow key={id}>
                      <TableCell>
                        {getValueRenderer("x-formstatus")({ text, color })}
                        <NavLink
                          to={{
                            pathname: `/testdata/${formId}`,
                            values: values,
                          }}
                        >
                          szczegóły
                        </NavLink>
                      </TableCell>
                      {columns.map(({ id, dataType }) => {
                        const value = values[id];
                        const hasValue = value !== null && value !== undefined;

                        return (
                          <TableCell align="right" key={id}>
                            {hasValue && getValueRenderer(dataType)(value)}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  )
              )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
